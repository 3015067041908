/* eslint-disable @typescript-eslint/no-explicit-any */

import { GridProps, Text } from '@chakra-ui/react';
import { GridInputWrapper } from '@dornach/ziegler-ui-kit';
import { useQuery } from '@tanstack/react-query';
import { UseFormReturn } from 'react-hook-form';
import CustomMultiSelect from '../custom-select';

interface Props<T> {
  label: string;
  name: string;
  placeholder?: string;
  queryKey?: string;
  testId: string;
  form?: UseFormReturn;
  isRequired?: boolean;
  isMulti?: boolean;
  selectRef?: UseFormReturn;
  getOptionLabel: (data: any) => any;
  queryFn?: () => Promise<T[] | false>;
  dataset?: T[];
  arg?: object;
  emptyLabel?: string;
  onChangeFilter?: (data: T) => void;
}

export const SelectDataStrapi = <T,>({
  form,
  label,
  placeholder,
  name,
  queryKey,
  queryFn,
  getOptionLabel,
  isRequired = false,
  testId,
  isMulti = false,
  selectRef,
  dataset,
  arg,
  emptyLabel,
  onChangeFilter,
}: Props<T> & GridProps) => {
  let options;

  options = useQuery({
    queryKey: [queryKey],
    queryFn,
    staleTime: Infinity,
    enabled: !dataset,
  }).data;

  if (dataset) {
    options = dataset;
  }

  const watchField = form.watch(name);

  const values = [];
  if (watchField && watchField.length > 0 && options) {
    for (const iterator of watchField) {
      values.push(
        options.find((option) => {
          if (
            (Number.isInteger(option.id) && option.id === parseInt(iterator.id, 10)) ||
            option.id === iterator.id
          ) {
            return option;
          }

          if (option.localizations?.data) {
            for (const localization of option.localizations.data) {
              if (
                (Number.isInteger(localization.id) &&
                  localization.id === parseInt(iterator.id, 10)) ||
                localization.id === iterator.id
              ) {
                return option;
              }
            }
          }
          return false;
        }),
      );
    }
  } else if (watchField && options) {
    values.push(
      options.find((option) => {
        if (
          (Number.isInteger(option.id) && option.id === parseInt(watchField.id, 10)) ||
          option.id === watchField.id
        ) {
          return option;
        }
        const optionData = option.localizations?.data || option.attributes?.localizations?.data;
        if (optionData) {
          for (const localization of optionData) {
            if (
              (Number.isInteger(localization.id) &&
                localization.id === parseInt(watchField.id, 10)) ||
              localization.id === watchField.id
            ) {
              return option;
            }
          }
        }
        return false;
      }),
    );
  }

  const customMultiSelectArgs: any = {
    name,
    isMulti,
    selectRef: form.register(name),
    getOptionLabel,
    getOptionValue: (option) => option.id,
    placeholder,
    options,
    value: values,
  };

  if (onChangeFilter) {
    customMultiSelectArgs.onChange = onChangeFilter;
  }

  return (
    <GridInputWrapper
      name={label}
      error={form.formState?.errors?.[name]}
      isRequired={isRequired}
      arg={arg}
      testId={testId ? `${testId}Label` : undefined}
    >
      {form.formState?.errors?.[name] && <Text color='red.500'>{emptyLabel}</Text>}
      {options && (
        <CustomMultiSelect
          required={isRequired}
          control={form.control}
          testId={testId ? `${testId}Input` : undefined}
          arg={{ ...customMultiSelectArgs }}
        />
      )}
    </GridInputWrapper>
  );
};
