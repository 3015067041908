import { theme as oldTheme } from '@dornach/ziegler-ui-kit';

const components = {
  ...oldTheme.components,
  Input: {
    ...oldTheme.components.Input,
    defaultProps: {
      variant: 'ZGreen',
      size: 'lg',
    },
  },
  Select: {
    ...oldTheme.components.Select,
    defaultProps: {
      variant: 'ZGreen',
      size: 'lg',
    },
  },
  Menu: {
    ...oldTheme.components.Menu,

    baseStyle: {
      ...oldTheme.components.Menu.baseStyle,
      item: {
        ...oldTheme.components.Menu.baseStyle.item,
        px: 4,
        lineHeight: 1.2,
        height: 12,
        color: 'ZSGreen.700',
        borderRadius: 0,
        fontSize: '16px',
        fontWeight: 500,
        _hover: { bgColor: 'ZLGreen.50' },
      },
    },
  },
  Button: {
    ...oldTheme.components.Button,
    baseStyle: {
      ...oldTheme.components.Button.baseStyle,
      _disabled: { opacity: '0.4' },
      _hover: {
        _disabled: {
          cursor: 'auto',
        },
        bgColor: {},
      },
    },
    defaultProps: {
      variant: 'ZGreenSolid',
      size: 'lg',
    },
    variants: {
      ...oldTheme.components.Button.variants,

      ZGreenSolid: {
        ...oldTheme.components.Button.variants.ZGreenSolid,
        _hover: {
          bgColor: 'ZLGreen.800',
          _disabled: {
            bgColor: 'ZSGreen.700',
          },
        },
      },

      ZGreenSecondary: {
        color: 'ZSGreen.700',
        bgColor: 'ZOGreen.100',
        _hover: {
          bgColor: 'ZOGreen.200',
        },
        _active: {
          bgColor: 'ZOGreen.300',
        },
      },
      buttonMenuDropDown: {
        px: 6,
        color: 'ZLGreen.600',
        bgColor: 'transparent',
        mx: 2,
        borderRadius: 0,
        borderY: '3px solid transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        fontSize: '16px',
        _after: {
          content: "''",
          position: 'absolute',
          bottom: '0',
          background: 'ZLGreen.600',
          left: 0,
          height: '3px',
          mb: '-20px',
          width: '100%',
          transition: 'transform .2s',
        },
        _hover: { color: 'ZLGreen.600', bgColor: 'none', _after: { transform: 'scaleX(1)' } },
        _focus: {
          color: 'ZLGreen.600',
          bgColor: 'none',
        },
      },
      ZGreenMenuButton: {
        ...oldTheme.components.Button.variants.ZGreenLink,
        mx: 2,
        borderRadius: 0,
        fontSize: '16px',
        a: {
          borderY: '3px solid transparent',
          display: 'inline-block',
          px: 4,
          py: 8,
          position: 'relative',
          _after: {
            content: "''",
            position: 'absolute',
            bottom: '0',
            background: 'ZLGreen.600',
            left: 0,
            height: '3px',
            width: '100%',
            transform: 'scaleX(0)',
            transition: 'transform .2s',
          },
          _hover: { _after: { transform: 'scaleX(1)' } },
        },
        '.active': {
          _after: { transform: 'scaleX(1)' },
        },
      },
      buttonMenu: {
        px: 4,
        color: 'ZSGreen.700',
        bgColor: 'transparent',
        w: '100%',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 500,
        _hover: { bgColor: 'ZLGreen.50' },
      },
      loginButton: {
        ...oldTheme.components.Button.variants.ZGreenGhost,
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.54)',
        border: 'none',
        boxShadow: 'md',
        _hover: {
          bgColor: 'transparent',
        },
      },
      zieglerButton: {
        color: 'brandColors.brandYellow',
        bgColor: 'brandColors.brandGreen',
        _hover: {
          bgColor: 'ZLGreen.700',
        },
      },
    },
  },
};

export default components;
