import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';

import { store } from './redux';
import theme from './theme/index';
import './i18n';
import './index.css';
import App from './App';
import { SpinnerScreen } from './components-common/spinner-screen';

const queryClient = new QueryClient();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Suspense fallback={<SpinnerScreen arg={{ h: '100vh', mt: 0 }} />}>
              <App />
            </Suspense>
          </BrowserRouter>
        </QueryClientProvider>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
);
