import { theme as oldTheme } from '@dornach/ziegler-ui-kit';
import { colors } from './colors';
import components from './components';
import layerStyles from './layer-styles';
import styles from './styles';
import textStyles from './text-styles';

const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
  '3xl': '120em',
};

const theme = {
  ...oldTheme,
  colors,
  breakpoints,
  textStyles,
  layerStyles,
  components,
  styles,
  fonts: {
    heading: "'Lato', sans-serif",
    body: "'Lato', sans-serif",
  },
};

export const reactSelectTheme = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    fontSize: '18px',
    fontWeight: 500,
    opacity: state.isDisabled ? 0.4 : 1,
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '48px',
    fontWeight: 500,
    borderColor: state.isFocused
      ? 'var(--chakra-colors-ZLGreen-400)'
      : 'var(--chakra-colors-inputGray-400)',
    boxShadow: state.isFocused && '0px 0px 0px 2px #81D785',
    backgroundColor: '#ffffff',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100000,
  }),
  placeholder: (provided) => ({
    ...provided,
    opacity: 0.5,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    '&:hover': {
      color: state.isFocused ? '#fff' : 'inherit',
    },
    color: state.isFocused ? 'var(--chakra-colors-ZSGreen-700)' : 'var(--chakra-colors-gray-600)',
    backgroundColor: state.isFocused ? '#f1f5f9' : 'inherit',
  }),
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isFocused
        ? 'var(--chakra-colors-ZSGreen-700)'
        : 'var(--chakra-colors-inputGray-700)',
      backgroundColor: state.isFocused ? 'var(--chakra-colors-ZLGreen-50)' : 'inherit',
      fontWeight: state.isSelected ? 'bold' : 'inherit',
      ':active': {
        backgroundColor: 'var(--chakra-colors-ZLGreen-100)',
      },
    };
  },
};

export default theme;
