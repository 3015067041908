import React from 'react';

import { BoxProps, Center, Spinner } from '@chakra-ui/react';

interface Props {
  arg?: BoxProps;
}

export const SpinnerScreen = ({ arg }: Props) => {
  return (
    <Center mt={6} minH='60vh' w='100%' {...arg}>
      <Spinner
        emptyColor='gray.200'
        thickness='4px'
        speed='0.6s'
        color='ZLGreen.600'
        h='100px'
        w='100px'
      />
    </Center>
  );
};
