import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { If } from '@dornach/ziegler-ui-kit';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { EditorState, convertFromRaw } from 'draft-js';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { fetchComments, fetchLikes } from '../../api';
import { closeSingleTopic, fetchSingleTopic, updateTopic } from '../../api/forum';
import { ReactComponent as EditIcon } from '../../assets/icons/icon_edit.svg';
import { strapiDataParser, strapiDataParserSingleWithMultRelations } from '../../common/strapi';
import { Comments } from '../../components-common/comments/comments-view';
import { GoBack } from '../../components-common/go-back';
import { Helmet } from '../../components-common/helmet';
import { ErrorHandleWrapper, IfElse } from '../../components-common/if';
import { DefaultModal } from '../../components-common/modals';
import { ScrollToAnchor } from '../../components-common/scroll-to-anchor';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import {
  COMMENTS_SORT_LIKED,
  COMMENTS_SORT_NEWEST,
  COMMENTS_SORT_OLDEST,
  DATE_FORMAT_HOURS,
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  HORIZONTAL_PADDING,
  PAGINATION_HEIGHT,
  PAGINATION_POSITION,
  PATH_FORUM,
  RESOURCE_NAME_COMMENT,
  RESOURCE_NAME_FORUM_TOPIC,
} from '../../constants';
import { parsedContent } from '../../helpers/contentHelper';
import { getRelativeDate } from '../../helpers/dateHelper';
import { UseApiParams } from '../../helpers/hooks/use-api-params';
import { parseLanguageCode } from '../../helpers/languageHelper';
import { setErrorAction } from '../../redux';
import { ReduxState } from '../../redux/state';
import { IQuestionForumAllData } from '../../types';
import { SingleComment } from '../comments/single-comment';
import { CreateTopicModal } from './create-topic-modal';

export const SingleTopicView = () => {
  const commentsRef = useRef(null);
  const { topicId } = useParams();
  const dispatch = useDispatch();
  const apiParams = UseApiParams();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state: ReduxState) => state.auth);
  const [singleTopic, setSingleTopic] = useState(null);
  const [comments, setComments] = useState([]);
  const [mainComment, setMainComment] = useState(null);
  const [editCandidate, setEditCandidate] = useState(null);
  const [closingTopic, setClosingTopic] = useState(0);
  const [isClosing, setIsClosing] = useState(false);
  const editorRef = useRef(null);
  const [scrollToId, setScrollToId] = useState(null);
  const {
    isOpen: isOpenCloseTopic,
    onOpen: onOpenCloseTopic,
    onClose: onCloseCloseTopic,
  } = useDisclosure();
  const {
    isOpen: isOpenEditTopic,
    onOpen: onOpenEditTopic,
    onClose: onCloseEditTopic,
  } = useDisclosure();

  const fetchTopics = () => {
    fetchSingleTopic(apiParams, {
      errorCallback: (value) => dispatch(setErrorAction(value)),
      id: topicId,
    }).then(({ data }: AxiosResponse<IQuestionForumAllData>) => {
      const parsedData = strapiDataParserSingleWithMultRelations(data, [
        'users_permissions_user',
        'categories',
      ]);

      const mainCommentObject = {
        content: parsedData.content,
        createdAt: parsedData.createdAt,
        thread_of: null,
        users_permissions_user: parsedData.users_permissions_user,
        id: parsedData.id,
      };

      setMainComment(mainCommentObject);

      setSingleTopic(parsedData);
      getComments(parsedData.id);
    });
  };

  const closeTopic = (event) => {
    setIsClosing(true);
    event.preventDefault();
    closeSingleTopic(apiParams, {
      errorCallback: (value) => dispatch(setErrorAction(value)),
      id: closingTopic,
    }).then(() => {
      setIsClosing(false);
      onCloseCloseTopic();
      fetchTopics();
    });
  };

  const getComments = async (topicIdForComment, scrollTo = null) => {
    const filters = {
      resource: RESOURCE_NAME_FORUM_TOPIC,
      resource_id: topicIdForComment,
    };

    const fields = ['id', 'thread_of', 'createdAt', 'updatedAt', 'content'];

    const populate = {
      users_permissions_user: {
        fields: ['imageUrl', 'name', 'surname', 'blocked', 'id'],
        populate: { ekspertise: { fields: ['expertiseName'] } },
      },
    };

    let sort: object | string = { createdAt: 'DESC' };

    const userSortPref = JSON.parse(localStorage.getItem('commentSort'));
    if (userSortPref && userSortPref.id) {
      switch (userSortPref.id) {
        case COMMENTS_SORT_NEWEST:
          sort = { createdAt: 'DESC' };
          break;

        case COMMENTS_SORT_OLDEST:
          sort = { createdAt: 'ASC' };
          break;

        case COMMENTS_SORT_LIKED:
          sort = 'MOST_LIKED';
          break;

        default:
          sort = { createdAt: 'DESC' };
          break;
      }
    }

    fetchComments(
      apiParams.jwt,
      apiParams.toast,
      apiParams.navigate,
      filters,
      fields,
      populate,
      sort,
    ).then((fetchCommentsResult) => {
      const parsedResult = strapiDataParser(fetchCommentsResult, ['users_permissions_user']);
      if (parsedResult && parsedResult.length > 0) {
        setComments([...parsedResult]);
        if (scrollTo) {
          setScrollToId(`${RESOURCE_NAME_COMMENT}${scrollTo}`);
        }
      }
    });
  };

  const getLikesForComments = async (commentId) => {
    const filters = {
      resource: { RESOURCE_NAME_COMMENT },
      resource_id: commentId,
    };

    const fields = ['id'];

    const populate = {
      users_permissions_user: {
        fields: ['name', 'surname', 'id'],
      },
    };

    fetchLikes(apiParams.jwt, apiParams.toast, apiParams.navigate, filters, fields, populate).then(
      (fetchLikeResult) => {
        const parsedResult = strapiDataParser(fetchLikeResult);
        if (parsedResult?.[0]) {
          return parsedResult[0];
        }
        return [];
      },
    );
  };

  useEffect(() => {
    fetchTopics();
    const queryParams = new URLSearchParams(location.hash.replace('#', ''));
    if (queryParams.has(RESOURCE_NAME_FORUM_TOPIC)) {
      setScrollToId(`${RESOURCE_NAME_FORUM_TOPIC}${queryParams.get(RESOURCE_NAME_FORUM_TOPIC)}`);
    } else if (queryParams.has(RESOURCE_NAME_COMMENT)) {
      setScrollToId(`${RESOURCE_NAME_COMMENT}${queryParams.get(RESOURCE_NAME_COMMENT)}`);
    }
  }, [apiParams.jwt, topicId]);

  let htmlBody = '';
  if (singleTopic?.content) {
    htmlBody = parsedContent(singleTopic.content);
  }

  const toggleEditCandidateContent = (comment) => {
    if (editCandidate) {
      setEditCandidate(null);
    } else {
      setEditCandidate({
        id: comment.id,
        content: EditorState.createWithContent(convertFromRaw(JSON.parse(comment.content))),
      });
    }
  };

  const onEditedCommentSave = (comment) => {
    const newData = {
      id: comment.id,
      data: {
        content: editorRef.current.getDBFormatContent(editCandidate.content),
      },
    };

    updateTopic(apiParams, newData).then(() => {
      setEditCandidate(null);
      editorRef.current?.setUploadedImages([]);
      editorRef.current?.setEditorState(EditorState.createEmpty());
      fetchTopics();
    });
  };

  let activityDate = singleTopic?.createdAt;
  let activityLabel = t('knowledge-base.created');

  if (singleTopic?.last_activity) {
    activityDate = singleTopic?.last_activity;
  }

  let topicClosed = false;
  let alertTopicClosed;
  if (singleTopic?.closed) {
    topicClosed = true;

    alertTopicClosed = (
      <Alert status='warning' mb={4} rounded='md'>
        <AlertIcon />
        {t('forum.topic-closed')}
      </Alert>
    );
  }

  if (singleTopic?.last_activity_type) {
    switch (singleTopic.last_activity_type) {
      case 'created':
        activityLabel = t('knowledge-base.created');
        break;

      case 'modified':
        activityLabel = t('knowledge-base.modified');
        break;

      case 'replied':
        activityLabel = t('forum.replied');
        break;

      default:
        break;
    }
  }

  const lang = parseLanguageCode(i18n.language);

  const topicCategories = singleTopic?.categories.map((category) => {
    for (let i = 0; i < category.localizations?.data?.length; i++) {
      const localeCategory = category.localizations?.data[i];
      if (localeCategory.attributes.locale === lang) {
        return {
          title: localeCategory.attributes?.title,
          color: localeCategory.attributes?.color,
          locale: localeCategory.attributes?.locale,
          id: localeCategory.id,
        };
      }
    }
    return category;
  });

  return (
    <div>
      <ErrorHandleWrapper>
        <ScrollToAnchor />
        <IfElse condition={!singleTopic}>
          <SpinnerScreen />
          {singleTopic && (
            <>
              <Helmet title={t('title.single-news', { singleNewsTitle: singleTopic.title })} />
              <Grid
                style={{ gridArea: 'header' }}
                templateAreas={`"title"`}
                gridTemplateColumns={`1fr`}
                h='100%'
                columnGap={FILTER_MENU_GAP_COL}
                position={'sticky'}
                top={PAGINATION_POSITION}
                paddingY={FILTER_MENU_GAP_ROW}
                bgColor={'white'}
                zIndex={98}
              >
                <GridItem area={'title'} alignSelf={'center'}>
                  <HStack minH={PAGINATION_HEIGHT} alignItems={'center'}>
                    <GoBack backText={t('nav.forum')} fallback={PATH_FORUM} />
                    <ChevronRightIcon color='gray.500' ml={2} />
                    <Text color={'gray.600'} variant='lg'>
                      {singleTopic.title}
                    </Text>
                    <Spacer />
                    {mainComment?.users_permissions_user?.id === user.id && (
                      <Button
                        variant='ZGreenSolid'
                        leftIcon={<EditIcon />}
                        size='md'
                        onClick={onOpenEditTopic}
                        data-testid='editTopicButton'
                      >
                        {t('forum.edit-topic')}
                      </Button>
                    )}
                  </HStack>
                </GridItem>
              </Grid>
              <Box w='80%' ml='auto' mr='auto'>
                <Box mx={8}>
                  <HStack pe={HORIZONTAL_PADDING} gap={4} mb={2}>
                    <HStack title={format(new Date(singleTopic.createdAt), DATE_FORMAT_HOURS)}>
                      <Text variant='sm' color='gray.550'>
                        {t('knowledge-base.created')}
                      </Text>
                      <Text variant='sm'>
                        {getRelativeDate(singleTopic.createdAt, user.locale)}
                      </Text>
                    </HStack>
                    <HStack
                      title={format(
                        new Date(singleTopic.modified ?? singleTopic.createdAt),
                        DATE_FORMAT_HOURS,
                      )}
                    >
                      <Text variant='sm' color='gray.550'>
                        {t('knowledge-base.modified')}
                      </Text>
                      <Text variant='sm'>
                        {getRelativeDate(
                          singleTopic.modified ?? singleTopic.createdAt,
                          user.locale,
                        )}
                      </Text>
                    </HStack>
                    <HStack
                      title={`${activityLabel} ${format(
                        new Date(activityDate),
                        DATE_FORMAT_HOURS,
                      )}`}
                    >
                      <Text variant='sm' color='gray.550'>
                        {t('forum.activity')}
                      </Text>
                      <Text variant='sm'>{getRelativeDate(activityDate, user.locale)}</Text>
                    </HStack>
                  </HStack>
                  <HStack pe={HORIZONTAL_PADDING} gap={4}>
                    {topicCategories.length > 0 &&
                      topicCategories.map((category) => (
                        <Link
                          key={category.id}
                          to={`${PATH_FORUM}?${encodeURI(`category[0][id]=${category.id}`)}`}
                        >
                          <HStack
                            gap={1}
                            display={{ base: 'none', lg: 'flex' }}
                            py={1}
                            px={2}
                            borderRadius={4}
                            bg={'ZLGreen.100'}
                            color={'ZSGreen.700'}
                            _hover={{ color: 'ZSGreen.800', bg: 'ZLGreen.300' }}
                            cursor={'pointer'}
                          >
                            <Box w='20px' h='20px' bg={category.color} borderRadius='50%' />

                            <Text variant={'sm'}>{category.title}</Text>
                          </HStack>
                        </Link>
                      ))}
                  </HStack>

                  <SingleComment
                    jwt={apiParams.jwt}
                    toast={apiParams.toast}
                    navigate={apiParams.navigate}
                    comment={mainComment}
                    loadLikes={getLikesForComments}
                    userId={user.id}
                    editCandidate={editCandidate}
                    ref={editorRef}
                    editable={!topicClosed}
                    closeable={!topicClosed}
                    repliable={!topicClosed}
                    imageable
                    resource={RESOURCE_NAME_FORUM_TOPIC}
                    setEditCandidate={setEditCandidate}
                    onEditedCommentSave={onEditedCommentSave}
                    toggleEditCandidateContent={toggleEditCandidateContent}
                    setThreadOf={() => {
                      commentsRef.current?.scrollTo();
                      commentsRef.current?.setFocus();
                    }}
                    setCloseCandidate={setClosingTopic}
                    setIsCloseModalOpen={onOpenCloseTopic}
                    scrollToId={scrollToId}
                    withBorder
                  />

                  {alertTopicClosed}

                  <Comments
                    comments={comments}
                    reload={getComments}
                    loadLikes={getLikesForComments}
                    resource={RESOURCE_NAME_FORUM_TOPIC}
                    resourceId={topicId}
                    ref={commentsRef}
                    editable={!topicClosed}
                    repliable={!topicClosed}
                    sortable
                    imageable
                    yourCommentLabel={t('forum.your-answer')}
                    commentsLabel={t('forum.answers', { nb: comments.length })}
                    commentsCollapsed={false}
                    replyEditorPlacement='bottom'
                    scrollToId={scrollToId}
                  />
                </Box>
              </Box>
            </>
          )}
        </IfElse>

        <Box textAlign='right' m={5}>
          <If condition={isOpenCloseTopic}>
            <DefaultModal
              isOpen={isOpenCloseTopic}
              onClose={onCloseCloseTopic}
              handleSubmit={closeTopic}
              titleModal={t('forum.close-topic')}
              applyButton={t('forum.close-topic')}
              closeButton={t('common.cancel')}
              isLoading={isClosing}
            >
              <Text>{t('forum.close-topic-confirm')}</Text>
            </DefaultModal>
          </If>
          <If condition={isOpenEditTopic}>
            <CreateTopicModal
              fetchTopics={fetchTopics}
              isOpen={isOpenEditTopic}
              onOpen={onOpenEditTopic}
              onClose={onCloseEditTopic}
              topic={singleTopic}
            />
          </If>
        </Box>
      </ErrorHandleWrapper>
    </div>
  );
};
