export const PATH_NEWS = '/news';
export const PATH_USERS = '/users';
export const PATH_OLD_USERS_DIRECTORY = '/users/old-directory';
export const PATH_RELATIVE_OLD_USERS_DIRECTORY = 'old-directory';
export const PATH_KNOWLEDGE_BASE = '/knowledge-base';
export const PATH_WELCOME = '/welcome';
export const PATH_ADMIN = '/admin';
export const PATH_PROFILE = '/profile';
export const PATH_AUTH_CALLBACK = '/auth/callback';
export const PATH_BRANCHES = '/branches';
export const PATH_ROOT = '/';
export const PATH_ADVERTS = '/adverts';
export const PATH_ABOUT_ZIEGLER = '/about-ziegler';
export const PATH_FORUM = '/forum';
export const PATH_FORUM_POST = '/forum/post';
export const PATH_TERMS = '/terms-of-use';
export const PATH_PRIVACY = '/privacy-policy';
export const PATH_UNSUBSCRIBE = '/unsubscribe';
export const PATH_COOKIES = '/cookies';
export const PATH_COMPLIANCE = '/compliance';
export const PATH_UNAUTHORIZED = '/unauthorized';
export const PATH_BUSINESS_APPS = '/business-apps';
export const PATH_DORNACH_PAGE = '/dornach';
export const PATH_RELATIVE_DORNACH_PAGE = 'dornach';
export const PATH_ZIEGLER_ONE = 'zieglerone';
export const PATH_NOT_FOUND = '/not-found';
export const PATH_FORBIDDEN = '/forbidden';
export const PATH_MAINTENANCE = '/maintenance';
export const PATH_LOGIN = '/login';
export const PATH_ORG_CHART = '/users/org-chart';

export const PATH_LOGIN_GOOGLE = 'google';
export const PATH_LOGIN_MICROSOFT = 'microsoft';
export const PATH_LOGIN_MICROSOFT_ZA = 'microsoft_za';

export const PROVIDER_GOOGLE = 'google';
export const PROVIDER_MICROSOFT = 'microsoft';
export const PROVIDER_MICROSOFT_ZA = 'microsoft_za';
