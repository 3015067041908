import { Box, Grid, GridItem, HStack, Heading, Progress, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Helmet } from '../../components-common/helmet';
import { ProfileForm } from '../../components-common/profile-form';
import {
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  FILTER_MENU_WIDTH,
  PAGINATION_POSITION,
} from '../../constants';

export const GetProfileProgession = (user) => {
  if (!user) {
    return 100;
  }
  const requestedFields = [
    'name',
    'surname',
    'email',
    'company',
    'country',
    'branchList',
    'department',
    'ekspertise',
    ['workPhone', 'mobilePhone'],
  ];
  let completed = 0;
  requestedFields.forEach((field) => {
    if (Array.isArray(field)) {
      for (const val of field) {
        if (user[val]) {
          completed++;
          break;
        }
      }
    } else if (user[field]) {
      completed++;
    }
  });

  return Math.round((completed / requestedFields.length) * 100);
};

export const ProfileView = () => {
  const [isSpinner, setIsSpinner] = useState(true);
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  return (
    <Box>
      <Helmet title={t('title.profile')} />
      <Grid
        templateAreas={`"header header"
          "main main"
          `}
        gridTemplateRows={'auto 1fr'}
        gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
        h='100%'
        columnGap={FILTER_MENU_GAP_COL}
      >
        <Grid
          style={{ gridArea: 'header' }}
          templateAreas={`"title progression"`}
          gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
          h='100%'
          columnGap={FILTER_MENU_GAP_COL}
          position={'sticky'}
          top={PAGINATION_POSITION}
          paddingY={FILTER_MENU_GAP_ROW}
          bgColor={'white'}
          zIndex={98}
        >
          <GridItem area={'title'} alignSelf={'center'} w='100%'>
            <Heading variant='mdLarge' color='brandColors.brandGreen'>
              {t('nav.edit-profile')}
            </Heading>
          </GridItem>
          <GridItem area={'progression'}>
            <HStack minH={10} justifyContent={'center'}>
              <Text>{t('common.profile-completeness')}</Text>
              <Progress
                width='200px'
                value={GetProfileProgession(user)}
                borderRadius={10}
                colorScheme='ZLGreen'
              />
              <Text>{GetProfileProgession(user)}%</Text>
            </HStack>
          </GridItem>
        </Grid>

        <GridItem area={'main'}>
          <ProfileForm isLoading={isSpinner} setIsLoading={setIsSpinner} />
        </GridItem>
      </Grid>
    </Box>
  );
};
