export const Link = ({ entityKey, contentState, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a style={{ color: '#208779', fontWeight: 'bold' }} href={url} target='_blank' rel='noreferrer'>
      {children}
    </a>
  );
};

export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};
