import React from 'react';
import { useTranslation } from 'react-i18next';
import { Center, Heading, Text } from '@chakra-ui/react';
import { ReactComponent as MaintenanceIcon } from '../../assets/icons/maintenance.svg';
import { Helmet } from '../../components-common/helmet';
import { Header } from '../header';

export const MaintenanceScreen = ({ shouldShowHeader }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('title.unauthorized')} />
      {shouldShowHeader && <Header shouldShowTermsAndPrivacy={false} />}
      <Center
        justifyContent='flex-start'
        flexDirection='column'
        background='ZLGreen.50'
        height='calc(100vh - 81px)'
      >
        <Center
          layerStyle='container'
          w='45vw'
          pt={16}
          pb={12}
          flexDirection='column'
          mt='20vh'
          borderRadius={8}
        >
          <Heading mb={4} mt={6} fontSize='24px' color='brandColors.brandGreen'>
            {t('maintenance.title')}
          </Heading>
          <MaintenanceIcon />
          <Text mt={4}>{t('maintenance.content')}</Text>
        </Center>
      </Center>
    </>
  );
};
