import { useEffect, useState } from 'react';
import { Input, InputRightElement, InputGroup } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  onClear,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (value !== initialValue) {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);

      return () => clearTimeout(timeout);
    }
  }, [value]);

  return (
    <InputGroup size='xs'>
      <Input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
      <InputRightElement cursor='pointer' onClick={onClear}>
        <CloseIcon w={2} h={2} color='red.500' />
      </InputRightElement>
    </InputGroup>
  );
};
