import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Center,
  Img,
  Heading,
  useToast,
  Flex,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  Box,
  AccordionPanel,
  Text,
  Progress,
  Link,
} from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/logo/Ziegler_team.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google.svg';
import { ReactComponent as MicrosoftIcon } from '../../assets/icons/microsoft.svg';
import { Helmet } from '../../components-common/helmet';
import {
  PATH_GOOGLE_CONNECT,
  PATH_MICROSOFT_CONNECT,
  PATH_MICROSOFT_ZA_CONNECT,
} from '../../api/utils/path';
import { SpinnerScreen } from '../../components-common/spinner-screen';

export const LoginScreen = ({ handleAuthCallback, isAuthLoading = false }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  if (isAuthLoading) {
    return (
      <>
        <Outlet context={{ toast, navigate, handleAuthCallback }} />
        <SpinnerScreen arg={{ h: '100vh', mt: 0 }} />
      </>
    );
  }

  let links;
  if (isLoading) {
    links = (
      <>
        <Box py={4} ps={10} pe={4} _hover={{ bg: 'ZLGreen.100' }}>
          <Text variant='smLarge'>{t('login.china')}</Text>
        </Box>
        <Box py={4} ps={10} pe={4} _hover={{ bg: 'ZLGreen.100' }}>
          <Text variant='smLarge'>{t('login.za')}</Text>
        </Box>
      </>
    );
  } else {
    links = (
      <>
        <Link
          href={`${PATH_MICROSOFT_CONNECT}`}
          style={{ textDecoration: 'none' }}
          onClick={() => {
            setIsLoading(true);
          }}
        >
          <Box py={4} ps={10} pe={4} _hover={{ bg: 'ZLGreen.100' }}>
            <Text variant='smLarge'>{t('login.china')}</Text>
          </Box>
        </Link>
        <Link
          href={`${PATH_MICROSOFT_ZA_CONNECT}`}
          style={{ textDecoration: 'none' }}
          onClick={() => {
            setIsLoading(true);
          }}
        >
          <Box py={4} ps={10} pe={4} _hover={{ bg: 'ZLGreen.100' }}>
            <Text variant='smLarge'>{t('login.za')}</Text>
          </Box>
        </Link>
      </>
    );
  }

  return (
    <>
      <Helmet title={t('title.login')} />
      <Center w='100%' minH='calc(100vh - 81px)' background='ZLGreen.50'>
        <Center
          layerStyle='container'
          p={0}
          w='100%'
          maxW='700px'
          flexDirection='column'
          mb={8}
          borderRadius={8}
          position='relative'
        >
          <Progress
            size='xs'
            isIndeterminate
            w='100%'
            colorScheme='green'
            position='absolute'
            top='4px'
            display={isLoading === false ? 'none' : 'block'}
          />
          <Center pt={16} pb={12} flexDirection='column'>
            <Img src={logo} display='block' w='200px' mb={10} />
            <Heading mb={2} fontSize='24px' color='brandColors.brandGreen'>
              {t('login.sign-in')}
            </Heading>

            <Flex flexDirection='column' maxWidth='100%'>
              <a href={`${PATH_GOOGLE_CONNECT}`}>
                <Button
                  isDisabled={isLoading}
                  loadingText={t('login.google-sign-in-button')}
                  leftIcon={<GoogleIcon />}
                  w='100%'
                  justifyContent='start'
                  variant='loginButton'
                  mt={6}
                  onClick={() => {
                    setIsLoading(true);
                  }}
                >
                  {t('login.google-sign-in-button')}
                </Button>
              </a>

              <Accordion allowToggle>
                <AccordionItem border='0'>
                  <AccordionButton padding='0' _hover={{ bg: 'transparent' }}>
                    <Button
                      isDisabled={isLoading}
                      loadingText={t('login.microsoft-sign-in-button')}
                      leftIcon={<MicrosoftIcon />}
                      rightIcon={<AccordionIcon />}
                      variant='loginButton'
                      mt={6}
                      w='100%'
                      justifyContent='start'
                    >
                      {t('login.microsoft-sign-in-button')}
                    </Button>
                  </AccordionButton>
                  <AccordionPanel padding='0' border='1px' borderColor='gray.200' bg='white'>
                    <Flex flexDirection='column' maxWidth='100%'>
                      <Heading variant='smLarge' ps={6} my={2}>
                        {t('login.choose-country')}
                      </Heading>

                      {links}
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          </Center>
        </Center>
      </Center>
    </>
  );
};
