import React, { FunctionComponent } from 'react';
import { BaseEdge, EdgeProps } from 'reactflow';

export const CustomEdge: FunctionComponent<EdgeProps> = (props: EdgeProps) => {
  const { sourceX, sourceY, targetX, targetY, markerEnd } = props;
  const edgePath = `M ${sourceX} ${sourceY} L ${sourceX} ${sourceY + 125} L ${targetX} ${
    sourceY + 125
  } ${targetX} ${targetY}`;
  return (
    <BaseEdge
      {...props}
      path={edgePath}
      markerEnd={markerEnd}
      style={{
        strokeWidth: 2,
        stroke: props?.data?.isActive
          ? 'var(--chakra-colors-ZLGreen-400)'
          : 'var(--chakra-colors-gray-300)',
      }}
    />
  );
};
