import { Center, Flex, Grid, GridItem, Heading, Text, useToast } from '@chakra-ui/react';
import { filter, get, identity, map, pickBy } from 'lodash';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { parseLanguageCode } from '../../helpers/languageHelper';

import { fetchAllCountries, fetchAllNews } from '../../api';
import { FiltersProvider } from '../../components-common/filters/filters-provider';
import { Helmet } from '../../components-common/helmet';
import { ErrorHandleWrapper } from '../../components-common/if';
import { InputAndLabel } from '../../components-common/inputs/input-label';
import { SelectDataStrapi } from '../../components-common/inputs/select-data-strapi';
import { Pagination } from '../../components-common/pagination';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import {
  DEFAULT_ENTRIES_PER_PAGE,
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  FILTER_MENU_WIDTH,
  MAIN_CONTENT_POSITION,
  PAGINATION_POSITION,
  PATH_NEWS,
} from '../../constants';
import { setErrorAction } from '../../redux';
import { SingleNews } from './single-news';

export const NewsListView = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const filterRef = useRef(null);
  const { t, i18n } = useTranslation();
  const { user, jwt, locales } = useSelector((state) => state.auth);
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [newsList, setNewsList] = useState([]);
  const [isSpinnerList, setIsSpinnerList] = useState(false);
  const [waitForDefaultValues, setWaitForDefaultValues] = useState(true);
  const urlPagination = pickBy(
    {
      page: queryParams.pagination?.page && +queryParams.pagination.page,
      pageSize: queryParams.pagination?.pageSize && +queryParams.pagination.pageSize,
      pageCount: queryParams.pagination?.pageCount && +queryParams.pagination.pageCount,
      total: queryParams.pagination?.total && +queryParams.pagination.total,
    },
    identity,
  );
  const [pagination, setPagination] = useState(urlPagination || { page: 1 });
  const [defaultFilters, setDefaultFilters] = useState();
  const [entriesPerPage, setEntriesPerPage] = useState(
    urlPagination?.pageSize || DEFAULT_ENTRIES_PER_PAGE,
  );

  useEffect(() => {
    if (!user || !locales) {
      return;
    }

    const countryGlobal = { countryName: 'Global', id: 'global' };
    const defaultCountries = [user.country, countryGlobal];

    const defaultLanguage = locales.find((language) => language.code === user.locale);
    setDefaultFilters({ country: defaultCountries, language: defaultLanguage });
    setWaitForDefaultValues(false);
  }, [user, locales]);

  const fetchNews = async (currentPage, pageSize = entriesPerPage) => {
    const filters = filterRef.current?.getFilterValues();

    const keywordFilter = filters?.keyword;
    const countryFilter = filters?.country;

    const locale =
      parseLanguageCode(get(filters, ['language', 'code'])) || parseLanguageCode(i18n.language);

    let keyword;
    let country;

    if (keywordFilter) {
      keyword = {
        $or: [
          {
            body_raw: {
              $containsi: keywordFilter,
            },
          },
          {
            title: {
              $containsi: keywordFilter,
            },
          },
        ],
      };
    }

    const countries = filter(
      map(countryFilter, (c) => c?.id !== 'global' && c?.id),
      (element) => !!element,
    );
    const containsGlobal = map(countryFilter, (c) => c?.id).indexOf('global') >= 0;

    if (countries.length > 0 && containsGlobal) {
      country = {
        $or: [{ countries: { id: { $in: countries } } }, { countries: { id: { $null: true } } }],
      };
    } else if (countries.length === 0 && containsGlobal) {
      country = { countries: { id: { $null: true } } };
    } else if (countries.length > 0 && !containsGlobal) {
      country = { countries: { id: { $in: countries } } };
    }

    const whereData = {
      $and: [{ publishedAt: { $notNull: true } }, { locale }, keyword, country],
    };

    const response = await fetchAllNews(
      jwt,
      toast,
      navigate,
      (value) => dispatch(setErrorAction(value)),
      whereData,
      pageSize,
      currentPage,
    );

    setPagination(response.data.pagination);
    setNewsList(response.data.results, ['header']);
  };

  const search = async () => {
    setIsSpinnerList(true);
    await fetchNews(1);
    setIsSpinnerList(false);
  };

  let newsListBody;
  if (isSpinnerList) {
    newsListBody = <SpinnerScreen />;
  } else if (newsList.length > 0) {
    newsListBody = (
      <Flex flexDirection='column' w='100%' data-testid='newsNewsList'>
        {newsList.map((news, i) => (
          <SingleNews
            idx={i}
            key={news.id}
            news={news}
            linkTo={`${PATH_NEWS}/${news.id}`}
            jwt={jwt}
            navigate={navigate}
            toast={toast}
            w='100%'
          />
        ))}
      </Flex>
    );
  } else {
    newsListBody = (
      <Center height='100%'>
        <Text variant='2xl'>{t('common.no-result')}</Text>
      </Center>
    );
  }

  return (
    <div>
      <ErrorHandleWrapper>
        <Helmet title={t('title.news')} />

        <Grid
          templateAreas={`"header header"
                    "nav main"
                    `}
          gridTemplateRows={'auto 1fr'}
          gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
          h='100%'
          columnGap={FILTER_MENU_GAP_COL}
        >
          <Grid
            style={{ gridArea: 'header' }}
            templateAreas={`"title pagination"`}
            gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
            h='100%'
            columnGap={FILTER_MENU_GAP_COL}
            position={'sticky'}
            top={PAGINATION_POSITION}
            paddingY={FILTER_MENU_GAP_ROW}
            bgColor={'white'}
            zIndex={98}
          >
            <GridItem area={'title'} alignSelf={'center'}>
              <Heading variant='mdLarge' color='brandColors.brandGreen'>
                {t('nav.news')}
              </Heading>
            </GridItem>
            <GridItem area={'pagination'}>
              <Pagination
                entriesPerPage={entriesPerPage}
                setEntriesPerPage={setEntriesPerPage}
                activePage={pagination?.page}
                changePageCallback={async (val, entriesPerPageValue) => {
                  const searchParams = qs.stringify({
                    ...queryParams,
                    pagination: { ...pagination, page: val, pageSize: entriesPerPage },
                  });
                  navigate(`${PATH_NEWS}?${searchParams}`);
                  await fetchNews(val, entriesPerPageValue);
                }}
                pages={pagination?.pageCount}
                testId='newsTop'
              />
            </GridItem>
          </Grid>
          <GridItem
            area={'nav'}
            position={'sticky'}
            top={MAIN_CONTENT_POSITION}
            h={`calc(100vh - ${MAIN_CONTENT_POSITION})`}
            overflowY={'auto'}
          >
            <FiltersProvider
              handleSearch={search}
              ref={filterRef}
              defaultValues={defaultFilters}
              isLoading={waitForDefaultValues}
            >
              <InputAndLabel label={t('common.keyword')} name='keyword' testId='newsKeyword' />
              <SelectDataStrapi
                testId='newsCountry'
                isMulti
                label={t('common.country')}
                placeholder={t('common.select-country')}
                name='country'
                queryKey='newsCountry'
                queryFn={() => fetchAllCountries({ jwt, toast, navigate })}
                getOptionLabel={(data) => {
                  return data.countryName;
                }}
              />
              <SelectDataStrapi
                testId='newsLanguage'
                label={t('common.language')}
                placeholder={t('common.select-language')}
                name={'language'}
                dataset={locales}
                getOptionLabel={(data) => data.name}
              />
            </FiltersProvider>
          </GridItem>
          <GridItem area={'main'} pb={12}>
            {newsListBody}
          </GridItem>
        </Grid>
      </ErrorHandleWrapper>
    </div>
  );
};
