import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useToast } from '@chakra-ui/react';
import { fetchLocales } from './api';
import { refreshToken } from './api/auth';
import { ScrollToTop } from './components-common/scroll-to-top';
import { SpinnerScreen } from './components-common/spinner-screen/index';
import { About } from './components/about';
import { AboutView } from './components/about/about-view';
import { CategoryView } from './components/about/category-view';
import { SubCategoryView } from './components/about/sub-category-view';
import { SubSubCategoryView } from './components/about/sub-sub-category-view';
import { AdvertsListView } from './components/adverts/adverts-list-view';
import { SingleAdvertView } from './components/adverts/single-advert-view';
import { BranchesListView } from './components/branches/branches-list-view';
import { BusinessApps } from './components/business-apps/business-apps-view';
import { ComplianceView } from './components/compliance/compliance-view';
import { CookiesView } from './components/cookies/cookies-view';
import { DornachPage } from './components/dornach-page';
import { ForbiddenScreen, NotFoundScreen } from './components/errors';
import { ForumListView } from './components/forum/forum-list-view';
import { SingleTopicView } from './components/forum/single-topic-view';
import { KnowledgeBaseSubpage } from './components/knowledge-base/knowledge-base-subpage';
import { KnowledgeBaseView } from './components/knowledge-base/knowledge-base-view';
import { LoginScreen } from './components/login';
import { GoogleLogin } from './components/login/google';
import { MicrosoftLogin } from './components/login/microsoft';
import { MicrosoftZaLogin } from './components/login/microsoft_za';
import { MainScreen } from './components/main';
import { MaintenanceScreen } from './components/maintenance-screen';
import { NewsListView } from './components/news/news-list-view';
import { SingleNewsView } from './components/news/single-news-view';
import { OrgChartView } from './components/org-chart';
import { PrivacyPolicyView } from './components/privacy-policy/privacy-policy-view';
import { ProfileView } from './components/profile/profile-view';
import { PublicScreen } from './components/public';
import { TermsOfUse } from './components/tems-of-use/terms-of-use-view';
import { Unauthorized } from './components/unauthorized';
import { UnsubscribeView } from './components/unsubscribe';
import { UsersListView } from './components/users/users-list-view';
import { WelcomeView } from './components/welcome/welcome-view';
import { ZieglerOnePage } from './components/ziegler-one-page';
import {
  PATH_ABOUT_ZIEGLER,
  PATH_ADVERTS,
  PATH_BRANCHES,
  PATH_BUSINESS_APPS,
  PATH_COMPLIANCE,
  PATH_COOKIES,
  PATH_DORNACH_PAGE,
  PATH_FORBIDDEN,
  PATH_FORUM,
  PATH_KNOWLEDGE_BASE,
  PATH_LOGIN,
  PATH_LOGIN_GOOGLE,
  PATH_LOGIN_MICROSOFT,
  PATH_LOGIN_MICROSOFT_ZA,
  PATH_MAINTENANCE,
  PATH_NEWS,
  PATH_NOT_FOUND,
  PATH_ORG_CHART,
  PATH_PRIVACY,
  PATH_PROFILE,
  PATH_RELATIVE_DORNACH_PAGE,
  PATH_ROOT,
  PATH_TERMS,
  PATH_UNAUTHORIZED,
  PATH_UNSUBSCRIBE,
  PATH_USERS,
  PATH_WELCOME,
  PATH_ZIEGLER_ONE,
  ROLE_INTRANET_ADMIN,
  SILENT_REFRESH_TIMEOUT,
} from './constants';
import { hydrateAction } from './redux';

const App = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isLogged } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const authFetchedRef = useRef(false);
  const location = useLocation();
  const silentRefreshTimerRef = useRef(null);

  const handleAuthCallback = async (response) => {
    if (!response) {
      setIsLoading(false);
      return false;
    }
    dispatch(hydrateAction({ ...response, user: { ...response.user }, isLogged: true }));

    const { jwt } = response;
    const dataLocales = await fetchLocales({ jwt, toast, navigate });
    dispatch(hydrateAction({ locales: dataLocales }));

    if (!response.user.afterFirstLogin && response.user.role?.name !== ROLE_INTRANET_ADMIN) {
      navigate(PATH_WELCOME);
    } else if (isLocationLogin(location)) {
      navigate(PATH_NEWS);
    }

    silentRefreshTimerRef.current = setTimeout(silentRefresh, SILENT_REFRESH_TIMEOUT);

    setIsLoading(false);
  };

  const silentRefresh = async () => {
    await refreshToken({
      toast,
      navigate,
      callback: (response) => {
        clearTimeout(silentRefreshTimerRef.current);
        if (!response) {
          return false;
        }

        dispatch(hydrateAction({ ...response, user: { ...response.user }, isLogged: true }));

        silentRefreshTimerRef.current = setTimeout(silentRefresh, SILENT_REFRESH_TIMEOUT);
      },
    });
  };

  useEffect(() => {
    return () => clearTimeout(silentRefreshTimerRef.current);
  }, []);

  useEffect(() => {
    if (authFetchedRef.current || isLogged || !handleAuthCallback) return;
    authFetchedRef.current = true;

    const initRefresh = async () => {
      setIsLoading(true);
      await refreshToken({
        toast,
        navigate,
        callback: handleAuthCallback,
        errorCallback: () => setIsLoading(false),
      });
    };

    if (!isLogged && !isLocationLogin(location)) {
      initRefresh();
    }
  }, [handleAuthCallback, location]);

  const isLocationLogin = (currentLocation) => {
    const loginRegexp = new RegExp(`${PATH_LOGIN}/.+$`);
    if (currentLocation.pathname.match(loginRegexp)) {
      return true;
    }

    return false;
  };

  if (isLoading && !isLogged && !isLocationLogin(location)) {
    return <SpinnerScreen arg={{ h: '100vh', mt: 0 }} />;
  }

  const authProviderRoutes = (
    <Route element={<LoginScreen isAuthLoading handleAuthCallback={handleAuthCallback} />}>
      <Route index path={`${PATH_LOGIN}/${PATH_LOGIN_GOOGLE}`} element={<GoogleLogin />} />
      <Route index path={`${PATH_LOGIN}/${PATH_LOGIN_MICROSOFT}`} element={<MicrosoftLogin />} />
      <Route
        index
        path={`${PATH_LOGIN}/${PATH_LOGIN_MICROSOFT_ZA}`}
        element={<MicrosoftZaLogin />}
      />
    </Route>
  );

  if (isLogged) {
    return (
      <>
        <ScrollToTop />

        <Routes>
          {authProviderRoutes}

          <Route path='*' element={<NotFoundScreen />} />
          <Route element={<MainScreen />}>
            <Route path={PATH_ROOT}>
              <Route index element={<NewsListView />} />
            </Route>
            <Route path={PATH_NEWS}>
              <Route index element={<NewsListView />} />
              <Route path=':newsId' element={<SingleNewsView />} />
            </Route>
            <Route path={PATH_USERS} element={<UsersListView />} />
            <Route path={PATH_WELCOME} element={<WelcomeView />} />
            <Route path={PATH_PROFILE} element={<ProfileView />} />
            <Route path={PATH_KNOWLEDGE_BASE}>
              <Route index element={<KnowledgeBaseView />} />
              <Route path=':id' element={<KnowledgeBaseSubpage />} />
            </Route>
            <Route path={PATH_ABOUT_ZIEGLER} element={<About />}>
              <Route index element={<AboutView />} />
              <Route path=':categoryName' element={<CategoryView />} />
              <Route path=':categoryName/:subCategoryName' element={<SubCategoryView />} />
              <Route
                path=':categoryName/:subCategoryName/:subSubCategoryName'
                element={<SubSubCategoryView />}
              />
              <Route path={`${PATH_RELATIVE_DORNACH_PAGE}`} element={<DornachPage />} />
              <Route path={`${PATH_ZIEGLER_ONE}`} element={<ZieglerOnePage />} />
            </Route>
            <Route path={PATH_BRANCHES} element={<BranchesListView />} />
            <Route path={PATH_BUSINESS_APPS} element={<BusinessApps />} />
            <Route path={PATH_TERMS} element={<TermsOfUse />} />
            <Route path={PATH_PRIVACY} element={<PrivacyPolicyView />} />
            <Route path={PATH_UNSUBSCRIBE} element={<UnsubscribeView />} />
            <Route path={PATH_COOKIES} element={<CookiesView />} />
            <Route path={PATH_COMPLIANCE} element={<ComplianceView />} />
            <Route path={PATH_UNAUTHORIZED} element={<Unauthorized />} />
            <Route path={PATH_DORNACH_PAGE} element={<DornachPage />} />
            <Route path={PATH_NOT_FOUND} element={<NotFoundScreen />} />
            <Route path={PATH_FORBIDDEN} element={<ForbiddenScreen />} />
            <Route path={PATH_MAINTENANCE} element={<MaintenanceScreen />} />
            <Route path={PATH_FORUM}>
              <Route index element={<ForumListView />} />
              <Route path=':topicId' element={<SingleTopicView />} />
            </Route>
            <Route path={PATH_ADVERTS}>
              <Route index element={<AdvertsListView />} />
              <Route path=':advertIds' element={<SingleAdvertView />} />
            </Route>
            <Route path={PATH_ORG_CHART}>
              <Route index element={<OrgChartView />} />
            </Route>
          </Route>
        </Routes>
      </>
    );
  }

  return (
    <>
      <ScrollToTop />
      <Routes>
        {authProviderRoutes}
        <Route element={<PublicScreen />}>
          <Route index element={<LoginScreen />} />
          <Route path='*' element={<LoginScreen />} />
          <Route path={PATH_LOGIN} element={<LoginScreen />} />
          <Route path={PATH_PRIVACY} element={<PrivacyPolicyView />} />
          <Route path={PATH_TERMS} element={<TermsOfUse />} />
          <Route path={PATH_UNSUBSCRIBE} element={<UnsubscribeView />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
