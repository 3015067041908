import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Center, Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';

const InfoModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='md'>
      {isOpen && (
        <>
          <ModalOverlay />
          <ModalContent>
            <Center m={4}>
              <Text>{t('common.profile-updated-info')}</Text>
            </Center>
            <Center>
              <Button m={4} onClick={onClose}>
                {t('common.ok')}
              </Button>
            </Center>
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

export default InfoModal;
