import axios from 'axios';
import { PATH_GOOGLE_AUTH_CALLBACK } from './utils/path';

export const getHeaders = (jwt) => ({
  headers: {
    Authorization: `Bearer ${jwt}`,
  },
});

export const fetchJWT = (token) => {
  return axios({
    method: 'GET',
    url: `${PATH_GOOGLE_AUTH_CALLBACK}${token}`,
  });
};
