import React, { useEffect, useState, useRef } from 'react';
import {
  Flex,
  useToast,
  Heading,
  useDisclosure,
  ScaleFade,
  Text,
  Button,
  HStack,
  Grid,
  GridItem,
  Center,
} from '@chakra-ui/react';
import { CopyIcon, DownloadIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { map, keys, isEmpty } from 'lodash';
import * as XLSX from 'xlsx';

import {
  exportBranches,
  fetchAllBranchesList,
  fetchBranchesListRelationAndBranches,
  findCountryFilterOptions,
} from '../../api';
import { strapiDataParser } from '../../common/strapi';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import { IfElse, ErrorHandleWrapper } from '../../components-common/if';
import { Helmet } from '../../components-common/helmet';
import { setErrorAction } from '../../redux';
import {
  DEFAULT_ENTRIES_PER_PAGE,
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  FILTER_MENU_WIDTH,
  MAIN_CONTENT_POSITION,
  PAGINATION_HEIGHT,
  PAGINATION_POSITION,
} from '../../constants';
import { Pagination } from '../../components-common/pagination';
import { Table } from './table';
import { findCityFilterOptions } from '../../api/city';
import './copy-email.css';

const EmailCell = ({ email }) => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    if (isOpen === true) {
      setTimeout(onToggle, 1000);
    }
  }, [isOpen]);

  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        cursor='pointer'
        width='100%'
        position='relative'
        onClick={() => {
          navigator.clipboard.writeText(email);
          onToggle();
        }}
      >
        {email}
        <CopyIcon
          position='absolute'
          right='0'
          display='none'
          w='24px'
          h='24px'
          bgColor='#FFFFFF'
          borderRadius='6px'
          color='ZOGreen.600'
        />
      </Flex>
      <ScaleFade initialScale={0.9} in={isOpen}>
        {isOpen && (
          <Text
            p={2}
            ml={document.getElementById(`cell-email-${email}`).clientWidth - 60}
            bg='ZLGreen.100'
            rounded='md'
            color='ZLGreen.600'
            fontSize='small'
            fontWeight='bold'
          >
            {t('news.copied')}
          </Text>
        )}
      </ScaleFade>
    </Flex>
  );
};

export const BranchesListView = () => {
  const tableRef = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { jwt } = useSelector((state) => state.auth);
  const { isInError } = useSelector((state) => state.error);
  const [isSpinner, setIsSpinner] = useState(true);
  const [entriesPerPage, setEntriesPerPage] = useState(DEFAULT_ENTRIES_PER_PAGE);
  const [branchList, setBranchList] = useState([]);

  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [pagination, setPagination] = useState({ page: 1 });
  const [companyList, setCompanyList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [headers, setHeaders] = useState([]);

  const tableHeaderNames = {
    branchName: t('branches.branch'),
    country: t('branches.country'),
    company: t('branches.organization'),
    email: t('branches.email'),
    phone: t('branches.phone'),
    city: t('branches.city'),
    postcode: t('branches.post-code'),
    address: t('branches.address'),
    certificates: t('branches.certificates'),
  };

  const filterOptions = {
    company: companyList,
    country: countryList,
    city: cityList,
    certificates: certificateList,
  };

  const orderPrefixes = {
    branchName: 'branchName',
    country: 'country.countryName',
    company: 'company.companyName',
    email: 'email',
    phone: 'phone',
    city: 'city.cityName',
    postcode: 'postcode',
    address: 'address',
    certificates: 'certificates.name',
  };

  const generateHeaders = (branch, newFilterOptions = {}) => {
    const tableHeaders = map(keys(branch), (header, index) => ({
      id: index,
      name: header,
      displayName: tableHeaderNames[header],
      filterOptions: newFilterOptions[header] || filterOptions[header] || null,
      orderPrefix: orderPrefixes[header],
    }));
    if (!isEmpty(countryList)) {
      return setHeaders(tableHeaders);
    }
  };

  const parsedBranches = (data) => {
    const parsedData = strapiDataParser(data, ['company', 'city', 'country', 'certificates']);
    return map(parsedData, (b) => ({
      branchName: b.branchName,
      country: b.country.id ? { id: b.country.id, name: b.country.countryName } : null,
      company: b.company.id ? { id: b.company.id, name: b.company.companyName } : null,
      email: b.email ? { name: b.email, cellFormatter: () => <EmailCell email={b.email} /> } : null,
      phone: b.phone ? `${b.phonePrefix ? `(${b.phonePrefix}) ` : ''}${b.phone}` : '',
      city: b.city.id ? { id: b.city.id, name: b.city.cityName } : null,
      postcode: b.postcode,
      address: b.address,
      certificates: map(b.certificates, (c) => c?.name)
        ?.slice(0, -1)
        .join(', '),
    }));
  };

  useEffect(() => {
    const initBranchesList = async () => {
      await fetchBranchesListRelationAndBranches(
        jwt,
        toast,
        navigate,
        (value) => dispatch(setErrorAction(value)),
        pagination.page,
        tableRef.current?.getFilters(),
        tableRef.current?.getOrder(),
        entriesPerPage,
      )
        .then(([branchesList, dataCountry, dataCompany, dataCity, dataCertificates]) => {
          setPagination(branchesList.data.meta.pagination);
          setBranchList(parsedBranches(branchesList));
          setCountryList(map(dataCountry, (entry) => ({ id: entry.id, name: entry.countryName })));
          setCityList(map(dataCity, (entry) => ({ id: entry.id, name: entry.cityName })));
          setCompanyList(map(dataCompany, (entry) => ({ id: entry.id, name: entry.companyName })));
          setCertificateList(
            map(dataCertificates.data, (entry) => ({ id: entry.id, name: entry.attributes?.name })),
          );
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
      setIsSpinner(false);
    };
    initBranchesList();
  }, []);

  const fetchCities = async (filter) => {
    await findCityFilterOptions(jwt, toast, navigate, filter).then((cities) => {
      const parsedCities = map(cities.data, (entry) => ({
        id: entry.id,
        name: entry.attributes.cityName,
      }));
      setCityList(parsedCities);
      if (branchList?.length > 0) {
        generateHeaders(branchList[0], { city: parsedCities });
      } else {
        generateHeaders(tableHeaderNames, { city: parsedCities });
      }
    });
  };

  const fetchCountries = async (filter) => {
    await findCountryFilterOptions(jwt, toast, navigate, filter).then((countries) => {
      if (!countries) return;
      const parsedCountries = map(countries.data, (entry) => ({
        id: entry.id,
        name: entry.attributes.countryName,
      }));
      setCountryList(parsedCountries);
      if (branchList?.length > 0) {
        generateHeaders(branchList[0], { country: parsedCountries });
      } else {
        generateHeaders(tableHeaderNames, { country: parsedCountries });
      }
    });
  };

  useEffect(() => {
    const initCities = async () => {
      await fetchCities(tableRef.current?.getFilters().country);
    };
    initCities();
  }, [tableRef.current?.getFilters().country]);

  useEffect(() => {
    const initCountries = async () => {
      await fetchCountries(tableRef.current?.getFilters().city);
    };
    initCountries();
  }, [tableRef.current?.getFilters().city]);

  useEffect(() => {
    generateHeaders(branchList?.length > 0 ? branchList[0] : tableHeaderNames);
  }, [branchList, cityList]);

  const fetchBranchesList = async (page, sortOrder, filters, pageSize = entriesPerPage) => {
    const response = await fetchAllBranchesList(
      jwt,
      toast,
      navigate,
      (value) => dispatch(setErrorAction(value)),
      page,
      filters,
      sortOrder,
      pageSize,
    );
    setPagination(response.data.meta.pagination);
    setBranchList(parsedBranches(response));
  };

  const parseBranchesForXls = (data) => {
    return map(data, (b) => ({
      branchName: b.branchName,
      country: b.country?.id ? b.country.countryName : null,
      company: b.company?.id ? b.company.companyName : null,
      email: b.email ? b.email : null,
      phone: b.phone ? `${b.phonePrefix ? `(${b.phonePrefix}) ` : ''}${b.phone}` : '',
      city: b.city?.id ? b.city.cityName : null,
      postcode: b.postcode,
      address: b.address,
      certificates: map(b.certificates, (c) => c?.name)
        ?.slice(0, -1)
        .join(', '),
    }));
  };
  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'BranchList.xlsx');
  };

  let branchesListBody;
  if (isSpinner) {
    branchesListBody = <SpinnerScreen />;
  } else {
    branchesListBody = (
      <Table
        ref={tableRef}
        headers={headers}
        data={branchList}
        search={fetchBranchesList}
        pinnable={false}
        defaultLeftPinned='branchName'
        defaultOrder='branchName:asc'
        fullWidthColumns={['email', 'phone']}
      />
    );
  }

  return (
    <div>
      <ErrorHandleWrapper condition={isInError}>
        <Helmet title={t('title.branches')} />

        <Grid
          templateAreas={`"header header"
          "main main"
          `}
          gridTemplateRows={'auto 1fr'}
          gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
          h='100%'
          columnGap={FILTER_MENU_GAP_COL}
        >
          <Grid
            style={{ gridArea: 'header' }}
            templateAreas={`"title pagination"`}
            gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
            h='100%'
            columnGap={FILTER_MENU_GAP_COL}
            position={'sticky'}
            top={PAGINATION_POSITION}
            paddingY={FILTER_MENU_GAP_ROW}
            bgColor={'white'}
            zIndex={98}
          >
            <GridItem area={'title'} alignSelf={'center'} w='100%'>
              <Heading variant='mdLarge' color='brandColors.brandGreen'>
                {t('nav.branches')}
              </Heading>
            </GridItem>
            <GridItem area={'pagination'}>
              <HStack justifyContent={'space-between'}>
                <Button
                  variant='ZGreenSolid'
                  leftIcon={<DownloadIcon />}
                  size='md'
                  data-testid='branchesTopDownloadXlsx'
                  onClick={async () => {
                    const data = await exportBranches(
                      jwt,
                      toast,
                      navigate,
                      (value) => dispatch(setErrorAction(value)),
                      tableRef.current?.getFilters(),
                    );
                    downloadExcel(parseBranchesForXls(JSON.parse(data.data)));
                  }}
                >
                  {t('branches.download-xlsx')}
                </Button>
                <Pagination
                  entriesPerPage={entriesPerPage}
                  setEntriesPerPage={setEntriesPerPage}
                  activePage={pagination.page}
                  changePageCallback={(val, pageSize) =>
                    fetchBranchesList(
                      val,
                      tableRef.current?.getOrder(),
                      tableRef.current?.getFilters(),
                      pageSize,
                    )
                  }
                  pages={pagination.pageCount}
                  testId='branchesTop'
                />
              </HStack>
            </GridItem>
          </Grid>

          <GridItem area={'main'} pb={12} minH={`calc(100vh - ${MAIN_CONTENT_POSITION})`}>
            {branchesListBody}
          </GridItem>
        </Grid>
      </ErrorHandleWrapper>
    </div>
  );
};
