import axios from 'axios';
import qs from 'qs';
import { getHeaders } from './common';
import { catchError } from './utils/catch-error';
import { PATH_POSTS } from './utils/path';

const fieldsAllNews = ['title', 'publishedAt', 'publication_date'];
const populateAllNews = {
  countries: {
    select: ['countryName'],
  },
  header: {
    select: ['url', 'formats'],
  },
  users_permissions_user: {
    select: ['name', 'surname', 'imageUrl', 'blocked'],
    populate: {
      ekspertise: {
        select: ['expertiseName'],
      },
    },
  },
  createdBy: {
    select: ['username', 'firstname', 'lastname', 'email'],
  },
};

export const fetchAllNews = (
  jwt,
  toast,
  navigate,
  errorCallback,
  filters,
  pageSize,
  page,
  fields = fieldsAllNews,
  populate = populateAllNews,
) => {
  const queryRaw = {
    where: filters,
    select: fields,
    populate,
    pageSize,
    page,
    orderBy: [{ publication_date: 'DESC' }, { id: 'DESC' }],
  };

  const query = qs.stringify(queryRaw);
  errorCallback(false);
  return axios
    .get(`${PATH_POSTS}?${query}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate, errorCallback));
};

const fieldsSingleNews = ['title', 'body', 'publishedAt', 'locale', 'publication_date'];
const populateSingleNews = {
  language: {
    fields: ['languageName'],
  },
  country: {
    fields: ['countryName'],
  },
  users_permissions_user: {
    fields: ['name', 'surname', 'imageUrl', 'blocked'],
    populate: {
      ekspertise: {
        select: ['expertiseName'],
      },
    },
  },
  header: {
    fields: ['url', 'formats'],
  },
  createdBy: {
    select: ['username', 'name', 'surname', 'email'],
  },
};
export const fetchSingleNews = (
  jwt,
  toast,
  navigate,
  errorCallback,
  id,
  fields = fieldsSingleNews,
  populate = populateSingleNews,
) => {
  const queryRaw = {
    select: fields,
    populate,
  };
  errorCallback(false);
  return axios
    .get(`${PATH_POSTS}/${id}?${qs.stringify(queryRaw)}`, getHeaders(jwt))
    .catch((error) => {
      catchError(error, toast, navigate, errorCallback);
    });
};
