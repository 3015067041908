import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { ILanguage, ILanguageName } from '../types';

import { getHeaders } from './common';
import { catchError } from './utils/catch-error';
import { PATH_LANGUAGES_LIST, PATH_NEWS_NOTIF_LANGUAGES_LIST } from './utils/path';

const fieldsAllCurrencies = ['name'];
const sort = ['name:asc'];

export const fetchAllLanguagesList = (jwt, toast, navigate) =>
  axios
    .get(
      `${PATH_LANGUAGES_LIST}?${qs.stringify({
        fields: fieldsAllCurrencies,
        sort,
        populate: false,
      })}`,
      getHeaders(jwt),
    )
    .then((response: { data: AxiosResponse<ILanguageName[]> }) => response)
    .catch((error) => catchError(error, toast, navigate));

export const fetchAllNewsNotifLanguagesList = (jwt, toast, navigate) =>
  axios
    .get(
      `${PATH_NEWS_NOTIF_LANGUAGES_LIST}?${qs.stringify({
        fields: ['id', 'code', 'name'],
        sort,
        populate: false,
      })}`,
      getHeaders(jwt),
    )
    .then((response: { data: AxiosResponse<ILanguage[]> }) => response)
    .catch((error) => catchError(error, toast, navigate));
