import axios, { AxiosResponse } from 'axios';
import { IExpertiseName } from '../types';
import { getHeaders } from './common';
import { PATH_FIND_MANY_EXPERTISES } from './utils/path';
import { catchError } from './utils/catch-error';

export const customFindManyExpertises = async ({ jwt, toast, navigate }) =>
  axios
    .get(PATH_FIND_MANY_EXPERTISES, getHeaders(jwt))
    .then((response: AxiosResponse<IExpertiseName[]>) => response.data)
    .catch((error) => catchError(error, toast, navigate));
