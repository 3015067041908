const styles = {
  global: {
    'html, body': {
      bgColor: 'white',
      color: 'gray.600',
      lineHeight: 'tall',
      fontSize: 'default',
      fontFamily: "'Lato', sans-serif",
    },
    _placeholder: {
      opacity: 0.5,
    },
  },
};

export default styles;
