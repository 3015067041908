import { Avatar, AvatarProps, Image, ImageProps } from '@chakra-ui/react';
import React from 'react';

interface Props {
  user?: {
    imageUrl: string;
    blocked: boolean;
  };
  size?: string;
  args?: ImageProps & AvatarProps;
}
export const UserAvatar = ({ user, size = '48px', args }: Props) => {
  if (!user?.blocked && user?.imageUrl) {
    return (
      <Image
        {...args}
        alt=''
        src={user?.imageUrl}
        w={size}
        h={size}
        fit='cover'
        borderRadius='100%'
        data-testid='userAvatar'
      />
    );
  }
  return <Avatar {...args} w={size} h={size} data-testid='userAvatar' />;
};
