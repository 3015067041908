import {
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AddIcon } from '@chakra-ui/icons';
import qs from 'qs';

import { fetchAllTopics } from '../../api/forum';
import { Helmet } from '../../components-common/helmet';
import { ErrorHandleWrapper, If } from '../../components-common/if';
import { UseApiParams } from '../../helpers/hooks/use-api-params';

import { fetchAllForumCategories } from '../../api/forum-categories';
import { strapiDataParser } from '../../common/strapi';
import { FiltersProvider } from '../../components-common/filters/filters-provider';
import { InputAndLabel } from '../../components-common/inputs/input-label';
import { SelectDataStrapi } from '../../components-common/inputs/select-data-strapi';
import { Pagination } from '../../components-common/pagination';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import {
  DEFAULT_ENTRIES_PER_PAGE,
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  FILTER_MENU_WIDTH,
  MAIN_CONTENT_POSITION,
  PAGINATION_POSITION,
  PATH_FORUM,
} from '../../constants';
import { parseLanguageCode } from '../../helpers/languageHelper';
import { setErrorAction } from '../../redux';
import { IForumTopic, IForumTopicListQS, IPagination } from '../../types';
import { CreateTopicModal } from './create-topic-modal';
import { SingleTopic } from './single-topic';

export const ForumListView = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const apiParams = UseApiParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [topicsList, setTopicsList] = useState<IForumTopic[]>([]);
  const filterRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const queryParams: IForumTopicListQS = qs.parse(location.search, { ignoreQueryPrefix: true });
  const urlPagination: IPagination = queryParams.pagination;
  const [pagination, setPagination] = useState(urlPagination || { page: 1, pageCount: 1 });
  const [entriesPerPage, setEntriesPerPage] = useState(
    urlPagination?.pageSize || DEFAULT_ENTRIES_PER_PAGE,
  );

  const fetchTopics = async (currentPage, pageSize = entriesPerPage) => {
    const filters = filterRef.current?.getFilterValues();

    const data = await fetchAllTopics(apiParams, {
      errorCallback: (value) => dispatch(setErrorAction(value)),
      filters,
      pageSize,
      page: currentPage,
    });

    const parsedData = strapiDataParser(data, [
      'users_permissions_user',
      'categories',
      'contributors',
    ]);

    if (data !== false) {
      setPagination(data.data.meta.pagination);
      setTopicsList(parsedData);
    }
  };

  const search = async () => {
    setIsLoading(true);
    await fetchTopics(1);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!filterRef || !location.state || !location.search) {
      return;
    }

    filterRef.current?.resetFromUrl(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    );
  }, [location.state, filterRef]);

  const onOpenAddModal = () => {
    onOpen();
  };

  let forumListBody;
  if (isLoading) {
    forumListBody = <SpinnerScreen />;
  } else if (topicsList.length > 0) {
    forumListBody = (
      <Flex flexDirection='column' w='100%' data-testid='forumTopicsList'>
        <Flex alignItems={'center'} gap={4} borderBottom={'1px solid #e3e3e3'} py={2}>
          <Flex alignItems={'start'} gap={2} flexGrow={1} flexShrink={0} flexBasis={'150px'}>
            <Text color={'gray.550'} variant={'lg'}>
              {t('forum.topic')}
            </Text>
          </Flex>

          <Flex grow={0} shrink={0} basis={'75px'} justifyContent={'center'}>
            <Text color={'gray.550'} variant={'lg'}>
              {t('forum.replies')}
            </Text>
          </Flex>
          <Flex grow={0} shrink={0} basis={'75px'} justifyContent={'center'}>
            <Text color={'gray.550'} variant={'lg'}>
              {t('comments.likes')}
            </Text>
          </Flex>
          <Flex grow={0} shrink={0} basis={'100px'} justifyContent={'center'}>
            <Text color={'gray.550'} variant={'lg'}>
              {t('forum.activity')}
            </Text>
          </Flex>
        </Flex>

        {topicsList.map((topic, i) => (
          <SingleTopic key={topic.id} topic={topic} />
        ))}
      </Flex>
    );
  } else {
    forumListBody = (
      <Center height='100%'>
        <Text variant='2xl'>{t('common.no-result')}</Text>
      </Center>
    );
  }

  return (
    <div>
      <ErrorHandleWrapper>
        <Helmet title={t('title.forum')} />

        <Grid
          templateAreas={`"header header"
                    "nav main"
                    `}
          gridTemplateRows={'auto 1fr'}
          gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
          h='100%'
          columnGap={FILTER_MENU_GAP_COL}
        >
          <Grid
            style={{ gridArea: 'header' }}
            templateAreas={`"title pagination"`}
            gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
            h='100%'
            columnGap={FILTER_MENU_GAP_COL}
            position={'sticky'}
            top={PAGINATION_POSITION}
            paddingY={FILTER_MENU_GAP_ROW}
            bgColor={'white'}
            zIndex={98}
          >
            <GridItem area={'title'} alignSelf={'center'} w='100%'>
              <Heading variant='mdLarge' color='brandColors.brandGreen'>
                {t('nav.forum')}
              </Heading>
            </GridItem>
            <GridItem area={'pagination'}>
              <HStack justifyContent={'space-between'}>
                <Button
                  variant='ZGreenSolid'
                  leftIcon={<AddIcon />}
                  size='md'
                  onClick={onOpenAddModal}
                  data-testid='addNewTopicButton'
                >
                  {t('forum.create-topic')}
                </Button>
                <Pagination
                  entriesPerPage={entriesPerPage}
                  setEntriesPerPage={setEntriesPerPage}
                  activePage={pagination.page}
                  changePageCallback={async (val, entriesPerPageValue) => {
                    const searchParams = qs.stringify({
                      ...queryParams,
                      pagination: { ...pagination, page: val, pageSize: entriesPerPage },
                    });
                    navigate(`${PATH_FORUM}?${searchParams}`);
                    await fetchTopics(val, entriesPerPageValue);
                  }}
                  pages={pagination.pageCount}
                  testId='forumTop'
                />
              </HStack>
            </GridItem>
          </Grid>
          <GridItem
            area={'nav'}
            position={'sticky'}
            top={MAIN_CONTENT_POSITION}
            h={`calc(100vh - ${MAIN_CONTENT_POSITION})`}
            w='100%'
            overflowY={'auto'}
          >
            <FiltersProvider handleSearch={search} ref={filterRef}>
              <InputAndLabel label={t('common.keyword')} name='keyword' testId='forumKeyword' />
              <SelectDataStrapi
                label={t('forum.category')}
                placeholder={t('forum.category-placeholder')}
                name={'category'}
                testId='forumCategories'
                isMulti
                queryKey='forumCategories'
                queryFn={() => fetchAllForumCategories(apiParams, parseLanguageCode(i18n.language))}
                getOptionLabel={(data) => data.title}
              />
            </FiltersProvider>
          </GridItem>
          <GridItem area={'main'} pb={12}>
            {forumListBody}
          </GridItem>
        </Grid>

        <If condition={isOpen}>
          <CreateTopicModal
            fetchTopics={search}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            topic={null}
          />
        </If>
      </ErrorHandleWrapper>
    </div>
  );
};
