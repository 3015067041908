import { Avatar, Box, Image, Text, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from './user-avatar';
import { IUserNameAndJobTitle } from '../../types';

interface Props extends BoxProps {
  user: IUserNameAndJobTitle;
  hideAvatar?: boolean;
}

export const UserDataHeader = ({ user, hideAvatar, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      display='flex'
      justifyContent='flex-start'
      alignItems='center'
      flexDirection='row'
      p={4}
      {...props}
    >
      {!hideAvatar && <UserAvatar user={user} />}
      <Box>
        <Text variant='xl' ml={4} fontWeight='semibold' as='h4' data-testid='userName'>
          {user.name} {user.surname}
        </Text>
        <Text variant='xl' ml={4} as='h4' data-testid='userName'>
          {user.job_title?.name}
        </Text>
      </Box>

      {user.blocked && (
        <Text ml={1} fontSize='xs' fontStyle='italic'>
          ({t('common.non-active')})
        </Text>
      )}
    </Box>
  );
};
