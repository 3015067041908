const textStyles = {
  inputLabel: {
    fontSize: ['default'],
    lineHeight: '24px',
    color: 'gray.700',
    display: 'inline',
  },
  inputLabelRed: {
    fontSize: ['default'],
    lineHeight: '24px',
    color: 'red.500',
    display: 'inline',
  },
};

export const styleNoStroke = { color: 'gray.600', path: { fill: 'gray.600 !important' } };
export const styleNoStrokeHover = {
  color: 'ZSGreen.700',
  path: { fill: 'ZSGreen.700 !important' },
};

export const styleOutline = { color: 'gray.600', path: { stroke: 'gray.600 !important' } };
export const styleOutlineHover = {
  color: 'ZSGreen.700',
  path: { stroke: 'ZSGreen.700 !important' },
};

export const styleDefault = { color: 'gray.600', path: { stroke: 'gray.600 !important' } };
export const styleHover = {
  color: 'ZSGreen.700',
  path: { fill: 'ZSGreen.700 !important', stroke: 'ZSGreen.700 !important' },
};

export default textStyles;
