import { Box, Heading, Img, SkeletonText, Text, useToast } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { googleGetDetails } from '../../../api';
import { CustomDrawer } from '../../../components-common/custom-drawer';
import {
  DATE_FORMAT,
  THUMBNAIL_SIZE_IMAGE_BIG,
  THUMBNAIL_SIZE_IMAGE_MEDIUM,
} from '../../../constants';
import { ReduxState } from '../../../redux/state';

interface RecordProps {
  title: string;
  value: string;
  isLoading: boolean;
}

const Record: React.FC<RecordProps> = ({ title, value, isLoading }) => {
  if (!isLoading) {
    return <SkeletonText noOfLines={1} spacing='4' mt={1} />;
  }
  return (
    <Box mt={1}>
      <Text variant='sm' display='inline-block' w='40%'>
        {title}
      </Text>
      <Text
        variant='sm'
        display='inline-block'
        w='60%'
        color='gray.600'
        overflowWrap='anywhere'
        verticalAlign='top'
      >
        {value}
      </Text>
    </Box>
  );
};

interface LeftDrawerProps {
  activeId: string | undefined;
  setActiveId: (id: string | undefined) => void;
  isOpen: boolean;
  onClose: () => void;
  isSharedDrive: boolean;
}

interface DataFile {
  iconLink?: string;
  name?: string;
  thumbnailLink?: string;
  size?: string;
  owners?: { displayName: string }[];
  lastModifyingUser?: { displayName: string };
  createdTime?: string;
  description?: string;
}

export const LeftDrawer: React.FC<LeftDrawerProps> = ({
  activeId,
  setActiveId,
  isOpen,
  onClose,
  isSharedDrive,
}) => {
  const { t } = useTranslation();
  const { jwt } = useSelector((state: ReduxState) => state.auth);
  const toast = useToast();
  const navigate = useNavigate();
  const [dataFile, setDataFile] = useState<DataFile>({});

  useEffect(() => {
    const initKBDrawer = async () => {
      const response = await googleGetDetails(jwt, toast, navigate, activeId, isSharedDrive);
      setDataFile(response);
    };
    if (activeId) {
      initKBDrawer();
    }
  }, [activeId]);

  const close = () => {
    setActiveId(undefined);
    onClose();
  };

  let drawer = (
    <CustomDrawer header={<SkeletonText noOfLines={1} />} close={close} isOpen={isOpen}>
      <SkeletonText noOfLines={4} spacing='2' mt={1} />
    </CustomDrawer>
  );

  if (dataFile) {
    const header = (
      <>
        <Img h='20px' mr={1} src={dataFile.iconLink} />
        <Heading size='md' color='ZSGreen.700' ml={2} w='300px'>
          <Text
            w='100%'
            textAlign='left'
            display='-webkit-box'
            overflow='hidden'
            wordBreak='break-all'
            sx={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: '1' }}
          >
            {dataFile.name}
          </Text>
        </Heading>
      </>
    );

    drawer = (
      <CustomDrawer header={header} close={close} isOpen={isOpen}>
        <>
          {dataFile.thumbnailLink && (
            <Img
              mb={5}
              w='100%'
              src={dataFile.thumbnailLink?.replace(
                THUMBNAIL_SIZE_IMAGE_MEDIUM,
                THUMBNAIL_SIZE_IMAGE_BIG,
              )}
            />
          )}
          <Heading variant='mdSmall'>{t('knowledge-base.system-properties')}</Heading>
          <Record
            isLoading={!!dataFile.name}
            title={t('knowledge-base.name')}
            value={dataFile.name || ''}
          />
          <Record
            isLoading={!!dataFile.name}
            title={t('knowledge-base.size')}
            value={dataFile.size || ''}
          />
          <Record
            isLoading={!!dataFile.name}
            title={t('knowledge-base.owner')}
            value={(dataFile.owners && dataFile.owners[0].displayName) || ''}
          />
          <Record
            isLoading={!!dataFile.name}
            title={t('knowledge-base.modified')}
            value={dataFile.lastModifyingUser?.displayName || ''}
          />
          {dataFile.createdTime && (
            <Record
              isLoading={!!dataFile.name}
              title={t('knowledge-base.created')}
              value={format(new Date(dataFile.createdTime), DATE_FORMAT)}
            />
          )}
          {dataFile.description && (
            <Heading variant='mdSmall' mt={5}>
              {t('knowledge-base.description')}
            </Heading>
          )}
          {!dataFile.name ? (
            <SkeletonText noOfLines={1} spacing='4' mt={1} />
          ) : (
            <Text mt={1}>{dataFile.description}</Text>
          )}
        </>
      </CustomDrawer>
    );
  }

  return drawer;
};
