import { Flex, Spinner, Text, Tooltip, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addLike, countLikes, deleteLike, fetchLikes } from '../../api';
import { ReactComponent as Like } from '../../assets/icons/icon_heart_like.svg';
import { ReactComponent as NoLike } from '../../assets/icons/icon_heart_no_like.svg';
import { strapiDataParser } from '../../common/strapi';
import { IfElse } from '../../components-common/if';

export const LikesCounter = ({ resource, resourceId, countOnly = true }) => {
  const { jwt, user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [nbLikes, setNbLikes] = useState(0);
  const [counted, setCounted] = useState(false);
  const [isLikeLoading, setIsLikeLoading] = useState([]);
  const [myLike, setMyLike] = useState(false);
  const [peopleWhoLiked, setPeopleWhoLiked] = useState([]);

  useEffect(() => {
    const initLikes = async () => {
      setIsLikeLoading(true);
      if (countOnly === true) {
        countLikes(jwt, toast, navigate, resource, resourceId).then((result) => {
          setNbLikes(result.data.result);
          setCounted(true);
          setIsLikeLoading(false);
        });
      } else {
        loadLikes();
      }
    };

    initLikes();
  }, [jwt, resource, resourceId, countOnly]);

  const loadLikes = async () => {
    const filters = {
      resource,
      resource_id: resourceId,
    };

    const fields = ['id'];

    const populate = {
      users_permissions_user: {
        fields: ['name', 'surname', 'id'],
      },
    };

    fetchLikes(jwt, toast, navigate, filters, fields, populate).then((result) => {
      const parsedResult = strapiDataParser(result, ['users_permissions_user']);
      if (parsedResult) {
        setNbLikes(parsedResult.length);

        const findMyLike = parsedResult.find((like) => like.users_permissions_user?.id === user.id);
        const peopleWhoLikedResult = parsedResult
          .filter((like) => {
            if (like.users_permissions_user.id) {
              return like;
            }
            return false;
          })
          .map((like) =>
            findMyLike?.id === like.id
              ? t('comments.you')
              : `${like.users_permissions_user?.name || ''} ${
                  like.users_permissions_user?.surname || ''
                }`,
          );
        setMyLike(findMyLike);
        setPeopleWhoLiked(peopleWhoLikedResult);
      } else {
        setNbLikes(0);
      }
      setIsLikeLoading(false);
    });
  };

  const handleLike = () => {
    setIsLikeLoading(true);
    if (myLike) {
      deleteLike(jwt, toast, navigate, myLike.id, resource, resourceId).then(() => {
        loadLikes();
      });
    } else {
      addLike(jwt, toast, navigate, resource, resourceId, user.id).then(() => {
        loadLikes();
      });
    }
  };

  let counter;
  if (countOnly === true) {
    let likes;
    if (counted === true) {
      likes = (
        <Text color='gray.600' variant='sm' ml={2}>
          {nbLikes}
        </Text>
      );
    } else {
      likes = (
        <Spinner
          emptyColor='gray.200'
          thickness='2px'
          speed='0.6s'
          color='ZLGreen.600'
          h='16px'
          w='16px'
        />
      );
    }

    counter = (
      <Flex flexDirection='row' alignItems='center'>
        {nbLikes > 0 ? <Like /> : <NoLike />}
        {likes}
      </Flex>
    );
  } else {
    counter = (
      <Tooltip
        label={peopleWhoLiked.join(', ')}
        bg='white'
        color='gray.600'
        border='1px solid'
        borderColor='gray.200'
        isDisabled={peopleWhoLiked.length === 0}
      >
        <Flex
          className={`zIcon ${myLike ? 'active' : ''}`}
          flexDirection='row'
          alignItems='center'
          cursor='pointer'
          color={myLike ? 'ZLGreen.600' : 'inherit'}
          _hover={{ color: 'ZLGreen.600' }}
          onClick={() => !isLikeLoading && handleLike()}
        >
          <IfElse condition={isLikeLoading}>
            <Spinner
              emptyColor='gray.200'
              thickness='2px'
              speed='0.6s'
              color='ZLGreen.600'
              h='16px'
              w='16px'
            />
            <NoLike />
          </IfElse>
          <Text color='gray.600' variant='sm' ml={2}>
            {nbLikes || 0}
          </Text>
        </Flex>
      </Tooltip>
    );
  }

  return counter;
};
