import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Heading,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { Helmet } from '../../components-common/helmet';
import { cookiesData } from '../../helpers/cookieHelper';

export const CookiesView = () => {
  const { t } = useTranslation();

  return (
    <Flex
      alignItems='center'
      flexDirection='column'
      minHeight='100vh'
      pb='120px'
      overflowX='hidden'
    >
      <Box w='80%' mt={6}>
        <Flex flexDirection='column'>
          <Helmet title={t('title.cookies')} />
          <Heading mb={8} fontSize='30px' color='brandColors.brandGreen'>
            {t('cookies-view.heading')}
          </Heading>
          <Box alignSelf='center'>
            <Accordion allowMultiple>
              {cookiesData(t).cookieObjects.map((cookieObject, i) => (
                <AccordionItem key={i} borderColor='gray.200'>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton _hover={{ background: 'transparent' }} my={4} py={0}>
                        <Flex flexDirection='column'>
                          <Flex justifyContent='space-between' alignItems='center' mb={4}>
                            <Text fontWeight='700' fontSize='18px' color='ZSGreen.700'>
                              {cookieObject.name}
                            </Text>

                            <Text fontSize='xs' color='gray.600'>
                              {cookiesData(t).modalDetails.necessaryCookieLabel}
                            </Text>
                          </Flex>
                          {cookieObject.description && (
                            <Text align='left' mb={4}>
                              {cookieObject.description}
                            </Text>
                          )}
                          <Flex justifyContent='flex-start'>
                            <Text fontSize='14px' fontWeight='700' color='ZSGreen.700'>
                              {isExpanded
                                ? cookiesData(t).modalDetails.hideDetailsLabel
                                : cookiesData(t).modalDetails.showDetailsLabel}
                            </Text>
                            <AccordionIcon />
                          </Flex>
                        </Flex>
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <Box>
                          {cookiesData(t).cookieObjects[i].cookieList.map((description, index) => (
                            <Flex
                              flexDirection='column'
                              background='gray.100'
                              borderBottom='1px solid'
                              borderBottomColor={
                                index + 1 !== cookiesData(t).cookieObjects[i].cookieList.length
                                  ? 'gray.200'
                                  : 'transparent'
                              }
                              p={3}
                              fontSize='12px'
                              key={i}
                            >
                              <Grid w='100%' templateColumns='repeat(5, 1fr)'>
                                <GridItem colSpan={1} fontWeight='700'>
                                  <Text>Name:</Text>
                                </GridItem>
                                <GridItem colSpan={4}>
                                  <Text>{description.name}</Text>
                                </GridItem>
                              </Grid>
                              <Grid w='100%' templateColumns='repeat(5, 1fr)'>
                                <GridItem colSpan={1} fontWeight='700'>
                                  <Text>Usage:</Text>
                                </GridItem>
                                <GridItem colSpan={4}>
                                  <Text>{description.usage}</Text>
                                </GridItem>
                              </Grid>
                              <Grid w='100%' templateColumns='repeat(5, 1fr)'>
                                <GridItem colSpan={1} fontWeight='700'>
                                  <Text>Expire:</Text>
                                </GridItem>
                                <GridItem colSpan={4}>
                                  <Text>{description.expire}</Text>
                                </GridItem>
                              </Grid>
                              <Grid w='100%' templateColumns='repeat(5, 1fr)'>
                                <GridItem colSpan={1} fontWeight='700'>
                                  <Text>Provider:</Text>
                                </GridItem>
                                <GridItem colSpan={4}>
                                  <Text>{description.provider}</Text>
                                </GridItem>
                              </Grid>
                              {description.cookiePolicy && (
                                <Grid w='100%' templateColumns='repeat(5, 1fr)'>
                                  <GridItem colSpan={1} fontWeight='700'>
                                    <Text>Cookie policy:</Text>
                                  </GridItem>
                                  <GridItem colSpan={4}>
                                    <Text>{description.cookiePolicy}</Text>
                                  </GridItem>
                                </Grid>
                              )}
                            </Flex>
                          ))}
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
