import axios from 'axios';
import qs from 'qs';

import { getHeaders } from './common';
import { catchError } from './utils/catch-error';
import { PATH_ADVERTS, PATH_ADVERTS_DELETE_MY, PATH_ADVERTS_EDIT_MY } from './utils/path';

const sort = ['publishedAt:desc'];

const populateAdverts = {
  users_permissions_user: {
    fields: ['id', 'surname', 'name', 'email', 'imageUrl'],
  },
  photo: {
    fields: ['name', 'width', 'height', 'url', 'formats'],
  },
  country: {
    fields: 'countryName',
  },
  advert_category: {
    populate: {
      localizations: {
        fields: ['advertCategoryName', 'locale'],
      },
    },
    fields: ['advertCategoryName', 'locale'],
  },
  currency: {
    fields: 'iso_code',
  },
  branch: { fields: 'branchName' },
};

export const fetchAllAdverts = async (
  jwt,
  toast,
  navigate,
  errorCallback,
  user,
  page,
  pageSize,
  filters,
) => {
  errorCallback(false);

  const pagination = {
    pageSize,
    page,
  };

  const defaultFilters = {
    $or: [
      {
        expires: {
          $gt: new Date().toISOString().split('T')[0],
        },
      },
      { users_permissions_user: user.id },
    ],
  };

  const mergedFilters = { $and: [defaultFilters, filters] };

  return axios
    .get(
      `${PATH_ADVERTS}/?${qs.stringify({
        populate: populateAdverts,
        sort,
        pagination,
        filters: mergedFilters,
      })}`,
      getHeaders(jwt),
    )
    .catch((error) => catchError(error, toast, navigate, errorCallback));
};

export const fetchSingleAdvert = async (jwt, toast, navigate, errorCallback, id) => {
  errorCallback(false);
  return axios
    .get(`${PATH_ADVERTS}/${id}?${qs.stringify({ populate: populateAdverts })}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate, errorCallback));
};

export const postNewAdvert = async (jwt, toast, navigate, advert, user, image) => {
  const formData = new FormData();
  const branch = advert.branch ? advert.branch : null;
  const data = {
    title: advert.title,
    content: advert.content,
    advert_category: advert.advert_category,
    expires: advert.expires,
    country: advert.country,
    branch,
    users_permissions_user: user.id,
    price: advert.price,
    negociate: advert.negociate,
    currency: advert.currency,
  };
  image.forEach((element) => {
    if (element.name) {
      formData.append('files.photo', element, element.name);
    }
  });

  formData.append('data', JSON.stringify(data));

  await axios.post(PATH_ADVERTS, formData, getHeaders(jwt)).catch((error) => {
    catchError(error, toast, navigate);
    return false;
  });
  return true;
};

export const updateAdvert = async (jwt, toast, navigate, advert, image) => {
  const formData = new FormData();
  const branch = advert.branch ? advert.branch : null;
  const data = {
    title: advert.title,
    content: advert.content,
    advert_category: advert.advert_category,
    expires: advert.expires,
    branch,
    country: advert.country,
    photo: advert.photo,
    price: advert.price,
    negociate: advert.negociate,
    currency: advert.currency,
  };

  image.forEach((element) => {
    if (element.name) {
      formData.append('files.photo', element, element.name);
    }
  });

  formData.append('data', JSON.stringify(data));
  await axios
    .put(`${PATH_ADVERTS_EDIT_MY}/${advert.id}`, formData, getHeaders(jwt))
    .catch((error) => {
      catchError(error, toast, navigate);
      return false;
    });
  return true;
};

export const deleteAdvert = async (jwt, toast, navigate, id) => {
  await axios
    .delete(`${PATH_ADVERTS_DELETE_MY}/${id}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};
