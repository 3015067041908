import { Box, Button, Center, Flex, Grid, Img, Text, VStack, useToast } from '@chakra-ui/react';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { googleGetAllFiles } from '../../../api';
import { ReactComponent as IconInfo } from '../../../assets/icons/knowledge-base/Icon_info.svg';
import {
  GOOGLE_DRIVE_JPEG_TYPE,
  GOOGLE_DRIVE_PDF_TYPE,
  GOOGLE_DRIVE_PNG_TYPE,
  ICON_SIZE_BIG,
  ICON_SIZE_SMALL,
  MIME_TYPE_FOLDER,
  OPEN_LINK_DRIVE,
} from '../../../constants';
import useInfiniteScroll from '../../../helpers/hooks/useInfiniteScroll';
import { ReduxState } from '../../../redux/state';
import { DriveItem } from '../drive-item';

interface RenderFolderGridProps {
  setIsSpinner: Dispatch<SetStateAction<boolean>>;
  dataFolder: {
    id: string;
    nextPageToken?: string;
    files: DriveItem[];
  };
  updateParentFolder: (files: DriveItem[]) => void;
  setPathList: Dispatch<SetStateAction<DriveItem[]>>;
  pathList: DriveItem[];
  leftToolbar: ReactNode;
  sortGoogleDrive: string;
  setDriveData: Dispatch<
    SetStateAction<
      | {
          id: string;
          nextPageToken?: string;
          files: DriveItem[];
        }
      | undefined
    >
  >;
  setCurrentFolderId: Dispatch<SetStateAction<string | null>>;
  activeId: string | undefined;
  onDrawerOpen: (string) => void;
  isSharedDrive: boolean;
}

export const RenderFolderGrid: React.FC<RenderFolderGridProps> = ({
  setIsSpinner,
  dataFolder,
  updateParentFolder,
  setPathList,
  pathList,
  leftToolbar,
  sortGoogleDrive,
  setDriveData,
  setCurrentFolderId,
  activeId,
  onDrawerOpen,
  isSharedDrive,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { jwt } = useSelector((state: ReduxState) => state.auth);

  const handleObserver = async ({ setIsFetching }) => {
    const { id, nextPageToken } = dataFolder;
    if (nextPageToken) {
      setIsSpinner(true);
      const response = await googleGetAllFiles(
        jwt,
        toast,
        navigate,
        id,
        sortGoogleDrive,
        undefined,
        nextPageToken,
        isSharedDrive,
      );

      setDriveData({
        ...dataFolder,
        files: [...dataFolder.files, ...response.files],
        nextPageToken: response.nextPageToken,
      });

      setIsSpinner(false);
      setIsFetching(false);
    }
  };

  useInfiniteScroll(handleObserver);

  const openFolder = async (folderData: DriveItem) => {
    setIsSpinner(true);
    const response = await googleGetAllFiles(
      jwt,
      toast,
      navigate,
      folderData.id,
      sortGoogleDrive,
      undefined,
      undefined,
      true,
      isSharedDrive,
    );
    setCurrentFolderId(folderData.id);
    updateParentFolder(response.files);
    setPathList([...(pathList || []), { id: folderData.id, name: folderData.name }]);
    setIsSpinner(false);
  };

  const openFile = (fileId: string) => {
    window.open(OPEN_LINK_DRIVE + fileId, '_blank');
  };

  const handlerFile = (file: DriveItem) => {
    if (file.mimeType === MIME_TYPE_FOLDER) {
      return openFolder(file);
    }
    return openFile(file.id);
  };

  interface DriveItemWrapperProps {
    item: DriveItem;
    testId?: string;
    children: ReactNode;
  }
  const DriveItemWrapper: React.FC<DriveItemWrapperProps> = ({ item, testId, children }) => (
    <Box
      borderRadius='8px'
      bgColor={item.id === activeId ? 'ZLGreen.50' : 'transparent'}
      w='256px'
      key={item.id}
      cursor='pointer'
      onClick={() => handlerFile(item)}
      boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
      _hover={{
        bgColor: 'ZLGreen.100',
      }}
      data-testid={testId}
      title={item.name}
    >
      {children}
    </Box>
  );

  const files = dataFolder.files?.filter((file) => file.mimeType !== MIME_TYPE_FOLDER);
  const renderFiles = (
    <Grid
      gridTemplateColumns='repeat(auto-fill, minmax(256px, 1fr))'
      gap={6}
      color='gray.600'
      fontWeight={700}
    >
      {files?.map((file, i) => (
        <DriveItemWrapper key={i} item={file} testId='knowledgeBaseFile'>
          <Img
            borderTopRadius='8px'
            w='100%'
            h='210px'
            objectFit='cover'
            src={
              file.mimeType === GOOGLE_DRIVE_PDF_TYPE ||
              file.mimeType === GOOGLE_DRIVE_JPEG_TYPE ||
              file.mimeType === GOOGLE_DRIVE_PNG_TYPE
                ? file.thumbnailLink
                : file.iconLink?.replace(ICON_SIZE_SMALL.toString(), ICON_SIZE_BIG.toString())
            }
          />
          <Center minH='46px' p={4} alignItems='center'>
            <Img mr={2} my={2} src={file.iconLink} />
            <Text
              w='100%'
              textAlign='left'
              display='-webkit-box'
              overflow='hidden'
              wordBreak='break-all'
              sx={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: '1' }}
            >
              {file.name}
            </Text>
            <Button
              variant='ZGreenLink'
              size='xs'
              mx={4}
              onClick={(event) => {
                event.stopPropagation();
                onDrawerOpen(file.id);
              }}
              bgColor={file.id === activeId ? 'ZSGreen.50' : 'transparent'}
            >
              <IconInfo fill='#4A5568' stroke='#4A5568' />
            </Button>
          </Center>
        </DriveItemWrapper>
      ))}
    </Grid>
  );
  const folders = dataFolder.files?.filter((file) => file.mimeType === MIME_TYPE_FOLDER);
  const renderFolders = (
    <Grid
      gridTemplateColumns='repeat(auto-fill, minmax(256px, 1fr))'
      gap={6}
      color='gray.600'
      fontWeight={700}
    >
      {folders?.map((file, i) => (
        <DriveItemWrapper key={i} item={file} testId='knowledgeBaseFolder'>
          <Center minH='100%' wordBreak='break-word' p={4}>
            <Img ml={4} src={file.iconLink} />
            <Text
              w='100%'
              ml={2}
              textAlign='left'
              display='-webkit-box'
              overflow='hidden'
              wordBreak='break-all'
              sx={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: '1' }}
            >
              {file.name}
            </Text>
            <Button
              variant='ZGreenLink'
              size='xs'
              mx={4}
              onClick={(event) => {
                event.stopPropagation();
                onDrawerOpen(file.id);
              }}
              bgColor={file.id === activeId ? 'ZSGreen.50' : 'transparent'}
            >
              <IconInfo fill='#4A5568' stroke='#4A5568' />
            </Button>
          </Center>
        </DriveItemWrapper>
      ))}
    </Grid>
  );

  return (
    <VStack gap={6} color='gray.550' w='100%' direction='column' alignItems='center'>
      {folders.length > 0 && (
        <Flex flexDirection='column' w='100%'>
          <Flex w='100%' mb={6} justifyContent='space-between'>
            <Text> {t('knowledge-base.folders')}</Text>
            {leftToolbar}
          </Flex>
          <Box w='100%' transition='.5s'>
            {renderFolders}
          </Box>
        </Flex>
      )}
      {files.length > 0 && (
        <Flex flexDirection='column' w='100%'>
          <Flex w='100%' justifyContent='space-between'>
            {' '}
            <Text> {t('knowledge-base.files')}</Text>
            {folders.length === 0 && leftToolbar}
          </Flex>
        </Flex>
      )}
      <Box w='100%' transition='.5s'>
        {renderFiles}
      </Box>
    </VStack>
  );
};
