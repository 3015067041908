import { Box } from '@chakra-ui/react';
import { Metadata, isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import './index.css';

const CustomPhoneInput = ({
  value,
  isDisabled,
  defaultCountry,
  style,
  emptyAccept,
  control,
  defaultValues,
  arg,
  testId,
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const metadata = new Metadata();

  let validPhoneNumber = false;

  const valid = (number, countryCodeData) => {
    const countryCodeUpper = countryCodeData?.toUpperCase();
    if (!number) {
      validPhoneNumber = emptyAccept;
      return emptyAccept;
    }
    if (number.length > 3 && number && countryCodeData) {
      metadata.selectNumberingPlan(countryCodeUpper);
      const phoneNumber = parsePhoneNumber(`${number}`, countryCodeUpper);

      const reg = new RegExp(`^${phoneNumber.countryCallingCode}`);

      if (
        phoneNumber.isValid() &&
        phoneNumber.country === countryCodeUpper &&
        isPossiblePhoneNumber(`${number.replace(reg, '')}`, countryCodeUpper)
      ) {
        validPhoneNumber = true;
        return true;
      }
      validPhoneNumber = false;
      return false;
    }
    if (!number) {
      validPhoneNumber = true;
      return true;
    }
    validPhoneNumber = false;
    return false;
  };

  return (
    <Box w='100%' className='numberPhoneInput' data-testid={testId && `${testId}`}>
      <Controller
        {...arg}
        rules={{
          validate: (value) =>
            validPhoneNumber || (emptyAccept && !value) || t('errors.bad-phone-number'),
        }}
        control={control}
        defaultValue=''
        render={({ field }) => (
          <PhoneInput
            disabled={isDisabled}
            inputStyle={{
              borderRadius: '4px',
              border: '1px solid var(--chakra-colors-inputGray-400)',
              width: '100%',
              height: '48px',
              fontSize: '16px',
              fontWeight: 500,
              ...style,
            }}
            buttonStyle={{
              borderColor: 'transparent',
              background: 'transparent',
            }}
            country={defaultCountry}
            {...field}
            ref={ref}
            isValid={(value, country) => {
              return valid(value, country?.iso2);
            }}
            onChange={(phone, data) => {
              field.onChange(phone);
            }}
          />
        )}
      />
    </Box>
  );
};

export default CustomPhoneInput;
