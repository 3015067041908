import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { ICommonApi, IUserNameAndSurname, IUserOrgChart, IUserUpdateData } from '../types';

import { getHeaders } from './common';
import { catchError } from './utils/catch-error';
import {
  PATH_USERS_DEVS,
  PATH_USERS_ME_UPDATE,
  PATH_USERS_MENTION,
  PATH_USERS_ORH_CHART,
} from './utils/path';

export const fieldsAllUsers = [
  'username',
  'email',
  'name',
  'surname',
  'imageUrl',
  'mobilePhone',
  'workPhone',
  'blocked',
];
export const populateAllUsers = {
  country: {
    select: ['countryName'],
  },
  department: {
    select: ['departmentName'],
  },
  company: {
    select: ['companyName'],
  },
  branchList: {
    select: ['branchName'],
  },
  ekspertise: {
    select: ['expertiseName'],
  },
  job_title: {
    select: ['name'],
  },
  line_manager: {
    select: ['name', 'surname'],
  },
  languages: {
    select: ['id', 'name'],
  },
};

export const fetchAllUsers = (
  { jwt, toast, navigate },
  {
    errorCallback,
    filters,
    fields = fieldsAllUsers,
    populate = populateAllUsers,
    pageSize,
    page,
  }: ICommonApi | undefined = {},
) => {
  const queryRaw = {
    fields,
    populate,
    filters: filters ? parseFilters(filters) : defaultFilters(),
    pagination:
      pageSize && page
        ? {
            pageSize,
            page,
          }
        : undefined,
  };
  errorCallback?.(false);
  return axios
    .get(`${PATH_USERS_DEVS}?${qs.stringify(queryRaw)}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate, errorCallback));
};

export const updateMe = (
  { jwt, toast, navigate },
  data: IUserUpdateData,
  callback: (data: IUserUpdateData) => void,
) => {
  const dataWithTimezoneOffset = { ...data, timezoneOffset: new Date().getTimezoneOffset() };
  return axios
    .put(`${PATH_USERS_ME_UPDATE}/${data.id}`, dataWithTimezoneOffset, getHeaders(jwt))
    .then((resp) => {
      callback?.(resp.data);
    })
    .catch((error) => catchError(error, toast, navigate));
};

const parseFilters = (filters) => ({
  $and: [
    filters.fullName && filters.fullName !== '' ? { fullName: filters.fullName } : undefined, // this filter must be the first in the array
    filters.email && filters.email !== '' ? { email: { $containsi: filters.email } } : undefined,
    filters.branch?.id && { branchList: filters.branch.id },
    { afterFirstLogin: true },
    filters.country?.id && { country: filters.country.id },
    filters.company?.id && { company: filters.company.id },
    filters.department?.id && { department: filters.department.id },
    filters.ekspertise?.id && { ekspertise: filters.ekspertise.id },
  ],
});

const defaultFilters = () => ({
  $and: [{ afterFirstLogin: true }],
});

export const populateUpdateMeAdverts = {
  adverts: {
    select: ['*'],
  },
};

export const fetchUserOrgChart = (jwt, toast, navigate, id) =>
  axios
    .get(`${PATH_USERS_ORH_CHART}/${id}`, getHeaders(jwt))
    .then((response: AxiosResponse<IUserOrgChart>) => response)
    .catch((error) => catchError(error, toast, navigate));

export const fetchUsersMention = ({ jwt, toast, navigate }, searchValue: string) =>
  axios
    .get(`${PATH_USERS_MENTION}/${searchValue || 'A'}`, getHeaders(jwt))
    .then((response: AxiosResponse<IUserNameAndSurname[]>) => response.data)
    .catch((error) => catchError(error, toast, navigate));
