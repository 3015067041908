import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  children: JSX.Element | JSX.Element[];
  onClose?: () => void;
  onApply?: () => void;
  handleSubmit?: React.FormEventHandler<HTMLFormElement>;
  titleModal: string;
  closeButton: string;
  applyButton: string;
  isLoading: boolean;
}

export const DefaultModal = ({
  isOpen,
  children,
  onClose,
  onApply,
  handleSubmit,
  titleModal,
  applyButton,
  closeButton,
  isLoading = false,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size={{ base: 'xs', sm: 'sm', md: 'md', lg: 'lg', xl: '2xl', '2xl': '3xl' }}
    >
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent p={{ base: 0, md: 2, lg: 4 }}>
          <ModalHeader>
            <Heading variant='lgSmall' color='brandColors.brandGreen'>
              {titleModal}
            </Heading>
          </ModalHeader>
          <ModalCloseButton mt={{ base: 0, md: 2, lg: 4 }} mr={{ base: 0, md: 2, lg: 4 }} />
          <ModalBody py={0}>{children}</ModalBody>
          <ModalFooter pt={0} mt={4}>
            <Button
              variant='ZGreenSecondary'
              mr={3}
              onClick={onClose}
              isDisabled={isLoading === true}
            >
              {closeButton}
            </Button>
            <Button
              colorScheme='green'
              onClick={onApply}
              type='submit'
              isLoading={isLoading === true}
              isDisabled={isLoading === true}
            >
              {applyButton}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
