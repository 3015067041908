import React, { Fragment } from 'react';
import { Text, Button, Center, Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { deleteAdvert } from '../../api';

const DeleteAdvertModal = ({
  isOpenDelete,
  onCloseDelete,
  deletingAdvert,
  jwt,
  navigate,
  toast,
}) => {
  const { t } = useTranslation();

  const onCloseModal = (refreshNavigation = false) => {
    onCloseDelete(refreshNavigation);
  };

  const yesChosen = async () => {
    await deleteAdvert(jwt, toast, navigate, deletingAdvert);
    onCloseModal(true);
  };

  return (
    <Modal isOpen={isOpenDelete} onClose={onCloseModal} size='xl'>
      {isOpenDelete && (
        <>
          <ModalOverlay />
          <ModalContent data-testid='advertsDeleteModal'>
            <Center m={4}>
              <Text data-testid='advertsDeleteModalTitle'>
                {t('adverts.delete-advert-confirm')}
              </Text>
            </Center>
            <Center>
              <Button m={4} onClick={yesChosen} data-testid='advertsDeleteModalYesButton'>
                {t('common.yes')}
              </Button>
              <Button m={4} onClick={onCloseModal} data-testid='advertsDeleteModalNoButton'>
                {t('common.no')}
              </Button>
            </Center>
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

export default DeleteAdvertModal;
