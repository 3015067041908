import { ISortType } from '../types';

export const DATE_FORMAT = 'dd MMM yyyy';
export const DATE_FORMAT_HOURS = 'dd MMM yyyy kk:mm:ss';

export const ROLE_INTRANET_ADMIN = 'IntranetAdmin';

export const DEFAULT_ENTRIES_PER_PAGE = 15;

export const MIME_TYPE_FOLDER = 'application/vnd.google-apps.folder';
export const OPEN_LINK_DRIVE = 'https://drive.google.com/open?id=';

export const CANNY_LINK = 'https://ziegler.canny.io/zieglerteam';

export const ADVERT_FEATURE = process.env.REACT_APP_ADVERT_FEATURE === '1';
export const FORUM_FEATURE = process.env.REACT_APP_FORUM_FEATURE === '1';

export * from './path';

export const SIZE_ICON = {
  weight: '20px',
  height: '20px',
};

export const SORT_ORDER_ASC = 'asc';
export const SORT_ORDER_DESC = 'desc';
export const SORT_ORDER = [SORT_ORDER_ASC, SORT_ORDER_DESC];

export const SORT_TYPES: ISortType[] = [
  { text: 'knowledge-base.name', sortBy: 'name' },
  { text: 'knowledge-base.viewed-by-me-time', sortBy: 'viewedByMeTime' },
  { text: 'knowledge-base.modified-time', sortBy: 'modifiedTime' },
];

export const SORT_TYPES_KB_ROOT = [
  { text: 'knowledge-base.title', sortBy: 'title' },
  { text: 'knowledge-base.created-at', sortBy: 'createdAt' },
  { text: 'knowledge-base.updated-at', sortBy: 'updatedAt' },
];

export const GOOGLE_DRIVE_PDF_TYPE = 'application/pdf';
export const GOOGLE_DRIVE_JPEG_TYPE = 'image/jpeg';
export const GOOGLE_DRIVE_PNG_TYPE = 'image/png';

export const THUMBNAIL_SIZE_IMAGE_BIG = '=s440';
export const THUMBNAIL_SIZE_IMAGE_MEDIUM = '=s220';
export const THUMBNAIL_SIZE_IMAGE_SMALL = '=s60';

export const ICON_SIZE_SMALL = 16;
export const ICON_SIZE_MEDIUM = 64;
export const ICON_SIZE_BIG = 256;

export const ADVERT_MAX_NB_PHOTOS = 5;

// 30min
export const SILENT_REFRESH_TIMEOUT = 1800000;

export const FILTER_MENU_WIDTH = '309px';
export const FILTER_MENU_GAP_COL = 8;
export const FILTER_MENU_GAP_ROW = 4;
export const MENU_HEIGHT = '80px';
export const PAGINATION_HEIGHT = '40px';
export const HORIZONTAL_PADDING = 4;

export const PAGINATION_POSITION = '80px'; // menu height
export const MAIN_CONTENT_POSITION = '152px'; // menu height + pagination height + 2x FILTER_MENU_GAP_ROW

export const RESOURCE_NAME_FORUM_TOPIC = 'forum-topic';
export const RESOURCE_NAME_COMMENT = 'comment';
export const RESOURCE_NAME_NEWS = 'post';

export const COMMENTS_SORT_NEWEST = 1;
export const COMMENTS_SORT_OLDEST = 2;
export const COMMENTS_SORT_LIKED = 3;
