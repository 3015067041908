import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Heading,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
  Link,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Helmet } from '../../components-common/helmet';
import { PATH_COMPLIANCE } from '../../constants';
import { FRONTEND_URL } from '../../api/utils/path';

const CONFIDENTIAL_EMAIL = 'confidential@zieglergroup.com';
const COMPLIANCE_FORM_LINK = 'https://ediweb.intranet.tzg/whistleblower/';

const PDF_1_NAME = 'CODE OF ETHICS_EN';
const PDF_1_LINK = `${FRONTEND_URL}/assets/CODE+OF+ETHICS_EN.pdf`;
const PDF_2_NAME = 'CODE D’ETHIQUE_FR';
const PDF_2_LINK = `${FRONTEND_URL}/assets/CODE+D’ETHIQUE_FR.pdf`;
const PDF_3_NAME = 'ETHIK-KODEX_DE';
const PDF_3_LINK = `${FRONTEND_URL}/assets/ETHIK-KODEX_DE.pdf`;

export const ComplianceView = () => {
  const { t } = useTranslation();
  const ZieglerLink = () => (
    <Link
      color='brandColors.brandYellow'
      fontWeight='bold'
      href={COMPLIANCE_FORM_LINK}
      target='_blank'
    >
      {COMPLIANCE_FORM_LINK}
    </Link>
  );

  const PdfLink = ({ link, title, testId }) => (
    <Link
      color='brandColors.brandGreen'
      fontWeight='bold'
      href={link}
      target='_blank'
      data-testid={testId}
    >
      <ExternalLinkIcon mr={2} />
      {title}
    </Link>
  );

  const EmailLink = () => (
    <Link fontWeight='bold' color='brandColors.brandYellow' href={`mailto:${CONFIDENTIAL_EMAIL}`}>
      {CONFIDENTIAL_EMAIL}
    </Link>
  );

  return (
    <Flex flexDirection='column' whiteSpace='pre-line' pt={4} pb={12}>
      <Helmet title={t('title.compliance')} />
      <Heading variant='mdLarge' color='brandColors.brandGreen' mb={10}>
        {t('compliance.title')}
      </Heading>
      <Flex>
        <Box width='65%' data-testid='coeZg'>
          <Heading size='md' color='brandColors.brandGreen' mb={6}>
            {t('compliance.header')}
          </Heading>
          <Text mb={2}>{t('compliance.ethics-content-1')}</Text>
          <Text mb={2}>{t('compliance.ethics-content-2')}</Text>
          <Text mb={2}>{t('compliance.ethics-content-3')}</Text>
          <Text>{t('compliance.ethics-content-4')}</Text>
        </Box>
        <Box width='35%'>
          <Flex justifyContent='flex-end'>
            <UnorderedList listStyleType='none'>
              <ListItem>
                <PdfLink link={PDF_1_LINK} title={PDF_1_NAME} testId='coeEn' />
              </ListItem>
              <ListItem>
                <PdfLink link={PDF_2_LINK} title={PDF_2_NAME} testId='coeFr' />
              </ListItem>
              <ListItem>
                <PdfLink link={PDF_3_LINK} title={PDF_3_NAME} testId='coeDe' />
              </ListItem>
            </UnorderedList>
          </Flex>
        </Box>
      </Flex>
      <Box mb={2} data-testid='coeHotLine'>
        <Heading size='md' color='brandColors.brandGreen' mt={10} mb={6}>
          {t('compliance.hot-line-title')}
        </Heading>
        <Text mb={2}>{t('compliance.hot-line-content-1')}</Text>
        <Text mb={2}>{t('compliance.hot-line-content-2')}</Text>
        <Text>{t('compliance.hot-line-content-3')}</Text>
      </Box>
      <Box mb={8}>
        <Text my={4}>{t('compliance.hot-line-list-title')}</Text>
        <OrderedList pl={4}>
          <ListItem>
            <Text my={4}>{t('compliance.hot-line-list-item-1')}</Text>
          </ListItem>
          <ListItem>
            <Text>{t('compliance.hot-line-list-item-2-title')}</Text>
            <UnorderedList pl={4}>
              <ListItem>
                <Trans i18nKey='compliance.hot-line-list-item-2-item-1'>
                  content
                  <ZieglerLink />
                  content
                </Trans>
              </ListItem>
              <ListItem>
                <Trans i18nKey='compliance.hot-line-list-item-2-item-2'>
                  content
                  <EmailLink />
                  content
                </Trans>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </OrderedList>
      </Box>
      <Box mb={10}>
        <Text mb={4}>{t('compliance.hot-line-content-5')}</Text>
        <Text>{t('compliance.hot-line-content-6')}</Text>
      </Box>
      <Box>
        <Text>{t('compliance.signature')}</Text>
        <Text>{t('compliance.signature-1')}</Text>
      </Box>
    </Flex>
  );
};
