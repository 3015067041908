import { Box, Button, Flex, GridProps, Heading } from '@chakra-ui/react';
import qs, { ParsedQs } from 'qs';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const FiltersProvider = forwardRef(
  <TFilters extends { [key: string]: string }>(
    {
      handleSearch,
      children,
      defaultValues,
      isLoading = false,
    }: {
      handleSearch: () => void;
      children: React.ReactElement<{ form: UseFormReturn; name: string } & GridProps>[];
      defaultValues?: FieldValues;
      isLoading?: boolean;
    },
    ref,
  ) => {
    type IFiltersOrParams =
      | { [TKeysFilters in keyof TFilters]?: TFilters[TKeysFilters] }
      | ParsedQs;
    const { t } = useTranslation();
    const location = useLocation();
    const params: IFiltersOrParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const valueKeys: (keyof TFilters)[] = React.Children.map(children, (child) => child.props.name);
    const navigate = useNavigate();

    const form = useForm({
      defaultValues,
    });

    const { handleSubmit, reset, setValue, getValues } = form;

    const filterNarrowing = (key: keyof TFilters, data: IFiltersOrParams): data is TFilters =>
      key in data;

    const filersFromUrl = (urlParams = params) => {
      return Object.fromEntries(
        valueKeys
          .map(
            (valueKey) => filterNarrowing(valueKey, urlParams) && [valueKey, urlParams[valueKey]],
          )
          .filter((value) => value),
      );
    };

    useEffect(() => {
      if (isLoading) {
        return;
      }

      if (params) {
        const filtersFromUrl = filersFromUrl();

        if (Object.keys(filtersFromUrl).length === 0) {
          reset(defaultValues, { keepDefaultValues: true });
        } else {
          reset(filersFromUrl(), { keepDefaultValues: false });
        }
      }
      handleSearch();
    }, [isLoading]);

    const filterParsed = (data: object | string) => {
      return Object.fromEntries(
        Object.keys(data).map((key) => {
          if (Array.isArray(data[key])) {
            return [
              key,
              data[key].map((item) => {
                if (item.id) {
                  return { id: item.id };
                }
                return false;
              }),
            ];
          }
          if (data[key]?.id) {
            return [key, { id: data[key].id }];
          }
          if (data[key]) {
            return [key, data[key]];
          }

          return [];
        }),
      );
    };

    useImperativeHandle(
      ref,
      () => ({
        getFilterValues: () => {
          return getValues();
        },
        resetFilter: (defaultValues) => {
          reset(defaultValues, { keepDefaultValues: false });
          handleSearch();
        },
        resetFromUrl: (params) => {
          reset(filersFromUrl(params), { keepDefaultValues: false });
          handleSearch();
        },
        setValueFilter: (name, value) => {
          setValue(name, value);
        },
      }),
      [],
    );

    const childrenWithProps = React.Children.map(children, (child, i) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { form, mt: i !== 0 ? 6 : 0 });
      }
      return child;
    });

    const search = (data: TFilters) => {
      navigate(`?${qs.stringify(filterParsed({ ...params, ...data }))}`);
      handleSearch();
    };
    const clearFilters = () => {
      reset(Object.fromEntries(valueKeys.map((valueKey) => [valueKey, null])));
      navigate('');
      handleSearch();
    };

    return (
      <Box
        layerStyle='container'
        borderColor='ZLGreen.50'
        w='100%'
        bgColor='ZLGreen.50'
        boxShadow={'none'}
        p={{ base: 4, xl: 6 }}
        alignContent={'center'}
      >
        <Heading variant='mdSmall' color='brandColors.brandGreen' data-testid='userFilters'>
          {t('common.filters')}
        </Heading>
        <form onSubmit={handleSubmit(search)}>
          <Flex direction={'column'} gap={4} mt={4} mb={8}>
            {childrenWithProps}
          </Flex>
          <Button
            onClick={clearFilters}
            w='100%'
            variant='ZGreenSecondary'
            data-testid='usersClearButton'
          >
            {t('common.clear')}
          </Button>
          <Button type='submit' mt={4} w='100%'>
            {t('common.search')}
          </Button>
        </form>
      </Box>
    );
  },
);

FiltersProvider.displayName = 'FiltersProvider';
