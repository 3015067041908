import { map } from 'lodash';

export const strapiDataParser = (response, relations) => {
  return response.data.data.map((newData) => {
    const newValue = {
      ...newData,
      ...newData.attributes,
      id: newData.id,
    };
    relations?.map((relationName) => {
      if (!newValue[relationName]?.data) {
        return null;
      }

      if (Array.isArray(newValue[relationName].data)) {
        const newRelationArray = [];
        newValue[relationName].data.map((relation) =>
          newRelationArray.push({
            ...relation.attributes,
            id: relation.id,
          }),
        );
        newValue[relationName] = newRelationArray;
      } else {
        newValue[relationName] = {
          ...map(newValue[relationName].data, (d) => d.attributes).filter((e) => e !== undefined),
          ...newValue[relationName].data?.attributes,
          id: newValue[relationName].data?.id || newValue[relationName].data[0]?.id,
        };
      }
      return null;
    });
    return newValue;
  });
};

export const strapiDataParserSingle = (response, relations) => {
  const newObject = response.data.data;

  const newValue = {
    ...newObject.attributes,
    id: newObject.id,
  };

  relations?.map((relationName) => {
    newValue[relationName] = {
      ...newValue[relationName].data?.attributes,
      id: newValue[relationName].data?.id,
    };
    return null;
  });

  return newValue;
};

export const strapiDataParserSingleWithMultRelations = (response, relations) => {
  const newObject = response.data.data || response.data;

  const newValue = {
    ...newObject.attributes,
    id: newObject.id,
  };

  relations?.map((relationName) => {
    if (!newValue[relationName].data) {
      newValue[relationName] = null;
      return null;
    }

    if (Array.isArray(newValue[relationName].data)) {
      const newRelationArray = [];
      newValue[relationName].data.map((relation) =>
        newRelationArray.push({
          ...relation.attributes,
          id: relation.id,
        }),
      );
      newValue[relationName] = newRelationArray;
    } else {
      newValue[relationName] = {
        ...newValue[relationName].data?.attributes,
        id: newValue[relationName].data?.id,
      };
    }
    return null;
  });

  return newValue;
};
