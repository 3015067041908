import { Box, Center, Heading, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Helmet } from '../../components-common/helmet';
import { ProfileForm } from '../../components-common/profile-form';
import './animation.css';

export const WelcomeView = () => {
  const { t } = useTranslation();
  const [isSpinner, setIsSpinner] = useState(true);

  return (
    <Center>
      <Helmet title={t('title.welcome')} />
      <Box w='1000px'>
        <Heading
          mt={6}
          variant='3xlLarge'
          w='100%'
          textAlign='center'
          color='ZLGreen.600'
          className='firstAnimationShow'
        >
          {t('welcome.header')}
        </Heading>
        <Text variant='3xl' color='ZLGreen.600' textAlign='center' className='secondAnimationShow'>
          {t('welcome.paragraph')}
        </Text>
        <div className='thirdAnimationShow'>
          <Box layerStyle='container' mt={6}>
            <ProfileForm
              isLoading={isSpinner}
              setIsLoading={setIsSpinner}
              shouldRedirect
              isFromLogin
            />
          </Box>
        </div>
      </Box>
    </Center>
  );
};
