/* eslint-disable */
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

export const defaultImgWidth = '400px';
export const defaultImgHeight = 'auto';

const defaultVideoWidth = '75%';
const defaultVideoHeight = '400px';

export const parsedContent = (content) => {
  const rawContentState = EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
  const options = {
    entityStyleFn: (entity) => {
      const entityType = entity.get('type').toLowerCase();
      if (entityType === 'draft-js-video-plugin-video') {
        const data = entity.getData();
        return {
          element: 'iframe',
          attributes: {
            src: data.src,
            width: defaultVideoWidth,
            height: defaultVideoHeight,
          },
        };
      }
      if (entityType === 'image') {
        const data = entity.getData();
        return {
          element: 'img',
          attributes: {
            src: data.src,
            width: data.width ? data.width + '%' : defaultImgWidth,
            height: data.height ? data.height + '%' : defaultImgHeight,
          },
          style: {
            float: data.alignment,
            display: 'block',
            margin: data.alignment === 'center' ? '10px auto' : '10px',
          },
        };
      }
      if (entityType === 'link') {
        const data = entity.getData();
        return {
          element: 'a',
          attributes: {
            href: data.url,
            target: '_blank',
          },
          style: {
            color: '#208779',
            fontWeight: 'bold',
          },
        };
      }
      if (entityType === 'mention') {
        return {
          element: 'span',
          style: {
            color: '#208779',
          },
        };
      }
      if (entityType === 'file') {
        const data = entity.getData();
        return {
          element: 'a',
          attributes: {
            href: data.url,
            target: '_blank',
          },
          style: {
            color: '#208779',
            fontWeight: 'bold',
          },
        };
      }
    },
    blockStyleFn: (block) => {
      if (block.getType() === 'align-center') {
        return {
          style: {
            textAlign: 'center',
          },
        };
      }
      if (block.getType() === 'align-left') {
        return {
          style: {
            textAlign: 'left',
          },
        };
      }
      if (block.getType() === 'align-right') {
        return {
          style: {
            textAlign: 'right',
          },
        };
      }
      if (block.getType() === 'blockquote') {
        return {
          style: {
            borderLeft: '5px solid #eee',
            color: '#666',
            fontFamily: '"Hoefler Text", "Georgia", serif',
            fontStyle: 'italic',
            margin: '16px 0',
            padding: '10px 20px',
          },
        };
      }
      if (['unordered-list-item', 'ordered-list-item'].includes(block.getType())) {
        return {
          style: {
            marginLeft: '30px',
          },
        };
      }
    },
    inlineStyleFn: (styles) => {
      const h1 = styles.find((value) => value.startsWith('H1'));
      const h2 = styles.find((value) => value.startsWith('H2'));
      const h3 = styles.find((value) => value.startsWith('H3'));
      const bold = styles.find((value) => value.startsWith('bold'));
      const italic = styles.find((value) => value.startsWith('italic'));
      const underline = styles.find((value) => value.startsWith('underline'));
      const colorKey = 'color-';
      const color = styles.find((value) => value.startsWith(colorKey));
      let withStyle = {};
      if (color) {
        withStyle.color = color.replace(colorKey, '');
      }
      if (bold) {
        withStyle.fontWeight = 'bold';
      }
      if (italic) {
        withStyle.fontStyle = 'italic';
      }
      if (underline) {
        withStyle.textDecoration = 'underline';
      }
      if (h1) {
        return {
          element: 'span',
          style: {
            ...withStyle,
            fontSize: '2em',
          },
        };
      }
      if (h2) {
        return {
          element: 'span',
          style: {
            ...withStyle,
            fontSize: '1.5em',
          },
        };
      }
      if (h3) {
        return {
          element: 'span',
          style: {
            ...withStyle,
            fontSize: '1.17em',
          },
        };
      }
      if (color || bold || italic || underline) {
        return {
          element: 'span',
          style: {
            ...withStyle,
          },
        };
      }
    },
  };
  return stateToHTML(rawContentState.getCurrentContent(), options);
};

export const rawContent = (content) => {
  const { blocks } = convertToRaw(content.getCurrentContent());
  return blocks.map((block) => (!block.text.trim() && '\n') || block.text).join('\n');
};
