import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setActiveCategoryRecursiveAction } from '../../redux';
import { PATH_ABOUT_ZIEGLER } from '../../constants';
import { MenuDropDown } from '../menu-drop-down';

export const AboutDropdown = ({ isInNav = true }) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categories);
  const activeCategories = useSelector((state) => state.categories);

  useEffect(() => {
    return () => {
      dispatch(
        setActiveCategoryRecursiveAction({
          activeCategory: null,
          activeSubCategory: null,
          activeSubSubCategory: null,
        }),
      );
    };
  }, []);

  return (
    <MenuDropDown
      expandRecord={(idListPath) => dispatch(setActiveCategoryRecursiveAction(idListPath))}
      activeCategories={activeCategories}
      pathParent={`${PATH_ABOUT_ZIEGLER}`}
      isInNav={isInNav}
      categories={categories}
      variableNameList={{ prefixName: 'about_' }}
    />
  );
};
