import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import 'reactflow/dist/style.css';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { useDetectOrDisplayMobile } from '../../helpers/hooks/use-detect-or-display-mobile';
import { MobileOrgChart } from './mobile/mobile-org-chart';
import { useSelector } from '../../redux/use-selector';

import { fetchUserOrgChart } from '../../api';

import { DesktopOrgChart } from './desktop/desktop-org-chart';
import { IUserOrgChart } from '../../types';

export const OrgChartView = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isMobile } = useDetectOrDisplayMobile();
  const { jwt } = useSelector((state) => state.auth);
  const [users, setUsers] = useState<IUserOrgChart[]>([]);
  const updateUsers = async (id?: number) => {
    const response = await fetchUserOrgChart(jwt, toast, navigate, id);
    if (response && response.data) {
      setUsers([...users, ...Object.values(response.data)]);
    }
  };

  useEffect(() => {
    updateUsers();
  }, []);

  const getNewCards = (id: number) => {
    updateUsers(id);
  };

  if (isMobile) {
    return <MobileOrgChart getNewCards={getNewCards} users={users} />;
  }
  return <DesktopOrgChart getNewCards={getNewCards} users={users} />;
};
