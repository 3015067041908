import React from 'react';
import { Box } from '@chakra-ui/react';
import ReactFlow, { MiniMap, Controls, Background, NodeTypes, EdgeTypes } from 'reactflow';
import 'reactflow/dist/style.css';

import { useOrgChartDesktop } from './use-org-chart-desktop';
import { ORG_CHART_NODE_WIDTH } from '../../../constants/org-chart';
import { CustomNode } from './custom-node';
import { CustomEdge } from './custom-edge';
import { IUserOrgChart } from '../../../types';
import { UserDetailsDrawer } from './user-details-drawer';
import { MENU_HEIGHT } from '../../../constants';

const edgeTypes: EdgeTypes = {
  myCustomEdge: CustomEdge,
};
const nodeTypes: NodeTypes = {
  myCustomNode: CustomNode,
};

export const DesktopOrgChart = ({ users, getNewCards }) => {
  const { nodes, edges, selectedUser, clearSelect } = useOrgChartDesktop<IUserOrgChart>({
    users,
    getNewCards,
  });

  return (
    <Box h={`calc(100vh - ${MENU_HEIGHT})`} w='100vw' position='absolute' left={0}>
      <UserDetailsDrawer user={selectedUser} clearSelect={clearSelect} />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultViewport={{
          x: (document.documentElement.clientWidth - ORG_CHART_NODE_WIDTH) / 2,
          y: 100,
          zoom: 1,
        }}
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </Box>
  );
};
