import React, { useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CookieBar } from '@dornach/ziegler-ui-kit';

import { Helmet } from '../../components-common/helmet';
import { cookiesData } from '../../helpers/cookieHelper';
import { Header } from '../header';

export const PublicScreen = () => {
  const { t } = useTranslation();
  const [cookieBarData] = useState(cookiesData(t));
  const location = useLocation();
  const indexEndSectionURL = location.pathname.indexOf('/', 1);
  const activeLocation = location.pathname.substr(
    0,
    indexEndSectionURL !== -1 ? indexEndSectionURL : undefined,
  );

  return (
    <>
      <Helmet title={t('title.default')} />
      <Header activeLocation={activeLocation} />

      <Outlet />
      {cookieBarData && <CookieBar {...cookiesData(t)} />}
    </>
  );
};
