import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IApiParams } from '../../types';
import { useSelector } from '../../redux/use-selector';

export const UseApiParams = (): IApiParams => {
  const toast = useToast();
  const navigate = useNavigate();
  const { jwt } = useSelector((state) => state.auth);

  return { jwt, toast, navigate };
};
