import { React } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { fr, enGB as en, de, nl } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text, Img, Heading, HStack, VStack } from '@chakra-ui/react';
import { resizedImgUrl } from '../../helpers/imageHelper';
import { ReactComponent as IconSell } from '../../assets/icons/icon_sell.svg';
import { ReactComponent as IconWallet } from '../../assets/icons/Icon_wallet.svg';
import { ReactComponent as CarbonTime } from '../../assets/icons/carbon_time.svg';
import { parseLanguageCode } from '../../helpers/languageHelper';

export const SingleAdvertSmall = ({ advert, linkTo, idx, currentUser, testId }) => {
  const { t, i18n } = useTranslation();

  const lang = parseLanguageCode(i18n.language);

  let myLocale;
  switch (lang) {
    case 'fr':
      myLocale = fr;
      break;

    case 'de':
      myLocale = de;
      break;

    case 'nl':
      myLocale = nl;
      break;

    default:
      myLocale = en;
      break;
  }

  let advertCategory;
  if (advert) {
    advertCategory = advert.advert_category?.data?.attributes?.advertCategoryName;
    advert.advert_category?.data?.attributes?.localizations?.data?.forEach((locale) => {
      if (locale.attributes.locale === lang) {
        advertCategory = locale.attributes.advertCategoryName;
      }
    });
  }

  let fit = 'cover';
  if (advert.photo[0]) {
    const fitHeight = advert.photo[0].height;
    const fitWidth = advert.photo[0].width;
    let ratio;
    if (fitWidth > fitHeight) {
      ratio = fitWidth / fitHeight;
    } else {
      ratio = fitHeight / fitWidth;
    }

    if (ratio > 2) {
      fit = 'contain';
    }
  }

  return (
    <Link to={linkTo} data-testid={testId}>
      <VStack position='relative' alignItems='start' spacing={2}>
        <Flex
          alignItems='center'
          height='150px'
          w='100%'
          bgColor={!advert.photo[0] ? 'ZLGreen.100' : ''}
        >
          {advert.photo[0] ? (
            <Img
              alt={advert.attributes.title}
              width='100%'
              height='100%'
              maxW='none'
              objectFit={fit}
              src={resizedImgUrl(advert.photo[0], 'small')}
            />
          ) : (
            <Box
              borderColor='gray.400'
              borderStyle='solid'
              borderWidth='1px'
              width='100%'
              height='100%'
              maxW='none'
            />
          )}
        </Flex>

        <Text variant='sm' color='gray.550'>
          {t('news.published-on')}{' '}
          {format(new Date(advert.publishedAt), 'd MMM yyyy', { locale: myLocale }).toUpperCase()}
        </Text>

        <Heading
          variant='mdLarge'
          overflow='hidden'
          maxW='100%'
          textOverflow='ellipsis'
          color='brandColors.brandGreen'
          _hover={{ color: 'ZSGreen.800' }}
        >
          {advert.title}
        </Heading>

        <HStack spacing='10px' color='gray.600'>
          <IconSell />
          <Text variant='sm'>{advertCategory}</Text>
        </HStack>

        <HStack spacing='10px' color='gray.600'>
          <IconWallet />
          <Text variant='sm'>
            {t('adverts.price')}
            {': '}
            <Text variant='sm' as='b' color='gray.600'>
              {advert.price && advert.price !== '' ? advert.price : '-'}
            </Text>
          </Text>
        </HStack>

        <HStack spacing='10px' color='gray.600'>
          <CarbonTime />
          <Text variant='sm' color='gray.600'>
            {t('adverts.expires')}
            {': '}
            <Text variant='sm' as='b' color='gray.600'>
              {format(new Date(advert.expires), 'd MMM yyyy', { locale: myLocale }).toUpperCase()}
            </Text>
          </Text>
        </HStack>
      </VStack>
    </Link>
  );
};
