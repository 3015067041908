import axios from 'axios';
import qs from 'qs';

import { getHeaders } from './common';
import {
  PATH_CATEGORIES,
  PATH_CATEGORIES_LIST,
  PATH_SUB_CATEGORIES,
  PATH_SUB_SUB_CATEGORIES,
} from './utils/path';
import { catchError } from './utils/catch-error';

const fieldsAllCategories = ['title', 'content'];
const fieldsAllSubCategory = ['title', 'content'];
const fieldsAllSubSubCategory = ['title', 'content'];

export const fetchCategoriesList = (jwt) =>
  axios.get(
    `${PATH_CATEGORIES_LIST}?${qs.stringify({ fields: fieldsAllCategories })}`,
    getHeaders(jwt),
  );
export const fetchSingleCategory = (jwt, id, navigate, errorCallback) => {
  errorCallback(false);
  return axios
    .get(
      `${PATH_CATEGORIES}/${id}?${qs.stringify({ fields: fieldsAllCategories })}`,
      getHeaders(jwt),
    )
    .catch((error) => {
      catchError(error, undefined, navigate, errorCallback);
    });
};
export const fetchSingleSubCategory = (jwt, id, navigate, errorCallback) => {
  errorCallback(false);
  return axios
    .get(
      `${PATH_SUB_CATEGORIES}/${id}?${qs.stringify({ fields: fieldsAllSubCategory })}`,
      getHeaders(jwt),
    )
    .catch((error) => {
      catchError(error, undefined, navigate, errorCallback);
    });
};
export const fetchSingleSubSubCategory = (jwt, id, navigate, errorCallback) => {
  errorCallback(false);
  return axios
    .get(
      `${PATH_SUB_SUB_CATEGORIES}/${id}?${qs.stringify({ fields: fieldsAllSubSubCategory })}`,
      getHeaders(jwt),
    )
    .catch((error) => {
      catchError(error, undefined, navigate, errorCallback);
    });
};
