import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams, useOutletContext } from 'react-router-dom';
import { authProvider } from '../../api/auth';
import { PROVIDER_GOOGLE } from '../../constants';

export const GoogleLogin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useOutletContext();
  const authFetchedRef = useRef(false);
  const { isLogged } = useSelector((state) => state.auth);

  useEffect(() => {
    if (authFetchedRef.current || isLogged) return;
    authFetchedRef.current = true;

    const getAuth = async (search) => {
      const authResponse = await authProvider({
        provider: PROVIDER_GOOGLE,
        accessToken: search.get('access_token'),
        toast: context.toast,
        navigate: context.navigate,
      });

      if (authResponse && authResponse.data) {
        return authResponse.data;
      }

      return false;
    };

    getAuth(searchParams).then(context.handleAuthCallback);
  }, []);
};
