export const parseLanguageCode = (language) => {
  let subLang;
  if (language) {
    subLang = language.substring(0, 2);
  } else {
    subLang = 'en';
  }

  switch (subLang) {
    case 'fr': {
      return 'fr';
    }
    case 'nl': {
      return 'nl';
    }
    case 'de': {
      return 'de';
    }
    default: {
      return 'en';
    }
  }
};
