import { Box } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { reactSelectTheme } from '../../theme';

/**
 * @typedef {object} CustomSelectProps
 * @property {any} arg
 * @property {object=} styleControl
 * @property {object=} styleContainer
 * @property {any} control
 * @property {boolean=} required
 * @property {string} testId
 */

/**
 * @param {CustomSelectProps} props
 */
const CustomSelect = ({ arg, styleControl, styleContainer, control, required = false, testId }) => {
  const customStyles = {
    ...reactSelectTheme,
    container: (provided, state) => ({
      ...reactSelectTheme.container(provided, state),
      ...styleContainer,
    }),
    control: (provided, state) => ({
      ...reactSelectTheme.control(provided, state),
      ...styleControl,
    }),
  };

  return (
    <Box className='hoverGreen' data-testid={testId}>
      <Controller
        {...arg}
        control={control}
        defaultValue=''
        rules={{ required }}
        render={({ field }) => (
          <Select
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            isClearable
            styles={customStyles}
            {...field}
            {...arg}
          />
        )}
      />
    </Box>
  );
};

export default CustomSelect;
