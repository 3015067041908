export const COLORS = [
  'color-#066a5d',
  'color-#ffe443',
  'color-#FFFFFF',
  'color-#FF0000',
  'color-#FF8000',
  'color-#FFFF00',
  'color-#80FF00',
  'color-#00FF00',
  'color-#00FF80',
  'color-#00FFFF',
  'color-#0080FF',
  'color-#0000FF',
  'color-#8000FF',
  'color-#FF00FF',
  'color-#FF0080',
  'color-#D8D9DE',
  'color-#6E7889',
  'color-#000000',
];

export const styleMap = {
  H1: {
    fontSize: '2em',
  },
  H2: {
    fontSize: '1.5em',
  },
  H3: {
    fontSize: '1.17em',
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  underline: {
    textDecoration: 'underline',
  },
  link: {
    color: '#208779',
    fontWeight: 'bold',
  },
  'color-#FFFFFF': {
    color: '#FFFFFF',
  },
  'color-#EEF2F6': {
    color: '#EEF2F6',
  },
  'color-#68778D': {
    color: '#68778D',
  },
  'color-#4A5568': {
    color: '#4A5568',
  },
  'color-#000000': {
    color: '#000000',
  },
  'color-#208779': {
    color: '#208779',
  },
  'color-#066a5d': {
    color: '#066a5d',
  },
  'color-#ffe443': {
    color: '#ffe443',
  },
  'color-#E53E3E': {
    color: '#E53E3E',
  },
  'color-#FF0000': {
    color: '#FF0000',
  },
  'color-#FF8000': {
    color: '#FF8000',
  },
  'color-#FFFF00': {
    color: '#FFFF00',
  },
  'color-#80FF00': {
    color: '#80FF00',
  },
  'color-#00FF00': {
    color: '#00FF00',
  },
  'color-#00FF80': {
    color: '#00FF80',
  },
  'color-#00FFFF': {
    color: '#00FFFF',
  },
  'color-#0080FF': {
    color: '#0080FF',
  },
  'color-#0000FF': {
    color: '#0000FF',
  },
  'color-#8000FF': {
    color: '#8000FF',
  },
  'color-#FF00FF': {
    color: '#FF00FF',
  },
  'color-#FF0080': {
    color: '#FF0080',
  },
  'color-#D8D9DE': {
    color: '#D8D9DE',
  },
  'color-#6E7889': {
    color: '#6E7889',
  },
};

export const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'blockquote':
      return 'blockquote';
    case 'align-center':
      return 'align-center';
    case 'align-left':
      return 'align-left';
    case 'align-right':
      return 'align-right';
    case 'unordered-list-item':
      return 'margin-left';
    case 'ordered-list-item':
      return 'margin-left';
    case 'atomic':
      return 'img-margin';
    case 'link':
      return 'link';
    default:
      return null;
  }
};
