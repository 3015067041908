import { Avatar, Flex, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export const AvatarTooltipGroup = ({ avatars, size, spacing = '-2', max, getName, getSrc }) => {
  const [truncated, setTruncated] = useState([]);
  const [truncatedLabel, setTruncatedLabel] = useState('');
  const [main, setMain] = useState([]);

  useEffect(() => {
    let mainArray = [];
    let truncatedArray = [];
    let truncatedArrayLabel = '';

    if (avatars && avatars.length && max && avatars.length > max) {
      mainArray = avatars.slice(0, max);
      truncatedArray = avatars.slice(max);
    } else {
      mainArray = avatars;
    }

    truncatedArray.map((v) => {
      if (truncatedArrayLabel !== '') {
        truncatedArrayLabel += `, ${v.name} ${v.surname}`;
      } else {
        truncatedArrayLabel += `${v.name} ${v.surname}`;
      }
      return true;
    });

    setTruncatedLabel(truncatedArrayLabel);

    // Because avatars are displaying in a row-reverse direction, we invert the array to have contributors in most recent order
    const reversed = mainArray.toReversed();

    setMain(reversed);
    setTruncated(truncatedArray);
  }, [avatars]);

  let truncatedAvatar;
  if (truncated.length > 0) {
    truncatedAvatar = (
      <Tooltip key={'more_avatar'} label={truncatedLabel}>
        <Avatar
          cursor={'default'}
          size={size}
          name={`+${truncated.length}`}
          bg={'gray.200'}
          color={'gray.600'}
          marginInlineStart={spacing}
          getInitials={() => {
            return `+${truncated.length}`;
          }}
        />
      </Tooltip>
    );
  }

  return (
    <Flex spacing={0} direction={'row-reverse'}>
      {truncatedAvatar}
      {main.map((avatar, i) => (
        <Tooltip key={avatar.id} label={getName(avatar)}>
          <Avatar
            cursor={'default'}
            size={size}
            borderColor={'white'}
            borderWidth='2px'
            borderRadius={'full'}
            marginInlineStart={spacing}
            name={getName(avatar)}
            src={getSrc(avatar)}
          />
        </Tooltip>
      ))}
    </Flex>
  );
};
