import { HierarchyPointNode, stratify } from 'd3-hierarchy';
import React, { useState } from 'react';

import { INode } from '../type';
import { IId } from '../../../types';

interface Props<T> {
  users?: T[];
  getNewCards: (id: number[] | number) => void;
}

export const useOrgChartMobile = <
  T extends { id: number; line_manager: IId; line_workers: IId[] },
>({
  users,
  getNewCards,
}: Props<T>) => {
  interface IHierarchyPointNodeModify extends HierarchyPointNode<INode<T>> {
    hiddenChildren: IHierarchyPointNodeModify[] | undefined;
  }
  const [selectedUsers, setSelectedUsers] = useState([]);

  // ---generating user trees---
  const generateNode = (users: T[]) =>
    users.map((users, i) => ({
      id: `${users.id}`,
      type: 'myCustomNode',
      position: { x: 0, y: 100 * i },
      data: {
        ...users,
        show: false,
      },
    }));

  const generateEdge = (users: T[]) => {
    return users
      .filter((users) => users.line_manager)
      .map((users, i) => ({
        id: `e${users.id}-${users.line_manager.id}`,
        target: `${users.id}`,
        source: `${users.line_manager.id}`,
        type: 'myCustomEdge',
      }));
  };

  const getHierarchy = (rawNodes, rawEdges) => {
    if (rawNodes.length === 0) return undefined;
    const hierarchy = stratify<INode<T>>()
      .id((node) => node.id)
      .parentId((node) => rawEdges.find((edge) => edge.target === node.id)?.source);
    const root = hierarchy(rawNodes);

    return root;
  };

  const hierarchy = React.useMemo(() => {
    if (!users || !users[0]) return;
    const newNodes = generateNode(users);

    const newEdges = generateEdge(users);
    return getHierarchy(newNodes, newEdges);
  }, [users]);

  const showCards = (id: number, depthLevel: number) => {
    const childrenWereTaken = !!hierarchy.find((user) => user.data.data.id === id)?.children?.[0];
    if (!selectedUsers[0] && !hierarchy.children?.[0] && id !== hierarchy.data.data.id) {
      getNewCards([id, hierarchy.data.data.id]);
      return setSelectedUsers([hierarchy.data.data.id, id]);
    }

    if (!childrenWereTaken) {
      getNewCards(id);
    }
    if (depthLevel !== undefined) {
      return setSelectedUsers(selectedUsers.slice(0, depthLevel + 1));
    }
    setSelectedUsers([...selectedUsers, id]);
  };

  return {
    hierarchy,
    openNode: showCards,
    selectedUsers,
  };
};
