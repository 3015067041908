import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import 'reactflow/dist/style.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PATH_ORG_CHART } from '../../../constants/path';

import { UserAccordion } from './user-accordion';
import { useOrgChartMobile } from './use-org-chart-mobile';

import { IUserOrgChart } from '../../../types';

export const MobileOrgChart = ({ users, getNewCards }) => {
  const { t } = useTranslation();
  const { hierarchy, openNode, selectedUsers } = useOrgChartMobile<IUserOrgChart>({
    users,
    getNewCards,
  });

  return (
    <Box>
      <Heading variant='mdLarge' color='brandColors.brandGreen' py={4}>
        <Link to={PATH_ORG_CHART}>{t('nav.org-chart')}</Link>
      </Heading>
      {hierarchy && (
        <UserAccordion
          childrenHierarchy={[hierarchy]}
          selectedUsers={selectedUsers}
          openNode={openNode}
        />
      )}
    </Box>
  );
};
