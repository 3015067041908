import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const GoBack = ({ backText, path = false, fallback }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const handleClick = () => {
    if (path) {
      navigate(path);
    } else if (location.key && location.key === 'default' && fallback) {
      navigate(fallback);
    } else {
      navigate(-1);
    }
  };

  return (
    <Heading
      cursor={'pointer'}
      variant='mdLarge'
      onClick={handleClick}
      color='brandColors.brandGreen'
    >
      {backText || t('common.go-back')}
    </Heading>
  );
};
