import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { IUserAuth } from '../types';
import { fetchJWT } from '../api/common';

export interface AuthState {
  user?: IUserAuth | null;
  jwt?: string | null;
  isLogged: boolean;
  loading: boolean;
  locales: object | null;
}

interface AuthCaseReducers extends SliceCaseReducers<AuthState> {
  hydrate: CaseReducer<AuthState, PayloadAction<AuthState>>;
  logout: CaseReducer<AuthState>;
  updateUser: CaseReducer<AuthState, PayloadAction<IUserAuth>>;
}

interface LoginActionPayload {
  jwt: string;
  user: IUserAuth;
}

const INIT_STATE: AuthState = {
  isLogged: false,
  loading: false,
  jwt: null,
  user: null,
  locales: null,
};

export const loginAction = createAsyncThunk<LoginActionPayload, string>(
  'auth/login',
  async (authToken) => {
    const { data } = await fetchJWT(authToken);
    return data;
  },
);

export const authSlice = createSlice<AuthState, AuthCaseReducers>({
  name: 'auth',
  initialState: {
    ...INIT_STATE,
  },
  reducers: {
    hydrate: (state, action) => {
      return { ...state, ...action.payload };
    },
    logout: (state) => {
      state.isLogged = false;
      state.jwt = null;
      state.user = null;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAction.fulfilled, (state, action: PayloadAction<LoginActionPayload>) => {
      const { jwt, user } = action.payload;
      state.isLogged = true;
      state.jwt = jwt;
      state.user = user;
    });
  },
});

export const {
  logout: logoutAction,
  hydrate: hydrateAction,
  updateUser: updateUserAction,
} = authSlice.actions;
