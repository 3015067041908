import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Center, Flex, Grid, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { IKnowledgeBasesMainFolder, IUserAuth } from '../../types';

import { PATH_KNOWLEDGE_BASE } from '../../constants';
import { resizedImgUrl } from '../../helpers/imageHelper';

interface CategoryProps {
  knowledgeBases: IKnowledgeBasesMainFolder[] | undefined;
  user: IUserAuth;
}

export const Category: React.FC<CategoryProps> = ({ knowledgeBases = [], user }) => {
  const navigate = useNavigate();

  return (
    <Grid
      justifyContent='center'
      h='100%'
      w='100%'
      gridTemplateColumns='repeat(auto-fill, minmax(328px, 328px))'
      gap={4}
      data-testid='knowledgeBaseListContainer'
    >
      {knowledgeBases?.map((data, i) => {
        if (
          !data.country?.id ||
          data.country?.id === user?.country?.id ||
          user?.optional_countries?.map((c) => c.id).includes(data.country.id)
        ) {
          return (
            <Box
              key={i}
              w='100%'
              cursor='pointer'
              onClick={() => navigate(`${PATH_KNOWLEDGE_BASE}/${data.id}`)}
              _hover={{
                color: 'gray.300',
              }}
              color='white'
              data-testid='knowledgeBaseRepository'
            >
              <VStack
                w='100%'
                h='240px'
                bgColor='#679C95'
                borderRadius={8}
                border='0px solid transparent'
              >
                <Image
                  objectFit='contain'
                  h='168px'
                  w='100%'
                  alt=''
                  src={resizedImgUrl(data.photo, 'large')}
                  borderRadius='8px 8px 0px 0px'
                  border='0px solid transparent'
                />
                <Center h='100%' w='100%'>
                  <Heading variant='smLarge' wordBreak='break-word' fontWeight={700}>
                    {data.title}
                  </Heading>
                </Center>
              </VStack>
            </Box>
          );
        }
        return null;
      })}
    </Grid>
  );
};
