import axios, { AxiosResponse } from 'axios';
import { IDepartmentName } from '../types';
import { getHeaders } from './common';
import { PATH_FIND_MANY_DEPARTMENTS } from './utils/path';
import { catchError } from './utils/catch-error';

export const customFindManyDepartments = async ({ jwt, toast, navigate }) =>
  axios
    .get(PATH_FIND_MANY_DEPARTMENTS, getHeaders(jwt))
    .then((response: AxiosResponse<IDepartmentName[]>) => response.data)
    .catch((error) => catchError(error, toast, navigate));
