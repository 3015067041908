import { Box, Button, Flex, Grid, Img, Link, Text, VStack } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import envelope from '../../assets/icons/ziegler-one/envelope.png';
import phone from '../../assets/icons/ziegler-one/phone.png';
import pin from '../../assets/icons/ziegler-one/pin.png';
import website from '../../assets/icons/ziegler-one/website.png';
import zieglerOneIcon from '../../assets/ziegler-one-button.png';
import zieglerOneHeader from '../../assets/ziegler-one-header.png';
import zieglerOneLogo from '../../assets/ziegler-one-logo.png';
import { Helmet } from '../../components-common/helmet';

const AGENT_QUOTE_FORM_DIRECTORY = 'https://www.zieglerone.com/agent-quote-form';
const INFO_EMAIL = 'info@zieglerone.com';
const ZIEGLER_ONE_LINK = 'https://www.zieglerone.com/';
const PHONE1 = '+27 76 394 6989';
const PHONE2 = '+27 83 235 5763';
const ADDRESS = 'Chaussee de Vilvorde 11, Brussels, Belgium, 1120';
const ZIEGLER_ONE_TEAMS_LINK = 'https://www.zieglerone.com/ziegler-one-teams-home';

export const ZieglerOnePage = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column' color='ZSBlue.600' data-testid={'aboutZieglerOneContent'} pb={12}>
      <Box alignSelf='center' width='90%' color='gray.500' data-testid='zieglerOneContainer'>
        <Helmet title={t('title.ziegler-one')} />
        <VStack alignItems={'start'} gap={8}>
          <Img src={zieglerOneLogo} maxW={'150px'} />
          <Link href={ZIEGLER_ONE_TEAMS_LINK} target='_blank'>
            <Img src={zieglerOneHeader} />
          </Link>
          <Button
            as='a'
            target='_blank'
            href={ZIEGLER_ONE_TEAMS_LINK}
            leftIcon={
              <Box w='60px'>
                <Img src={zieglerOneIcon} />
              </Box>
            }
            variant='ZGreenGhost'
            textTransform='uppercase'
            border='none'
            w='420px'
            py={8}
            whiteSpace='normal'
            wordBreak='break-word'
            overflow='hidden'
          >
            {t('ziegler-one.platform-access')}
          </Button>
          <Text variant='lg' color={'black'}>
            <Trans i18nKey='ziegler-one.description'>
              Ziegler One serves as a strategic support division to manage the onboarding strategy
              of all agents on behalf of the Ziegler Group, while procuring new agents into the
              Ziegler Partnership Platform.
            </Trans>
          </Text>
          <Button
            alignSelf={'center'}
            as='a'
            target='_blank'
            href={ZIEGLER_ONE_TEAMS_LINK}
            variant='zieglerButton'
            textTransform='uppercase'
            size={'sm'}
          >
            {t('ziegler-one.agent-dashboard')}
          </Button>
          <Box w={'100%'}>
            <Box w={'80px'} borderTop={'4px solid'} borderColor={'ZYellow.300'} mb={4} />
            <Text fontSize='3xl' color='ZLGreen.600'>
              {t('ziegler-one.contact-us')}
            </Text>

            <Grid
              gridRow='auto auto'
              gridColumnGap={4}
              gridRowGap={4}
              gridTemplateColumns='auto auto'
              mb={8}
            >
              <Flex alignItems='center'>
                <Flex width='90px' alignItems='center' justifyContent='center'>
                  <Img src={envelope} />
                </Flex>
                <Box>
                  <Link href={`mailto:${AGENT_QUOTE_FORM_DIRECTORY}`} target='_blank'>
                    <Text fontSize='small'>{INFO_EMAIL}</Text>
                  </Link>
                </Box>
              </Flex>
              <Flex alignItems='center'>
                <Flex width='90px' alignItems='center' justifyContent='center'>
                  <Img src={website} />
                </Flex>
                <Box>
                  <Link href={ZIEGLER_ONE_LINK} target='_blank'>
                    <Text fontSize='small'>www.zieglerone.com</Text>
                  </Link>
                </Box>
              </Flex>
              <Flex alignItems='center'>
                <Flex width='90px' alignItems='center' justifyContent='center'>
                  <Img src={phone} />
                </Flex>
                <Box>
                  <Link href={`tel:${PHONE1}`} target='_blank'>
                    <Text fontSize='small'>{PHONE1}</Text>
                  </Link>
                  <Link href={`tel:${PHONE2}`} target='_blank'>
                    <Text fontSize='small'>{PHONE2}</Text>
                  </Link>
                </Box>
              </Flex>
              <Flex alignItems='center'>
                <Flex width='90px' alignItems='center' justifyContent='center'>
                  <Img src={pin} />
                </Flex>
                <Box>
                  <Text fontSize='small'>{ADDRESS} </Text>
                </Box>
              </Flex>
            </Grid>
          </Box>
        </VStack>
      </Box>
    </Flex>
  );
};
