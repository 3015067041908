import cookiesList from '../config/cookies.json';
import { PATH_PRIVACY } from '../constants';

export const cookiesData = (t) => ({
  colorBackground: '#fff',
  forceShow: !JSON.parse(localStorage.getItem('accept-cookie-bar')),
  storageKey: 'accept-cookie-bar',
  cookieText: t('cookie-bar.cookie-text'),
  acceptButtonText: t('cookie-bar.accept-button-text'),
  updateConsentText: t('cookie-bar.update-consent-text'),
  necessaryCookiesKey: 'strictlyNecessary',
  cookieObjects: [JSON.parse(JSON.stringify(cookiesList))],
  cookieHeading: t('cookie-bar.cookie-heading'),
  modalDetails: {
    heading: t('cookie-bar.modal-heading'),
    cookieLink: { url: PATH_PRIVACY, title: t('cookie-bar.modal-link-title') },
    necessaryCookieLabel: t('cookie-bar.modal-necessary-cookies-label'),
    showDetailsLabel: t('cookie-bar.modal-show-details'),
    hideDetailsLabel: t('cookie-bar.modal-hide-details'),
    buttonLabel: t('cookie-bar.modal-button-label'),
  },
  theme: 'ZGreen',
});
