import { PATH_LOGIN, PATH_MAINTENANCE, PATH_NOT_FOUND, PATH_FORBIDDEN } from '../../constants';

export const catchError = (error, toast, navigate, errorCallback = null): false => {
  if (error.response.status === 401 && navigate) {
    navigate(PATH_LOGIN);
    return false;
  }
  if (error.response.status === 404 && navigate) {
    navigate(PATH_NOT_FOUND);
    return false;
  }
  if (error.response.status === 502 && navigate) {
    navigate(PATH_MAINTENANCE);
    return false;
  }
  if (error.response.status === 403 && navigate) {
    navigate(PATH_FORBIDDEN);
    return false;
  }

  if (toast) {
    toast({
      title: error.code,
      description: error.response?.data?.error?.message || error.message,
      status: 'error',
      duration: 9000,
      position: 'bottom-left',
    });
  }

  if (errorCallback) {
    errorCallback(true);
  }

  return false;
};
