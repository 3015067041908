const layerStyles = {
  container: {
    bgColor: '#fff',
    boxShadow: 'md',
    border: '1px solid',
    borderColor: 'gray.200',
    borderRadius: 4,
    p: 6,
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column-reverse',
  },
  iconWrapper: {
    fontSize: 'small',
    m: 1,
    minW: '16px',
    minH: '16px',
    ml: '-4px',
  },
  treeCardsGrid: {
    templateColumns: [
      'repeat(2, 1fr)',
      'repeat(2, 1fr)',
      'repeat(3, 1fr)',
      'repeat(3, 1fr)',
      'repeat(3, 1fr)',
    ],
    gap: 4,
  },
};

export default layerStyles;
