import { HierarchyNode } from 'd3-hierarchy';
import React from 'react';
import { Box } from '@chakra-ui/react';
import 'reactflow/dist/style.css';

import { INode, IOpenNode } from '../type';
import { IUserNameAndJobTitle, IUserOrgChart } from '../../../types';
import { UserCartAccordion } from './user-cart-accordion';

interface Props {
  childrenHierarchy: HierarchyNode<INode<IUserOrgChart>>[] | IUserNameAndJobTitle[];
  selectedUsers: number[];
  openNode: IOpenNode;
  depthLevel?: number;
}

export const UserAccordion = ({
  childrenHierarchy,
  selectedUsers,
  openNode,
  depthLevel = 0,
}: Props) => (
  <>
    {childrenHierarchy.map((user, i) => {
      const selectedUser = !!(selectedUsers?.[depthLevel] === parseInt(user?.id, 10));
      const parentUser = !!(
        selectedUsers.length - depthLevel > 0 &&
        !selectedUser &&
        depthLevel !== 0
      );
      const childUser = !!(selectedUsers.length - depthLevel > 0 && selectedUser);

      const userData = user.data?.data || user;
      const nextAcordionChildren =
        (selectedUser && user.children) || (depthLevel === 0 && user.data?.data.line_workers);
      const isLast = !childUser && childrenHierarchy.length === i + 1;

      const style = {
        border: '1px solid',
        borderTop: depthLevel === 0 ? '1px solid' : '0px',
        borderColor: 'var(--chakra-colors-gray-200)',
        borderRadius: `${depthLevel === 0 ? '8px 8px' : '0 0'} ${
          isLast && !nextAcordionChildren
            ? '8px 8px'
            : `0 ${
                selectedUsers.length - depthLevel === 2 ||
                selectedUsers.length - depthLevel === 1 ||
                !selectedUsers[0]
                  ? '8px'
                  : '0'
              }`
        }`,
      };
      const getAnimation = (): string => {
        if (parentUser) {
          return 'closeBox';
        }
        if (!selectedUser && depthLevel !== 0 && childrenHierarchy.length > 1) {
          return 'openBoxDelay';
        }
        return 'openBox';
      };
      return (
        <>
          <Box
            transition={'all 1s'}
            className={getAnimation()}
            ml={!childUser && depthLevel !== 0 && 6}
            key={userData.id}
          >
            <UserCartAccordion
              childUser={childUser}
              style={style}
              user={userData}
              selectedUsers={selectedUsers}
              openNode={openNode}
              depthLevel={depthLevel}
              selectedUser={selectedUser}
            />
          </Box>
          {nextAcordionChildren && (
            <Box ml={selectedUsers.length - depthLevel === 2 && 6}>
              <UserAccordion
                childrenHierarchy={nextAcordionChildren}
                selectedUsers={selectedUsers}
                openNode={openNode}
                depthLevel={depthLevel + 1}
              />
            </Box>
          )}
        </>
      );
    })}
  </>
);
