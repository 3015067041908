import { Box, Button, Center, Flex, Img, Text, Tooltip, useToast } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { googleGetAllFiles } from '../../../api';
import { ReactComponent as IconInfo } from '../../../assets/icons/knowledge-base/Icon_info.svg';
import { parserDate } from '../../../components-common/parser-date';
import {
  GOOGLE_DRIVE_JPEG_TYPE,
  GOOGLE_DRIVE_PDF_TYPE,
  GOOGLE_DRIVE_PNG_TYPE,
  ICON_SIZE_MEDIUM,
  ICON_SIZE_SMALL,
  MIME_TYPE_FOLDER,
  OPEN_LINK_DRIVE,
  THUMBNAIL_SIZE_IMAGE_MEDIUM,
  THUMBNAIL_SIZE_IMAGE_SMALL,
} from '../../../constants';
import useInfiniteScroll from '../../../helpers/hooks/useInfiniteScroll';
import { ReduxState } from '../../../redux/state';
import { DriveData, DriveItem } from '../drive-item';

interface RenderFolderListProps {
  setIsSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  dataFolder: {
    id: string;
    nextPageToken?: string;
    files: DriveItem[];
  };
  updateParentFolder: (files: DriveItem[]) => void;
  setPathList: Dispatch<SetStateAction<DriveItem[]>>;
  pathList: { id: string; name: string }[];
  leftToolbar: React.ReactNode;
  sortGoogleDrive: string;
  setDriveData: Dispatch<SetStateAction<DriveData>>;
  setCurrentFolderId: Dispatch<SetStateAction<string | null>>;
  activeId: string | undefined;
  onDrawerOpen: (string) => void;
  isSharedDrive: boolean;
}

export const RenderFolderList: React.FC<RenderFolderListProps> = ({
  setIsSpinner,
  dataFolder,
  updateParentFolder,
  setPathList,
  pathList,
  leftToolbar,
  sortGoogleDrive,
  setDriveData,
  setCurrentFolderId,
  activeId,
  onDrawerOpen,
  isSharedDrive,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { jwt } = useSelector((state: ReduxState) => state.auth);

  const handleObserver = async ({ setIsFetching }) => {
    const { id, nextPageToken } = dataFolder;
    if (nextPageToken) {
      setIsSpinner(true);
      const response = await googleGetAllFiles(
        jwt,
        toast,
        navigate,
        id,
        sortGoogleDrive,
        undefined,
        nextPageToken,
        true,
        isSharedDrive,
      );

      setDriveData({
        ...dataFolder,
        files: [...(dataFolder.files || []), ...response.files],
        nextPageToken: response.nextPageToken,
      });

      setIsSpinner(false);
      setIsFetching(false);
    }
  };

  useInfiniteScroll(handleObserver);

  const openFolder = async (folderData: DriveItem) => {
    setIsSpinner(true);
    const response = await googleGetAllFiles(
      jwt,
      toast,
      navigate,
      folderData.id,
      sortGoogleDrive,
      undefined,
      undefined,
      true,
      isSharedDrive,
    );
    setCurrentFolderId(folderData.id);
    updateParentFolder(response.files);
    setPathList([...pathList, { id: folderData.id, name: folderData.name }]);
    setIsSpinner(false);
  };

  const openFile = (fileId: string) => {
    window.open(OPEN_LINK_DRIVE + fileId, '_blank');
  };

  const handlerFile = (file: DriveItem) => {
    if (file.mimeType === MIME_TYPE_FOLDER) {
      return openFolder(file);
    }
    return openFile(file.id);
  };

  const renderFolders = dataFolder.files?.map((file: DriveItem, i: number) => (
    <Flex
      key={i}
      cursor='pointer'
      onClick={() => handlerFile(file)}
      borderBottom='1px solid'
      borderColor='gray.100'
      px={0}
      alignItems='center'
      bgColor={file.id === activeId ? 'ZLGreen.50' : 'transparent'}
      _hover={{
        bgColor: 'gray.100',
      }}
      data-testid={file.mimeType === MIME_TYPE_FOLDER ? 'knowledgeBaseFolder' : 'knowledgeBaseFile'}
    >
      <Center w='52px' justifyContent='flex-start'>
        <Img
          src={
            file.mimeType === GOOGLE_DRIVE_PDF_TYPE ||
            file.mimeType === GOOGLE_DRIVE_JPEG_TYPE ||
            file.mimeType === GOOGLE_DRIVE_PNG_TYPE
              ? file.thumbnailLink?.replace(THUMBNAIL_SIZE_IMAGE_MEDIUM, THUMBNAIL_SIZE_IMAGE_SMALL)
              : file.iconLink?.replace(ICON_SIZE_SMALL.toString(), ICON_SIZE_MEDIUM.toString())
          }
        />
      </Center>
      <Box flex='1' h='100%' mx={4}>
        <Text
          w='100%'
          textAlign='left'
          display='-webkit-box'
          overflow='hidden'
          wordBreak='break-all'
          fontWeight={700}
          sx={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: '1' }}
        >
          {file.name}
        </Text>
        <Tooltip label={file.description}>
          <Text
            display='-webkit-box'
            overflow='hidden'
            wordBreak='break-all'
            sx={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: '1' }}
          >
            {file.description}
          </Text>
        </Tooltip>
      </Box>
      <Button
        variant='ZGreenLink'
        size='xs'
        mx={4}
        onClick={(event) => {
          event.stopPropagation();
          onDrawerOpen(file.id);
        }}
        bgColor={file.id === activeId ? 'ZSGreen.50' : 'transparent'}
      >
        <IconInfo fill='#4A5568' stroke='#4A5568' />
      </Button>
      <Center color='gray.550' justifyContent='flex-start'>
        <Text fontSize='small'>{parserDate(file.createdTime)}</Text>
      </Center>
    </Flex>
  ));

  return (
    <Flex w='100%' direction='column' alignItems='center' pb={8}>
      <Flex mb={6} justifyContent='flex-end' w='100%'>
        {leftToolbar}
      </Flex>
      <Flex w='100%' transition='.5s' color='gray.600'>
        <Box transition='.5s' w='100%'>
          {renderFolders}
        </Box>
      </Flex>
    </Flex>
  );
};
