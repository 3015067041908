import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import { CookieBar } from '@dornach/ziegler-ui-kit';
import { getCategoriesAction, getFavouritesAction, logoutAction } from '../../redux';
import { Header } from '../header';
import {
  PATH_NEWS,
  PATH_ROOT,
  PATH_WELCOME,
  ROLE_INTRANET_ADMIN,
  PATH_TERMS,
  PATH_PRIVACY,
  PATH_UNAUTHORIZED,
  PATH_NOT_FOUND,
  PATH_MAINTENANCE,
  PATH_LOGIN,
  FILTER_MENU_GAP_ROW,
  MENU_HEIGHT,
  PATH_ORG_CHART,
} from '../../constants';
import { fetchBanner } from '../../api/banner';
import { strapiDataParserSingle } from '../../common/strapi';
import { Helmet } from '../../components-common/helmet';
import { cookiesData } from '../../helpers/cookieHelper';
import { logOut } from '../../api/auth';

export const MainScreen = () => {
  const [banner, setBanner] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const { jwt, user } = useSelector((state) => state.auth);
  const { isInError: isInReduxError } = useSelector((state) => state.error);
  const [cookieBarData] = useState(cookiesData(t));

  const isInError =
    [PATH_NOT_FOUND, PATH_MAINTENANCE, PATH_UNAUTHORIZED].includes(location.pathname) ||
    isInReduxError;

  const isOrgChart = [PATH_ORG_CHART].includes(location.pathname);

  const indexEndSectionURL = location.pathname.indexOf('/', 1);
  const activeLocation = location.pathname.substr(
    0,
    indexEndSectionURL !== -1 ? indexEndSectionURL : undefined,
  );

  const getBanner = async () => {
    await fetchBanner(jwt, i18n.language, (newBanner) => {
      setBanner(strapiDataParserSingle(newBanner));
    });
  };

  useEffect(() => {
    if (!user.blocked) {
      dispatch(getCategoriesAction({ jwt, currentPath: location.pathname }));
      dispatch(getFavouritesAction({ jwt }));
      getBanner();
    }
  }, [i18n.language]);

  useEffect(() => {
    if (user.blocked) {
      return navigate(PATH_UNAUTHORIZED);
    }
    if (
      !user.afterFirstLogin &&
      user.role?.name !== ROLE_INTRANET_ADMIN &&
      ![PATH_PRIVACY, PATH_TERMS, PATH_WELCOME].includes(activeLocation)
    ) {
      return navigate(PATH_WELCOME);
    }
    if ([PATH_ROOT, PATH_UNAUTHORIZED].includes(activeLocation)) {
      return navigate(PATH_NEWS);
    }
  }, []);

  const loggingOut = useCallback(() => {
    logOut({
      callback: () => {
        if (dispatch(logoutAction())) {
          navigate(PATH_LOGIN);
        }
      },
    });
  }, [dispatch]);

  return (
    <>
      <Helmet title={t('title.default')} />
      {user.blocked ? (
        <Header isInError={isInError} />
      ) : (
        <Header
          banner={banner}
          logoutAction={loggingOut}
          activeLocation={activeLocation}
          isInError={isInError}
        />
      )}
      <Box minH={`calc(100vh - ${MENU_HEIGHT})`} w='80%' marginStart='auto' marginEnd='auto'>
        <Outlet />
      </Box>
      {cookieBarData && <CookieBar {...cookiesData(t)} />}
    </>
  );
};
