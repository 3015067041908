import { Icon } from '@chakra-ui/react';
import {
  IconIntEmployee,
  IconIntLocalization,
  IconIntMail,
  IconIntOffice,
  IconIntPhone,
} from '@dornach/ziegler-ui-kit';
import { TFunction } from 'i18next';
import { FaUserTie } from 'react-icons/fa';
import { GoGlobe as IconGlobe } from 'react-icons/go';
import { RiBriefcase2Line } from 'react-icons/ri';
import { ReactComponent as IconMobile } from '../../assets/icons/users/icon_mobile.svg';

import { IUserAuth } from '../../types';

export const companyName = (user: IUserAuth) => ({
  icon: (arg) => <IconIntOffice {...arg} />,
  label: `${user.company?.companyName ?? ''} ${user.department?.departmentName ?? ''}`,
  condition: !!(user.company?.companyName || user.department?.departmentName),
  dataTestId: 'userCompany',
});
export const countryName = (user: IUserAuth) => ({
  icon: (arg) => <IconIntLocalization {...arg} />,
  label: `${user.country?.countryName ?? ''} - ${user.branchList?.branchName ?? ''}`,
  condition: !!(user.country?.countryName || user.branchList?.branchName),
  dataTestId: 'userCountry',
});
export const ekspertise = (user: IUserAuth) => ({
  icon: (arg) => <IconIntEmployee {...arg} />,
  label: user.ekspertise?.expertiseName,
  condition: !!user.ekspertise?.expertiseName,
  dataTestId: 'userExpertise',
});
export const workPhone = (user: IUserAuth) => ({
  icon: (arg) => <IconIntPhone {...arg} />,
  label: user.workPhone,
  condition: !!user.workPhone,
  dataTestId: 'userPhone',
});
export const mobilePhone = (user: IUserAuth) => ({
  icon: (arg) => <IconMobile />,
  label: user.mobilePhone,
  condition: !!user.mobilePhone,
  dataTestId: 'userMobilePhone',
});
export const email = (user: IUserAuth) => ({
  icon: (arg) => <IconIntMail {...arg} />,
  label: user.email,
  condition: !!user.email,
  dataTestId: 'userEmail',
});
export const jobTitle = (user: IUserAuth) => ({
  icon: (arg) => <Icon as={RiBriefcase2Line} ml={0.5} mt={0.5} {...arg} />,
  label: user.job_title?.name,
  condition: !!user.job_title,
  dataTestId: 'userJobTitle',
});
export const lineManager = (user: IUserAuth) => ({
  icon: (arg) => <Icon as={FaUserTie} ml={0.5} {...arg} />,
  label: `${user.line_manager?.name ?? ''} ${user.line_manager?.surname ?? ''}`,
  condition: !!user.line_manager,
  dataTestId: 'userLineManager',
});

export const languages = (user: IUserAuth, t: TFunction<string>) => ({
  icon: (arg) => <Icon as={IconGlobe} ml={0.5} mt={1} {...arg} />,
  label: user.languages?.map((option) => t(`languages.${option.name}`)).join(', '),
  condition: !!user.languages?.[0],
  dataTestId: 'userlanguages',
});
