import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuButton, MenuList, MenuItem, Text } from '@chakra-ui/react';
import { ISortType } from '../../../types';

import { ReactComponent as IconSortDown } from '../../../assets/icons/knowledge-base/Icon_sort_down.svg';
import { ReactComponent as IconSortUp } from '../../../assets/icons/knowledge-base/Icon_sort_up.svg';
import { SORT_ORDER_ASC, SORT_ORDER_DESC } from '../../../constants';

interface SortProps {
  sortTypes: ISortType[];
  sortType: ISortType;
  setSortType: (sortType: ISortType) => void;
  sortOrder: string;
  setSortOrder: (sortOrder: string) => void;
}

export const Sort: React.FC<SortProps> = ({
  sortTypes,
  sortType,
  setSortType,
  sortOrder,
  setSortOrder,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      display='flex'
      variant='ZGreenLink'
      color='gray.600'
      size='xs'
      justifyContent='flex-end'
    >
      <Menu>
        <MenuButton
          fontSize='14px'
          variant='ZGreenLink'
          size='xs'
          color='#68778D'
          fontWeight={500}
          as={Button}
        >
          <Text display='inline-block'>{t('knowledge-base.sort-by')}:</Text>
          <Text display='inline-block' ml={1} fontWeight={700}>
            {t(sortType.text)}
          </Text>
        </MenuButton>
        <MenuList borderColor='gray.200' color={'ZSGreen.700'}>
          {sortTypes.map((dataSort, i) => (
            <MenuItem
              key={i}
              fontSize={'16px'}
              onClick={() => setSortType(dataSort)}
              bgColor={sortType.text === dataSort.text ? 'ZSGreen.50' : undefined}
              _hover={{ bgColor: 'ZLGreen.50' }}
            >
              {t(dataSort.text)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Button
        onClick={() =>
          setSortOrder(sortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC)
        }
        variant='ZGreenLink'
        color='gray.600'
        size='xs'
        ml={2}
      >
        {sortOrder !== SORT_ORDER_ASC ? <IconSortDown /> : <IconSortUp />}
      </Button>
    </Button>
  );
};
