import axios from 'axios';
import qs from 'qs';
import { getHeaders } from './common';
import { catchError } from './utils/catch-error';
import { PATH_LIKES } from './utils/path';
import { maxLimit } from './utils/common';

export const fetchLikes = (jwt, toast, navigate, filters, fields, populate) => {
  const query = qs.stringify({
    filters,
    fields,
    populate,
    pagination: {
      pageSize: maxLimit,
      page: 1,
    },
  });
  return axios
    .get(`${PATH_LIKES}?${query}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};

export const countLikes = (jwt, toast, navigate, resource, resourceId) => {
  const query = qs.stringify({
    resource,
    resource_id: resourceId,
  });
  return axios
    .get(`${PATH_LIKES}/getCount?${query}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};

export const addLike = (jwt, toast, navigate, resource, resourceId, user) => {
  const data = {
    data: {
      resource,
      resource_id: resourceId,
      users_permissions_user: user,
    },
  };
  return axios
    .post(PATH_LIKES, data, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};

export const deleteLike = (jwt, toast, navigate, id, resource, resourceId) => {
  const query = qs.stringify({
    resource,
    resource_id: resourceId,
  });
  return axios
    .delete(`${PATH_LIKES}/${id}?${query}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};
