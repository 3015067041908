import axios from 'axios';
import qs from 'qs';
import { getHeaders } from './common';
import { PATH_DORNACH_INTERVENTION } from './utils/path';

export const fetchDornachIntervention = (jwt, callback) =>
  axios
    .get(
      `${PATH_DORNACH_INTERVENTION}?${qs.stringify({
        fields: ['content'],
      })}`,
      getHeaders(jwt),
    )
    .then((response) => {
      if (response.data.error) {
        // eslint-disable-next-line no-console
        console.warn(response.data.error);
      } else {
        callback?.(response);
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.warn(error);
    });
