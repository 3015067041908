import React from 'react';
import { Box, GridItem, Text } from '@chakra-ui/react';

export const GridInputWrapper = ({
  name,
  error,
  rowSpan = 1,
  colSpan = 1,
  arg,
  isRequired,
  children,
  testId,
}) => {
  return (
    <GridItem layerStyle='inputWrapper' rowSpan={rowSpan} colSpan={colSpan} {...arg}>
      {error && <Text color='red.500'>{error.message}</Text>}
      {children}
      <Box className='labelGreen' data-testid={testId && `${testId}Label`}>
        <Text display='inline'>{name}</Text>
        {isRequired && (
          <Text color='red.500' className='labelGreen' display='inline'>
            *
          </Text>
        )}
      </Box>
    </GridItem>
  );
};
