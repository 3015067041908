import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelCase, find, flatMap, get, snakeCase } from 'lodash';
import { fetchCategoriesList } from '../api/categories';
import { PATH_ABOUT_ZIEGLER, PATH_RELATIVE_DORNACH_PAGE, PATH_ZIEGLER_ONE } from '../constants';
import { parsePath } from '../helpers/pathHelper';

const INIT_STATE = {
  categories: null,
  activeCategory: null,
  activeSubCategory: null,
  activeSubSubCategory: null,
};

export const getCategoriesAction = createAsyncThunk('auth/getCategories', async (params) => {
  const { data } = await fetchCategoriesList(params.jwt);
  const activeLocation = params.currentPath;
  const initiallyActive = {};

  const categDornach = {
    id: 99999,
    title: 'Dornach',
    icon: null,
    about_sub_categories: [],
  };

  const categZOne = {
    id: 999999,
    title: 'ZieglerOne',
    icon: null,
  };

  let initialCategory;
  if (activeLocation.includes(`${PATH_ABOUT_ZIEGLER}/${PATH_RELATIVE_DORNACH_PAGE}`)) {
    initialCategory = categDornach;
  } else if (activeLocation.includes(`${PATH_ABOUT_ZIEGLER}/${PATH_ZIEGLER_ONE}`)) {
    initialCategory = categZOne;
  } else {
    initialCategory = find(data, (category) =>
      activeLocation.includes(`${PATH_ABOUT_ZIEGLER}/${parsePath(get(category, 'title'))}`),
    );
  }

  initiallyActive.category = get(initialCategory, 'id');

  data.push(categDornach);
  data.push(categZOne);

  if (initialCategory) {
    const subCategories = flatMap(data, (category) => category.about_sub_categories);
    const initialSubCategory = find(subCategories, (subCategory) =>
      activeLocation.includes(
        `${PATH_ABOUT_ZIEGLER}/${parsePath(get(initialCategory, 'title'))}/${parsePath(
          get(subCategory, 'title'),
        )}`,
      ),
    );
    initiallyActive.subCategory = get(initialSubCategory, 'id');

    if (initialSubCategory) {
      const subSubCategories = flatMap(
        subCategories,
        (subCategory) => subCategory.about_sub_sub_categories,
      );
      const initialSubSubCategory = find(
        subSubCategories,
        (subSubCategory) =>
          activeLocation ===
          `${PATH_ABOUT_ZIEGLER}/${parsePath(get(initialCategory, 'title'))}/${parsePath(
            get(initialSubCategory, 'title'),
          )}/${parsePath(get(subSubCategory, 'title'))}`,
      );
      initiallyActive.subSubCategory = get(initialSubSubCategory, 'id');
    }
  }

  return { data, initiallyActive };
});

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    ...INIT_STATE,
  },
  reducers: {
    setActiveCategory: (state, action) => {
      const activeCategoryCandidate = find(
        state.categories,
        (category) => category.id === action.payload,
      );
      state.activeSubSubCategory = null;
      state.activeSubCategory = null;
      state.activeCategory = get(activeCategoryCandidate, 'id');
    },
    setActiveSubCategory: (state, action) => {
      const activeCategoryCandidate = find(
        state.categories,
        (category) => category.id === action.payload.category,
      );
      state.activeCategory = get(activeCategoryCandidate, 'id');

      const subCategories = flatMap(state.categories, (category) => category.about_sub_categories);
      const activeSubCategoryCandidate = find(subCategories, (subCategory) => {
        return subCategory.id === action.payload.subCategory;
      });
      state.activeSubCategory = get(activeSubCategoryCandidate, 'id');

      state.activeSubSubCategory = null;
    },
    setActiveSubSubCategory: (state, action) => {
      const activeCategoryCandidate = find(
        state.categories,
        (category) => category.id === action.payload.category,
      );
      state.activeCategory = get(activeCategoryCandidate, 'id');

      const subCategories = flatMap(state.categories, (category) => category.about_sub_categories);
      const activeSubCategoryCandidate = find(
        subCategories,
        (subCategory) => subCategory.id === action.payload.subCategory,
      );
      state.activeSubCategory = get(activeSubCategoryCandidate, 'id');

      const subSubCategories = flatMap(
        subCategories,
        (subCategory) => subCategory.about_sub_sub_categories,
      );
      const activeSubSubCategoryCandidate = find(subSubCategories, (subSubCategory) => {
        return subSubCategory.id === action.payload.subSubCategory;
      });
      state.activeSubSubCategory = get(activeSubSubCategoryCandidate, 'id');
    },
    setActiveCategoryRecursive: (state, action) => {
      state.activeCategory = null;
      state.activeSubCategory = null;
      state.activeSubSubCategory = null;

      const activeRecursiveCategoryCandidate = ({ data, name = 'Category', prefix = 'Sub' }) => {
        const newName = camelCase(`${prefix}_${name}`);
        const newNameActive = camelCase(`active_${newName}`);

        if (!action.payload[newNameActive]) {
          state[newNameActive] = null;
          return;
        }
        const newData = flatMap(
          data,
          (category) => category[`about_${snakeCase(newName)}`.replace('category', 'categories')],
        );
        const dataCandidate = find(
          newData,
          (candidate) => candidate.id === action.payload[newNameActive],
        );
        state[newNameActive] = get(dataCandidate, 'id');
        activeRecursiveCategoryCandidate({ data: newData, name: newName });
      };

      const activeCategoryCandidate = find(
        state.categories,
        (category) => category.id === action.payload.activeCategory,
      );
      state.activeCategory = get(activeCategoryCandidate, 'id');

      return activeRecursiveCategoryCandidate({ data: state.categories });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoriesAction.fulfilled, (state, action) => {
      const { category, subCategory, subSubCategory } = action.payload.initiallyActive;
      state.categories = action.payload.data;
      state.activeCategory = category;
      state.activeSubCategory = subCategory;
      state.activeSubSubCategory = subSubCategory;
    });
  },
});

export const {
  setActiveCategory: setActiveCategoryAction,
  setActiveSubCategory: setActiveSubCategoryAction,
  setActiveSubSubCategory: setActiveSubSubCategoryAction,
  setActiveCategoryRecursive: setActiveCategoryRecursiveAction,
} = categoriesSlice.actions;
