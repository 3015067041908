import React from 'react';
import { ErrorScreen } from '../components-common/error';
import { ReactComponent as NotFoundIcon } from '../assets/icons/not-found.svg';
import { ReactComponent as ErrorIcon } from '../assets/icons/error.svg';

export const NotFoundScreen = () => (
  <ErrorScreen label={'common.not-found'} icon={<NotFoundIcon />} />
);

export const ForbiddenScreen = () => (
  <ErrorScreen label={'common.forbidden'} icon={<ErrorIcon />} />
);
export const ErrorHandleScreen = () => (
  <ErrorScreen label={'common.something-went-wrong'} icon={<ErrorIcon />} />
);
