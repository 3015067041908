import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { find, map, isEmpty, omit, pick, filter, camelCase } from 'lodash';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';

import { DebouncedInput } from '../../components-common/debounced-input';

export const Table = forwardRef(
  (
    {
      headers,
      data,
      search,
      pinnable = true,
      defaultLeftPinned,
      defaultRightPinned,
      defaultOrder,
      fullWidthColumns = [],
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const [order, setOrder] = useState(defaultOrder);
    const [filters, setFilters] = useState({});
    const [recentlyUpdated, setRecentlyUpdated] = useState(null);
    const [rightPinnedColumn, setRightPinnedColumn] = useState(defaultRightPinned || null);
    const [leftPinnedColumn, setLeftPinnedColumn] = useState(defaultLeftPinned || null);

    useImperativeHandle(
      ref,
      () => ({
        getFilters: () => {
          return filters;
        },
        getOrder: () => {
          return order;
        },
      }),
      [search],
    );

    const onOrderChange = (header, orderToSet) => {
      let newOrder = `${header.orderPrefix}:${orderToSet}`;
      if (newOrder === order) {
        newOrder = null;
      }
      setOrder(newOrder);

      search(1, newOrder, filters);
    };

    /* You could end with empty header array because fetching the headers in branches list is async */
    if (!headers || headers.length === 0) {
      return <Box />;
    }

    const TableToPin = ({ placement, testId }) => {
      let calculatedHeaders;
      let calculatedData;
      switch (placement) {
        case 'left':
          calculatedHeaders = [find(headers, (d) => d.name === leftPinnedColumn)];
          calculatedData = map(data, (d) => pick(d, [leftPinnedColumn]));
          break;
        case 'right':
          calculatedHeaders = [find(headers, (d) => d.name === rightPinnedColumn)];
          calculatedData = map(data, (d) => pick(d, [rightPinnedColumn]));
          break;
        case 'center':
          calculatedHeaders = filter(
            headers,
            (h) => ![leftPinnedColumn, rightPinnedColumn].includes(h.name),
          );
          calculatedData = map(data, (d) => omit(d, [leftPinnedColumn, rightPinnedColumn]));
          break;
        default:
          calculatedHeaders = headers;
          calculatedData = data;
      }

      const SortButton = ({ header, sortOrder }) => (
        <Box
          cursor='pointer'
          w='24px'
          h='24px'
          border='0'
          borderRadius='6px'
          pt={0}
          verticalAlign='bottom'
          display='flex'
          alignItems='center'
          justifyContent='center'
          background={order?.includes(`${header.orderPrefix}:${sortOrder}`) ? '#5AF5CC' : 'inherit'}
          _hover={{ background: '#B3FCE4' }}
          onClick={() => onOrderChange(header, sortOrder)}
        >
          {sortOrder === 'asc' ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Box>
      );

      return (
        <TableContainer data-testid={testId}>
          <ChakraTable variant='simple'>
            <Thead bgColor='gray.50'>
              <Tr data-testid={`${testId}ColumnTitle`}>
                {map(calculatedHeaders, (header) => (
                  <Th
                    key={header.id}
                    minWidth='185px'
                    data-testid={`${camelCase(`${testId}.${header.name}`)}ColumnTitle`}
                  >
                    <>
                      <Flex alignItems='center' mb={2}>
                        <Box>{header.displayName}</Box>
                        <Flex alignItems='center' ml='auto'>
                          <SortButton header={header} sortOrder='asc' />
                          <SortButton header={header} sortOrder='desc' />
                        </Flex>

                        {pinnable && (
                          <Flex alignItems='center' ml='auto'>
                            <Box
                              className={`customButton ${
                                leftPinnedColumn === header.name ? 'customButtonActive' : ''
                              }`}
                              w='24px'
                              h='24px'
                              onClick={() => {
                                if (leftPinnedColumn === header.name) {
                                  setLeftPinnedColumn(null);
                                } else if (rightPinnedColumn === header.name) {
                                  setRightPinnedColumn(null);
                                  setLeftPinnedColumn(header.name);
                                } else {
                                  setLeftPinnedColumn(header.name);
                                }
                              }}
                            >
                              <ChevronLeftIcon />
                            </Box>
                            <Box
                              className={`customButton ${
                                rightPinnedColumn === header.name ? 'customButtonActive' : ''
                              }`}
                              w='24px'
                              h='24px'
                              onClick={() => {
                                if (rightPinnedColumn === header.name) {
                                  setRightPinnedColumn(null);
                                } else if (leftPinnedColumn === header.name) {
                                  setLeftPinnedColumn(null);
                                  setRightPinnedColumn(header.name);
                                } else {
                                  setRightPinnedColumn(header.name);
                                }
                              }}
                            >
                              <ChevronRightIcon />
                            </Box>
                          </Flex>
                        )}
                      </Flex>
                      <>
                        <datalist id={`${header.name}list`}>
                          {map(header.filterOptions, (value, index) => (
                            <option
                              label={value?.name?.toString()}
                              value={value?.name?.toString()}
                              key={`${index}-${value?.name}`}
                            />
                          ))}
                        </datalist>
                        <DebouncedInput
                          type='text'
                          value={(filters[header.name]?.name || filters[header.name]) ?? ''}
                          onChange={(value) => {
                            setRecentlyUpdated(header.name);
                            const updatedFilters = { ...filters };
                            updatedFilters[header.name] =
                              (header.filterOptions
                                ? find(header.filterOptions, (f) => f.name === value)
                                : value) || value;
                            setFilters(updatedFilters);
                            search(1, order, updatedFilters);
                          }}
                          placeholder={`${t('common.search')}...`}
                          list={`${header.name}list`}
                          autoFocus={recentlyUpdated === header.name}
                          onClear={() => {
                            const updatedFilters = { ...filters };
                            updatedFilters[header.name] = null;
                            setFilters(updatedFilters);
                            search(1, order, updatedFilters);
                          }}
                        />
                      </>
                    </>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody className='notranslate'>
              {map(calculatedData, (row, i) => (
                <Tr key={i} height='53px' data-testid={`${testId}Separate`}>
                  {map(row, (cell, index) => {
                    let cellName = '';
                    if (cell?.name) {
                      cellName = cell.name;
                    } else if (cell && typeof cell !== 'object') {
                      cellName = cell.toString();
                    }

                    const isCellEllipsis =
                      document.getElementById(`cell-${index}-${cellName}`)?.scrollWidth >
                      document.getElementById(`cell-${index}-${cellName}`)?.clientWidth;

                    return (
                      <Td
                        key={`${index}-${cellName}`}
                        maxWidth={
                          fullWidthColumns.includes(index) &&
                          ![leftPinnedColumn, rightPinnedColumn].includes(index)
                            ? 'unset'
                            : '160px'
                        }
                      >
                        <Tooltip
                          label={cellName}
                          bg='white'
                          className='notranslate'
                          color='gray.550'
                          border='1px solid'
                          borderColor='gray.200'
                          minWidth='185px'
                          placement='top-start'
                          isDisabled={!isCellEllipsis || isEmpty(cellName)}
                        >
                          <Box
                            id={`cell-${index}-${cellName}`}
                            overflow='hidden'
                            whiteSpace='nowrap'
                            textOverflow='ellipsis'
                            width='100%'
                          >
                            {cell?.cellFormatter ? cell?.cellFormatter() : cellName}
                          </Box>
                        </Tooltip>
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </Tbody>
          </ChakraTable>
        </TableContainer>
      );
    };

    return (
      <Grid
        gridTemplateColumns={`${leftPinnedColumn ? 'minmax(8rem, 20%)' : ''} 1fr ${
          rightPinnedColumn ? 'minmax(8rem, 20%)' : ''
        }`}
        gridColumnGap='1rem'
        position='relative'
        overflow='hidden'
        overflowY='auto'
        data-testid='branchesTable'
      >
        {leftPinnedColumn ? (
          <GridItem pb='15px'>
            <TableToPin placement='left' testId='branchesTablePinnedLeft' />
          </GridItem>
        ) : null}
        <GridItem overflowX='auto'>
          <TableToPin placement='center' testId='branchesTableUnpinned' />
        </GridItem>
        {rightPinnedColumn ? (
          <GridItem pb='15px'>
            <TableToPin placement='right' testId='branchesTablePinnedRight' />
          </GridItem>
        ) : null}
      </Grid>
    );
  },
);

Table.displayName = 'Table';
