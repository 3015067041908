import { BoxProps } from '@chakra-ui/react';
import React from 'react';

import { IOpenNode } from '../type';
import { userNarrowing } from '../../../narrowings';
import { IUserNameAndJobTitle, IUserOrgChart } from '../../../types';
import { AnimotionBox } from '../../../components-common/animation';
import { UserDataAll, UserDataHeader } from '../../../components-common/user-data';

interface Props {
  user: IUserOrgChart | IUserNameAndJobTitle;
  selectedUsers: number[];
  openNode: IOpenNode;
  style: BoxProps;
  childUser: boolean;
  depthLevel: number;
  selectedUser: boolean;
}

export const UserCartAccordion = ({
  user,
  selectedUsers,
  openNode,
  style,
  childUser,
  depthLevel,
  selectedUser,
}: Props) => {
  const isLastParent = !!(selectedUsers.length - depthLevel === 2);

  if ((childUser || isLastParent) && userNarrowing(user)) {
    return (
      <AnimotionBox {...style} className={selectedUser && 'selectedUser'}>
        <UserDataAll
          onClick={() => openNode(user.id, depthLevel)}
          user={user}
          hideBody={selectedUsers.length - depthLevel > 1}
          hideAvatar={selectedUsers.length - depthLevel > 1 && !isLastParent}
        />
      </AnimotionBox>
    );
  }

  return <UserDataHeader user={user} onClick={() => openNode(user.id)} {...style} />;
};
