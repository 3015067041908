import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { ICommonApi, IKnowledgeBasesMainFolder, IKnowledgeBasesManu, IMeta } from '../types';

import { getHeaders } from './common';
import { catchError } from './utils/catch-error';
import { maxLimit } from './utils/common';
import { PATH_KNOWLEDGE_BASES } from './utils/path';

const fieldsAllDataKnowledgeBases = ['title', 'link', 'description', 'isSharedDrive'];
const populateAllDataKnowledgeBases = {
  photo: {
    fields: ['url'],
  },
};
export const fetchAllDataKnowledgeBases = (
  { jwt, toast, navigate },
  {
    errorCallback,
    fields = fieldsAllDataKnowledgeBases,
    populate = populateAllDataKnowledgeBases,
    sort,
  }: ICommonApi,
) => {
  const queryRaw = {
    fields,
    populate,
    pagination: {
      limit: maxLimit,
      start: 0,
    },
    sort,
  };
  errorCallback(false);
  return axios
    .get(`${PATH_KNOWLEDGE_BASES}?${qs.stringify(queryRaw)}`, getHeaders(jwt))
    .then((response: AxiosResponse<{ data: IKnowledgeBasesManu; meta: IMeta }>) => response)
    .catch((error) => catchError(error, toast, navigate, errorCallback));
};

export const fetchDataKnowledgeBases = (
  { jwt, toast, navigate },
  {
    errorCallback,
    id,
    fields = fieldsAllDataKnowledgeBases,
    populate = populateAllDataKnowledgeBases,
  },
) => {
  const queryRaw = {
    fields,
    populate,
  };
  errorCallback(false);
  return axios
    .get(`${PATH_KNOWLEDGE_BASES}/${id}?${qs.stringify(queryRaw)}`, getHeaders(jwt))
    .then((response: AxiosResponse<IKnowledgeBasesMainFolder>) => response)
    .catch((error) => catchError(error, toast, navigate, errorCallback));
};
