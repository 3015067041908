import axios from 'axios';
import qs from 'qs';

import { getHeaders } from './common';
import { PATH_ADVERTS_CATEGORY } from './utils/path';
import { catchError } from './utils/catch-error';

const fieldsAllAdvertsCategories = ['advertCategoryName'];
const sort = ['advertCategoryName:asc'];
const poupulate = ['localizations'];

export const fetchAllAdvertsCategories = async (jwt, toast, navigate, language) => {
  const response = await axios
    .get(
      `${PATH_ADVERTS_CATEGORY}?${qs.stringify({
        populate: poupulate,
        locale: language,
        fields: fieldsAllAdvertsCategories,
        sort,
      })}`,
      getHeaders(jwt),
    )
    .catch((error) => catchError(error, toast, navigate));
  return response.data;
};
