import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Heading, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { Helmet } from '../../components-common/helmet';

const GDPREmail = 'info.GDPR@dornach.eu';

export const PrivacyPolicyView = () => {
  const { t } = useTranslation();

  const PrivacyHeading = ({ text }) => (
    <Heading mb={6} mt={12} fontSize='30px' color='brandColors.brandGreen'>
      {text}
    </Heading>
  );

  const PrivacyLink = ({ url, title }) => (
    <Link color='ZSGreen.700' fontWeight='bold' href={url} target='_blank'>
      {title}
    </Link>
  );

  const EmailLink = ({ email }) => (
    <Link fontWeight='bold' color='ZSGreen.600' href={`mailto:${email}`}>
      {email}
    </Link>
  );

  const PrivacyList = ({ listKey, components }) => (
    <Trans
      i18nKey={listKey}
      components={{ 1: <UnorderedList pl={2} />, 0: <ListItem />, ...components }}
    />
  );

  return (
    <Flex
      alignItems='center'
      flexDirection='column'
      minHeight='100vh'
      pb='120px'
      overflowX='hidden'
    >
      <Box w='80%' mt={6}>
        <Flex flexDirection='column'>
          <Helmet title={t('title.privacy-policy')} />
          <Heading mb={8} fontSize='30px' color='brandColors.brandGreen'>
            {t('privacy.heading')}
          </Heading>
          <Box alignSelf='center' width='45%'>
            <PrivacyHeading text={t('privacy.general-heading')} />
            <Text mb={4}>{t('privacy.general-content-1')}</Text>
            <Text mb={4}>{t('privacy.general-content-2')}</Text>
            <Text mb={4}>{t('privacy.general-content-3')}</Text>
            <Text mb={4}>{t('privacy.general-content-4')}</Text>
            <Text mb={4}>{t('privacy.general-content-5')}</Text>
            <Text>{t('privacy.general-name')}</Text>
            <Text>{t('privacy.general-function')}</Text>
            <Text>{t('privacy.general-address')}</Text>
            <Text mb={4}>
              <Trans i18nKey='privacy.general-email'>
                content
                <EmailLink email={GDPREmail} />
              </Trans>
            </Text>
            <Trans i18nKey='privacy.general-content-6'>
              content
              <PrivacyLink
                url='https://edpb.europa.eu/about-edpb/about-edpb/members_en'
                title='https://edpb.europa.eu/about-edpb/about-edpb/members_en'
              />
            </Trans>
            <PrivacyHeading text={t('privacy.processing-heading')} />
            <Text>{t('privacy.processing-list-1-heading')}</Text>
            <PrivacyList listKey='privacy.processing-list-1-content' />
            <Heading my={8} fontSize='20px' fontWeight='bold'>
              {t('privacy.processing-content-heading-1')}
            </Heading>
            <Text mb={4}>{t('privacy.processing-list-2-heading')}</Text>
            <PrivacyList listKey='privacy.processing-list-2-content' />
            <Heading my={8} fontSize='20px' fontWeight='bold'>
              {t('privacy.processing-content-heading-2')}
            </Heading>
            <Text mb={4}>{t('privacy.processing-list-3-heading')}</Text>
            <PrivacyList listKey='privacy.processing-list-3-content' />
            <PrivacyHeading text={t('privacy.rights-heading')} />
            <Text mb={4}>{t('privacy.rights-list-heading')}</Text>
            <PrivacyList listKey='privacy.rights-list-content' />
            <Text mt={4}>{t('privacy.rights-content')}</Text>
            <PrivacyHeading text={t('privacy.duties-heading')} />
            <Text mb={4}>{t('privacy.duties-content-1')}</Text>
            <Text>{t('privacy.duties-content-2')}</Text>
            <PrivacyHeading text={t('privacy.storage-heading')} />
            <Text mb={4}>{t('privacy.storage-content-1')}</Text>
            <Text>{t('privacy.storage-content-2')}</Text>
            <PrivacyHeading text={t('privacy.complaints-heading')} />
            <Text mb={4}>
              <Trans i18nKey='privacy.complaints-content-1'>
                content
                <EmailLink email={GDPREmail} />
                content
              </Trans>
            </Text>
            <Text>{t('privacy.complaints-content-2')}</Text>
            <Box
              position='relative'
              _before={{
                content: "''",
                height: '1px',
                width: '100vw',
                background: 'gray.100',
                position: 'absolute',
                top: '-50px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <Text mb={12} mt='100px'>
                <Trans i18nKey='privacy.link'>
                  content
                  <PrivacyLink
                    url='https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&from=NL'
                    title='[i] REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC'
                  />
                </Trans>
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
