import { Grid, Text } from '@chakra-ui/react';
import { GridInputWrapper } from '@dornach/ziegler-ui-kit';
import React, { useRef, useState } from 'react';
import { Controller, FieldValues, UseFormReturn, UseFormWatch, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { newTopic, updateTopic } from '../../api/forum';
import { fetchAllForumCategories } from '../../api/forum-categories';
import { RichEditor, RichEditorProps } from '../../components-common/editor';
import { InputAndLabel } from '../../components-common/inputs/input-label';
import { SelectDataStrapi } from '../../components-common/inputs/select-data-strapi';
import { DefaultModal } from '../../components-common/modals';
import { UseApiParams } from '../../helpers/hooks/use-api-params';
import { parseLanguageCode } from '../../helpers/languageHelper';
import { IForumTopic, IQuestionForumAllData } from '../../types';

interface ModalTopicProps {
  isOpen: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  topic?: IForumTopic;
  fetchTopics: () => void;
}

export const CreateTopicModal: React.FC<ModalTopicProps> = ({
  isOpen,
  onClose,
  onOpen,
  topic,
  fetchTopics,
}) => {
  const apiParams = UseApiParams();
  const richEditorRef = useRef(null);

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  let defaultValues;

  if (topic) {
    const contentValue = topic.content;
    defaultValues = {
      title: topic.title,
      categories: topic.categories,
      content: contentValue
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(contentValue)))
        : EditorState.createEmpty(),
    };
  } else {
    defaultValues = {
      title: '',
      categories: null,
      content: EditorState.createEmpty(),
    };
  }

  const form: UseFormReturn<FieldValues> = useForm({ defaultValues });
  const { handleSubmit, watch, setValue, getValues, reset } = form;
  const content: UseFormWatch<FieldValues> = form.watch('content');

  /* Workaround for supporting typescript with a no typescript component */
  const richEditorProps: RichEditorProps = {
    content,
    setContent: (content) => setValue('content', content),
    users: null,
    testId: 'forumCreateModalContent',
    placeholder: t('forum.title-placeholder'),
  };

  const onSubmit = async (data, event) => {
    if (isLoading === true) {
      return;
    }
    setIsLoading(true);

    let newData;
    if (!topic?.id) {
      const rawContentState = convertToRaw(getValues().content.getCurrentContent());
      newData = {
        data: {
          ...data,
          content: JSON.stringify(rawContentState),
        },
      };
    } else {
      newData = {
        id: topic.id,
        data: {
          ...data,
          content: undefined,
        },
      };
    }

    let result: boolean | IQuestionForumAllData = false;
    if (topic?.id) {
      result = await updateTopic(apiParams, newData);
    } else {
      result = await newTopic(apiParams, newData);
    }

    if (result) {
      fetchTopics();
      onCloseModal();
    }
    setIsLoading(false);
  };

  const onCloseModal = () => {
    onClose();
  };

  let contentEditor;
  if (!topic?.id) {
    contentEditor = (
      <GridInputWrapper
        isRequired
        arg={{ minHeight: '178px', maxHeight: '350px', rowGap: 2 }}
        name={t('adverts.content')}
        testId='forumCreateModalContent'
      >
        <Controller
          name='content'
          control={form.control}
          rules={{
            validate: {
              required: (v) => {
                if (v?.getCurrentContent()?.hasText()) {
                  return true;
                }
                return t('errors.empty');
              },
              minLength: (v) => {
                if (v.getCurrentContent().getPlainText().length > 15) {
                  return true;
                }
                return t('errors.min-length', { min: 15 });
              },
            },
          }}
          render={({ field }) => <RichEditor {...richEditorProps} {...field} ref={richEditorRef} />}
        />
        {form.formState?.errors?.content && (
          /* eslint-disable-next-line @typescript-eslint/no-base-to-string */ // Seems to be a TS bug
          <Text color='red.500'>{form.formState.errors.content.message?.toString()}</Text>
        )}
      </GridInputWrapper>
    );
  }

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      handleSubmit={handleSubmit(onSubmit)}
      titleModal={topic?.id ? t('forum.edit-topic') : t('forum.create-new-topic')}
      applyButton={topic?.id ? t('common.save') : t('forum.create-topic')}
      closeButton={t('common.cancel')}
      isLoading={isLoading}
    >
      <Grid gridTemplateRows={'1fr'} gridTemplateColumns={'1fr'} rowGap='4' columnGap='6'>
        <InputAndLabel
          label={t('forum.title')}
          name='title'
          placeholder={t('forum.title-placeholder')}
          form={form}
          registerOptions={{
            required: t('errors.empty'),
            minLength: { value: 5, message: t('errors.min-length', { min: 5 }) },
          }}
          isRequired
          testId='forumCreateModalTitle'
          arg={{ rowGap: 2 }}
        />
        <SelectDataStrapi
          label={t('forum.category')}
          placeholder={t('forum.category-placeholder')}
          name='categories'
          queryKey='forumCategories'
          queryFn={() => fetchAllForumCategories(apiParams, parseLanguageCode(i18n.language))}
          getOptionLabel={(data) => data.title}
          form={form}
          isRequired
          isMulti
          emptyLabel={t('errors.empty')}
          testId='forumCreateModalCategory'
          arg={{ rowGap: 2 }}
        />

        {contentEditor}
      </Grid>
    </DefaultModal>
  );
};
