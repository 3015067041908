import axios from 'axios';
import {
  PATH_GOOGLE_AUTH_CALLBACK,
  PATH_MICROSOFT_AUTH_CALLBACK,
  PATH_MICROSOFT_ZA_AUTH_CALLBACK,
  PATH_REFRESH_TOKEN,
  PATH_LOGOUT,
} from './utils/path';
import { catchError } from './utils/catch-error';
import { PROVIDER_GOOGLE, PROVIDER_MICROSOFT, PROVIDER_MICROSOFT_ZA } from '../constants';

const providerError = {
  code: 'UNKNOWN_PROVIDER',
  message: 'Provider unknown',
  response: { status: 200 },
};

export const authProvider = ({ provider, accessToken, toast, navigate }) => {
  let pathProviderAuthCallback;
  switch (provider) {
    case PROVIDER_GOOGLE:
      pathProviderAuthCallback = PATH_GOOGLE_AUTH_CALLBACK;
      break;

    case PROVIDER_MICROSOFT:
      pathProviderAuthCallback = PATH_MICROSOFT_AUTH_CALLBACK;
      break;

    case PROVIDER_MICROSOFT_ZA:
      pathProviderAuthCallback = PATH_MICROSOFT_ZA_AUTH_CALLBACK;
      break;

    default:
      catchError(providerError, toast, navigate);
      return false;
  }

  return axios
    .get(`${pathProviderAuthCallback}?access_token=${accessToken}`, { withCredentials: true })
    .catch((error) => catchError(error, toast, navigate));
};

export const refreshToken = ({ toast, navigate, callback, errorCallback }) => {
  return axios
    .post(`${PATH_REFRESH_TOKEN}`, {}, { withCredentials: true })
    .then((response) => {
      if (callback) {
        callback(response.data);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
      catchError(error, toast, navigate);
    });
};

export const logOut = ({ callback }) => {
  return axios
    .post(`${PATH_LOGOUT}`, {}, { withCredentials: true })
    .then((response) => {
      if (callback) {
        callback(response.data);
      }
    })
    .catch((error) => {
      if (callback) {
        callback();
      }
    });
};
