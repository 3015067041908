import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Text,
  Td,
  Tr,
  Img,
  Link,
} from '@chakra-ui/react';

import headerImage from '../../assets/dornach-image.png';
import contact from '../../assets/contact.png';
import files from '../../assets/files.png';
import { ReactComponent as Logo } from '../../assets/dornach-logo.svg';
import { Helmet } from '../../components-common/helmet';
import { strapiDataParserSingle } from '../../common/strapi';
import { fetchDornachIntervention } from '../../api/dornach-intervention';
import { parsedContent } from '../../helpers/contentHelper';

const GENERAL_PHONE = '+32-2-422.21.22';
const FRENCH_PHONE = '+33-3-20.69.36.04';
const CONTACT_URL = 'https://dornach.atlassian.net/servicedesk/customer/portals';
const DOCUMENTS_URL = 'https://www.zieglerteam.net/knowledge-base/69';

const Separator = () => <Box width='240px' height='5px' bgColor='ZSBlue.300' mb={2} />;

export const DornachPage = () => {
  const { jwt } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [interventionContent, setInterventionContent] = useState(null);

  const getDornachIntervention = async () => {
    await fetchDornachIntervention(jwt, (response) => {
      setInterventionContent(strapiDataParserSingle(response).content);
    });
  };

  useEffect(() => {
    const initDornachPage = async () => {
      await getDornachIntervention();
    };
    initDornachPage();
  }, []);

  return (
    <Flex flexDirection='column' color='ZSBlue.600' data-testid={'aboutDornachContent'} pb={12}>
      <Box alignSelf='center' width='50%'>
        <Helmet title={t('title.dornach')} />
        <Flex justifyContent='center'>
          <Logo width='300px' height='100px' />
        </Flex>
        <Img src={headerImage} />
        <Separator />
        <Heading mb={4} size='md' fontWeight='bold'>
          {t('dornach.heading')}
        </Heading>
        <Text mb={4}>{t('dornach.paragraph-1')}</Text>
        <Text mb={4}>{t('dornach.paragraph-2')}</Text>
        <Text mb={8}>{t('dornach.paragraph-3')}</Text>
        <Text fontSize='lg' fontWeight='bold' textAlign='center' mb={2}>
          {t('dornach.problem-heading-1')}
        </Text>
        <Text fontSize='lg' fontWeight='bold' textAlign='center' mb={8}>
          {t('dornach.problem-heading-2')}
        </Text>
        <Link color='brandColors.brandGreen' fontWeight='bold' href={CONTACT_URL} target='_blank'>
          <Img mt={2} mb={4} src={contact} />
        </Link>
        <Separator />
        <Text mb={4}>
          <Trans i18nKey='dornach.problem-text-1' components={{ 1: <b /> }} />
        </Text>
        <Text mb={4}>{t('dornach.problem-text-2')}</Text>
        <TableContainer mb={8}>
          <Table size='sm' border='1px solid' borderColor='gray.200'>
            <Tbody>
              <Tr>
                <Td>{t('dornach.general-phone')}</Td>
                <Td>{GENERAL_PHONE}</Td>
              </Tr>
              <Tr>
                <Td>{t('dornach.france-phone')}</Td>
                <Td>{FRENCH_PHONE}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        {interventionContent && (
          <Box mb={8} p={6} bgColor='ZSBlue.300'>
            <Text fontSize='lg' fontWeight='bold' textAlign='center' mb={2}>
              {t('dornach.interventions-heading')}
            </Text>
            <Box dangerouslySetInnerHTML={{ __html: parsedContent(interventionContent) }} />
          </Box>
        )}
        <Text fontSize='lg' fontWeight='bold' textAlign='center'>
          {t('dornach.documents-heading')}
        </Text>
        <Link color='brandColors.brandGreen' fontWeight='bold' href={DOCUMENTS_URL} target='_blank'>
          <Img mt={4} src={files} />
        </Link>
      </Box>
    </Flex>
  );
};
