import {
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react';
import { GridInputWrapper } from '@dornach/ziegler-ui-kit';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UseApiParams } from '../../helpers/hooks/use-api-params';

import { customFindManyBranches, fetchUserRelationData, updateMe } from '../../api';
import {
  FILTER_MENU_GAP_COL,
  FILTER_MENU_WIDTH,
  MAIN_CONTENT_POSITION,
  PATH_NEWS,
} from '../../constants';
import { parseLanguageCode } from '../../helpers/languageHelper';
import { updateUserAction } from '../../redux';
import CustomMultiSelect from '../custom-select';
import { IfElse } from '../if';
import CustomPhoneInput from '../phone-input';
import { SpinnerScreen } from '../spinner-screen';
import InfoModal from './info-modal';
import { IUserForm } from './type';

import {
  IBranchName,
  ICompanyName,
  ICountryName,
  IDepartmentName,
  IExpertiseName,
  ILanguage,
  ILanguageName,
  ILocalI18,
  IUserAuth,
  IUserUpdateData,
} from '../../types';

export const ProfileForm = ({
  isLoading,
  setIsLoading,
  shouldRedirect = false,
  isFromLogin = false,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [countriesList, setCountriesList] = useState<ICountryName[]>([]);
  const [branchesList, setBranchesList] = useState<IBranchName[] | undefined>([]);
  const [departmentsList, setDepartmentsList] = useState<IDepartmentName[]>([]);
  const [expertisesList, setExpertisesList] = useState<IExpertiseName[]>([]);
  const [companiesList, setCompaniesList] = useState<ICompanyName[]>([]);
  const [languagesList, setLanguagesList] = useState<ILanguage[]>([]);
  const [newsNotifLanguagesList, setNewsNotifLanguagesList] = useState<ILanguage[]>([]);
  const [additionalLanguagesList, setAdditionalLanguagesList] = useState<ILanguageName[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const apiParams = UseApiParams();
  const { user, locales } = useSelector(
    (state: { auth: { user: IUserAuth; locales: ILocalI18[] } }) => state.auth,
  );

  const defaultValues: IUserForm = {
    name: user.name,
    surname: user.surname,
    email: user.email,
    company: user.company,
    country: user.country,
    branchList: user.branchList,
    department: user.department,
    ekspertise: user.ekspertise,
    mobilePhone: user.mobilePhone,
    workPhone: user.workPhone,
    language: undefined,
    additionalLanguages: user.languages,
    afterFirstLogin: true,
    news_notif_languages: user.news_notif_languages,
    news_notif_countries: user.news_notif_countries,
  };

  const formControl = useForm({
    defaultValues,
  });

  const {
    control: controlUserData,
    register: userData,
    handleSubmit: handleSubmitUserData,
    setValue: setValueUserData,
    formState: { errors },
    watch: watchUserData,
    setError,
  } = formControl;

  const selectedCountry = watchUserData('country');
  const selectedBranch = watchUserData('branchList');

  useEffect(() => {
    let isSubscribed = true;
    const asyncEffect = async () => {
      if (isSubscribed) {
        return fetchUserRelationData(apiParams, user.country)
          .then(
            ([
              dataCountries,
              dataDepartments,
              dataExpertises,
              dataCompanies,
              dataBranches,
              dataLanguagesList,
              dataNewsNotifLanguagesList,
            ]) => {
              if (dataCountries) {
                setCountriesList(dataCountries);
              }
              setCompaniesList(dataCompanies);
              setBranchesList(dataBranches);
              setIsLoading(false);
              if (dataLanguagesList) {
                setAdditionalLanguagesList(dataLanguagesList.data.data);
              }
              if (dataNewsNotifLanguagesList) {
                setNewsNotifLanguagesList(dataNewsNotifLanguagesList.data.data);
              }
              if (dataDepartments) {
                setDepartmentsList(dataDepartments);
              }
              if (dataExpertises) {
                setExpertisesList(dataExpertises);
              }
            },
          )
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            setIsLoading(false);
          });
      }
    };
    asyncEffect();

    isSubscribed = false;
  }, []);

  useEffect(() => {
    setLanguagesList(locales);
  }, [locales]);

  useEffect(() => {
    const reloadLanguage = async () => {
      const initialLanguage = user.locale || parseLanguageCode(i18n.language);
      setValueUserData(
        'language',
        find(languagesList, (language) => language.code === initialLanguage),
      );
    };
    reloadLanguage();
  }, [languagesList, user]);

  const handleSubmit = (data) => {
    const updateData: IUserUpdateData = {
      id: user.id,
      afterFirstLogin: data.afterFirstLogin,
      country: data.country,
      branchList: data.branchList?.id,
      department: data.department?.id,
      ekspertise: data.ekspertise?.id,
      company: data.company?.id,
      email: data.email,
      name: data.name,
      surname: data.surname,
      mobilePhone: data.mobilePhone,
      workPhone: data.workPhone,
      locale: data.language.code,
      languages: data.additionalLanguages.map((dataLanguage) => dataLanguage.id),
      news_notif_languages: data.news_notif_languages.map((dataLanguage) => dataLanguage.id),
      news_notif_countries: data.news_notif_countries,
    };

    i18n.changeLanguage(data.language.code);
    updateMe(apiParams, updateData, async (dataUpdated) => {
      dispatch(updateUserAction({ ...user, ...dataUpdated } as IUserAuth));
      setIsModalOpen(true);
      if (shouldRedirect) {
        navigate(PATH_NEWS);
      }
    });
  };

  const changeCountry = async (value) => {
    setBranchesList(null);
    if (value?.id) {
      const dataBranches = await customFindManyBranches(apiParams, {
        country: { id: value.id },
      });
      setBranchesList(dataBranches);
    }
    setValueUserData('branchList', null);
    setValueUserData('country', value, { shouldValidate: true });
  };

  return (
    <IfElse condition={isLoading}>
      <SpinnerScreen />
      <form onSubmit={handleSubmitUserData(handleSubmit)}>
        <Tabs orientation='vertical' isFitted variant={'soft-rounded'} colorScheme='ZSGreen'>
          <Grid
            templateAreas={`"nav main"`}
            gridTemplateRows={'auto 1fr'}
            gridTemplateColumns={`${isFromLogin ? 0 : FILTER_MENU_WIDTH} 1fr`}
            h='100%'
            w='100%'
            columnGap={FILTER_MENU_GAP_COL}
          >
            <GridItem
              display={`${isFromLogin ? 'none' : 'block'}`}
              area={'nav'}
              position={'sticky'}
              top={MAIN_CONTENT_POSITION}
              h={`calc(100vh - ${MAIN_CONTENT_POSITION})`}
              overflowY={'auto'}
            >
              <TabList>
                <Tab>{t('nav.edit-profile')}</Tab>
                <Tab>{t('nav.email-notifications')}</Tab>
              </TabList>
            </GridItem>

            <GridItem area={'main'}>
              <TabPanels mb={12}>
                <TabPanel>
                  <Grid
                    templateColumns={{
                      base: 'repeat(1, 1fr)',
                      md: 'repeat(2, 1fr)',
                      lg: 'repeat(2, 1fr)',
                    }}
                    gap={6}
                    data-testid='profileView'
                  >
                    <GridInputWrapper
                      name={t('common.name')}
                      isRequired
                      error={errors.name}
                      testId='profileName'
                    >
                      <Input
                        isDisabled
                        {...userData('name', { required: t('errors.empty') })}
                        _disabled={{
                          cursor: 'not-allowed',
                          bgColor: 'transparent',
                          opacity: 0.4,
                        }}
                        fontSize='16px'
                        data-testid='profileNameInput'
                      />
                    </GridInputWrapper>
                    <GridInputWrapper
                      name={t('common.surname')}
                      isRequired
                      error={errors.surname}
                      testId='profileSurname'
                    >
                      <Input
                        isDisabled
                        {...userData('surname', { required: t('errors.empty') })}
                        _disabled={{
                          cursor: 'not-allowed',
                          bgColor: 'transparent',
                          opacity: 0.4,
                        }}
                        fontSize='16px'
                        data-testid='profileSurnameInput'
                      />
                    </GridInputWrapper>
                    <GridInputWrapper
                      name={t('common.email')}
                      isRequired
                      error={errors.email}
                      testId='profileEmail'
                    >
                      <Input
                        isDisabled
                        _disabled={{
                          cursor: 'not-allowed',
                          bgColor: 'transparent',
                          opacity: 0.4,
                        }}
                        fontSize='16px'
                        {...userData('email', { required: t('errors.empty') })}
                        data-testid='profileEmailInput'
                      />
                    </GridInputWrapper>
                    <GridInputWrapper
                      name={t('common.organization')}
                      error={errors.company}
                      isRequired
                      testId='profileCompany'
                    >
                      <CustomMultiSelect
                        control={controlUserData}
                        arg={{
                          name: 'company',
                          selectRef: userData('company'),
                          getOptionLabel: (option) => option.companyName,
                          getOptionValue: (option) => option.id,
                          placeholder: t('common.select-organization'),
                          options: companiesList,
                          rules: {
                            validate: {
                              positive: (company) => !!company?.id || t('errors.empty'),
                            },
                          },
                        }}
                        styleContainer={{ fontSize: '16px' }}
                        testId='profileCompanyInput'
                      />
                    </GridInputWrapper>
                    <GridInputWrapper
                      name={t('common.country')}
                      error={errors.country}
                      isRequired
                      testId='profileCountry'
                    >
                      <CustomMultiSelect
                        control={controlUserData}
                        arg={{
                          name: 'country',
                          selectRef: userData('country'),
                          onChange: changeCountry,
                          getOptionLabel: (option) => option.countryName,
                          getOptionValue: (option) => option.id,
                          placeholder: t('common.select-country'),
                          options: countriesList,
                          rules: {
                            validate: {
                              positive: (country) => !!country?.id || t('errors.empty'),
                            },
                          },
                        }}
                        styleContainer={{ fontSize: '16px' }}
                        testId='profileCountryInput'
                      />
                    </GridInputWrapper>
                    <GridInputWrapper
                      name={t('common.branch')}
                      isRequired
                      error={errors.branchList}
                      testId='profileBranch'
                    >
                      {errors.branchList && <Text color='red.500'>{t('errors.empty')}</Text>}
                      <CustomMultiSelect
                        control={controlUserData}
                        required
                        arg={{
                          name: 'branchList',
                          isDisabled: !branchesList && !selectedCountry,
                          selectRef: userData('branchList', { required: t('errors.empty') }),
                          getOptionLabel: (option) => option.branchName,
                          getOptionValue: (option) => option.id,
                          placeholder: t('common.select-branch'),
                          value: selectedBranch,
                          options: branchesList,
                          rules: {
                            validate: {
                              positive: (branch) => !!branch?.id || t('errors.empty'),
                            },
                          },
                        }}
                        styleContainer={{ fontSize: '16px' }}
                        testId='profileBranchInput'
                      />
                    </GridInputWrapper>

                    <GridInputWrapper
                      name={t('common.department')}
                      error={errors.department}
                      testId='profileDepartment'
                    >
                      <CustomMultiSelect
                        control={controlUserData}
                        arg={{
                          name: 'department',
                          selectRef: userData('department'),
                          getOptionLabel: (option) => option.departmentName,
                          getOptionValue: (option) => option.id,
                          placeholder: t('common.select-department'),
                          options: departmentsList,
                        }}
                        styleContainer={{ fontSize: '16px' }}
                        testId='profileDepartmentInput'
                      />
                    </GridInputWrapper>
                    <GridInputWrapper
                      name={t('common.expertise')}
                      error={errors.ekspertise}
                      testId='profileExpertise'
                    >
                      <CustomMultiSelect
                        control={controlUserData}
                        arg={{
                          name: 'ekspertise',
                          selectRef: userData('ekspertise'),
                          getOptionLabel: (option) => option.expertiseName,
                          getOptionValue: (option) => option.id,
                          placeholder: t('common.select-expertise'),
                          options: expertisesList,
                        }}
                        styleContainer={{ fontSize: '16px' }}
                        testId='profileExpertiseInput'
                      />
                    </GridInputWrapper>
                    <GridInputWrapper
                      name={t('common.work-phone')}
                      error={errors.workPhone}
                      testId='profilePhone'
                    >
                      <CustomPhoneInput
                        isDisabled={undefined}
                        defaultCountry={undefined}
                        style={undefined}
                        value={undefined}
                        defaultValues={user.workPhone}
                        emptyAccept
                        control={controlUserData}
                        testId='profilePhoneInput'
                        arg={{
                          name: 'workPhone',
                        }}
                      />
                    </GridInputWrapper>
                    <GridInputWrapper
                      name={t('common.mobile-phone')}
                      error={errors.mobilePhone}
                      testId='profileMobilePhone'
                    >
                      <CustomPhoneInput
                        isDisabled={undefined}
                        defaultCountry={undefined}
                        style={undefined}
                        value={undefined}
                        defaultValues={user.mobilePhone}
                        emptyAccept
                        control={controlUserData}
                        testId='profileMobilePhoneInput'
                        arg={{
                          name: 'mobilePhone',
                        }}
                      />
                    </GridInputWrapper>
                    <GridInputWrapper
                      name={t('common.application-language')}
                      error={errors.language}
                      testId='profileLanguage'
                    >
                      <CustomMultiSelect
                        control={controlUserData}
                        arg={{
                          name: 'language',
                          selectRef: userData('language'),
                          getOptionLabel: (option) => option.name,
                          getOptionValue: (option) => option.code,
                          placeholder: t('common.select-language'),
                          options: languagesList,
                        }}
                        styleContainer={{ fontSize: '16px' }}
                        testId='profileLanguageInput'
                      />
                    </GridInputWrapper>
                    <GridInputWrapper
                      name={t('common.languages')}
                      testId='profileAdditionalLanguagesInput'
                    >
                      <CustomMultiSelect
                        control={controlUserData}
                        arg={{
                          name: 'additionalLanguages',
                          selectRef: userData('additionalLanguages'),
                          getOptionLabel: (option) =>
                            t(`languages.${option.name || option.attributes.name}`),
                          getOptionValue: (option) => option.id,
                          placeholder: t('common.select-languages'),
                          options: additionalLanguagesList,
                          isMulti: true,
                          rules: {
                            validate: {
                              positive: (language) => !!language?.id || t('errors.empty'),
                            },
                          },
                        }}
                        styleContainer={{ fontSize: '16px' }}
                        testId='profileAdditionalLanguagesInput'
                      />
                    </GridInputWrapper>
                  </Grid>
                  <Flex mt={4}>
                    <Text color='red.500' mr={1}>
                      *
                    </Text>
                    <Text fontSize='sm'> - {t('common.asterix-info')}</Text>
                  </Flex>

                  <Center mt={6}>
                    <Button
                      isDisabled={Object.keys(errors).length !== 0}
                      w='200px'
                      type='submit'
                      variant='ZYellowSolid'
                      data-testid='profileSaveButton'
                    >
                      {t('common.save')}
                    </Button>
                  </Center>
                </TabPanel>

                <TabPanel>
                  <Heading variant='mdLarge' color='brandColors.brandGreen'>
                    {t('profile.news-notifications')}
                  </Heading>
                  <Grid
                    templateColumns={{
                      base: 'repeat(1, 1fr)',
                      md: 'repeat(2, 1fr)',
                      lg: 'repeat(2, 1fr)',
                    }}
                    gap={6}
                    data-testid='profileView'
                  >
                    <GridInputWrapper
                      name={t('common.language')}
                      testId='profileNewsNotifLanguages'
                    >
                      <CustomMultiSelect
                        control={controlUserData}
                        arg={{
                          name: 'news_notif_languages',
                          isMulti: true,
                          selectRef: userData('news_notif_languages'),
                          getOptionLabel: (option) => option.name || option.attributes.name,
                          getOptionValue: (option) => option.id,
                          placeholder: t('languages.all-languages'),
                          options: newsNotifLanguagesList,
                        }}
                        styleContainer={{ fontSize: '16px' }}
                        testId='profileNewsNotifLanguagesInput'
                      />
                    </GridInputWrapper>

                    <GridInputWrapper name={t('common.country')} testId='profileNewsNotifCountries'>
                      <CustomMultiSelect
                        control={controlUserData}
                        arg={{
                          name: 'news_notif_countries',
                          isMulti: true,
                          selectRef: userData('news_notif_countries'),
                          getOptionLabel: (option) => option.countryName,
                          getOptionValue: (option) => option.id,
                          placeholder: t('profile.all-countries'),
                          options: countriesList,
                        }}
                        styleContainer={{ fontSize: '16px' }}
                        testId='profileNewsNotifCountriesInput'
                      />
                    </GridInputWrapper>
                  </Grid>

                  <Center mt={6}>
                    <Button
                      isDisabled={Object.keys(errors).length !== 0}
                      w='200px'
                      type='submit'
                      variant='ZYellowSolid'
                      data-testid='profileSaveButton'
                    >
                      {t('common.save')}
                    </Button>
                  </Center>
                </TabPanel>
              </TabPanels>
            </GridItem>
          </Grid>
        </Tabs>

        <InfoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </form>
    </IfElse>
  );
};
