import { AddIcon, EditIcon, StarIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Link,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { filter, map } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateMe } from '../../api';
import { fetchBusinessApps } from '../../api/business-apps';
import {
  fetchPrivateBusinessApps,
  updatePrivateBusinessApp,
} from '../../api/business-apps-private';
import { strapiDataParser } from '../../common/strapi';
import { Helmet } from '../../components-common/helmet';
import { ErrorHandleWrapper } from '../../components-common/if';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import {
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  FILTER_MENU_WIDTH,
  MAIN_CONTENT_POSITION,
  PAGINATION_POSITION,
} from '../../constants';
import { getFavouritesAction, setErrorAction } from '../../redux';
import { LinkModal } from './link-modal';

export const BusinessApps = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jwt, user } = useSelector((state) => state.auth);
  const { favourites } = useSelector((state) => state.favourites);
  const favouritePublicApps = map(
    filter(favourites, (f) => !f.isPrivate),
    (u) => u.id,
  );
  const [businessApps, setBusinessApps] = useState([]);
  const [singlePrivateAppId, setSinglePrivateAppId] = useState(null);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isFavouriteLoading, setIsFavouriteLoading] = useState({ id: null, isPrivate: null });

  useEffect(() => {
    const initBusiness = async () => {
      await reload();
    };
    initBusiness();
  }, [favourites]);

  useEffect(() => {
    if (singlePrivateAppId) {
      setIsLinkModalOpen(true);
    }
  }, [singlePrivateAppId]);

  const reload = async () => {
    let apps = await fetchBusinessApps(jwt, toast, navigate, (value) =>
      dispatch(setErrorAction(value)),
    );
    apps = map(strapiDataParser(apps), (app) => {
      return {
        ...app,
        isPrivate: false,
        favourite: favouritePublicApps.includes(app.id),
      };
    });
    let privateApps = await fetchPrivateBusinessApps(jwt, toast, navigate, user.id, (value) =>
      dispatch(setErrorAction(value)),
    );
    privateApps = map(strapiDataParser(privateApps), (app) => {
      return { ...app, isPrivate: true };
    });
    setBusinessApps([
      ...apps.sort((a, b) => a.id - b.id),
      ...privateApps.sort((a, b) => a.id - b.id),
    ]);
    setSinglePrivateAppId(null);
    setIsFavouriteLoading({ id: null, isPrivate: null });
  };

  let businessAppsBody = <SpinnerScreen />;
  if (businessApps) {
    businessAppsBody = (
      <>
        {map(businessApps, (app, index) => (
          <Flex
            key={app.isPrivate ? `private-${app.id}` : `global-${app.id}`}
            alignItems='center'
            justifyContent='space-between'
            width='60%'
            mt={index === 0 ? 0 : 4}
            pt={index === 0 ? 0 : 4}
            borderBottom={index !== app.length - 1 ? '1px solid' : '0px solid'}
            borderColor='gray.200'
            data-testid='separateBusinessAtList'
          >
            <Box whiteSpace='normal' wordBreak='break-word' overflow='hidden'>
              <Link href={app.link} target='_blank'>
                <Text
                  className='notranslate'
                  variant='2xl'
                  fontWeight={700}
                  cursor='pointer'
                  _hover={{
                    color: 'ZLGreen.600',
                  }}
                >
                  {app.title}
                </Text>
              </Link>
              <Text color='gray.400'>{app.description}</Text>
            </Box>
            <Flex>
              {app.isPrivate && (
                <EditIcon
                  mr={4}
                  cursor='pointer'
                  _hover={{
                    color: 'ZLGreen.600',
                  }}
                  onClick={() => setSinglePrivateAppId(app.id)}
                />
              )}
              {isFavouriteLoading.id === app.id &&
              isFavouriteLoading.isPrivate === app.isPrivate ? (
                <Spinner
                  emptyColor='gray.200'
                  thickness='2px'
                  speed='0.6s'
                  color='ZLGreen.600'
                  h='16px'
                  w='16px'
                />
              ) : (
                <StarIcon
                  cursor='pointer'
                  color={app.favourite ? 'brandColors.brandYellow' : 'inherit'}
                  _hover={{
                    color: 'brandColors.brandYellow',
                  }}
                  pointerEvents={isFavouriteLoading.id ? 'none' : 'inherit'}
                  opacity={isFavouriteLoading.id ? '.5' : '1'}
                  onClick={() => {
                    if (app.isPrivate) {
                      setIsFavouriteLoading({ id: app.id, isPrivate: app.isPrivate });
                      updatePrivateBusinessApp(jwt, toast, navigate, app.id, {
                        favourite: !app.favourite,
                      }).then(() => {
                        dispatch(getFavouritesAction({ jwt }));
                      });
                    } else {
                      setIsFavouriteLoading({ id: app.id, isPrivate: app.isPrivate });
                      updateMe(
                        { jwt, toast, navigate },
                        {
                          id: user.id,
                          business_apps: app.favourite
                            ? filter(favouritePublicApps, (f) => f !== app.id)
                            : [...favouritePublicApps, app.id],
                        },
                      ).then(() => {
                        dispatch(getFavouritesAction({ jwt }));
                      });
                    }
                  }}
                />
              )}
            </Flex>
          </Flex>
        ))}

        <LinkModal
          isOpen={isLinkModalOpen}
          onSaveCallback={reload}
          onCloseCallback={() => {
            setIsLinkModalOpen(false);
            setSinglePrivateAppId(null);
          }}
          id={singlePrivateAppId}
        />
      </>
    );
  }

  return (
    <ErrorHandleWrapper>
      <Helmet title={t('title.business-apps')} />

      <Grid
        templateAreas={`"header header"
          "main main"
          `}
        gridTemplateRows={'auto 1fr'}
        gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
        h='100%'
        columnGap={FILTER_MENU_GAP_COL}
      >
        <Grid
          style={{ gridArea: 'header' }}
          templateAreas={`"title pagination"`}
          gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
          h='100%'
          columnGap={FILTER_MENU_GAP_COL}
          position={'sticky'}
          top={PAGINATION_POSITION}
          paddingY={FILTER_MENU_GAP_ROW}
          bgColor={'white'}
          zIndex={98}
        >
          <GridItem area={'title'} alignSelf={'center'} w='100%'>
            <Heading variant='mdLarge' color='brandColors.brandGreen'>
              {t('common.business-apps')}
            </Heading>
          </GridItem>
          <GridItem area={'pagination'}>
            <HStack justifyContent={'space-between'}>
              <Button
                variant='ZGreenSolid'
                leftIcon={<AddIcon />}
                size='md'
                data-testid='businessButton'
                onClick={() => {
                  setIsLinkModalOpen(true);
                }}
              >
                {t('business-apps.add-link')}
              </Button>
            </HStack>
          </GridItem>
        </Grid>

        <GridItem area={'main'} pb={12} minH={`calc(100vh - ${MAIN_CONTENT_POSITION})`}>
          {businessAppsBody}
        </GridItem>
      </Grid>
    </ErrorHandleWrapper>
  );
};
