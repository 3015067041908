import { useSelector } from 'react-redux';
import { noUndefined } from '../../narrowings/no-undefined';
import { ErrorState } from '../../redux/state';
import { ErrorHandleScreen } from '../../components/errors';

export const If = ({ children, condition }) => {
  if (condition) {
    return children;
  }
  return null; // can cause strange errors
};

export const IfElse = <T,>({
  condition,
  children,
}: {
  children: JSX.Element[];
  condition: undefined | T;
}): JSX.Element => (noUndefined(condition) && condition ? children[0] : children[1]);

export const ErrorHandleWrapper = ({ children }: { children?: JSX.Element[] }) => {
  const { isInError } = useSelector((state: ErrorState) => state.error);
  if (isInError) {
    return <ErrorHandleScreen />;
  }
  return children;
};
