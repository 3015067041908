import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { identity, pickBy } from 'lodash';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  customFindManyBranches,
  customFindManyCountries,
  fetchAllAdverts,
  fetchAllAdvertsCategories,
} from '../../api/index';
import { strapiDataParser } from '../../common/strapi';
import { FiltersProvider } from '../../components-common/filters/filters-provider';
import { Helmet } from '../../components-common/helmet';
import { ErrorHandleWrapper, If } from '../../components-common/if';
import { InputAndLabel } from '../../components-common/inputs/input-label';
import { SelectDataStrapi } from '../../components-common/inputs/select-data-strapi';
import { Pagination } from '../../components-common/pagination';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import {
  DEFAULT_ENTRIES_PER_PAGE,
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  FILTER_MENU_WIDTH,
  MAIN_CONTENT_POSITION,
  PAGINATION_POSITION,
  PATH_ADVERTS,
} from '../../constants';
import { parseLanguageCode } from '../../helpers/languageHelper';
import { setErrorAction } from '../../redux';
import { AddAdvertModal } from './add-advert-modal';
import { SingleAdvert } from './single-advert';

export const AdvertsListView = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { jwt, user } = useSelector((state) => state.auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const filterRef = useRef(null);

  const [singleAdvert, setSingleAdvert] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [adverts, setAdverts] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const urlPagination = pickBy(
    {
      page: queryParams.pagination?.page && +queryParams.pagination.page,
      pageSize: queryParams.pagination?.pageSize && +queryParams.pagination.pageSize,
      pageCount: queryParams.pagination?.pageCount && +queryParams.pagination.pageCount,
      total: queryParams.pagination?.total && +queryParams.pagination.total,
    },
    identity,
  );
  const [pagination, setPagination] = useState(urlPagination || { page: 1 });
  const [entriesPerPage, setEntriesPerPage] = useState(
    urlPagination?.pageSize || DEFAULT_ENTRIES_PER_PAGE,
  );

  useEffect(() => {
    const initAdvertList = async () => {
      await Promise.all([
        fetchFilteredAdverts(pagination.page, entriesPerPage),
        fetchAdvertsCategories(),
        fetchCountries(),
        fetchBranches(''),
      ]);
    };
    initAdvertList();
  }, [jwt, i18n]);

  const fetchAdvertsCategories = () => {
    fetchAllAdvertsCategories(jwt, toast, navigate, parseLanguageCode(i18n.language)).then(
      (categories) => {
        setCategoriesList(categories.data);
      },
    );
  };

  const fetchCountries = () => {
    customFindManyCountries({ jwt, toast, navigate }).then((countries) => {
      setCountriesList(countries);
    });
  };

  const fetchBranches = (country) => {
    let countryFilter = {};
    if (country !== null && country !== '') {
      countryFilter = { country: { id: country.id } };
    }
    customFindManyBranches({ jwt, toast, navigate }, countryFilter).then((branches) => {
      setBranchesList(branches);
    });
  };

  const fetchFilteredAdverts = async (currentPage, pageSize = entriesPerPage) => {
    const filters = filterRef.current?.getFilterValues();

    const data = await fetchAllAdverts(
      jwt,
      toast,
      navigate,
      (value) => dispatch(setErrorAction(value)),
      user,
      currentPage || pagination.page,
      pageSize,
      parsedFilters(filters),
    );

    const parsedData = strapiDataParser(data, ['photo', 'users_permissions_user']);

    setPagination(data.data.meta.pagination);
    setAdverts(parsedData);
  };

  const parsedFilters = (filters) => {
    const categoryFilter = filters?.advert_category;
    let categFilter;

    if (categoryFilter) {
      const categories = categoryFilter.id;

      /* categoryFilter[0].localizations?.data?.forEach((locale) => {
      categories.push(locale.id);
    }); */

      categFilter = {
        $or: [
          { advert_category: { id: { $eq: categories } } },
          { advert_category: { localizations: { id: { $eq: categories } } } },
        ],
      };
    }

    let createdFilter;
    if (filters?.createdBy?.id) {
      switch (filters.createdBy.id) {
        case 'me':
          createdFilter = { users_permissions_user: { id: user.id } };
          break;

        case 'others':
          createdFilter = { users_permissions_user: { id: { $ne: user.id } } };
          break;

        default:
          break;
      }
    }

    return {
      $and: [
        filters?.keywords !== ''
          ? {
              $or: [
                { title: { $containsi: filters?.keywords } },
                { content: { $containsi: filters?.keywords } },
              ],
            }
          : undefined,
        categFilter,
        filters?.country?.id && { country: filters.country.id },
        filters?.branch?.id && { branch: filters.branch.id },
        createdFilter,
      ],
    };
  };

  const search = async () => {
    // We want to retrieve all advert_category localizations, when we filter on a category we want also filtering on their other languages counterparts
    setIsLoading(true);
    await fetchFilteredAdverts(1);
    setIsLoading(false);
  };

  const onOpenAddModal = () => {
    // reset advert we want to edit first
    setSingleAdvert(null);
    onOpen();
  };

  let advertListBody;
  if (isLoading) {
    advertListBody = <SpinnerScreen />;
  } else if (adverts.length > 0) {
    advertListBody = (
      <Flex flexDirection='column' w='100%'>
        {adverts.map((advert, i) => {
          return (
            <SingleAdvert
              key={advert.id}
              idx={i}
              advert={advert}
              linkTo={`${advert.id}`}
              currentUser={user}
            />
          );
        })}
      </Flex>
    );
  } else {
    advertListBody = (
      <Center height='100%'>
        <Text variant='2xl'>{t('common.no-result')}</Text>
      </Center>
    );
  }

  return (
    <div>
      <ErrorHandleWrapper>
        <Helmet title={t('title.adverts')} />

        <Grid
          templateAreas={`"header header"
                    "nav main"
                    `}
          gridTemplateRows={'auto 1fr'}
          gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
          h='100%'
          columnGap={FILTER_MENU_GAP_COL}
        >
          <Grid
            style={{ gridArea: 'header' }}
            templateAreas={`"title pagination"`}
            gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
            h='100%'
            columnGap={FILTER_MENU_GAP_COL}
            position={'sticky'}
            top={PAGINATION_POSITION}
            paddingY={FILTER_MENU_GAP_ROW}
            bgColor={'white'}
            zIndex={98}
          >
            <GridItem area={'title'} alignSelf={'center'} w='100%'>
              <Heading variant='mdLarge' color='brandColors.brandGreen'>
                {t('nav.adverts')}
              </Heading>
            </GridItem>
            <GridItem area={'pagination'}>
              <HStack justifyContent={'space-between'}>
                <Button
                  variant='ZGreenSolid'
                  leftIcon={<AddIcon />}
                  size='md'
                  onClick={onOpenAddModal}
                  data-testid='addNewAdvertButton'
                >
                  {t('adverts.new-advert')}
                </Button>
                <Pagination
                  entriesPerPage={entriesPerPage}
                  setEntriesPerPage={setEntriesPerPage}
                  activePage={pagination.page}
                  changePageCallback={async (val, entriesPerPageValue) => {
                    const searchParams = qs.stringify({
                      ...queryParams,
                      pagination: { ...pagination, page: val, pageSize: entriesPerPage },
                    });
                    navigate(`${PATH_ADVERTS}?${searchParams}`);
                    await fetchFilteredAdverts(val, entriesPerPageValue);
                  }}
                  pages={pagination.pageCount}
                  testId='advertsTop'
                />
              </HStack>
            </GridItem>
          </Grid>
          <GridItem
            area={'nav'}
            position={'sticky'}
            top={MAIN_CONTENT_POSITION}
            h={`calc(100vh - ${MAIN_CONTENT_POSITION})`}
            w='100%'
            overflowY={'auto'}
          >
            <FiltersProvider handleSearch={search} ref={filterRef}>
              <InputAndLabel label={t('common.keyword')} name='keywords' testId='advertsKeyword' />
              <SelectDataStrapi
                testId='advertsCategory'
                label={t('adverts.category')}
                placeholder={t('common.select-category')}
                name='advert_category'
                dataset={categoriesList}
                getOptionLabel={(data) => {
                  return data.attributes.advertCategoryName;
                }}
              />
              <SelectDataStrapi
                testId='advertCountry'
                label={t('common.country')}
                placeholder={t('common.select-country')}
                name='country'
                dataset={countriesList}
                getOptionLabel={(data) => {
                  return data.countryName;
                }}
                getOptionValue={(data) => {
                  return !data.id ? data.countryName : data.id;
                }}
                onChangeFilter={(e) => {
                  filterRef.current.setValueFilter('country', e);
                  filterRef.current.setValueFilter('branch', null);
                  fetchBranches(e);
                }}
              />
              <SelectDataStrapi
                testId='advertBranch'
                label={t('common.branch')}
                placeholder={t('common.select-branch')}
                name='branch'
                dataset={branchesList}
                getOptionLabel={(data) => {
                  return data.branchName;
                }}
                getOptionValue={(data) => {
                  return !data.id ? data.branchName : data.id;
                }}
              />
              <SelectDataStrapi
                testId='advertCreatedby'
                label={t('adverts.created-by')}
                placeholder={t('adverts.select-created-by')}
                name='createdBy'
                dataset={[
                  { id: 'me', label: t('adverts.select-created-by-me') },
                  { id: 'others', label: t('adverts.select-created-by-others') },
                ]}
                getOptionLabel={(data) => {
                  return data.label;
                }}
              />
            </FiltersProvider>
          </GridItem>
          <GridItem area={'main'} pb={12}>
            {advertListBody}
          </GridItem>
        </Grid>

        <If condition={isOpen}>
          <AddAdvertModal
            fetchFilteredAdverts={search}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            jwt={jwt}
            singleAdvert={singleAdvert}
            currentUser={user}
          />
        </If>
      </ErrorHandleWrapper>
    </div>
  );
};
