import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Center, HStack, Input, Select, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {object} PaginationProps
 * @property {number} activePage
 * @property {number} pages
 * @property {(any,any) => Promise<void>} changePageCallback
 * @property {number} entriesPerPage
 * @property {(value: number) => void} setEntriesPerPage
 * @property {any=} arg
 * @property {string} testId
 */

/**
 * @param {PaginationProps} props
 * @returns {JSX.Element}
 */
export const Pagination = ({
  activePage = 1,
  pages,
  changePageCallback,
  entriesPerPage,
  setEntriesPerPage,
  arg,
  testId,
}) => {
  const { t } = useTranslation();
  const [valueInput, setValueInput] = useState(1);
  const numOfPages = pages?.toString().length || 1;
  const maxPages = 10 ** numOfPages;

  const changeValueInput = (newValueInput) => {
    const newValue = parseInt(newValueInput, 10);
    // 9999-Max number pages
    if (!newValue || newValue < maxPages || newValue < 1) {
      setValueInput(newValue || '');
    }
  };

  useEffect(() => {
    setValueInput(activePage);
  }, [activePage]);

  const changePage = () => {
    if (valueInput && valueInput <= pages && valueInput >= 1) {
      changePageCallback(valueInput);
    } else {
      setValueInput(activePage);
    }
  };

  const nextPage = () => {
    const page = parseInt(activePage, 10);
    if (page < pages) {
      changePageCallback(page + 1);
    }
  };

  const prevPage = () => {
    const page = parseInt(activePage, 10);
    if (page > 1) {
      changePageCallback(page - 1);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      changePage();
    }
  };

  const handleEntriesPerPage = (e) => {
    setEntriesPerPage(e.target.value);
    changePageCallback(1, e.target.value);
  };

  return (
    <HStack {...arg} gap='40px' justifyContent={'end'} minH={10}>
      {entriesPerPage && setEntriesPerPage && (
        <HStack gap={4} data-testid={testId && `${testId}ItemsPerPage`}>
          <Select size='sm' value={entriesPerPage} onChange={handleEntriesPerPage} width='auto'>
            {[15, 30, 60].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          <Text variant='sm' color='gray.550'>
            {t('common.entries-per-page')}
          </Text>
        </HStack>
      )}

      <HStack gap={0} data-testid={testId && `${testId}Pagination`}>
        <Button
          onClick={prevPage}
          isDisabled={activePage === 1}
          variant='ZGreenGhost'
          border='none'
          _hover={{ bgColor: 'none' }}
          _active={{ bgColor: 'none' }}
          _focus={{ bgColor: 'none' }}
          maxH='32px'
          minW='0'
          h='100%'
          padding={0}
        >
          <ChevronLeftIcon boxSize={8} />
        </Button>
        <Center justifyContent='flex-start' color='ZOGreen.600' fontSize='12px'>
          <Input
            onKeyPress={handleKeyPress}
            onBlur={changePage}
            type='number'
            onChange={(val) => changeValueInput(val.target.value)}
            color='ZSGreen.700'
            fontWeight='bold'
            textAlign='center'
            min='1'
            max={pages}
            w={`${numOfPages * 12}px`}
            minW='32px'
            maxH='32px'
            p={0}
            fontSize='md'
            bgColor='transparent'
            value={valueInput}
            disabled={activePage === 1 && activePage === pages}
          />
          <Text opacity='0.4' textAlign='center' w='32px' fontSize='md'>
            /
          </Text>
          <Text opacity='0.4' fontSize='md' align={'center'} minW='32px' w={`${numOfPages * 10}px`}>
            {pages || 1}
          </Text>
        </Center>
        <Button
          border='none'
          onClick={nextPage}
          isDisabled={activePage === pages}
          variant='ZGreenGhost'
          _hover={{ bgColor: 'none' }}
          _active={{ bgColor: 'none' }}
          _focus={{ bgColor: 'none' }}
          maxH='32px'
          minW='0'
          h='100%'
          padding={0}
        >
          <ChevronRightIcon boxSize={8} />
        </Button>
      </HStack>
    </HStack>
  );
};
