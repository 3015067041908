import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Flex, VStack, useToast } from '@chakra-ui/react';
import { IKnowledgeBasesMainFolder } from '../../types';

import { fetchAllDataKnowledgeBases } from '../../api/knowledge-bases';
import { IfElse, ErrorHandleWrapper } from '../../components-common/if';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import { Helmet } from '../../components-common/helmet';
import { strapiDataParser } from '../../common/strapi';
import { setErrorAction } from '../../redux';
import { ReduxState } from '../../redux/state';
import {
  FILTER_MENU_GAP_ROW,
  MAIN_CONTENT_POSITION,
  PAGINATION_POSITION,
  SORT_ORDER_ASC,
  SORT_TYPES_KB_ROOT,
} from '../../constants';
import { Category } from './category';
import { Breadcrumbs } from './breadcrumbs';
import { Sort } from './sort';

export const KnowledgeBaseView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [knowledgeBases, setKnowledgeBases] = useState<IKnowledgeBasesMainFolder[]>();
  const [sortType, setSortType] = useState(SORT_TYPES_KB_ROOT[0]);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER_ASC);

  const { user, jwt } = useSelector((state: ReduxState) => state.auth);

  useEffect(() => {
    reload();
  }, [sortType, sortOrder]);

  const reload = async () => {
    const response = await fetchAllDataKnowledgeBases(
      { jwt, toast, navigate },
      {
        errorCallback: (value) => dispatch(setErrorAction(value)),
        sort: `${sortType.sortBy}:${sortOrder}`,
      },
    );
    setKnowledgeBases(strapiDataParser(response, ['photo', 'country']));
  };

  return (
    <ErrorHandleWrapper>
      <Helmet title={t('title.knowledge-base')} data-testid='knowledgeBaseHeading' />
      <IfElse condition={knowledgeBases}>
        <VStack gap={0} w='100%'>
          <Flex
            w='100%'
            alignContent={'center'}
            flexWrap={'wrap'}
            justifyContent='start'
            minH={10}
            position={'sticky'}
            top={PAGINATION_POSITION}
            paddingY={FILTER_MENU_GAP_ROW}
            bgColor={'white'}
            zIndex={98}
          >
            <Breadcrumbs items={[{ name: t('knowledge-base.knowledge-base') }]} />
          </Flex>
          <Divider color='gray.100' opacity={1} />
          <Flex w='100%' justifyContent='flex-end' fontSize={'14px'} paddingY={FILTER_MENU_GAP_ROW}>
            <Sort
              sortTypes={SORT_TYPES_KB_ROOT}
              sortType={sortType}
              setSortType={setSortType}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
            />
          </Flex>
          <Flex
            w='100%'
            justifyContent='flex-start'
            minH={`calc(100vh - ${MAIN_CONTENT_POSITION} - 57px)`}
          >
            <Category knowledgeBases={knowledgeBases} user={user} />
          </Flex>
        </VStack>
        <SpinnerScreen />
      </IfElse>
    </ErrorHandleWrapper>
  );
};
