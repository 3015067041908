import { Box } from '@chakra-ui/react';
import { cloneElement, useEffect, useRef, useState } from 'react';

export const AnimotionBox = ({ children, ...props }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  return (
    <Box height={`${height}px`} overflow='hidden' transition='all 0.5s ease' {...props}>
      <Box ref={ref}>{children}</Box>
    </Box>
  );
};
