import { TFunction } from 'i18next';
import React from 'react';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IRecordInfo } from './type';
import {
  companyName,
  countryName,
  ekspertise,
  workPhone,
  mobilePhone,
  email,
  jobTitle,
  lineManager,
  languages,
} from './records-info';
import { IUserAuth } from '../../types';

export const allDataTemplate = (user: IUserAuth, t: TFunction<string>): IRecordInfo[] => [
  companyName(user),
  countryName(user),
  ekspertise(user),
  workPhone(user),
  mobilePhone(user),
  email(user),
  jobTitle(user),
  lineManager(user),
  languages(user, t),
];

interface Props extends BoxProps {
  user: IUserAuth;
  template?: (user: IUserAuth) => IRecordInfo[];
}
export const UserDataBody = ({ user, template, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Box p={4} {...props}>
      {(template ? template(user) : allDataTemplate(user, t)).map((data, i) => {
        return (
          data.condition && (
            <Flex fontSize='small' alignItems='center' key={i}>
              <Box layerStyle='iconWrapper'>{data.icon({ boxSize: 5, ml: 0.5 })}</Box>
              <Text ml={2} data-testid={data.dataTestId}>
                {data.label}
              </Text>
            </Flex>
          )
        );
      })}
    </Box>
  );
};
