import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Center, Img, Heading, Text, Link } from '@chakra-ui/react';
import logo from '../../assets/icons/logo/Ziegler_team.svg';
import { ReactComponent as Blocked } from '../../assets/icons/blocked.svg';
import { Helmet } from '../../components-common/helmet';

const SERVICE_DESK = 'https://dornach.atlassian.net/servicedesk/customer/portals';

export const Unauthorized = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('title.unauthorized')} />
      <Center justifyContent='flex-start' flexDirection='column' background='ZLGreen.50'>
        <Center
          layerStyle='container'
          w='45vw'
          pt={16}
          pb={12}
          flexDirection='column'
          mt='20vh'
          borderRadius={8}
        >
          <Img src={logo} display='block' w='200px' mb={3} />
          <Blocked />
          <Heading mb={2} mt={6} fontSize='24px' color='brandColors.brandGreen'>
            {t('unauthorized.title')}
          </Heading>
          <Text>
            <Trans i18nKey='unauthorized.content'>
              content
              <Link fontWeight='bold' color='ZSGreen.600' target='_blank' href={`${SERVICE_DESK}`}>
                Service Desk
              </Link>
            </Trans>
          </Text>
        </Center>
      </Center>
    </>
  );
};
