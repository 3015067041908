import React from 'react';
import { camelCase, isEmpty, map } from 'lodash';

import { parsePath } from '../../helpers/pathHelper';
import { AboutButton } from '../recursive-accordion/buttons';
import { RecursiveAccordion } from '../recursive-accordion';

const dornachUrl = '/about-ziegler/dornach';
const dornachPageId = 99999;

export const MenuDropDown = ({
  isInNav = true,
  expandRecord,
  activeCategories,
  pathParent,
  categories,
  variableNameList: {
    prefixPodCategory = 'sub_',
    parentName = '',
    prefixNameActive = 'active_',
    postfixNameActive = 'category',
    prefixName = '',
    postfixName = 'categories',
    prefixTest = 'about',
  },
}) => {
  const name = camelCase(`${prefixNameActive}${parentName}${postfixNameActive}`);
  return map(categories, (category) => {
    let to;
    if (category.id === dornachPageId) {
      to = dornachUrl;
    } else if (category.path) {
      to = category.path;
    } else {
      to = `${pathParent}/${parsePath(category.title)}`;
    }
    const active = activeCategories.activeCategory;

    return !isEmpty(category.about_sub_categories) && category ? (
      <RecursiveAccordion
        expandRecord={expandRecord}
        recordActive={activeCategories}
        key={category.id}
        idListPath={{ [name]: category.id }}
        pathParent={pathParent}
        isInNav={isInNav}
        data={category}
        variableNameList={{
          prefixPodCategory,
          parentName,
          prefixNameActive,
          postfixNameActive,
          prefixName,
          prefixTest,
          postfixName,
        }}
      />
    ) : (
      <AboutButton
        isInNav={isInNav}
        resource={category}
        active={active}
        key={category.id}
        testId={`${camelCase(`about${category.title}`)}Button`}
        linkTo={to}
        linkClick={() => expandRecord({ [name]: category.id })}
      />
    );
  });
};
