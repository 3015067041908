import { GridProps, Input } from '@chakra-ui/react';
import { GridInputWrapper } from '@dornach/ziegler-ui-kit';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';

interface Props {
  label: string;
  name: string;
  form?: UseFormReturn;
  isRequired?: boolean;
  testId: string;
  placeholder?: string;
  arg?: GridProps;
  registerOptions?: RegisterOptions;
}

export const InputAndLabel = ({
  form,
  label,
  name,
  isRequired = false,
  testId,
  placeholder,
  arg,
  registerOptions,
}: Props & GridProps) => {
  return (
    <GridInputWrapper
      name={label}
      error={form.formState?.errors?.[name]}
      isRequired={isRequired}
      testId={testId ? `${testId}Label` : undefined}
      arg={arg}
    >
      <Input
        {...form.register(name, registerOptions)}
        data-testid={testId ? `${testId}Input` : undefined}
        placeholder={placeholder}
      />
    </GridInputWrapper>
  );
};
