import { Box, Flex, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AvatarTooltipGroup } from '../../components-common/avatar-tooltip-group';
import {
  DATE_FORMAT,
  DATE_FORMAT_HOURS,
  PATH_FORUM,
  RESOURCE_NAME_FORUM_TOPIC,
} from '../../constants';
import { getRelativeDate } from '../../helpers/dateHelper';
import { parseLanguageCode } from '../../helpers/languageHelper';
import { getAuthor } from '../../helpers/userHelper';
import { ReduxState } from '../../redux/state';
import { IForumTopic } from '../../types';
import { CommentsCounter } from '../comments/comments-counter';
import { LikesCounter } from '../likes/likes-counter';

interface Props {
  topic: IForumTopic;
}

export const SingleTopic = ({ topic }: Props) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '100px 0px',
  });

  const { t, i18n } = useTranslation();
  const { user } = useSelector((state: ReduxState) => state.auth);
  const lang = parseLanguageCode(i18n.language);
  const navigate = useNavigate();

  const extractFirst50Words = (text) => {
    const first50Words = text?.split(' ').slice(0, 50).join(' ');
    const lastPeriodIndex = first50Words.lastIndexOf('.');
    if (lastPeriodIndex === -1) {
      return first50Words;
    }
    return first50Words.slice(0, lastPeriodIndex + 1);
  };

  const topicCategories = topic.categories.map((category) => {
    for (let i = 0; i < category.localizations?.data?.length; i++) {
      const localeCategory = category.localizations?.data[i];
      if (localeCategory.attributes.locale === lang) {
        return {
          title: localeCategory.attributes?.title,
          color: localeCategory.attributes?.color,
          locale: localeCategory.attributes?.locale,
          id: localeCategory.id,
        };
      }
    }
    return category;
  });

  let activityDate = topic.createdAt;
  let activityLabel = t('knowledge-base.created');

  if (topic.last_activity) {
    activityDate = topic.last_activity;
  }

  if (topic.last_activity_type) {
    switch (topic.last_activity_type) {
      case 'created':
        activityLabel = t('knowledge-base.created');
        break;

      case 'modified':
        activityLabel = t('knowledge-base.modified');
        break;

      case 'replied':
        activityLabel = t('forum.replied');
        break;

      default:
        break;
    }
  }

  return (
    <Flex ref={ref} alignItems={'center'} gap={4} borderBottom={'1px solid #e3e3e3'} py={4}>
      <VStack alignItems={'start'} gap={2} flexGrow={1} flexShrink={0} flexBasis={'150px'}>
        <Text variant={'sm'} color={'gray.550'}>
          {t('news.published-on')} - {format(new Date(topic?.createdAt), DATE_FORMAT)} -{' '}
          <strong>{getAuthor(topic.users_permissions_user)}</strong>
        </Text>

        <HStack>
          <VStack alignItems={'start'} gap={0}>
            <Link to={`${PATH_FORUM}/${topic.id}`}>
              <Heading
                variant={'lgSmall'}
                color='brandColors.brandGreen'
                _hover={{ color: 'ZLGreen.800' }}
                cursor={'pointer'}
              >
                {topic.title}
              </Heading>
            </Link>
            <Box noOfLines={2}>{extractFirst50Words(topic.content_raw)}</Box>
          </VStack>
        </HStack>

        <HStack gap={4} flexWrap={'wrap'}>
          {topicCategories.length > 0 &&
            topicCategories.map((category) => (
              <HStack
                key={category.id}
                gap={1}
                display={{ base: 'none', lg: 'flex' }}
                py={1}
                px={2}
                borderRadius={4}
                bg={'ZLGreen.100'}
                color={'ZSGreen.700'}
                _hover={{ color: 'ZSGreen.800', bg: 'ZLGreen.300' }}
                cursor={'pointer'}
                onClick={() => {
                  navigate(`${PATH_FORUM}?${encodeURI(`category[0][id]=${category.id}`)}`, {
                    state: { searchkey: category.id },
                  });
                }}
              >
                <Box w='20px' h='20px' bg={category.color} borderRadius='50%' />

                <Text userSelect={'none'} variant={'sm'}>
                  {category.title}
                </Text>
              </HStack>
            ))}
        </HStack>
      </VStack>

      <Flex grow={0} shrink={0} basis={'150px'} justifyContent={'end'}>
        <AvatarTooltipGroup
          size='sm'
          spacing='-2'
          avatars={topic.contributors}
          max='3'
          getName={(avatar) => `${avatar.name} ${avatar.surname}`}
          getSrc={(avatar) => avatar.imageUrl}
        />
      </Flex>
      <Flex grow={0} shrink={0} basis={'75px'} justifyContent={'center'}>
        {inView ? (
          <CommentsCounter resource={RESOURCE_NAME_FORUM_TOPIC} resourceId={topic.id} />
        ) : null}
      </Flex>
      <Flex grow={0} shrink={0} basis={'75px'} justifyContent={'center'}>
        {inView ? (
          <LikesCounter
            resource={RESOURCE_NAME_FORUM_TOPIC}
            resourceId={topic.id}
            countOnly={false}
          />
        ) : null}
      </Flex>
      <Flex
        grow={0}
        shrink={0}
        basis={'100px'}
        justifyContent={'center'}
        title={`${activityLabel} ${format(new Date(activityDate), DATE_FORMAT_HOURS)}`}
      >
        <Text color='gray.600' variant='sm'>
          {getRelativeDate(activityDate, user.locale)}
        </Text>
      </Flex>
    </Flex>
  );
};
