import React from 'react';
import { AccordionButton, Box, Flex, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow_up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';

const paddingHelper = {
  0: 4,
  1: 8,
  2: 12,
};

export const AboutAccordionButton = ({
  resource,
  active,
  linkTo,
  linkClick,
  nestedLevel,
  isExpanded,
  isInNav,
  testId,
}) => {
  const navigate = useNavigate();

  const handleAccordionButtonClick = () => {
    if (linkClick && navigate && (resource.hasContent || resource.id === 999999)) {
      linkClick();
      navigate(linkTo);
    }
  };

  let icon;
  if (resource.icon) {
    icon = <Image mr={2} alt='' src={resource.icon?.url} w='24px' h='16px' fit='cover' />;
  }

  return (
    <AccordionButton
      _hover={{ bgColor: 'ZLGreen.50' }}
      _active={{ bgColor: 'ZSGreen.50' }}
      pl={paddingHelper[nestedLevel]}
      borderBottom='1px solid'
      borderBottomColor={isInNav ? 'transparent' : 'gray.100'}
      color={'ZSGreen.700'}
      bgColor={resource.id === active ? 'ZSGreen.50' : 'inherit'}
      className={resource.icon && 'icon'}
      data-testid={testId}
      onClick={handleAccordionButtonClick}
    >
      <Flex textAlign='left' alignItems='center'>
        {icon}
        <Box>{resource.title}</Box>
      </Flex>
      {isExpanded ? <ArrowUp /> : <ArrowDown />}
    </AccordionButton>
  );
};

export const AboutButton = ({
  resource,
  active,
  linkTo,
  linkClick,
  nestedLevel = 0,
  isInNav,
  testId,
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (linkClick && navigate) {
      linkClick();
      navigate(linkTo);
    }
  };

  let icon;
  if (resource.icon) {
    icon = (
      <Box mr={2}>
        <Image alt='' src={resource.icon?.url} w='24px' h='16px' fit='cover' />
      </Box>
    );
  }

  return (
    <Box
      onClick={handleButtonClick}
      _hover={{ bgColor: 'ZLGreen.50', cursor: 'pointer' }}
      _active={{ bgColor: 'ZSGreen.50' }}
      py={2}
      pl={paddingHelper[nestedLevel]}
      pr={4}
      borderBottom='1px solid'
      borderBottomColor={isInNav ? 'transparent' : 'gray.100'}
      color={'ZSGreen.700'}
      bgColor={resource.id === active ? 'ZSGreen.50' : 'inherit'}
      className={`about-button ${resource.icon && 'icon'}`}
      data-testid={testId}
    >
      <Flex alignItems='center'>
        {icon}
        <Box wordBreak='break-all'>{resource.title}</Box>
      </Flex>
    </Box>
  );
};
