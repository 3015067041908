import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { get, map } from 'lodash';
import { IApiParams, ICountryName } from '../types';
import { strapiDataParser } from '../common/strapi';

import { getHeaders } from './common';
import { PATH_COUNTRIES, PATH_FIND_MANY_COUNTRIES } from './utils/path';
import { catchError } from './utils/catch-error';

const fieldsAllCountries = ['countryName'];
const sort = ['countryName:asc'];

export const fetchAllCountries = ({ jwt, toast, navigate }: IApiParams) =>
  axios
    .get(
      `${PATH_COUNTRIES}?${qs.stringify({
        fields: fieldsAllCountries,
        sort,
        populate: false,
        pagination: {
          start: 0,
          limit: 99999,
        },
      })}`,
      getHeaders(jwt),
    )
    .then((response: AxiosResponse<ICountryName[]>) =>
      map([...strapiDataParser(response), { countryName: 'Global', id: 'global' }], (country) => ({
        id: country.id,
        countryName: country.countryName,
      })),
    )
    .catch((error) => catchError(error, toast, navigate));

export const customFindManyCountries = async ({ jwt, toast, navigate }) =>
  axios
    .get(PATH_FIND_MANY_COUNTRIES, getHeaders(jwt))
    .then((response: AxiosResponse<ICountryName[]>) => response.data)
    .catch((error) => catchError(error, toast, navigate));

export const findCountryFilterOptions = async (jwt, toast, navigate, filter) => {
  const cityFilter = {
    city: filter,
  };
  await axios
    .get(
      `${PATH_COUNTRIES}?${qs.stringify({
        ...parseFilters(cityFilter),
        fields: fieldsAllCountries,
      })}`,
      getHeaders(jwt),
    )
    .then((response: AxiosResponse<ICountryName[]>) => {
      return response.data;
    })
    .catch((error) => catchError(error, toast, navigate));
};

const parseFilters = (filters) => ({
  filters: {
    $and: [
      get(filters, ['city', 'id'])
        ? {
            cities: [get(filters, ['city', 'id'])],
          }
        : {},
      get(filters, ['city']) && !get(filters, ['city', 'id'])
        ? {
            cities: {
              cityName: {
                $containsi: get(filters, ['city']),
              },
            },
          }
        : {},
    ],
  },
});
