import React from 'react';
import { useTranslation } from 'react-i18next';
import { Center, Heading, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Helmet } from '../helmet';

interface Props {
  label: string;
  icon: JSX.Element;
}

export const ErrorScreen = ({ label, icon }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Helmet title={t('title.not-found')} />
      <Center
        justifyContent='flex-start'
        flexDirection='column'
        background='ZLGreen.50'
        width='100%'
      >
        <Center
          layerStyle='container'
          w='45vw'
          pt={16}
          pb={12}
          flexDirection='column'
          my='10vh'
          borderRadius={8}
        >
          {icon}
          <Heading mb={2} mt={6} fontSize='24px' color='brandColors.brandGreen'>
            {t(label)}
          </Heading>
          <Button
            mt={6}
            onClick={() => {
              navigate('/');
            }}
          >
            {t('common.back-home')}
          </Button>
        </Center>
      </Center>
    </>
  );
};
