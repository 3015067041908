import { Avatar, Box, Flex, Heading, Img, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useInView } from 'react-intersection-observer';
import { DATE_FORMAT } from '../../constants';

import { resizedImgUrl } from '../../helpers/imageHelper';
import { getAuthor } from '../../helpers/userHelper';
import { CommentsCounter } from '../comments/comments-counter';
import { LikesCounter } from '../likes/likes-counter';

export const SingleNews = ({ news, linkTo, idx, jwt }) => {
  const { ref, inView } = useInView(
    {
      triggerOnce: true,
      rootMargin: '100px 0px',
    },
    [jwt, news],
  );

  const { t } = useTranslation();
  const firstRecorded = idx === 0;
  const isBlocked = get(news, ['users_permissions_user', 'blocked']);
  const publicationDate = news.publication_date || news.publishedAt;

  return (
    <Flex
      ref={ref}
      mt={firstRecorded ? 0 : 4}
      pt={firstRecorded ? 0 : 4}
      borderTop={!firstRecorded ? '1px solid' : '0px solid'}
      borderColor='gray.100'
      data-testid='separatePostAtList'
    >
      <Box w='300px' h='180px' bgColor='ZLGreen.100'>
        <Link to={linkTo} data-testid='postImage'>
          {inView ? (
            <Img
              alt=''
              height='100%'
              width='300px'
              maxW='none'
              objectFit='cover'
              src={resizedImgUrl(news.header, 'small')}
            />
          ) : null}
        </Link>
      </Box>

      <Flex ml={4} flexDirection='column' h='auto'>
        <Flex flexDirection='column'>
          <Text mt={2} fontSize='small' color='gray.550' data-testid='postPublicationDate'>
            {t('news.published-on')} {format(new Date(publicationDate), DATE_FORMAT)}
          </Text>
          <Link to={linkTo}>
            <Flex flexDirection='row' alignItems='flex-start' justifyContent='space-between'>
              <Heading
                my={2}
                size='lg'
                color='brandColors.brandGreen'
                _hover={{ color: 'ZSGreen.800' }}
                data-testid='postTitle'
              >
                {news.title}
              </Heading>
            </Flex>
          </Link>
          <Flex alignItems='center'>
            <Avatar
              src={!isBlocked && get(news.users_permissions_user, 'imageUrl')}
              mr={4}
              data-testid='postAuthorAvatar'
            />
            <Box fontSize='md' data-testid='postAuthorName'>
              <Flex alignItems='center' color={isBlocked ? 'gray.400' : 'inherit'}>
                <Text className='notranslate' fontWeight='700'>
                  {getAuthor(news.users_permissions_user)}
                </Text>
                {isBlocked && (
                  <Text ml={1} fontSize='xs' fontStyle='italic'>
                    ({t('common.non-active')})
                  </Text>
                )}
              </Flex>
            </Box>
          </Flex>
        </Flex>
        <Flex flexDirection='row' gap={2} alignItems='center' mt={2}>
          {inView ? (
            <>
              <LikesCounter resource='post' resourceId={news.id} />
              <CommentsCounter resource='post' resourceId={news.id} />
            </>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};
