import axios from 'axios';
import qs from 'qs';

import { PATH_BUSINESS_APPS, PATH_USERS } from './utils/path';
import { getHeaders } from './common';
import { catchError } from './utils/catch-error';

export const fetchBusinessApps = (jwt, toast, navigate, errorCallback) => {
  errorCallback(false);
  return axios
    .get(`${PATH_BUSINESS_APPS}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate, errorCallback));
};

export const fetchFavouriteBusinessApps = (jwt, userId) => {
  const query = qs.stringify({
    fields: ['id'],
    populate: ['business_apps'],
  });
  return axios.get(`${PATH_USERS}/${userId}?${query}`, getHeaders(jwt));
};
