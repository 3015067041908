import { ChevronRightIcon, DeleteIcon, EditIcon, EmailIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { de, enGB as en, fr, nl } from 'date-fns/locale';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAllAdverts, fetchSingleAdvert } from '../../api';
import { strapiDataParser, strapiDataParserSingleWithMultRelations } from '../../common/strapi';
import { GoBack } from '../../components-common/go-back';
import { Helmet } from '../../components-common/helmet';
import { ErrorHandleWrapper, If } from '../../components-common/if';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import {
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  HORIZONTAL_PADDING,
  PAGINATION_HEIGHT,
  PAGINATION_POSITION,
  PATH_ADVERTS,
  ROLE_INTRANET_ADMIN,
} from '../../constants';
import { parsedContent } from '../../helpers/contentHelper';
import { resizedImgUrl } from '../../helpers/imageHelper';
import { parseLanguageCode } from '../../helpers/languageHelper';
import { getAuthor } from '../../helpers/userHelper';
import { setErrorAction } from '../../redux';
import { AddAdvertModal } from './add-advert-modal';
import DeleteAdvertModal from './delete-advert-modal';
import { SingleAdvertSmall } from './single-advert-small';

export const SingleAdvertView = () => {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const { advertIds } = useParams();
  const { jwt, user } = useSelector((state) => state.auth);
  const [advert, setAdvert] = useState([]);
  const [otherAdverts, setOtherAdverts] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [deletingAdvert, setDeletingAdvert] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

  const onCloseDeleteCustom = (refreshNavigation) => {
    onCloseDelete();
    if (refreshNavigation === true) {
      navigate(PATH_ADVERTS);
    }
  };

  useEffect(() => {
    const initSingleAdvert = async () => {
      setOtherAdverts([]);
      setAdvert(null);
      setDeletingAdvert(0);

      await fetchTheAdvert().then((id) => {
        // When we got result we fetch the 3 most recent others adverts
        fetchAllAdverts(
          jwt,
          toast,
          navigate,
          (value) => dispatch(setErrorAction(value)),
          user,
          1,
          3,
          {
            $and: [
              {
                id: {
                  $ne: advertIds,
                },
              },
            ],
          },
        ).then((result) => {
          const parsedResult = strapiDataParser(result, ['photo', 'users_permissions_user']);
          setOtherAdverts(parsedResult);
        });
      });
    };
    initSingleAdvert();

    return () => {
      setOtherAdverts([]);
      setSelectedPhoto(null);
      setAdvert(null);
      setDeletingAdvert(0);
    };
  }, [jwt, advertIds]);

  const fetchTheAdvert = async () => {
    setSelectedPhoto(null);
    setIsLoading(true);
    const response = await fetchSingleAdvert(
      jwt,
      toast,
      navigate,
      (value) => dispatch(setErrorAction(value)),
      advertIds,
    );

    const parsedData = strapiDataParserSingleWithMultRelations(response, [
      'photo',
      'users_permissions_user',
      'advert_category',
      'country',
      'branch',
      'currency',
    ]);

    setAdvert(parsedData);

    if (parsedData.photo && parsedData.photo.length > 0) {
      setSelectedPhoto(parsedData.photo[0]);
    }
    setIsLoading(false);
    return parsedData.id;
  };

  const editAdvertOnClick = (id) => {
    onOpen();
  };

  const deleteAdvertOnClick = (id) => {
    setDeletingAdvert(id);
    onOpenDelete();
  };

  const lang = parseLanguageCode(i18n.language);

  let myLocale;
  switch (lang) {
    case 'fr':
      myLocale = fr;
      break;

    case 'de':
      myLocale = de;
      break;

    case 'nl':
      myLocale = nl;
      break;

    default:
      myLocale = en;
      break;
  }

  let advertCategory;
  if (advert) {
    advertCategory = advert?.advert_category?.advertCategoryName;
    advert?.advert_category?.localizations?.data?.forEach((locale) => {
      if (locale.attributes.locale === lang) {
        advertCategory = locale.attributes.advertCategoryName;
      }
    });
  }

  if (isLoading) {
    return <SpinnerScreen />;
  }
  let selectedImage = null;
  let listImages = null;

  if (selectedPhoto && selectedPhoto != null) {
    selectedImage = (
      <Box mb={8} width='100%' height='538px'>
        <Link w='100%' h='100%' href={selectedPhoto.url} isExternal>
          <Center w='100%' h='100%'>
            <Image
              w='100%'
              h='auto'
              maxHeight='100%'
              fit='contain'
              alt=''
              src={resizedImgUrl(selectedPhoto, 'large')}
            />
          </Center>
        </Link>
      </Box>
    );
  }

  if (advert.photo) {
    listImages = (
      <Grid alignItems='center' templateColumns='repeat(5, 1fr)' gap={4} w='100%' h='auto'>
        {advert.photo.map((e) => {
          return (
            <Image
              cursor='pointer'
              onClick={() => setSelectedPhoto(e)}
              key={e.id}
              alt=''
              fit='cover'
              w='100%'
              h='100%'
              maxHeight='88px'
              src={resizedImgUrl(e, 'thumbnail')}
            />
          );
        })}
      </Grid>
    );
  }

  let buttons;
  if (user?.id === advert?.users_permissions_user?.id || user?.role.name === ROLE_INTRANET_ADMIN) {
    buttons = (
      <>
        <Button
          variant='ZOneGreenSolid'
          color='ZSGreen.700'
          leftIcon={<EditIcon />}
          size='md'
          onClick={() => editAdvertOnClick(advert.id)}
          data-testid='advertEditButton'
        >
          {t('adverts.edit-advert')}
        </Button>
        <Button
          variant='ZOneGreenSolid'
          color='ZSGreen.700'
          leftIcon={<DeleteIcon />}
          size='md'
          onClick={() => deleteAdvertOnClick(advert.id)}
          data-testid='advertDeleteButton'
        >
          {t('adverts.delete-advert')}
        </Button>
      </>
    );
  } else {
    buttons = (
      <Link
        target='_blank'
        href={`mailto:${advert?.users_permissions_user?.email}?subject=ZieglerTeam -- ${encodeURI(
          advert?.title,
        )}&body=${encodeURI(window.location.href)}`}
      >
        <Button
          variant='ZGreenSolid'
          leftIcon={<EmailIcon />}
          size='md'
          data-testid='advertContactButton'
        >
          {t('common.contact-user')}
        </Button>
      </Link>
    );
  }

  let negociate;
  if (advert.negociate === true && advert.price && advert.price !== '') {
    negociate = <Text>{t('adverts.price-negociate')}</Text>;
  }

  return (
    <div>
      <ErrorHandleWrapper>
        <Helmet title={t('title.single-advert', { singleAdvertTitle: advert.title })} />

        <Grid
          style={{ gridArea: 'header' }}
          templateAreas={`"title"`}
          gridTemplateColumns={`1fr`}
          h='100%'
          columnGap={FILTER_MENU_GAP_COL}
          position={'sticky'}
          top={PAGINATION_POSITION}
          paddingY={FILTER_MENU_GAP_ROW}
          bgColor={'white'}
          zIndex={98}
        >
          <GridItem area={'title'} alignSelf={'center'}>
            <HStack minH={PAGINATION_HEIGHT} alignItems={'center'}>
              <GoBack backText={t('nav.adverts')} fallback={PATH_ADVERTS} />
              <ChevronRightIcon color='gray.500' ml={2} />
              <Text color={'gray.600'} variant='lg'>
                {advert.title}
              </Text>
            </HStack>
          </GridItem>
        </Grid>

        <Box w='80%' ml='auto' mr='auto'>
          <Box px={HORIZONTAL_PADDING} mx={8}>
            {selectedImage}
            {listImages}

            <Heading
              mt={8}
              mb={14}
              wordBreak='break-word'
              variant='lgLarge'
              color='brandColors.brandGreen'
            >
              {advert.title}
            </Heading>

            <Grid
              templateColumns='1fr 1.5fr 2fr 1.5fr'
              borderColor='gray.100'
              py={4}
              borderBottomWidth='1px'
              borderTopWidth='1px'
              borderStyle='solid'
            >
              <VStack
                alignItems='start'
                spacing={1}
                borderEndWidth='1px'
                borderColor='gray.100'
                borderStyle='solid'
              >
                <Text data-testid='advertPrice'>
                  {t('adverts.price')}
                  {':'}
                </Text>
                <HStack>
                  <Heading variant='lgLarge'>
                    {advert.price && advert.price !== '' ? advert.price : '-'}
                  </Heading>
                  <Heading variant='mdLarge'>
                    {advert.price &&
                    advert.price !== '' &&
                    advert.currency?.iso_code &&
                    advert.currency?.iso_code !== ''
                      ? advert.currency.iso_code
                      : ''}
                  </Heading>
                </HStack>
                {negociate}
              </VStack>

              <VStack
                alignItems='start'
                pl={4}
                spacing={2}
                borderEndWidth='1px'
                borderColor='gray.100'
                borderStyle='solid'
              >
                <HStack>
                  <Text data-testid='advertCategory'>
                    {t('adverts.category')}
                    {':'}
                  </Text>
                  <Text as='b'>{advertCategory}</Text>
                </HStack>
              </VStack>

              <VStack
                alignItems='start'
                pl={4}
                spacing={2}
                borderEndWidth='1px'
                borderColor='gray.100'
                borderStyle='solid'
              >
                <HStack>
                  <Text data-testid='advertPublishedOn'>
                    {t('news.published-on')}
                    {':'}
                  </Text>
                  <Text as='b'>
                    {format(new Date(advert.publishedAt), 'd MMM yyyy', {
                      locale: myLocale,
                    }).toUpperCase()}
                  </Text>
                </HStack>

                <HStack>
                  <Text data-testid='advertExpires'>
                    {t('adverts.expires')}
                    {':'}
                  </Text>
                  <Text as='b'>
                    {format(new Date(advert.expires), 'd MMM yyyy', {
                      locale: myLocale,
                    }).toUpperCase()}
                  </Text>
                </HStack>
              </VStack>

              <VStack alignItems='start' pl={4} spacing={2}>
                <HStack>
                  <Text data-testid='advertCountry'>
                    {t('common.country')}
                    {':'}
                  </Text>
                  <Text as='b'>{advert.country?.countryName}</Text>
                </HStack>

                <HStack>
                  <Text data-testid='advertBranch'>
                    {t('common.branch')}
                    {':'}
                  </Text>
                  <Text as='b'>{advert.branch?.branchName}</Text>
                </HStack>
              </VStack>
            </Grid>

            <Flex borderColor='gray.100' py={4} borderBottomWidth='1px' borderStyle='solid'>
              <Flex alignItems='center' data-testid='advertAuthor'>
                <Avatar src={get(advert.users_permissions_user, 'imageUrl')} mr={4} />
                <Box fontSize='md'>
                  <Flex alignItems='center'>
                    <Text className='notranslate' fontWeight='700'>
                      {getAuthor(advert.users_permissions_user)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>

              <VStack ml='auto' alignItems='none'>
                {buttons}
              </VStack>
            </Flex>

            <Box mb={20}>
              <Heading my={4} variant='smLarge' data-testid='advertDescription'>
                {t('adverts.content')}
              </Heading>
              <Box>
                <Box
                  wordBreak='break-word'
                  dangerouslySetInnerHTML={{ __html: parsedContent(advert.content) }}
                />
              </Box>

              <Box mt={2}>
                <Heading mb={8} variant='smLarge' data-testid='otherAdvertsTitle'>
                  {t('adverts.other-advert')}
                </Heading>
                <SimpleGrid columns={3} spacing={16}>
                  {otherAdverts.map((advertIterated, i) => {
                    return (
                      <SingleAdvertSmall
                        key={advertIterated.id}
                        idx={i}
                        advert={advertIterated}
                        linkTo={`../${advertIterated.id}`}
                        currentUser={user}
                        testId={'oneOfOtherAdverts'}
                      />
                    );
                  })}
                </SimpleGrid>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box textAlign='right' m={5}>
          <If condition={isOpenDelete}>
            <DeleteAdvertModal
              deletingAdvert={deletingAdvert}
              isOpenDelete={isOpenDelete}
              onOpenDelete={onOpenDelete}
              onCloseDelete={onCloseDeleteCustom}
              jwt={jwt}
              toast={toast}
              navigate={navigate}
            />
          </If>

          <If condition={isOpen}>
            <AddAdvertModal
              fetchFilteredAdverts={fetchTheAdvert}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              jwt={jwt}
              singleAdvert={advert}
              currentUser={user}
            />
          </If>
        </Box>
      </ErrorHandleWrapper>
    </div>
  );
};
