import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Link as ChakraLink,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Progress,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { get, map } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { UseApiParams } from '../../helpers/hooks/use-api-params';

import { ReactComponent as Logo } from '../../assets/icons/logo/Ziegler_team.svg';
import {
  ADVERT_FEATURE,
  CANNY_LINK,
  FORUM_FEATURE,
  MENU_HEIGHT,
  PATH_ABOUT_ZIEGLER,
  PATH_ADVERTS,
  PATH_BRANCHES,
  PATH_BUSINESS_APPS,
  PATH_COMPLIANCE,
  PATH_COOKIES,
  PATH_DORNACH_PAGE,
  PATH_FORUM,
  PATH_KNOWLEDGE_BASE,
  PATH_NEWS,
  PATH_ORG_CHART,
  PATH_PRIVACY,
  PATH_PROFILE,
  PATH_TERMS,
  PATH_USERS,
} from '../../constants';

import { updateMe } from '../../api';
import { ReactComponent as ApplicationTab } from '../../assets/icons/icon_applications_tab.svg';
import { ReactComponent as Compliance } from '../../assets/icons/icon_complience.svg';
import { ReactComponent as DornachIcon } from '../../assets/icons/icon_for_IT.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/icon_support.svg';
import { AboutDropdown } from '../../components-common/about-dropdown';
import { HeaderButtonList } from '../../components-common/header-button-list';
import { IfElse } from '../../components-common/if';
import { parsedContent } from '../../helpers/contentHelper';
import { parseLanguageCode } from '../../helpers/languageHelper';
import { updateUserAction } from '../../redux';
import { GetProfileProgession } from '../profile/profile-view';
import './header.css';

const HeaderButton = ({ to, children, activeLocation, arg }) => (
  <Button variant='ZGreenMenuButton' {...arg} pointerEvents={arg?.isDisabled ? 'none' : 'initial'}>
    <IfElse condition={to}>
      {to && (
        <Link to={to} className={activeLocation === to ? 'active' : ''}>
          {children}
        </Link>
      )}
      <> {children}</>
    </IfElse>
  </Button>
);

export const AboutMenu = ({ activeLocation }) => {
  const { t } = useTranslation();

  return (
    <HeaderButtonList
      textMainButton={t('nav.about-ziegler')}
      pathMainButton={PATH_ABOUT_ZIEGLER}
      activeLocation={activeLocation}
      testId='zieglerTabButton'
    >
      <AboutDropdown />
    </HeaderButtonList>
  );
};

export const UserMenu = ({ activeLocation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <HeaderButtonList
      textMainButton={t('nav.users')}
      pathMainButton={PATH_USERS}
      activeLocation={activeLocation}
      testId='userMenuButton'
      showMenu
    >
      <MenuItem onClick={() => navigate(PATH_ORG_CHART)}>{t('nav.org-chart')}</MenuItem>
      <MenuItem onClick={() => navigate(PATH_USERS)}>{t('nav.user-list')}</MenuItem>
    </HeaderButtonList>
  );
};

export const Header = ({
  logoutAction,
  activeLocation,
  banner,
  shouldShowTermsAndPrivacy = true,
  isInError,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { user, locales } = useSelector((state) => state.auth);
  const { favourites } = useSelector((state) => state.favourites);
  const [languagesList, setLanguagesList] = useState(locales);
  const apiParams = UseApiParams();
  const [profileProgression, setProfileProgression] = useState(100);

  useEffect(() => {
    setLanguagesList(locales);
  }, [locales]);

  useEffect(() => {
    if (user?.locale && user.locale !== parseLanguageCode(i18n.language)) {
      changeLanguage(user.locale);
    }

    setProfileProgression(GetProfileProgession(user));
  }, [user]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const updateData = {
      id: user.id,
      locale: lng,
    };
    updateMe(apiParams, updateData, async () => {
      dispatch(updateUserAction({ ...user, locale: lng }));
    });
  };

  let knowledgebase;
  if (user?.provider === 'google') {
    knowledgebase = (
      <HeaderButton
        activeLocation={activeLocation}
        isActive
        to={PATH_KNOWLEDGE_BASE}
        arg={{ 'data-testid': 'knowledgeBaseTabButton' }}
      >
        {t('nav.knowledge-base')}
      </HeaderButton>
    );
  } else {
    knowledgebase = (
      <Tooltip label={t('common.comingSoon')}>
        <Box>
          <HeaderButton
            activeLocation={activeLocation}
            isActive
            to={PATH_KNOWLEDGE_BASE}
            arg={{ isDisabled: true, 'data-testid': 'knowledgeBaseTabButton' }}
          >
            {t('nav.knowledge-base')}
          </HeaderButton>
        </Box>
      </Tooltip>
    );
  }

  const profilePopoverDisplayedRef = useRef(false);

  const {
    isOpen: isOpenProfilePopover,
    onToggle: onToggleProfilePopover,
    onClose: onCloseProfilePopover,
  } = useDisclosure();

  useEffect(() => {
    if (profilePopoverDisplayedRef.current) return;
    profilePopoverDisplayedRef.current = true;
    onToggleProfilePopover();
  }, []);

  let userProfile;

  if (profileProgression !== 100) {
    userProfile = (
      <Tooltip label={t('nav.display-profile')}>
        <Popover
          closeOnBlur={false}
          isOpen={isOpenProfilePopover}
          onClose={onCloseProfilePopover}
          offset={[-100, 8]}
        >
          <PopoverTrigger>
            <MenuButton minH='40px' layerStyle='inputWrapper' data-testid='navUser'>
              <Flex alignItems='center'>{user && <Avatar src={get(user, 'imageUrl')} />}</Flex>
            </MenuButton>
          </PopoverTrigger>
          <PopoverContent color='gray.600' borderColor='ZSGreen.700'>
            <PopoverHeader pt={4} fontWeight='bold' color='ZSGreen.700' border='0'>
              {t('common.profile-uncomplete')}
            </PopoverHeader>
            <PopoverArrow borderColor='ZSGreen.700' />
            <PopoverCloseButton />
            <PopoverBody>
              {t('common.profile-uncomplete-info')}
              <HStack minH={10}>
                <Text>{t('common.profile-completeness')}</Text>
                <Progress
                  width='200px'
                  value={profileProgression}
                  borderRadius={10}
                  colorScheme='ZLGreen'
                />
                <Text>{profileProgression}%</Text>
              </HStack>
            </PopoverBody>
            <PopoverFooter
              border='0'
              display='flex'
              alignItems='center'
              justifyContent='center'
              pb={4}
            >
              <ButtonGroup size='sm'>
                <Link to={PATH_PROFILE}>
                  <Button
                    onClick={() => {
                      onToggleProfilePopover();
                    }}
                  >
                    {t('nav.edit-profile')}
                  </Button>
                </Link>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </Tooltip>
    );
  } else {
    userProfile = (
      <MenuButton minH='40px' layerStyle='inputWrapper' data-testid='navUser'>
        <Flex alignItems='center'>{user && <Avatar src={get(user, 'imageUrl')} />}</Flex>
      </MenuButton>
    );
  }

  return (
    <>
      <Flex
        align='center'
        justify={user?.afterFirstLogin ? 'space-around' : 'space-between'}
        borderBottom='1px solid'
        borderColor='gray.200'
        w='100%'
        px='2.5%'
        bgColor='white'
        position='sticky'
        top='0'
        zIndex={100}
        height={MENU_HEIGHT}
      >
        <Link to={user?.afterFirstLogin ? PATH_NEWS : '/'} data-testid='navLogo'>
          <Logo width='120px' height='100%' />
        </Link>
        <IfElse condition={user?.afterFirstLogin && !isInError}>
          <Flex alignItems='center' justifyContent='center' ml={10} p={0}>
            <HeaderButton
              activeLocation={activeLocation}
              isActive
              to={PATH_NEWS}
              arg={{ 'data-testid': 'newsTabButton' }}
            >
              {t('nav.news')}
            </HeaderButton>
            <AboutMenu activeLocation={activeLocation} />
            <UserMenu activeLocation={activeLocation} />
            {knowledgebase}
            <HeaderButton
              activeLocation={activeLocation}
              isActive
              to={PATH_BRANCHES}
              arg={{ 'data-testid': 'branchesTabButton' }}
            >
              {t('nav.branches')}
            </HeaderButton>

            <HeaderButton
              activeLocation={activeLocation}
              isActive
              to={FORUM_FEATURE && PATH_FORUM}
              arg={{ isDisabled: !FORUM_FEATURE, 'data-testid': 'forumTabButton' }}
            >
              {t('nav.forum')}
            </HeaderButton>

            <HeaderButton
              activeLocation={activeLocation}
              isActive
              to={ADVERT_FEATURE && PATH_ADVERTS}
              arg={{ isDisabled: !ADVERT_FEATURE, 'data-testid': 'advertsTabButton' }}
            >
              {t('nav.adverts')}
            </HeaderButton>
          </Flex>
          {shouldShowTermsAndPrivacy && !isInError && (
            <Flex alignItems='center' justifyContent='center' ml={10} p={0}>
              <HeaderButton activeLocation={activeLocation} isActive to={PATH_PRIVACY}>
                {t('nav.privacy-policy')}
              </HeaderButton>
              <HeaderButton activeLocation={activeLocation} isActive to={PATH_TERMS}>
                {t('nav.terms')}
              </HeaderButton>
            </Flex>
          )}
        </IfElse>
        <Spacer />
        {user?.afterFirstLogin && !isInError && (
          <HStack alignItems='center' gap={4}>
            <Flex alignItems='center' data-testid='navDornach'>
              <Tooltip label={t('nav.dornach')}>
                <Link
                  to={PATH_DORNACH_PAGE}
                  cursor='pointer'
                  className={`zIcon dornachIcon ${
                    PATH_DORNACH_PAGE === activeLocation ? 'active' : ''
                  }`}
                >
                  <DornachIcon width='30px' height='30px' />
                </Link>
              </Tooltip>
            </Flex>
            <Flex alignItems='center' data-testid='navApps'>
              <Menu>
                <Tooltip label={t('nav.applications')}>
                  <MenuButton
                    layerStyle='inputWrapper'
                    className={`zIcon dornachIcon ${
                      PATH_BUSINESS_APPS === activeLocation ? 'active' : ''
                    }`}
                  >
                    <ApplicationTab width='30px' height='30px' />
                  </MenuButton>
                </Tooltip>
                <MenuList
                  borderColor='gray.200'
                  color={'ZSGreen.700'}
                  maxWidth='30vh'
                  maxHeight='70vh'
                  overflowY='scroll'
                  zIndex='3'
                >
                  {map(favourites, (favourite) => (
                    <ChakraLink
                      target='_blank'
                      href={favourite.link}
                      key={
                        favourite.isPrivate ? `private-${favourite.id}` : `global-${favourite.id}`
                      }
                      overflowWrap='break-word'
                      wordBreak='break-word'
                      _hover={{ borderBottom: 'none' }}
                    >
                      <MenuItem _hover={{ bgColor: 'ZLGreen.50' }}>{favourite.title}</MenuItem>
                    </ChakraLink>
                  ))}
                  <MenuDivider />
                  <Link to={PATH_BUSINESS_APPS}>
                    <MenuItem
                      bgColor={PATH_BUSINESS_APPS === activeLocation && 'ZSGreen.50'}
                      _hover={{ bgColor: 'ZLGreen.50' }}
                      data-testid='navShowAllApps'
                    >
                      {t('common.show-all')}
                    </MenuItem>
                  </Link>
                </MenuList>
              </Menu>
            </Flex>
            <Flex alignItems='center' data-testid='navComply'>
              <Tooltip label={t('nav.compliance')}>
                <Link
                  to={PATH_COMPLIANCE}
                  cursor='pointer'
                  className={`zIcon dornachIcon ${
                    PATH_COMPLIANCE === activeLocation ? 'active' : ''
                  }`}
                >
                  <Compliance width='30px' height='30px' />
                </Link>
              </Tooltip>
            </Flex>
            <Menu>
              <>
                <Tooltip label={t('nav.support')}>
                  <MenuButton
                    color={'gray.600'}
                    layerStyle='inputWrapper'
                    _hover={{ color: 'ZSGreen.700' }}
                    data-testid='navUser'
                  >
                    <QuestionIcon width='25px' height='25px' />
                  </MenuButton>
                </Tooltip>

                <MenuList borderColor='gray.200' color={'ZSGreen.700'}>
                  <Link to={PATH_PRIVACY}>
                    <MenuItem
                      bgColor={PATH_PRIVACY === activeLocation && 'ZSGreen.50'}
                      _hover={{ bgColor: 'ZLGreen.50' }}
                    >
                      {t('footer.privacy-policy')}
                    </MenuItem>
                  </Link>
                  <Link to={PATH_COOKIES}>
                    <MenuItem
                      bgColor={PATH_COOKIES === activeLocation && 'ZSGreen.50'}
                      _hover={{ bgColor: 'ZLGreen.50' }}
                    >
                      {t('footer.cookies')}
                    </MenuItem>
                  </Link>
                  <Link to={PATH_TERMS}>
                    <MenuItem
                      bgColor={PATH_TERMS === activeLocation && 'ZSGreen.50'}
                      _hover={{ bgColor: 'ZLGreen.50' }}
                    >
                      {t('footer.terms')}
                    </MenuItem>
                  </Link>
                  <Link to={CANNY_LINK} target='_blank'>
                    <MenuItem _hover={{ bgColor: 'ZLGreen.50' }}>{t('footer.canny')}</MenuItem>
                  </Link>
                </MenuList>
              </>
            </Menu>

            <Menu>
              <>
                {userProfile}

                <MenuList borderColor='gray.200' color={'ZSGreen.700'}>
                  <Link to={PATH_PROFILE}>
                    <MenuItem
                      bgColor={PATH_PROFILE === activeLocation && 'ZSGreen.50'}
                      _hover={{ bgColor: 'ZLGreen.50' }}
                    >
                      {t('nav.edit-profile')}
                    </MenuItem>
                  </Link>
                  <Accordion allowToggle>
                    <AccordionItem border='none'>
                      <AccordionButton _hover={{ bgColor: 'ZLGreen.50' }}>
                        <Flex
                          w='100%'
                          justifyContent='space-between'
                          alignItems='center'
                          textAlign='left'
                        >
                          <Box>{t('common.language')}</Box>
                          <AccordionIcon />
                        </Flex>
                      </AccordionButton>
                      <AccordionPanel p='0' w='100%'>
                        {map(languagesList, (language) => (
                          <Box
                            key={language.code}
                            py={1}
                            pl={8}
                            pr={4}
                            cursor='pointer'
                            bgColor={
                              language.code === parseLanguageCode(i18n.language)
                                ? 'ZSGreen.50'
                                : 'initial'
                            }
                            _hover={{ bgColor: 'ZLGreen.50' }}
                            onClick={() => changeLanguage(language.code)}
                          >
                            {language.name}
                          </Box>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                  <MenuItem _hover={{ bgColor: 'ZLGreen.50' }} onClick={logoutAction}>
                    {t('nav.log-out')}
                  </MenuItem>
                </MenuList>
              </>
            </Menu>
          </HStack>
        )}
      </Flex>
      {banner?.show && (
        <Box
          p={1}
          bgColor={banner.type === 'intervention' ? 'ZLGreen.400' : 'orange.500'}
          w='100%'
          textAlign='center'
          color='#fff'
          fontWeight='700'
          boxShadow='md'
          data-testid='navIntervention'
          position={'relative'}
          zIndex={99}
        >
          <Box
            wordBreak='break-word'
            dangerouslySetInnerHTML={{ __html: parsedContent(banner.alertText) }}
          />
        </Box>
      )}
    </>
  );
};
