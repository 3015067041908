import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow_up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';
import useComponentVisible from '../../helpers/hooks/useComponentVisible';

export const HeaderButtonList = ({
  activeLocation,
  children,
  pathMainButton,
  textMainButton,
  testId,
  showMenu,
}) => {
  const navigate = useNavigate();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  let dataTestId;
  if (testId) {
    dataTestId = { 'data-testid': testId };
  }
  return (
    <Menu isOpen={!showMenu ? isComponentVisible : undefined}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            onClick={() => (!showMenu ? navigate(pathMainButton) : setIsComponentVisible(!isOpen))}
            {...dataTestId}
            rightIcon={
              isOpen ? (
                <ArrowUp onClick={!showMenu ? () => setIsComponentVisible(false) : undefined} />
              ) : (
                <ArrowDown
                  onClick={
                    !showMenu
                      ? (e) => {
                          e.stopPropagation();
                          setIsComponentVisible(true);
                        }
                      : undefined
                  }
                />
              )
            }
            variant='buttonMenuDropDown'
            _after={{
              content: "''",
              position: 'absolute',
              bottom: '0',
              background: 'ZLGreen.600',
              left: 0,
              height: '3px',
              mb: '-20px',
              width: '100%',
              transform: activeLocation === pathMainButton ? 'scaleX(1)' : 'scaleX(0)',
              transition: 'transform .2s',
            }}
            _focus={{
              color: 'ZLGreen.600',
              bgColor: 'none',
            }}
          >
            {textMainButton}
          </MenuButton>
          <MenuList ref={ref} borderColor='gray.200'>
            {children}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
