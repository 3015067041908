import React from 'react';
import { Helmet as CoreHelmet } from 'react-helmet';

export const Helmet = ({ title }) => {
  return (
    <CoreHelmet>
      <title>{title}</title>
    </CoreHelmet>
  );
};
