import axios from 'axios';
import qs from 'qs';

import { getHeaders } from './common';
import { PATH_COMPANY, PATH_FIND_MANY_COMPANY } from './utils/path';
import { catchError } from './utils/catch-error';

const fieldsAllCompany = ['companyName'];

export const fetchAllCompany = (jwt, toast, navigate) =>
  axios
    .get(`${PATH_COMPANY}?${qs.stringify({ fields: fieldsAllCompany })}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));

export const customFindManyCompany = async ({ jwt, toast, navigate }) => {
  const response = await axios
    .get(PATH_FIND_MANY_COMPANY, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
  return response.data;
};
