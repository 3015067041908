export const getRelativeDate = (date, locale = 'en') => {
  const today = +new Date();
  const diff = +new Date(date) - today;

  const seconds = Math.ceil(diff / 1000);
  const minutes = Math.ceil(seconds / 60);
  const hours = Math.ceil(minutes / 60);
  const days = Math.ceil(hours / 24);
  const weeks = Math.ceil(days / 7);
  const months = Math.ceil(days / 30);
  const years = Math.ceil(months / 12);

  const formatter = new Intl.RelativeTimeFormat(locale, {
    numeric: 'auto',
  });

  if (years <= -1) {
    return formatter.format(years, 'year');
  }
  if (months <= -1) {
    return formatter.format(months, 'month');
  }
  if (weeks <= -1) {
    return formatter.format(weeks, 'week');
  }
  if (days <= -1) {
    return formatter.format(days, 'day');
  }
  if (hours <= -1) {
    return formatter.format(hours, 'hour');
  }
  if (minutes <= -1) {
    return formatter.format(minutes, 'minute');
  }
  return formatter.format(seconds, 'second');
};
