import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  ScaleFade,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { get, isEmpty } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { ReactComponent as Reply } from '../../assets/icons/bi_reply.svg';
import { ReactComponent as Close } from '../../assets/icons/icon_close_topic.svg';
import { ReactComponent as Trash } from '../../assets/icons/icon_delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/icon_edit.svg';
import { ReactComponent as Copy } from '../../assets/icons/uil_link.svg';
import { RichEditor } from '../../components-common/editor';
import { DATE_FORMAT_HOURS, HORIZONTAL_PADDING, RESOURCE_NAME_COMMENT } from '../../constants';
import { parsedContent, rawContent } from '../../helpers/contentHelper';
import { getRelativeDate } from '../../helpers/dateHelper';
import {
  styleDefault,
  styleHover,
  styleNoStroke,
  styleNoStrokeHover,
  styleOutline,
  styleOutlineHover,
} from '../../theme/text-styles';
import { LikesCounter } from '../likes/likes-counter';
import './index.css';

/**
 * @typedef {object} SingleCommentProps
 * @property {string} jwt
 * @property {CreateToastFnReturn} toast
 * @property {NavigateFunction} navigate
 * @property {any} comment
 * @property {(any) => Promise<void>} loadLikes
 * @property {number} userId
 * @property {any} editCandidate
 * @property {any} ref
 * @property {any} setEditCandidate
 * @property {(any) => void} onEditedCommentSave
 * @property {(any) => void} toggleEditCandidateContent
 * @property {(any) => void} setThreadOf
 * @property {(any) => void} setCloseCandidate
 * @property {(any) => void} setIsCloseModalOpen
 * @property {boolean=} editable
 * @property {boolean=} repliable
 * @property {boolean=} removable
 * @property {boolean=} closeable
 * @property {string} resource
 * @property {boolean=} imageable
 * @property {string=} scrollToId
 * @property {boolean=} withBorder
 */

/**
 * @template T
 * @typedef {import('react').ForwardRefExoticComponent<import('react').PropsWithChildren<SingleCommentProps> & import('react').RefAttributes<T>>} ForwardRefComponent
 */

/**
 * @type {ForwardRefComponent<HTMLDivElement>}
 */
export const SingleComment = forwardRef(
  (
    {
      jwt,
      toast,
      navigate,
      comment,
      userId,
      editCandidate,
      editable,
      repliable = true,
      removable,
      closeable,
      setEditCandidate,
      onEditedCommentSave,
      toggleEditCandidateContent,
      setThreadOf,
      setDeleteCandidate,
      setIsDeleteModalOpen,
      setCloseCandidate,
      setIsCloseModalOpen,
      resource = RESOURCE_NAME_COMMENT,
      imageable = false,
      scrollToId,
      withBorder = false,
    },
    commentEditorRef,
  ) => {
    const { t } = useTranslation();
    const { isOpen, onToggle } = useDisclosure();
    const [isScrollingTo, setIsScrollingTo] = useState(false);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (isOpen === true) {
        setTimeout(onToggle, 1000);
      }
    }, [isOpen]);

    useEffect(() => {
      if (scrollToId === `${resource}${comment?.id}`) {
        setTimeout(() => {
          const element = document.getElementById(`${resource}${comment?.id}`);
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
          setIsScrollingTo(true);
        }, 300);
      }
    }, [scrollToId]);

    const { ref: reflazy, inView } = useInView(
      {
        triggerOnce: true,
        rootMargin: '50px 0px',
      },
      [comment],
    );

    const isContentEmpty = (content) => {
      return isEmpty(rawContent(content)) || rawContent(content) === '\n';
    };

    const editorButtons = (currentComment, content, onSave, onCancel) => (
      <Box alignSelf='flex-end'>
        <Button size='sm' variant='ZGreenSecondary' alignSelf='flex-end' mr={2} onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button
          size='sm'
          colorScheme='green'
          alignSelf='flex-end'
          isDisabled={isContentEmpty(content)}
          onClick={onSave}
        >
          {t('common.ok')}
        </Button>
      </Box>
    );

    let author;
    let authorExpertise = '';
    let isOwner = false;

    if (comment?.users_permissions_user?.id) {
      author = `${get(comment, ['users_permissions_user', 'name'])} ${get(comment, [
        'users_permissions_user',
        'surname',
      ])}`;
    } else {
      author = t('common.deleted_user');
    }

    if (get(comment?.users_permissions_user, 'id') === userId) {
      isOwner = true;
    }

    if (comment?.users_permissions_user?.ekspertise) {
      authorExpertise = get(
        comment?.users_permissions_user?.ekspertise?.data?.attributes,
        'expertiseName',
        '',
      );
    }

    let borderBottom;
    if (withBorder) {
      borderBottom = 'solid 1px var(--chakra-colors-gray-200)';
    }

    return (
      <Grid
        ref={reflazy}
        templateColumns='auto 1fr auto'
        columnGap={4}
        py={8}
        pe={HORIZONTAL_PADDING}
        id={`${resource}${comment?.id}`}
        borderBottom={borderBottom}
        className={isScrollingTo ? 'scrollingInto' : ''}
      >
        <GridItem mb={4}>
          {inView ? (
            <Avatar
              src={
                !get(comment, ['users_permissions_user', 'blocked']) &&
                get(comment.users_permissions_user, 'imageUrl')
              }
            />
          ) : (
            <Avatar />
          )}
        </GridItem>

        <GridItem mb={4}>
          <VStack align='start' spacing={0}>
            <Heading
              variant='smSmall'
              className='notranslate'
              color={get(comment, ['users_permissions_user', 'blocked']) ? 'gray.400' : 'inherit'}
            >
              {author}
            </Heading>
            <Text variant='md' color='gray.550'>
              {authorExpertise}
            </Text>
          </VStack>
        </GridItem>
        <GridItem mb={4}>
          <Text
            color='gray.550'
            variant='sm'
            title={format(new Date(comment.createdAt), DATE_FORMAT_HOURS)}
          >
            {getRelativeDate(comment.createdAt, user.locale)}
          </Text>
          {comment?.updatedAt && comment?.updatedAt !== comment?.createdAt && (
            <Text
              color='gray.550'
              variant='sm'
              textAlign='end'
              fontStyle='italic'
              title={format(new Date(comment.updatedAt), DATE_FORMAT_HOURS)}
            >
              {t('knowledge-base.modified')}
            </Text>
          )}
        </GridItem>

        {comment?.content && (
          <GridItem colStart={2} colEnd={4} color={'gray.600'} fontSize='lg'>
            {editCandidate && editCandidate.id === comment.id ? (
              <Flex flexDirection='column'>
                <Box minH='150px' width='100%' mb={4} id='comment'>
                  <RichEditor
                    autoFocus
                    content={editCandidate.content}
                    setContent={(newContent) =>
                      setEditCandidate({ id: comment.id, content: newContent })
                    }
                    ref={commentEditorRef}
                    imageUpload={imageable}
                  />
                </Box>
                {editorButtons(
                  comment,
                  editCandidate.content,
                  () => onEditedCommentSave(comment),
                  () => toggleEditCandidateContent(comment),
                )}
              </Flex>
            ) : (
              <Box dangerouslySetInnerHTML={{ __html: parsedContent(comment.content) }} />
            )}
          </GridItem>
        )}

        <GridItem colStart={2} colEnd={4} color='gray.600' mt={2}>
          <HStack gap={4}>
            {inView ? (
              <LikesCounter resource={resource} resourceId={comment.id} countOnly={false} />
            ) : null}

            <Text variant='sm'>|</Text>

            {isOwner && closeable && (
              <HStack
                gap={2}
                cursor='pointer'
                sx={styleOutline}
                _hover={styleOutlineHover}
                onClick={() => {
                  setCloseCandidate(comment.id);
                  setIsCloseModalOpen(true);
                }}
              >
                <Close width={16} />
                <Text variant='sm'>{t('forum.close-topic')}</Text>
              </HStack>
            )}

            {!comment.thread_of && repliable && (
              <HStack
                gap={2}
                cursor='pointer'
                sx={styleDefault}
                _hover={styleHover}
                onClick={() => setThreadOf(comment.id)}
              >
                <Reply />
                <Text variant='sm'>{t('comments.reply')}</Text>
              </HStack>
            )}

            <HStack
              gap={2}
              cursor='pointer'
              position={'relative'}
              sx={styleNoStroke}
              _hover={styleNoStrokeHover}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}${window.location.pathname}#${resource}=${comment?.id}`,
                );
                onToggle();
              }}
            >
              <Copy />
              <Text variant='sm'>{t('news.copy-link')}</Text>
              <ScaleFade
                initialScale={0.9}
                in={isOpen}
                style={{ position: 'absolute', bottom: '20px', left: '15px' }}
              >
                <Text
                  p={2}
                  bg='ZLGreen.100'
                  rounded='md'
                  color='ZLGreen.600'
                  fontSize='small'
                  fontWeight='bold'
                >
                  {t('news.copied')}
                </Text>
              </ScaleFade>
            </HStack>

            {isOwner && editable && (
              <HStack
                gap={2}
                cursor='pointer'
                sx={styleDefault}
                _hover={styleHover}
                onClick={() => {
                  toggleEditCandidateContent(comment);
                }}
              >
                <Edit width={16} />
                <Text variant='sm'>{t('common.edit')}</Text>
              </HStack>
            )}

            {isOwner && removable && (
              <HStack
                gap={2}
                cursor='pointer'
                sx={styleDefault}
                _hover={styleHover}
                onClick={() => {
                  setDeleteCandidate(comment.id);
                  setIsDeleteModalOpen(true);
                }}
              >
                <Trash />
                <Text variant='sm'>{t('common.delete')}</Text>
              </HStack>
            )}
          </HStack>
        </GridItem>
      </Grid>
    );
  },
);

SingleComment.displayName = 'SingleComment';
