import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authSlice } from './auth';
import { categoriesSlice } from './categories';
import { favouritesSlice } from './favourites';
import { errorSlice } from './error';

// DevTools: https://github.com/zalmoxisus/redux-devtools-extension

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    categories: categoriesSlice.reducer,
    favourites: favouritesSlice.reducer,
    error: errorSlice.reducer,
  },
  composedEnhancers: composeWithDevTools(),
});
