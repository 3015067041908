import axios, { AxiosResponse } from 'axios';
import { catchError } from './utils/catch-error';
import { IApiParams, ILocalI18 } from '../types';
import { PATH_LOCALES } from './utils/path';
import { getHeaders } from './common';

export const fetchLocales = ({ jwt, toast, navigate }: IApiParams) =>
  axios
    .get(`${PATH_LOCALES}`, getHeaders(jwt))
    .then(
      (response: AxiosResponse<ILocalI18[]>) =>
        response.data &&
        response.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((data) => ({ id: data.id, name: data.name, code: data.code })),
    )
    .catch((error) => catchError(error, toast, navigate));
