import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Input,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { googleGetAllFiles } from '../../api';
import { fetchDataKnowledgeBases } from '../../api/knowledge-bases';
import { ReactComponent as IconList } from '../../assets/icons/knowledge-base/Icon_list.svg';
import { BlurSpinner } from '../../components-common/blur-spinner';
import { Helmet } from '../../components-common/helmet';
import { ErrorHandleWrapper, IfElse } from '../../components-common/if';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import {
  FILTER_MENU_GAP_ROW,
  MAIN_CONTENT_POSITION,
  PAGINATION_POSITION,
  SORT_ORDER_ASC,
  SORT_TYPES,
} from '../../constants';
import { parsedContent } from '../../helpers/contentHelper';
import { setErrorAction } from '../../redux';
import { ReduxState } from '../../redux/state';
import { Breadcrumbs } from './breadcrumbs';
import { LeftDrawer } from './drawer';
import { DriveData, DriveItem } from './drive-item';
import { RenderFolderGrid } from './file-explorer/render-folder-grid';
import { RenderFolderList } from './file-explorer/render-folder-list';
import { Sort } from './sort';

interface SectionData {
  title: string;
  description: string;
  idFolder: string;
}

export const KnowledgeBaseSubpage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [driveData, setDriveData] = useState<DriveData>();
  const [path, setPath] = useState<{ name: string; fileId: string }[]>([]);
  const [sectionData, setSectionData] = useState<SectionData>();
  const [isSpinner, setIsSpinner] = useState(false);
  const [showList, setShowList] = useState(false);
  const [isSharedDrive, setIsSharedDrive] = useState(false);
  const [pathList, setPathList] = useState<DriveItem[]>([]);
  const [sortType, setSortType] = useState(SORT_TYPES[0]);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER_ASC);
  const [currentFolderId, setCurrentFolderId] = useState<null | string>(null);
  const { jwt } = useSelector((state: ReduxState) => state.auth);
  const { id } = useParams();
  const toast = useToast();
  const sortGoogleDrive = `${sortType.sortBy} ${sortOrder}`;
  const { register: registerSearch, handleSubmit: handleSubmitSearch } = useForm();

  const [activeId, setActiveId] = useState<string | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getGoogleFiles = async (
    idFolder: string | undefined = undefined,
    isSharedDrive = false,
  ) => {
    return googleGetAllFiles(
      jwt,
      toast,
      navigate,
      currentFolderId || idFolder,
      sortGoogleDrive,
      undefined,
      undefined,
      showList,
      isSharedDrive,
    );
  };

  useEffect(() => {
    const initKBPage = async () => {
      const responseKnowledgeBases = await fetchDataKnowledgeBases(
        { jwt, toast, navigate },
        {
          id,
          errorCallback: (value: boolean) => dispatch(setErrorAction(value)),
        },
      );
      if (responseKnowledgeBases === false) {
        dispatch(setErrorAction(true));
      } else {
        const { link, title, description } = responseKnowledgeBases.data;
        const indexStartId = link.lastIndexOf('/');
        const indexEndId = link.lastIndexOf('?');
        const idFolder =
          currentFolderId || link.substr(indexStartId + 1, indexEndId - indexStartId - 1);
        setIsSharedDrive(responseKnowledgeBases.data.isSharedDrive);
        addPath(title, idFolder);
        const response = await getGoogleFiles(idFolder, responseKnowledgeBases.data.isSharedDrive);
        response.id = idFolder;

        setDriveData(response);
        setSectionData({ title, description, idFolder });
        setPathList([{ id: idFolder, name: title, nextPageToken: response.nextPageToken }]);
      }
    };
    initKBPage();
  }, []);

  useEffect(() => {
    const handleSort = async () => {
      const response = await getGoogleFiles();
      response.id = currentFolderId;
      setDriveData(response);
    };
    if (currentFolderId) {
      handleSort();
    }
  }, [sortGoogleDrive]);

  const search = async (data: FieldValues) => {
    setIsSpinner(true);
    const response = await googleGetAllFiles(
      jwt,
      toast,
      navigate,
      pathList?.slice(-1)?.pop()?.id,
      sortGoogleDrive,
      `and name contains '${data.keyword}'`,
      undefined,
      showList,
      isSharedDrive,
    );
    setDriveData(response);
    setIsSpinner(false);
  };

  const addPath = (name: string, fileId: string) => setPath([...path, { name, fileId }]);

  const updateFolder = (value: DriveItem[]) => {
    if (driveData) {
      setDriveData({ ...driveData, files: value });
    }
  };

  const handlerShowList = async () => {
    setShowList(!showList);
  };

  const renderSearch = (
    <form onSubmit={handleSubmitSearch(search)}>
      <Flex>
        <Input
          {...registerSearch('keyword')}
          size='md'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          placeholder={
            pathList && pathList.length > 0
              ? `${t('knowledge-base.searchIn')} ${pathList[pathList.length - 1].name}`
              : undefined
          }
        />
        <Button ml={3} type='submit' size='md' minWidth='unset'>
          {t('knowledge-base.search')}
        </Button>
      </Flex>
    </form>
  );

  const leftToolbar = (
    <Flex h={8}>
      <Button
        variant='ZGreenLink'
        size='xs'
        mr={2}
        onClick={handlerShowList}
        bgColor={showList ? 'ZSGreen.50' : 'transparent'}
      >
        <IconList stroke='#4A5568' />
      </Button>
      <Sort
        sortTypes={SORT_TYPES}
        sortType={sortType}
        setSortType={setSortType}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
    </Flex>
  );

  const openFolder = async (idFolder: string) => {
    const response = await googleGetAllFiles(
      jwt,
      toast,
      navigate,
      idFolder,
      sortGoogleDrive,
      undefined,
      undefined,
      showList,
      isSharedDrive,
    );
    const indexOpenFolder = pathList?.findIndex((folderData) => folderData.id === idFolder);
    if (indexOpenFolder !== undefined) {
      setPathList(pathList?.slice(0, indexOpenFolder + 1));
    }
    setCurrentFolderId(idFolder);
    updateFolder(response.files);
  };

  const handleDrawerOpen = (fileId) => {
    setActiveId(fileId);
    onOpen();
  };

  const description = sectionData?.description ? JSON.parse(sectionData.description) : null;
  const hasDescription = description?.blocks.some((block) => block.text.trim().length > 0);

  const driveDataProps = {
    sortGoogleDrive,
    setIsSpinner,
    pathList: pathList || [],
    setPathList,
    dataFolder: driveData as { id: string; nextPageToken?: string; files: DriveItem[] },
    updateParentFolder: updateFolder,
    leftToolbar,
    setDriveData,
    setCurrentFolderId,
    activeId,
    onDrawerOpen: (fileId) => handleDrawerOpen(fileId),
    isSharedDrive,
  };

  return (
    <ErrorHandleWrapper>
      <Helmet
        title={t('title.knowledge-base-single', { knowledgeBaseTitle: sectionData?.title })}
      />
      <IfElse condition={sectionData}>
        <VStack gap={0} w='100%'>
          <HStack
            w='100%'
            alignContent={'center'}
            flexWrap={'wrap'}
            justifyContent='space-between'
            position={'sticky'}
            top={PAGINATION_POSITION}
            paddingY={FILTER_MENU_GAP_ROW}
            bgColor={'white'}
            zIndex={98}
          >
            <Breadcrumbs
              items={[
                { name: t('knowledge-base.knowledge-base') },
                ...(Array.isArray(pathList) ? pathList : []),
              ]}
              openFolder={openFolder}
              activeId={activeId}
              onDrawerOpen={(fileId) => handleDrawerOpen(fileId)}
            />
            <Box>
              <Flex
                direction={['column', 'row']}
                justify={['flex-start', 'space-between']}
                alignItems={['flex-start', 'center']}
              >
                <Box order={[1, 2]} mb={[4, 0]}>
                  {renderSearch}
                </Box>
              </Flex>
            </Box>
          </HStack>
          <VStack
            gap={FILTER_MENU_GAP_ROW}
            w='100%'
            minH={`calc(100vh - ${MAIN_CONTENT_POSITION})`}
          >
            {hasDescription && sectionData && (
              <Box
                mt={4}
                overflow='hidden'
                wordBreak='break-all'
                dangerouslySetInnerHTML={{ __html: parsedContent(sectionData.description) }}
              />
            )}
            <Divider color='gray.100' opacity={1} />
            <Flex w='100%' direction='column' alignItems='center'>
              <Box position='relative' w='100%'>
                <BlurSpinner condition={isSpinner} />
                {driveData && (
                  <IfElse condition={showList}>
                    <RenderFolderList {...driveDataProps} />
                    <RenderFolderGrid {...driveDataProps} />
                  </IfElse>
                )}
              </Box>
              <LeftDrawer
                activeId={activeId}
                setActiveId={setActiveId}
                isOpen={isOpen}
                onClose={onClose}
                isSharedDrive={isSharedDrive}
              />
            </Flex>
          </VStack>
        </VStack>
        <SpinnerScreen />
      </IfElse>
    </ErrorHandleWrapper>
  );
};
