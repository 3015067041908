import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react';
import { If } from '@dornach/ziegler-ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  close: () => void;
  header: JSX.Element | string;
  isOpen: boolean;
  children: JSX.Element;
  hideBlur?: boolean;
}

export const CustomDrawer = ({ close, header, isOpen, children, hideBlur }: Props) => {
  const { t } = useTranslation();

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={close} size='sm'>
      <If condition={!hideBlur}>
        <DrawerOverlay />
      </If>
      <DrawerContent containerProps={hideBlur ? { height: 0 } : undefined}>
        <Flex alignItems='center' my={2}>
          <DrawerCloseButton mt={3} />
          <DrawerHeader w='100%'>
            <Flex alignItems='center' w='100%'>
              {header}
            </Flex>
          </DrawerHeader>
        </Flex>
        <Divider mb={4} color='gray.100' opacity={1} />
        <DrawerBody>{children}</DrawerBody>
        <Divider mt={4} color='gray.100' opacity={1} />
        <DrawerFooter bgColor='gray.50'>
          <Button variant='ZGreenGhost' onClick={close}>
            {t('common.close')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
