import { useState, MutableRefObject, useRef } from 'react';

export const useStateRef = <T>(defaultValue: T): [MutableRefObject<T>, (value: T) => void, T] => {
  const [value, setStateValue] = useState<T>(defaultValue);
  const valueRef = useRef<T>(value);

  const setValue = (data: T) => {
    if (data !== valueRef.current) {
      valueRef.current = data;
      setStateValue(data);
    }
  };

  return [valueRef, setValue, value];
};
