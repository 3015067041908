import { useEffect, useState } from 'react';

export const useDetectOrDisplayMobile = () => {
  const calcIsMobile = () =>
    window.innerWidth / parseFloat(getComputedStyle(document.querySelector('body'))['font-size']) <
    48;

  const [isMobile, setIsMobile] = useState<boolean>(calcIsMobile());
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(calcIsMobile());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile };
};
