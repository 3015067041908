import {
  Box,
  Button,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoIosColorPalette, IoMdQuote } from 'react-icons/io';
import { IoVideocamOutline } from 'react-icons/io5';
import { TfiAlignCenter, TfiAlignLeft, TfiAlignRight } from 'react-icons/tfi';
import { ReactComponent as SeparatorIcon } from '../../assets/icons/Separator.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/editor/akar-icons_image.svg';
import { ReactComponent as OrderedListIcon } from '../../assets/icons/editor/icon_list-ol.svg';
import { ReactComponent as Underline } from '../../assets/icons/editor/icon_underline.svg';
import { ReactComponent as UnorderedListIcon } from '../../assets/icons/editor/icon_unordered-list.svg';
import { ReactComponent as Bold } from '../../assets/icons/editor/uil_bold.svg';
import { ReactComponent as Italic } from '../../assets/icons/editor/uil_italic.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/editor/uil_link-add.svg';
import { GridInputWrapper } from '../grid-input-wrapper';
import { COLORS } from './customStyles';

const linkPattern =
  // eslint-disable-next-line
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

const icons = {
  bold: <Bold />,
  italic: <Italic />,
  underline: <Underline />,
  image: <ImageIcon />,
  link: <LinkIcon />,
  blockquote: <IoMdQuote />,
  'align-left': <TfiAlignLeft />,
  'align-center': <TfiAlignCenter />,
  'align-right': <TfiAlignRight />,
  H1: <Text>H1</Text>,
  H2: <Text>H2</Text>,
  H3: <Text>H3</Text>,
  'ordered-list-item': <OrderedListIcon />,
  'unordered-list-item': <UnorderedListIcon />,
  video: <IoVideocamOutline />,
  color: <IoIosColorPalette />,
};

export const Separator = () => (
  <Box mx={2} display='flex' mt={1.5}>
    <SeparatorIcon />
  </Box>
);

export const CustomImageButton = ({ onClick, uploadImageCallBack, fileInputRef }) => (
  <Box className='buttonWrapper' onClick={onClick}>
    <Box className='customButton'>{icons.image}</Box>
    <input
      id='upload-image'
      onChange={uploadImageCallBack}
      multiple={false}
      ref={fileInputRef}
      type='file'
      hidden
    />
  </Box>
);

export const CustomVideoButton = ({ uploadVideoCallback }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  return (
    <>
      <Box
        className='buttonWrapper'
        onMouseDown={(e) => {
          e.preventDefault();
          setIsVideoModalOpen(true);
        }}
      >
        <Box className='customButton'>{icons.video}</Box>
      </Box>
      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        onAddVideo={uploadVideoCallback}
      />
    </>
  );
};

export const CustomBlockTypeButton = ({ type, toggleBlockType, blockType }) => (
  <Box
    className='buttonWrapper'
    onMouseDown={(e) => {
      e.preventDefault();
      toggleBlockType(type);
    }}
  >
    <Box className={`customButton ${blockType === type ? 'customButtonActive' : ''}`}>
      {icons[type]}
    </Box>
  </Box>
);

export const CustomInlineStyleButton = ({ type, toggleInlineStyle, currentStyle }) => (
  <Box
    className='buttonWrapper'
    onMouseDown={(e) => {
      e.preventDefault();
      toggleInlineStyle(type);
    }}
  >
    <Box className={`customButton ${currentStyle.has(type) ? 'customButtonActive' : ''}`}>
      {icons[type]}
    </Box>
  </Box>
);

const HyperlinkModal = ({ isOpen, onClose, onAddLink, selectedText }) => {
  const { t } = useTranslation();
  const formMethodsLink = useForm({ defaultValues: {} });

  useEffect(() => {
    if (selectedText) {
      formMethodsLink.setValue('title', selectedText);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
    formMethodsLink.setValue('url', null);
    formMethodsLink.setValue('title', null);
  };

  const handleSubmitLink = (data) => {
    let { url } = formMethodsLink.getValues();
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = `http://${url}`;
    }
    onAddLink(url, formMethodsLink.getValues().title);
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='green.700'>{t('common.insert-hyperlink')}</ModalHeader>
        <ModalCloseButton />
        <form
          onSubmit={(e) => {
            e.stopPropagation();

            return formMethodsLink.handleSubmit(handleSubmitLink)(e);
          }}
        >
          <ModalBody>
            <Grid gap={6}>
              <GridInputWrapper name='URL' isRequired error={formMethodsLink.formState.errors.url}>
                <Input
                  {...formMethodsLink.register('url', {
                    required: t('errors.empty'),
                    pattern: {
                      value: linkPattern,
                      message: t('errors.valid'),
                    },
                  })}
                  autoFocus
                  borderColor='gray.200'
                  borderRadius={4}
                  color='primary'
                  _hover={{ borderColor: 'gray.300' }}
                  _focus={{ border: '2px solid', borderColor: 'ZLGreen.600', color: 'primary' }}
                />
              </GridInputWrapper>
              <GridInputWrapper
                name={t('common.title')}
                isRequired
                error={formMethodsLink.formState.errors.title}
              >
                <Input
                  {...formMethodsLink.register('title', { required: t('errors.empty') })}
                  isDisabled={!!selectedText}
                  borderColor='gray.200'
                  color='primary'
                  _hover={{ borderColor: 'gray.300' }}
                  _focus={{ border: '2px solid', borderColor: 'ZLGreen.600', color: 'primary' }}
                />
              </GridInputWrapper>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button mr={4} variant='ZGreenGhost' onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button type='submit'>{t('common.save')}</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const VideoModal = ({ isOpen, onClose, onAddVideo }) => {
  const { t } = useTranslation();
  const formMethodsVideo = useForm({ defaultValues: {} });

  const handleClose = () => {
    onClose();
    formMethodsVideo.setValue('src', null);
  };

  const handleSubmitVideo = (data) => {
    const { src } = formMethodsVideo.getValues();
    onAddVideo(src);
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='green.700'>{t('common.insert-video')}</ModalHeader>
        <ModalCloseButton />
        <form
          onSubmit={(e) => {
            e.stopPropagation();

            return formMethodsVideo.handleSubmit(handleSubmitVideo)(e);
          }}
        >
          <ModalBody>
            <Grid gap={6}>
              <GridInputWrapper
                name='URL'
                isRequired
                error={formMethodsVideo.formState.errors.title}
              >
                <Input
                  {...formMethodsVideo.register('src', { required: t('errors.empty') })}
                  autoFocus
                  borderColor='gray.200'
                  borderRadius={4}
                  color='primary'
                  _hover={{ borderColor: 'gray.300' }}
                  _focus={{ border: '2px solid', borderColor: 'ZLGreen.600', color: 'primary' }}
                />
              </GridInputWrapper>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button mr={4} variant='ZGreenGhost' onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button type='submit'>{t('common.save')}</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export const CustomLinkButton = ({ onAddLink, selectedText }) => {
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  return (
    <>
      <Box
        className='buttonWrapper'
        onMouseDown={(e) => {
          e.preventDefault();
          setIsLinkModalOpen(true);
        }}
      >
        <Box className='customButton'>{icons.link}</Box>
      </Box>
      <HyperlinkModal
        isOpen={isLinkModalOpen}
        onClose={() => setIsLinkModalOpen(false)}
        onAddLink={onAddLink}
        selectedText={selectedText}
      />
    </>
  );
};

const styles = {
  colorButton: {
    cursor: 'pointer',
    marginRight: 16,
    padding: '2px 0',
    borderRadius: '99999px',
  },
};

const ColorButton = ({ newStyle, active, toggleColor }) => {
  const onToggle = (e) => {
    e.preventDefault();
    toggleColor(newStyle);
  };
  const color = newStyle.replace('color-', '');
  let style = { backgroundColor: color, width: '20px', height: '20px' };
  if (active) {
    style = {
      ...style,
      ...styles.colorButton,
      backgroundColor: 'unset',
      borderWidth: '3px',
      borderColor: color,
    };
  } else {
    style = { ...style, ...styles.colorButton };
  }
  return <Box style={style} onMouseDown={onToggle} />;
};

export const ColorControls = ({ currentStyle, onToggle }) => (
  <div className='buttonWrapper color'>
    <div className='customButton'>{icons.color}</div>
    <div className='color-picker'>
      {COLORS.map((style) => (
        <ColorButton
          key={style}
          active={currentStyle.has(style)}
          newStyle={style}
          toggleColor={onToggle}
        />
      ))}
    </div>
  </div>
);
