import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { IfElse, ErrorHandleWrapper } from '../../components-common/if';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import { fetchSingleSubCategory } from '../../api/categories';
import { strapiDataParserSingle } from '../../common/strapi';
import { parsedContent } from '../../helpers/contentHelper';
import { setErrorAction } from '../../redux';

export const SubCategoryView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jwt } = useSelector((state) => state.auth);
  const { activeSubCategory } = useSelector((state) => state.categories);
  const [isLoading, setIsLoading] = useState(true);
  const [subCategory, setSubCategory] = useState(null);

  const fetchCategory = () => {
    setIsLoading(true);
    fetchSingleSubCategory(jwt, activeSubCategory, navigate, (value) =>
      dispatch(setErrorAction(value)),
    ).then((response) => {
      setSubCategory(strapiDataParserSingle(response));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (activeSubCategory) {
      fetchCategory();
    }
  }, [activeSubCategory]);

  if (!subCategory) {
    return <Box />;
  }

  return (
    <ErrorHandleWrapper>
      <Flex mt={16}>
        <IfElse condition={isLoading}>
          <SpinnerScreen />
          {subCategory && (
            <Box data-testid='zieglerCountriesInput'>
              <Heading mb={4} variant='xlSmall' _hover={{ color: 'ZLGreen.600' }}>
                {subCategory.title}
              </Heading>
              {subCategory.content && (
                <Box dangerouslySetInnerHTML={{ __html: parsedContent(subCategory.content) }} />
              )}
            </Box>
          )}
        </IfElse>
      </Flex>
    </ErrorHandleWrapper>
  );
};
