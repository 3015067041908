import axios from 'axios';
import qs from 'qs';

import { getHeaders } from './common';
import { PATH_CURRENCIES } from './utils/path';
import { catchError } from './utils/catch-error';

const fieldsAllCurrencies = ['iso_code'];
const sort = ['iso_code:asc'];

export const fetchAllCurrencies = (jwt, toast, navigate) =>
  axios
    .get(
      `${PATH_CURRENCIES}?${qs.stringify({ fields: fieldsAllCurrencies, sort, populate: false })}`,
      getHeaders(jwt),
    )
    .catch((error) => catchError(error, toast, navigate));
