import axios from 'axios';
import qs from 'qs';
import { getHeaders } from './common';
import { catchError } from './utils/catch-error';
import { ROOT_API_PATH } from './utils/path';

const defaultConfig = {
  fields: 'nextPageToken, files(id,name,description,iconLink,mimeType,thumbnailLink, createdTime)',
};

export const googleGetAllFiles = async (
  jwt,
  toast,
  navigate,
  idFile,
  orderBy,
  q,
  page,
  isListView,
  isSharedDrive,
) => {
  const qString = `'${idFile}' in parents `;
  const order = () => {
    if (isListView) {
      return orderBy ? `${orderBy}` : 'name';
    }
    return orderBy ? `folder, ${orderBy}` : 'folder';
  };
  const params = qs.stringify({
    ...defaultConfig,
    orderBy: order(),
    pageSize: 100,
    pageToken: page,
    q: q ? qString + q : qString,
    isSharedDrive,
  });
  const response = await axios
    .get(`${ROOT_API_PATH}/api/knowledge-base-list?${params}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
  return response.data;
};

export const googleGetDetails = async (jwt, toast, navigate, idFile, isSharedDrive) => {
  const params = qs.stringify({
    fields: 'id,name,description,iconLink,size,thumbnailLink,owners,lastModifyingUser, createdTime',
    fileId: idFile,
    isSharedDrive,
  });
  const response = await axios
    .get(`${ROOT_API_PATH}/api/knowledge-base-details?${params}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
  return response.data;
};
