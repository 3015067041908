import { Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { countComments } from '../../api';
import { ReactComponent as NoComment } from '../../assets/icons/icon_comment.svg';
import { ReactComponent as Comment } from '../../assets/icons/icon_comment_added.svg';

export const CommentsCounter = ({ resource, resourceId }) => {
  const { jwt } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const toast = useToast();
  const [nbComments, setNbComments] = useState(0);
  const [counted, setCounted] = useState(false);

  useEffect(() => {
    const initComments = async () => {
      countComments(jwt, toast, navigate, resource, resourceId).then((result) => {
        setNbComments(result.data.result);
        setCounted(true);
      });
    };

    initComments();
  }, [jwt, resource, resourceId]);

  let comments;
  if (counted === true) {
    comments = (
      <Text color='gray.600' variant='sm'>
        {nbComments}
      </Text>
    );
  } else {
    comments = (
      <Spinner
        emptyColor='gray.200'
        thickness='2px'
        speed='0.6s'
        color='ZLGreen.600'
        h='16px'
        w='16px'
      />
    );
  }

  return (
    <Flex flexDirection='row' alignItems='center' gap={2}>
      {nbComments > 0 ? <Comment /> : <NoComment />}
      {comments}
    </Flex>
  );
};
