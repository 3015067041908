import axios from 'axios';
import qs from 'qs';
import { PATH_BUSINESS_APPS_PRIVATE } from './utils/path';
import { getHeaders } from './common';
import { catchError } from './utils/catch-error';

export const addLink = (jwt, toast, navigate, newLink) => {
  const data = {
    data: newLink,
  };
  return axios
    .post(`${PATH_BUSINESS_APPS_PRIVATE}`, data, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};

export const fetchPrivateBusinessApps = (jwt, toast, navigate, userId, errorCallback) => {
  errorCallback(false);
  const query = qs.stringify({
    populate: ['users_permissions_user'],
    filters: {
      $and: [{ users_permissions_user: userId }],
    },
    pagination: {
      start: 0,
      limit: 99999,
    },
  });
  return axios
    .get(`${PATH_BUSINESS_APPS_PRIVATE}?${query}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate, errorCallback));
};

export const fetchFavouritePrivateBusinessApps = (jwt, userId) => {
  const query = qs.stringify({
    fields: ['id', 'title', 'link'],
    filters: {
      $and: [{ users_permissions_user: userId, favourite: true }],
    },
    pagination: {
      start: 0,
      limit: 99999,
    },
  });
  return axios.get(`${PATH_BUSINESS_APPS_PRIVATE}?${query}`, getHeaders(jwt));
};

export const fetchSinglePrivateBusinessApp = (jwt, toast, navigate, id) =>
  axios
    .get(`${PATH_BUSINESS_APPS_PRIVATE}/${id}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));

export const updatePrivateBusinessApp = (jwt, toast, navigate, id, data) =>
  axios
    .put(`${PATH_BUSINESS_APPS_PRIVATE}/${id}`, { data }, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));

export const removePrivateBusinessApp = (jwt, toast, navigate, id) =>
  axios
    .delete(`${PATH_BUSINESS_APPS_PRIVATE}/${id}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
