import { Avatar, Center, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomDrawer } from '../../../components-common/custom-drawer';
import { UserDataBody } from '../../../components-common/user-data';
import { IUserOrgChart } from '../../../types';

interface Props {
  clearSelect: () => void;
  user?: IUserOrgChart;
}

export const UserDetailsDrawer = ({ user, clearSelect }: Props) => {
  const { t } = useTranslation();

  const header = (
    <Heading size='md' color='ZSGreen.700' ml={2} w='300px'>
      <Text
        w='100%'
        textAlign='left'
        display='-webkit-box'
        overflow='hidden'
        wordBreak='break-all'
        sx={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: '1' }}
      >
        User details
      </Text>
    </Heading>
  );
  return (
    <CustomDrawer isOpen={!!user} close={clearSelect} header={header} hideBlur>
      {user && (
        <>
          <Center justifyContent='flex-start'>
            {!user.blocked && user.imageUrl ? (
              <Image
                alt=''
                src={user.imageUrl}
                w='48px'
                h='48px'
                fit='cover'
                borderRadius='100%'
                data-testid='userAvatar'
              />
            ) : (
              <Avatar w='48px' h='48px' data-testid='userAvatar' />
            )}
            <Text variant='xl' ml={4} fontWeight='semibold' as='h4' data-testid='userName'>
              {user.name} {user.surname}
            </Text>
            {user.blocked && (
              <Text ml={1} fontSize='xs' fontStyle='italic'>
                ({t('common.non-active')})
              </Text>
            )}
          </Center>
          <UserDataBody user={user} />
        </>
      )}
    </CustomDrawer>
  );
};
