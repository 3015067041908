import { React } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { isAfter, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Avatar, Flex, Box, Text, Img, Heading, HStack } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import { resizedImgUrl } from '../../helpers/imageHelper';
import { getAuthor } from '../../helpers/userHelper';
import { ReactComponent as IconSell } from '../../assets/icons/icon_sell.svg';
import { ReactComponent as IconWallet } from '../../assets/icons/Icon_wallet.svg';
import { parseLanguageCode } from '../../helpers/languageHelper';

export const SingleAdvert = ({ advert, linkTo, idx, currentUser }) => {
  const { t, i18n } = useTranslation();
  const firstRecorded = idx === 0;

  const lang = parseLanguageCode(i18n.language);

  let advertCategory = advert.advert_category?.data?.attributes.advertCategoryName;
  advert.advert_category?.data?.attributes?.localizations?.data?.forEach((locale) => {
    if (locale.attributes.locale === lang) {
      advertCategory = locale.attributes.advertCategoryName;
    }
  });

  const { ref, inView } = useInView(
    {
      triggerOnce: true,
      rootMargin: '100px 0px',
    },
    [advert],
  );

  let fit = 'cover';
  if (advert.photo[0]) {
    const fitHeight = advert.photo[0].height;
    const fitWidth = advert.photo[0].width;
    let ratio;
    if (fitWidth > fitHeight) {
      ratio = fitWidth / fitHeight;
    } else {
      ratio = fitHeight / fitWidth;
    }

    if (ratio > 2) {
      fit = 'contain';
    }
  }

  let negociate;
  if (advert.negociate === true && advert.price && advert.price !== '') {
    negociate = <Text variant='sm'>({t('adverts.price-negociate')})</Text>;
  }

  let isExpired = false;
  if (advert.expires) {
    isExpired = isAfter(new Date(), parseISO(advert.expires));
  }

  const expired = (
    <>
      <Box backgroundColor='black' height='100%' width='100%' opacity='0.7' position='absolute' />
      <Heading width='100%' textAlign='center' variant='lgLarge' color='white' position='absolute'>
        EXPIRED
      </Heading>
    </>
  );

  return (
    <Box
      ref={ref}
      mt={firstRecorded ? 0 : 4}
      pt={firstRecorded ? 0 : 4}
      borderTop={!firstRecorded ? '1px solid' : '0px solid'}
      borderColor='gray.100'
      minHeight='180px'
    >
      <Link to={linkTo}>
        <Flex position='relative'>
          <Flex
            position='relative'
            alignItems='center'
            maxHeight='180px'
            w='300px'
            bgColor={!advert.photo[0] ? 'ZLGreen.100' : ''}
            data-testid='advertImage'
          >
            {advert.photo[0] && inView ? (
              <Img
                alt={advert.attributes.title}
                width='300px'
                height='100%'
                maxW='none'
                objectFit={fit}
                src={resizedImgUrl(advert.photo[0], 'small')}
              />
            ) : (
              <Box
                borderColor='gray.400'
                borderStyle='solid'
                borderWidth='1px'
                width='300px'
                height='100%'
                maxW='none'
              />
            )}
            {isExpired && expired}
          </Flex>

          <Flex ml={8} flexDirection='column' h='auto'>
            <Flex spacing='8px' flexDirection='column'>
              <Flex flexDirection='row' alignItems='flex-start' justifyContent='space-between'>
                <Heading
                  variant='lgSmall'
                  wordBreak='break-word'
                  color='brandColors.brandGreen'
                  _hover={{ color: 'ZSGreen.800' }}
                  data-testid='advertTitle'
                >
                  {advert.title}
                </Heading>
              </Flex>

              <HStack mt={2} spacing='10px' color='gray.600'>
                <IconSell />
                <Text variant='sm'>{advertCategory}</Text>
                <Text variant='sm'>|</Text>
                <IconWallet />
                <Text variant='sm'>
                  {t('adverts.price')}
                  {': '}
                </Text>
                <Text variant='sm' as='b'>
                  {advert.price && advert.price !== '' ? advert.price : '-'}
                </Text>
                <Text variant='sm' as='b'>
                  {advert.price &&
                  advert.price !== '' &&
                  advert.currency?.data?.attributes.iso_code &&
                  advert.currency?.data?.attributes.iso_code !== ''
                    ? ` ${advert.currency?.data?.attributes.iso_code}`
                    : ''}
                </Text>
                {negociate}
              </HStack>

              <HStack mt={2} spacing='10px' color='gray.550'>
                <Text variant='sm'>
                  {t('common.country')} - {advert.attributes.country.data?.attributes.countryName}
                </Text>
                <Text variant='sm' color='gray.600'>
                  |
                </Text>
                <Text variant='sm' color='gray.550'>
                  {t('common.branch')} - {advert.attributes.branch.data?.attributes.branchName}
                </Text>
              </HStack>

              <Flex mt={2} alignItems='center'>
                <Avatar src={get(advert.users_permissions_user, 'imageUrl')} mr={4} />
                <Box fontSize='md'>
                  <Flex alignItems='center' color={'inherit'}>
                    <Text className='notranslate' fontWeight='700'>
                      {getAuthor(advert.users_permissions_user)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
};
