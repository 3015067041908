import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Img,
  ScaleFade,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ChevronRightIcon } from '@chakra-ui/icons';
import { fetchComments, fetchLikes, fetchSingleNews } from '../../api';
import { ReactComponent as NoComment } from '../../assets/icons/icon_comment.svg';
import { ReactComponent as Copy } from '../../assets/icons/uil_link.svg';
import { strapiDataParser } from '../../common/strapi';
import { Comments } from '../../components-common/comments/comments-view';
import { GoBack } from '../../components-common/go-back';
import { Helmet } from '../../components-common/helmet';
import { ErrorHandleWrapper, If, IfElse } from '../../components-common/if';
import { ScrollToAnchor } from '../../components-common/scroll-to-anchor';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import {
  COMMENTS_SORT_LIKED,
  COMMENTS_SORT_NEWEST,
  COMMENTS_SORT_OLDEST,
  DATE_FORMAT,
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  HORIZONTAL_PADDING,
  PAGINATION_HEIGHT,
  PAGINATION_POSITION,
  PATH_NEWS,
  RESOURCE_NAME_COMMENT,
  RESOURCE_NAME_NEWS,
} from '../../constants';
import { parsedContent } from '../../helpers/contentHelper';
import { resizedImgUrl } from '../../helpers/imageHelper';
import { parseLanguageCode } from '../../helpers/languageHelper';
import { getAuthor } from '../../helpers/userHelper';
import { setErrorAction } from '../../redux';
import { styleNoStroke, styleNoStrokeHover } from '../../theme/text-styles';
import { LikesCounter } from '../likes/likes-counter';
import { RecentNews } from './recent-news';

export const SingleNewsView = () => {
  const commentsRef = useRef(null);
  const { newsId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const { jwt } = useSelector((state) => state.auth);
  const [singleNews, setSingleNews] = useState(null);
  const [comments, setComments] = useState([]);
  const { isOpen, onToggle } = useDisclosure();
  const [scrollToId, setScrollToId] = useState(null);

  const fetchNews = () => {
    fetchSingleNews(jwt, toast, navigate, (value) => dispatch(setErrorAction(value)), newsId).then(
      ({ data }) => {
        setSingleNews(data);
        getComments(data.id);
      },
    );
  };

  const getComments = async (newsIdForComment) => {
    const filters = {
      resource: RESOURCE_NAME_NEWS,
      resource_id: newsIdForComment,
    };

    const fields = ['id', 'thread_of', 'createdAt', 'updatedAt', 'content'];

    const populate = {
      users_permissions_user: {
        fields: ['imageUrl', 'name', 'surname', 'blocked', 'id'],
        populate: { ekspertise: { fields: ['expertiseName'] } },
      },
    };

    let sort = { createdAt: 'DESC' };

    const userSortPref = JSON.parse(localStorage.getItem('commentSort'));
    if (userSortPref && userSortPref.id) {
      switch (userSortPref.id) {
        case COMMENTS_SORT_NEWEST:
          sort = { createdAt: 'DESC' };
          break;

        case COMMENTS_SORT_OLDEST:
          sort = { createdAt: 'ASC' };
          break;

        case COMMENTS_SORT_LIKED:
          sort = 'MOST_LIKED';
          break;

        default:
          sort = { createdAt: 'DESC' };
          break;
      }
    }

    fetchComments(jwt, toast, navigate, filters, fields, populate, sort).then(
      (fetchCommentsResult) => {
        const parsedResult = strapiDataParser(fetchCommentsResult, ['users_permissions_user']);
        if (parsedResult && parsedResult.length > 0) {
          setComments(parsedResult);
        } else {
          setComments([]);
        }
      },
    );
  };

  const getLikesForComments = async (commentId) => {
    const filters = {
      resource: { RESOURCE_NAME_COMMENT },
      resource_id: commentId,
    };

    const fields = ['id'];

    const populate = {
      users_permissions_user: {
        fields: ['name', 'surname', 'id'],
      },
    };

    fetchLikes(jwt, toast, navigate, filters, fields, populate).then((fetchLikeResult) => {
      const parsedResult = strapiDataParser(fetchLikeResult);
      if (parsedResult?.[0]) {
        return parsedResult[0];
      }
      return [];
    });
  };

  useEffect(() => {
    fetchNews();
    const queryParams = new URLSearchParams(location.hash.replace('#', ''));
    if (queryParams.has(RESOURCE_NAME_NEWS)) {
      setScrollToId(`${RESOURCE_NAME_NEWS}${queryParams.get(RESOURCE_NAME_NEWS)}`);
    } else if (queryParams.has(RESOURCE_NAME_COMMENT)) {
      setScrollToId(`${RESOURCE_NAME_COMMENT}${queryParams.get(RESOURCE_NAME_COMMENT)}`);
    }
  }, [jwt, newsId]);

  useEffect(() => {
    if (isOpen === true) {
      setTimeout(onToggle, 1000);
    }
  }, [isOpen]);

  const isChrome = window.navigator.userAgent.indexOf('Chrome') !== -1;
  const expertise = get(singleNews, ['users_permissions_user', 'ekspertise', 'expertiseName']);
  const isBlocked = get(singleNews, ['users_permissions_user', 'blocked']);
  const publicationDate = singleNews?.publication_date || singleNews?.publishedAt;

  let htmlBody = '';
  if (singleNews?.body) {
    htmlBody = parsedContent(singleNews.body);
  }

  return (
    <div>
      <ErrorHandleWrapper>
        <ScrollToAnchor />
        <IfElse condition={!singleNews}>
          <SpinnerScreen />
          {singleNews && (
            <>
              <Helmet title={t('title.single-news', { singleNewsTitle: singleNews.title })} />

              <Grid
                style={{ gridArea: 'header' }}
                templateAreas={`"title"`}
                gridTemplateColumns={`1fr`}
                h='100%'
                columnGap={FILTER_MENU_GAP_COL}
                position={'sticky'}
                top={PAGINATION_POSITION}
                paddingY={FILTER_MENU_GAP_ROW}
                bgColor={'white'}
                zIndex={98}
              >
                <GridItem area={'title'} alignSelf={'center'}>
                  <HStack minH={PAGINATION_HEIGHT} alignItems={'center'}>
                    <GoBack backText={t('nav.news')} fallback={PATH_NEWS} />
                    <ChevronRightIcon color='gray.500' ml={2} />
                    <Text color={'gray.600'} variant='lg'>
                      {singleNews.title}
                    </Text>
                  </HStack>
                </GridItem>
              </Grid>

              <Box w='80%' ml='auto' mr='auto'>
                {isChrome && singleNews.locale !== parseLanguageCode(i18n.language) && (
                  <Alert status='info' mb={4} rounded='md'>
                    <AlertIcon />
                    {t('news.translate')}
                  </Alert>
                )}
                <Box mx={8}>
                  <Flex flexDirection='row' justifyContent='space-between'>
                    <Box pe={HORIZONTAL_PADDING}>
                      <Flex alignItems='center'>
                        <Avatar
                          src={!isBlocked && get(singleNews.users_permissions_user, 'imageUrl')}
                          mr={4}
                        />
                        <Box fontSize='md'>
                          <Flex alignItems='center' color={isBlocked ? 'gray.400' : 'inherit'}>
                            <Text className='notranslate' fontWeight='700'>
                              {getAuthor(singleNews.users_permissions_user)}
                            </Text>
                            {isBlocked && (
                              <Text ml={1} fontSize='xs' fontStyle='italic'>
                                ({t('common.non-active')})
                              </Text>
                            )}
                          </Flex>
                          {expertise && <Text color='gray.550'>{expertise}</Text>}
                        </Box>
                      </Flex>
                      <Text mt={4} ml={8} pl={8} fontSize='small' color='gray.550'>
                        {t('news.published-on')} - {format(new Date(publicationDate), DATE_FORMAT)}
                      </Text>
                    </Box>
                    <HStack pe={HORIZONTAL_PADDING} gap={2} alignItems='center'>
                      <Box height='auto'>
                        <LikesCounter
                          resource='post'
                          resourceId={singleNews.id}
                          countOnly={false}
                        />
                      </Box>

                      <HStack
                        gap={2}
                        cursor='pointer'
                        sx={styleNoStroke}
                        _hover={styleNoStrokeHover}
                        onClick={() => {
                          commentsRef.current?.setFocus();
                          const element = document.getElementById('comment');
                          element.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'nearest',
                          });
                        }}
                      >
                        <NoComment />
                        <Text variant='sm'>{t('news.comment')}</Text>
                      </HStack>

                      <HStack
                        gap={2}
                        cursor='pointer'
                        position={'relative'}
                        sx={styleNoStroke}
                        _hover={styleNoStrokeHover}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${window.location.origin}${window.location.pathname}`,
                          );
                          onToggle();
                        }}
                      >
                        <Copy />
                        <Text variant='sm'>{t('news.copy-link')}</Text>
                        <ScaleFade
                          style={{ position: 'absolute', bottom: '20px', left: '15px' }}
                          initialScale={0.9}
                          in={isOpen}
                        >
                          <Text
                            p={2}
                            bg='ZLGreen.100'
                            rounded='md'
                            color='ZLGreen.600'
                            fontSize='small'
                            fontWeight='bold'
                          >
                            {t('news.copied')}
                          </Text>
                        </ScaleFade>
                      </HStack>
                    </HStack>
                  </Flex>
                  <Flex pe={HORIZONTAL_PADDING} justifyContent='space-between'>
                    <Heading my={4} size='xl' color='brandColors.brandGreen'>
                      {singleNews.title}
                    </Heading>
                  </Flex>
                  <If condition={singleNews.header?.url}>
                    <Box w='100%' h='50vh'>
                      <Img
                        alt=''
                        height='100%'
                        width='100%'
                        maxW='none'
                        objectFit='contain'
                        src={resizedImgUrl(singleNews.header, 'large')}
                      />
                    </Box>
                  </If>
                  <Box pe={HORIZONTAL_PADDING} mt={6}>
                    <Box wordBreak='break-word' dangerouslySetInnerHTML={{ __html: htmlBody }} />
                  </Box>
                  <Comments
                    comments={comments}
                    reload={getComments}
                    loadLikes={getLikesForComments}
                    resource='post'
                    resourceId={newsId}
                    ref={commentsRef}
                    editable
                    scrollToId={scrollToId}
                    commentsCollapsed={!scrollToId}
                  />
                  <RecentNews />
                </Box>
              </Box>
            </>
          )}
        </IfElse>
      </ErrorHandleWrapper>
    </div>
  );
};
