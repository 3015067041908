import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

import { IApiParams, IForumCategory } from '../types';

import { getHeaders } from './common';
import { PATH_FORUM_CATEGORIES } from './utils/path';
import { catchError } from './utils/catch-error';
import { strapiDataParser } from '../common/strapi';

const fields = ['title', 'color'];
const sort = ['title:asc'];
const populate = { localizations: { fields: ['title', 'color'] } };

export const fetchAllForumCategories = async ({ jwt, toast, navigate }: IApiParams, language) => {
  const response: AxiosResponse<IForumCategory[]> | boolean = await axios
    .get(
      `${PATH_FORUM_CATEGORIES}?${qs.stringify({
        populate,
        locale: language,
        fields,
        sort,
        pagination: {
          start: 0,
          limit: 99999,
        },
      })}`,
      getHeaders(jwt),
    )
    .catch((error) => catchError(error, toast, navigate));
  if (response) {
    return strapiDataParser(response);
  }
};
