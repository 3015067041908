import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Heading, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { Helmet } from '../../components-common/helmet';

const zieglerLinkTitle = 'www.zieglerteam.net';
const zieglerLinkUrl = 'https://www.zieglerteam.net';
const zieglerEmail = 'info_ziegler@zieglergroup.com';
const GDPREmail = 'info.GDPR@dornach.eu';
const privacyEmail = 'privacy@zieglergroup.com';
const webmasterEmail = 'webmaster@zieglergroup.com';

export const TermsOfUse = () => {
  const { t } = useTranslation();

  const TermsHeading = ({ text }) => (
    <Heading mb={6} mt={10} fontSize='30px' color='brandColors.brandGreen'>
      {text}
    </Heading>
  );

  const ZieglerLink = () => (
    <Link color='ZSGreen.700' fontWeight='bold' href={zieglerLinkUrl} target='_blank'>
      {zieglerLinkTitle}
    </Link>
  );

  const EmailLink = ({ email }) => (
    <Link fontWeight='bold' color='ZSGreen.600' href={`mailto:${email}`}>
      {email}
    </Link>
  );

  const TermsList = ({ listKey, components }) => (
    <Trans
      i18nKey={listKey}
      components={{ 1: <UnorderedList pl={2} />, 0: <ListItem />, ...components }}
    />
  );

  return (
    <Flex
      alignItems='center'
      flexDirection='column'
      minHeight='100vh'
      pb='120px'
      overflowX='hidden'
    >
      <Box w='80%' mt={6}>
        <Flex flexDirection='column'>
          <Helmet title={t('title.terms')} />
          <Heading mb={8} fontSize='30px' color='brandColors.brandGreen'>
            {t('terms.heading')}
          </Heading>
          <Box alignSelf='center' width='45%'>
            <TermsHeading text={t('terms.presentation-heading')} />
            <Trans i18nKey='terms.presentation-content'>
              content
              <ZieglerLink />
              content
            </Trans>
            <TermsHeading text={t('terms.owner-heading')} />
            <Box>
              <Text>{t('terms.owner-company')}</Text>
              <Text>{t('terms.owner-address-1')}</Text>
              <Text mb={4}>{t('terms.owner-address-2')}</Text>
              <Text>{t('terms.owner-data-1')}</Text>
              <Text mb={4}>{t('terms.owner-data-2')}</Text>
              <Trans i18nKey='terms.owner-email'>
                content
                <EmailLink email={zieglerEmail} />
              </Trans>
              <Text>{t('terms.owner-tel')}</Text>
              <Text mb={8}>{t('terms.owner-fax')}</Text>
              <Text>{t('terms.owner-creator')}</Text>
              <Text>{t('terms.owner-manager')}</Text>
              <Trans i18nKey='terms.owner-webmaster'>
                content
                <EmailLink email={webmasterEmail} />
                content
              </Trans>

              <Text>{t('terms.owner-host')}</Text>
            </Box>
            <TermsHeading text={t('terms.general-heading')} />
            <Trans i18nKey='terms.general-content'>
              content
              <ZieglerLink />
              content
            </Trans>
            <TermsHeading text={t('terms.revisions-heading')} />
            <Text mb={4}>
              <Trans i18nKey='terms.revisions-content-1'>
                content
                <ZieglerLink />
                content
              </Trans>
            </Text>
            <Text mb={4}>{t('terms.revisions-content-2')}</Text>
            <Text>
              <Trans i18nKey='terms.revisions-content-3'>
                content
                <ZieglerLink />
                content
              </Trans>
            </Text>
            <TermsHeading text={t('terms.description-heading')} />
            <Text mb={4}>
              <Trans i18nKey='terms.description-content-1'>
                content
                <ZieglerLink />
                content
              </Trans>
            </Text>
            <Text mb={4}>
              <Trans i18nKey='terms.description-content-2'>
                content
                <ZieglerLink />
                content
              </Trans>
            </Text>
            <Text>
              <Trans i18nKey='terms.description-content-3'>
                content
                <ZieglerLink />
                content
              </Trans>
            </Text>
            <TermsHeading text={t('terms.contractual-heading')} />
            <Text mb={4}>{t('terms.contractual-content')}</Text>
            <TermsHeading text={t('terms.intellectual-heading')} />
            <Text mb={4}>{t('terms.intellectual-content-1')}</Text>
            <Text mb={4}>{t('terms.intellectual-content-2')}</Text>
            <Text mb={4}>{t('terms.intellectual-content-3')}</Text>
            <Text>{t('terms.intellectual-content-4')}</Text>
            <TermsHeading text={t('terms.limitations-heading')} />
            <Text mb={4}>
              <Trans i18nKey='terms.limitations-content-1'>
                content
                <ZieglerLink />
                content
              </Trans>
            </Text>
            <Text mb={4}>{t('terms.limitations-content-2')}</Text>
            <Text mb={4}>{t('terms.limitations-content-3')}</Text>
            <Text>{t('terms.limitations-content-4')}</Text>
            <TermsHeading text={t('terms.data-heading')} />
            <Text mb={4}>{t('terms.data-content-1')}</Text>
            <Text mb={4}>{t('terms.data-content-2')}</Text>
            <Text mb={4}>{t('terms.data-content-3')}</Text>
            <Text mb={4}>{t('terms.data-content-4')}</Text>
            <Text mb={4}>
              <Trans i18nKey='terms.data-content-5'>
                content
                <ZieglerLink />
                content
              </Trans>
            </Text>
            <Text mb={4}>
              <Trans i18nKey='terms.data-content-6'>
                content
                <EmailLink email={GDPREmail} />
                content
              </Trans>
            </Text>
            <Text mb={4}>{t('terms.data-content-7')}</Text>
            <Text>
              <Trans i18nKey='terms.data-content-8'>
                content
                <EmailLink email={privacyEmail} />
                content
              </Trans>
            </Text>
            <TermsHeading text={t('terms.prohibited-heading')} />
            <Text mb={4}>{t('terms.prohibited-content-1')}</Text>
            <Text mb={4}>{t('terms.prohibited-list-1-heading')}</Text>
            <Text mb={4}>
              <TermsList listKey='terms.prohibited-list-1-content' />
            </Text>
            <Text mb={4}>{t('terms.prohibited-list-2-heading')}</Text>
            <TermsList listKey='terms.prohibited-list-2-content' />
            <TermsHeading text={t('terms.hypertext-heading')} />
            <Text mb={4}>{t('terms.hypertext-content-1')}</Text>
            <Text mb={4}>{t('terms.hypertext-content-2')}</Text>
            <Text mb={4}>{t('terms.hypertext-list-heading')}</Text>
            <TermsList
              listKey='terms.hypertext-list-content'
              components={{
                bold: <Text fontWeight='bold' display='inline' />,
                0: <ListItem mb={2} />,
              }}
            />
            <TermsHeading text={t('terms.applicable-heading')} />
            <Text>{t('terms.applicable-content')}</Text>
            <TermsHeading text={t('terms.divisibility-heading')} />
            <Text mb={4}>{t('terms.divisibility-content-1')}</Text>
            <Text>{t('terms.divisibility-content-2')}</Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
