import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

import { GridInputWrapper } from '../../components-common/grid-input-wrapper';
import {
  addLink,
  fetchSinglePrivateBusinessApp,
  removePrivateBusinessApp,
  updatePrivateBusinessApp,
} from '../../api/business-apps-private';
import { strapiDataParserSingle } from '../../common/strapi';

export const LinkModal = ({ isOpen, onSaveCallback, onCloseCallback, id }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { jwt, user } = useSelector((state) => state.auth);

  const defaultNewLink = { title: '', description: '', link: '' };
  const {
    register: newLink,
    handleSubmit: handleSubmitNewLink,
    setValue: setNewLinkValue,
    getValues: getNewLinkValues,
    formState: { errors },
  } = useForm({ defaultValues: defaultNewLink });

  useEffect(() => {
    const initLinkModal = async () => {
      const response = await fetchSinglePrivateBusinessApp(jwt, toast, navigate, id);
      const singlePrivateApp = strapiDataParserSingle(response);
      setNewLinkValue('title', singlePrivateApp.title);
      setNewLinkValue('description', singlePrivateApp.description);
      setNewLinkValue('link', singlePrivateApp.link);
    };
    if (id) {
      initLinkModal();
    }
  }, [id]);

  const onLinkSave = () => {
    let { link } = getNewLinkValues();

    if (!link.startsWith('https://') && !link.includes('http')) {
      link = `https://${link}`;
    }
    if (link.startsWith('http://')) {
      link = link.replace('http', 'https');
    }

    setNewLinkValue('link', link);
    if (id) {
      updatePrivateBusinessApp(jwt, toast, navigate, id, getNewLinkValues()).then(() =>
        onSaveCallback(),
      );
    } else {
      addLink(jwt, toast, navigate, {
        ...getNewLinkValues(),
        users_permissions_user: user.id,
      }).then(() => onSaveCallback());
    }
    closeLinkModal();
  };

  const closeLinkModal = () => {
    setNewLinkValue('title', '');
    setNewLinkValue('description', '');
    setNewLinkValue('link', '');
    onCloseCallback(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeLinkModal} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('business-apps.add-link')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmitNewLink(onLinkSave)}>
          <ModalBody>
            <Grid gap={6}>
              <GridInputWrapper
                name={t('business-apps.title-label')}
                isRequired
                error={errors.title}
              >
                <Input
                  {...newLink('title', {
                    required: t('errors.empty'),
                    validate: (value) => {
                      return value.trim().length > 0 || t('errors.empty');
                    },
                  })}
                  autoFocus
                  borderColor='gray.200'
                  color='primary'
                  _hover={{ borderColor: 'gray.300' }}
                  _focus={{ border: '2px solid', borderColor: 'ZLGreen.600', color: 'primary' }}
                />
              </GridInputWrapper>
              <GridInputWrapper name={t('business-apps.description-label')}>
                <Input
                  {...newLink('description')}
                  borderColor='gray.200'
                  color='primary'
                  _hover={{ borderColor: 'gray.300' }}
                  _focus={{ border: '2px solid', borderColor: 'ZLGreen.600', color: 'primary' }}
                />
              </GridInputWrapper>
              <GridInputWrapper name={t('business-apps.link-label')} isRequired error={errors.link}>
                <Input
                  {...newLink('link', {
                    required: t('errors.empty'),
                    validate: (value) => {
                      return value.trim().length > 0 || t('errors.empty');
                    },
                  })}
                  borderColor='gray.200'
                  color='primary'
                  _hover={{ borderColor: 'gray.300' }}
                  _focus={{ border: '2px solid', borderColor: 'ZLGreen.600', color: 'primary' }}
                />
              </GridInputWrapper>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Flex width='100%' justifyContent={id ? 'space-between' : 'flex-end'}>
              {id && (
                <Button
                  variant='ZGreenGhost'
                  leftIcon={<DeleteIcon mb={1} fontSize='md' />}
                  onClick={() =>
                    removePrivateBusinessApp(jwt, toast, navigate, id).then(() => {
                      onSaveCallback();
                      closeLinkModal();
                    })
                  }
                >
                  {t('common.delete')}
                </Button>
              )}
              <Button type='submit'>{t('common.save')}</Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
