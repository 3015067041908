import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Helmet } from '../../components-common/helmet';
import { PATH_PROFILE } from '../../constants';

export const UnsubscribeView = () => {
  const { t } = useTranslation();
  const ProfileLink = () => (
    <Link to={PATH_PROFILE} target='_blank'>
      <Text color='ZSGreen.700' fontWeight='bold'>
        https://www.zieglerteam.net/profile
      </Text>
    </Link>
  );

  return (
    <Flex
      alignItems='center'
      flexDirection='column'
      minHeight='100vh'
      pb='120px'
      overflowX='hidden'
    >
      <Box w='80%' mt={6}>
        <Flex flexDirection='column'>
          <Helmet title={t('title.unsubscribe')} />
          <Heading mb={8} fontSize='30px' color='brandColors.brandGreen'>
            {t('unsubscribe.title')}
          </Heading>
          <Box alignSelf='center' width='45%'>
            <Text mb={4}>{t('unsubscribe.content')}</Text>

            <Text mb={4}>
              <Trans i18nKey='unsubscribe.wrong-locale-text'>
                content
                <ProfileLink />
              </Trans>
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
