import { Handle, Position, NodeProps } from 'reactflow';
import React, { FunctionComponent } from 'react';
import { Avatar, Box, Center, Image, Text } from '@chakra-ui/react';
import {
  IconIntArrowDown,
  IconIntArrowUp,
  IconIntEmployee,
  If,
  IfElse,
} from '@dornach/ziegler-ui-kit';
import { useTranslation } from 'react-i18next';
import { UserDataBody, UserDataHeader } from '../../../components-common/user-data';

import { INodeData } from '../type';
import { ORG_CHART_NODE_WIDTH } from '../../../constants/org-chart';
import { ekspertise } from '../../../components-common/user-data/records-info';
import { IUserOrgChart } from '../../../types';

export const CustomNode: FunctionComponent<NodeProps<INodeData & IUserOrgChart>> = ({ data }) => {
  const { t } = useTranslation();
  const numberEmployees = data.line_workers.length;
  return (
    <Box>
      <Box
        p={4}
        w={`${ORG_CHART_NODE_WIDTH}px`}
        position='relative'
        wordBreak='break-word'
        boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        border='solid 2px'
        borderColor={data.isSelectedNode ? 'ZLGreen.400' : 'blackAlpha.200'}
        borderRadius='5px'
        onClick={() => data.selectNode()}
      >
        <UserDataHeader user={data} />
        <UserDataBody user={data} template={(user) => [ekspertise(user)]} p={0} pl={5} pb={2} />
        <Handle type='target' position={Position.Top} />
        <Handle type='source' position={Position.Bottom} />
      </Box>
      <If condition={numberEmployees > 0}>
        <Center>
          <Center
            onClick={() => data.handleNode()}
            _hover={{ borderColor: 'gray.300', bgColor: 'gray.50' }}
            w='200px'
            position='absolute'
            bottom='-55px'
            boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
            border='solid 1px'
            borderColor='blackAlpha.200'
            borderRadius='100px'
            bgColor='#fff'
            py={1}
            fontWeight='700'
            fontSize='18px'
          >
            <IconIntEmployee colorsList={['gray.600']} boxSize='25px' />
            <Text px={2}>{numberEmployees}</Text>
            <IfElse condition={data.children}>
              <IconIntArrowDown colorsList={['gray.600']} boxSize='25px' />
              <IconIntArrowUp colorsList={['gray.600']} boxSize='25px' />
            </IfElse>
          </Center>
        </Center>
      </If>
    </Box>
  );
};
