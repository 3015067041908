import { Accordion, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { camelCase, map } from 'lodash';
import { AboutAccordionButton, AboutButton } from './buttons';
import { parsePath } from '../../helpers/pathHelper';
import './recursive-accordion.css';

export const RecursiveAccordion = ({
  isInNav,
  idListPath = {},
  data,
  pathParent,
  expandRecord,
  recordActive,
  nestedLevel = 0,
  variableNameList: {
    prefixPodCategory,
    parentName,
    prefixNameActive,
    postfixNameActive,
    prefixName,
    postfixName,
    prefixTest,
  },
}) => {
  const childrenName = `${prefixPodCategory}${parentName}`;
  if (!data) {
    return <div />;
  }

  const activeId =
    recordActive?.[camelCase(`${prefixNameActive}${parentName}${postfixNameActive}`)];

  const path = `${pathParent}/${parsePath(data.title)}`;
  const children = data[`${prefixName}${childrenName}${postfixName}`];
  if (!children || (children && !children[0])) {
    return (
      <AboutButton
        isInNav={isInNav}
        resource={data}
        active={activeId}
        nestedLevel={nestedLevel}
        key={idListPath}
        testId={`${camelCase(prefixTest + data.title)}Button`}
        linkTo={`${path}`}
        linkClick={() => {
          expandRecord(idListPath);
        }}
      />
    );
  }

  const defaultIndex = [];
  if (activeId === data.id) {
    defaultIndex.push(0);
  }

  return (
    <Accordion allowMultiple defaultIndex={defaultIndex}>
      <AccordionItem border='none'>
        {({ isExpanded }) => (
          <>
            <AboutAccordionButton
              nestedLevel={nestedLevel}
              isInNav={isInNav}
              resource={data}
              active={activeId}
              isExpanded={isExpanded}
              testId={`${camelCase(prefixTest + data.title)}Accordion`}
              linkTo={path}
              linkClick={() => expandRecord(idListPath)}
            />
            <AccordionPanel p='0' w='100%' border='none'>
              {map(children, (subCategory) => {
                const nevIdListPath = {
                  ...idListPath,
                  [camelCase(`${prefixNameActive}${childrenName}${postfixNameActive}`)]:
                    subCategory.id,
                };
                return (
                  <RecursiveAccordion
                    recordActive={recordActive}
                    expandRecord={expandRecord}
                    key={`${subCategory.id}`}
                    idListPath={nevIdListPath}
                    nestedLevel={nestedLevel + 1}
                    isInNav={isInNav}
                    data={subCategory}
                    pathParent={path}
                    variableNameList={{
                      parentName: `${childrenName}`,
                      prefixNameActive,
                      postfixNameActive,
                      prefixName,
                      postfixName,
                      prefixPodCategory,
                      prefixTest,
                    }}
                  />
                );
              })}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
