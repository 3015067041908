import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Tooltip,
  Button,
  Flex,
  Text,
  Heading,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { PAGINATION_HEIGHT, PATH_KNOWLEDGE_BASE } from '../../../constants';
import { ReactComponent as IconInfo } from '../../../assets/icons/knowledge-base/Icon_info.svg';

interface BreadcrumbItemInterface {
  name: string;
  id?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItemInterface[];
  openFolder?: (id: string) => void;
  activeId?: string | undefined;
  onDrawerOpen?: (string) => void;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  items,
  openFolder,
  activeId,
  onDrawerOpen,
}) => {
  const navigate = useNavigate();
  const isLastChild = (index) => items.length > 1 && Boolean(items.length === index + 1);
  const isMobile = window.matchMedia('(max-width: 767px)').matches;

  if (isMobile && items.length > 3) {
    return (
      <>
        <Breadcrumb separator={<ChevronRightIcon color='gray.500' />}>
          <BreadcrumbItem>
            <BreadcrumbLink
              color='ZSGreen.700'
              fontWeight='bold'
              onClick={() => navigate(PATH_KNOWLEDGE_BASE)}
            >
              {items[0].name}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text color='ZSGreen.700' fontWeight='bold'>
              ...
            </Text>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              color='ZSGreen.700'
              fontWeight='bold'
              onClick={() => {
                if (
                  openFolder &&
                  items.length >= 2 &&
                  items[items.length - 2] &&
                  typeof items[items.length - 2].id === 'string'
                ) {
                  openFolder(items[items.length - 2].id);
                }
              }}
            >
              {items[items.length - 2].name}
            </BreadcrumbLink>
            <ChevronRightIcon color='gray.500' ml={2} />
          </BreadcrumbItem>
        </Breadcrumb>
        <Breadcrumb separator={<ChevronRightIcon color='gray.500' />}>
          <BreadcrumbItem isCurrentPage>
            <TruncatedBreadcrumbLink
              item={items[items.length - 1]}
              openFolder={openFolder}
              navigate={navigate}
              activeId={activeId}
              onDrawerOpen={onDrawerOpen}
              isLastItem
            />
          </BreadcrumbItem>
        </Breadcrumb>
      </>
    );
  }
  return (
    <Breadcrumb separator={<ChevronRightIcon color='gray.500' />}>
      {items.map((item, index) => (
        <BreadcrumbItem
          key={index}
          isCurrentPage={isLastChild(index)}
          isLastChild={isLastChild(index)}
        >
          <TruncatedBreadcrumbLink
            item={item}
            openFolder={openFolder}
            navigate={navigate}
            activeId={activeId}
            onDrawerOpen={onDrawerOpen}
            isLastItem={isLastChild(index)}
          />
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

interface TruncatedBreadcrumbLinkProps {
  item: {
    id?: string;
    name: string;
  };
  openFolder?: (id: string) => void;
  navigate: (path: string) => void;
  activeId?: string | undefined;
  onDrawerOpen?: (string) => void;
  isLastItem: boolean;
}
const TruncatedBreadcrumbLink = ({
  item,
  openFolder,
  navigate,
  activeId,
  onDrawerOpen,
  isLastItem,
}: TruncatedBreadcrumbLinkProps) => {
  const isTruncated = item.name.length > 20;
  const displayName = isTruncated ? `${item.name.substring(0, 20)}...` : item.name;

  return isTruncated ? (
    <Tooltip label={item.name} placement='top'>
      <Box>
        <CustomBreadcrumbLink
          item={item}
          openFolder={openFolder}
          navigate={navigate}
          displayName={displayName}
          activeId={activeId}
          onDrawerOpen={onDrawerOpen}
          isLastItem={isLastItem}
        />
      </Box>
    </Tooltip>
  ) : (
    <CustomBreadcrumbLink
      item={item}
      openFolder={openFolder}
      navigate={navigate}
      displayName={displayName}
      activeId={activeId}
      onDrawerOpen={onDrawerOpen}
      isLastItem={isLastItem}
    />
  );
};

interface CustomBreadcrumbLinkProps {
  item: {
    id?: string;
    name: string;
  };
  openFolder?: (id: string) => void;
  navigate: (path: string) => void;
  displayName: string;
  activeId?: string | undefined;
  onDrawerOpen?: (string) => void;
  isLastItem: boolean;
}

const CustomBreadcrumbLink = ({
  item,
  openFolder,
  navigate,
  displayName,
  activeId,
  onDrawerOpen,
  isLastItem,
}: CustomBreadcrumbLinkProps) => (
  <BreadcrumbLink
    onClick={async () => {
      if (openFolder && item.id) {
        openFolder(item.id);
      } else {
        navigate(PATH_KNOWLEDGE_BASE);
      }
    }}
    fontWeight={!isLastItem ? 'bold' : 'inherit'}
    color={!isLastItem ? 'ZSGreen.700' : 'inherit'}
    _hover={{ textDecoration: 'none' }}
  >
    <Flex minH={PAGINATION_HEIGHT} alignItems='center'>
      <Heading
        variant='mdLarge'
        color={!isLastItem ? 'brandColors.brandGreen' : 'inherit'}
        fontWeight={!isLastItem ? 'bold' : 'inherit'}
      >
        {displayName}
      </Heading>
      {onDrawerOpen && isLastItem && (
        <Button
          variant='ZGreenLink'
          size='xs'
          mx={2}
          onClick={(event) => {
            event.stopPropagation();
            onDrawerOpen(item.id);
          }}
          bgColor={activeId === item.id ? 'ZSGreen.50' : 'transparent'}
        >
          <IconInfo fill='#4A5568' stroke='#4A5568' />
        </Button>
      )}
    </Flex>
  </BreadcrumbLink>
);
