import { Box, Flex, Spinner, useToast } from '@chakra-ui/react';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import '@draft-js-plugins/alignment/lib/plugin.css';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createEmojiPlugin, { defaultTheme as defaultEmojiTheme } from '@draft-js-plugins/emoji';
import '@draft-js-plugins/emoji/lib/plugin.css';
import createFocusPlugin from '@draft-js-plugins/focus';
import '@draft-js-plugins/focus/lib/plugin.css';
import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/image/lib/plugin.css';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import createVideoPlugin from '@draft-js-plugins/video';
import '@draft-js-plugins/video/lib/plugin.css';
import { EditorState, Entity, Modifier, RichUtils, convertToRaw } from 'draft-js';
import { map, mapValues } from 'lodash';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUsersMention } from '../../api';
import { uploadImage } from '../../api/forum';
import { defaultImgHeight, defaultImgWidth } from '../../helpers/contentHelper';
import {
  ColorControls,
  CustomBlockTypeButton,
  CustomImageButton,
  CustomInlineStyleButton,
  CustomLinkButton,
  CustomVideoButton,
  Separator,
} from './customButtons';
import { getBlockStyle, styleMap } from './customStyles';
import './editor.css';
import { FileComponent, findFileEntities } from './fileDecorator';
import { Link, findLinkEntities } from './linkDecorator';
import mentionsStyles from './mentionsStyles.module.css';
import toolbarStyles from './toolbarStyles.css';

/**
 * @typedef {Object} RichEditorProps
 * @property {any} content
 * @property {string} placeholder
 * @property {string} testId
 * @property {Array} users
 * @property {Function} setContent
 */

/**
 * RichEditor
 * @param {RichEditorProps} props
 */
export const RichEditor = forwardRef(
  ({ content, setContent, imageUpload = true, autoFocus = false, testId }, ref) => {
    const toast = useToast();
    const navigate = useNavigate();
    const { jwt } = useSelector((state) => state.auth);
    const editorRef = useRef(null);
    const fileInputRef = useRef(null);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [hasFocus, setHasFocus] = useState(autoFocus);

    const [editorState, setEditorState] = useState(content);
    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [ignoreSuggestionValue, setIgnoreSuggestionValue] = useState();
    const [isUploading, setIsUploading] = useState(false);

    const { plugins, Toolbar, AlignmentTool, MentionSuggestions, EmojiSuggestions, EmojiSelect } =
      useMemo(() => {
        const toolbarPlugin = createToolbarPlugin({ theme: { toolbarStyles } });
        const focusPlugin = createFocusPlugin();
        const resizeablePlugin = createResizeablePlugin();
        const blockDndPlugin = createBlockDndPlugin();
        const alignmentPlugin = createAlignmentPlugin();
        const mentionPlugin = createMentionPlugin({
          entityMutability: 'IMMUTABLE',
          theme: mentionsStyles,
          mentionPrefix: '@',
          supportWhitespace: true,
        });

        const emojiPlugin = createEmojiPlugin({
          useNativeArt: true,
          theme: {
            ...defaultEmojiTheme,
            emojiSelectButton: 'customButton',
            emojiSelectButtonPressed: 'customButton',
          },
        });

        const decorator = composeDecorators(
          resizeablePlugin.decorator,
          alignmentPlugin.decorator,
          focusPlugin.decorator,
          blockDndPlugin.decorator,
        );
        const imagePlugin = createImagePlugin({ decorator, image: 'img-margin' });
        const videoPlugin = createVideoPlugin();
        const pluginsUseMemo = [
          toolbarPlugin,
          focusPlugin,
          resizeablePlugin,
          blockDndPlugin,
          alignmentPlugin,
          imagePlugin,
          mentionPlugin,
          videoPlugin,
          emojiPlugin,
        ];
        return {
          plugins: pluginsUseMemo,
          Toolbar: toolbarPlugin.Toolbar,
          AlignmentTool: alignmentPlugin.AlignmentTool,
          MentionSuggestions: mentionPlugin.MentionSuggestions,
          EmojiSuggestions: emojiPlugin.EmojiSuggestions,
          EmojiSelect: emojiPlugin.EmojiSelect,
        };
      }, []);

    const entityStyleFn = (entityMap) => {
      return mapValues(entityMap, (entity) => {
        if (entity.type === 'IMAGE') {
          return {
            ...entity,
            data: {
              ...entity.data,
              width: entity.data.width ? `${entity.data.width}%` : undefined,
              height: entity.data.height ? `${entity.data.height}%` : undefined,
            },
          };
        }
        return { ...entity };
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        getUploadedImages: () => {
          return uploadedImages;
        },
        setUploadedImages: (images) => {
          return setUploadedImages(images);
        },
        setEditorState: (newEditorState) => {
          return setEditorState(newEditorState);
        },
        getInitialEditorState: () => {
          return EditorState.createEmpty();
        },
        getDBFormatContent: (value) => {
          let rawContentState = convertToRaw(value.getCurrentContent());
          rawContentState = {
            ...rawContentState,
            entityMap: entityStyleFn(rawContentState.entityMap),
          };
          return JSON.stringify(rawContentState);
        },
        setFocus: () => {
          setHasFocus(true);
          editorRef?.current?.getEditorRef().focus();
        },
        scrollTo: () => {
          editorRef?.current?.getEditorRef()?.editor?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        },
      }),
      [uploadedImages],
    );

    const selection = editorState?.getSelection();
    const blockType = editorState
      ?.getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    const currentStyle = editorState?.getCurrentInlineStyle();

    useEffect(() => {
      if (hasFocus) {
        setTimeout(() => {
          editorRef?.current?.getEditorRef().focus();
        });
      } else {
        setTimeout(() => {
          editorRef?.current?.getEditorRef().blur();
        });
      }
    }, []);

    const onChange = (newEditorState) => {
      setContent(newEditorState);
      setEditorState(newEditorState);
    };

    const uploadImageCallBack = async (event) => {
      setIsUploading(true);
      const file = event.target.files[0];
      const newUploadedImages = [...uploadedImages];
      let imageObject = {
        file,
        defaultWidth: defaultImgWidth,
        defaultHeight: defaultImgHeight,
      };
      await uploadImage(jwt, toast, navigate, file).then(({ data }) => {
        imageObject = {
          ...imageObject,
          localSrc: `${data[0].url}`,
          id: data[0].id,
        };
        newUploadedImages.push(imageObject);
        setUploadedImages(newUploadedImages);
        setIsUploading(false);
      });

      if (file.type === 'application/pdf') {
        onAddFile(imageObject.localSrc, file.name);
      } else {
        onChange(plugins[5].addImage(editorState, imageObject.localSrc));
      }
    };

    const uploadVideoCallback = (src) => {
      onChange(plugins[7].addVideo(editorState, { src }));
    };

    const onOpenChange = useCallback((_open) => {
      setOpen(_open);
    }, []);

    const onSearchChange = useCallback(
      async ({ value }) => {
        if (value?.[0] === ' ') {
          return setSuggestions([]);
        }
        if (
          ignoreSuggestionValue &&
          value.slice(0, ignoreSuggestionValue.length) === ignoreSuggestionValue
        ) {
          return setSuggestions([]);
        }

        const response = await fetchUsersMention({ jwt, toast, navigate }, value);
        if (!response || response?.length === 0) {
          setIgnoreSuggestionValue(value);
          return setSuggestions([]);
        }

        const mentions = map(response, (user) => {
          return { name: `${user.name} ${user.surname}`, link: '', avatar: '', id: user.id };
        });
        setSuggestions(defaultSuggestionsFilter(value, mentions));
      },
      [ignoreSuggestionValue],
    );

    const toggleBlockType = (blockTypeValue) => {
      onChange(RichUtils.toggleBlockType(editorState, blockTypeValue));
    };

    const toggleInlineStyle = (inlineStyle) => {
      onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const onAddLink = (linkUrl, displayLink) => {
      if (linkUrl && displayLink) {
        let newState;
        if (getCurrentSelection()) {
          const entityKey = Entity.create('LINK', 'MUTABLE', { url: linkUrl });
          const editorStateWithEntity = Modifier.applyEntity(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            entityKey,
          );
          newState = EditorState.push(editorState, editorStateWithEntity, 'apply-entity');
        } else {
          const currentContent = editorState.getCurrentContent();
          currentContent.createEntity('LINK', 'MUTABLE', {
            url: linkUrl,
          });
          const entityKey = currentContent.getLastCreatedEntityKey();
          const textWithEntity = Modifier.insertText(
            currentContent,
            selection,
            displayLink,
            null,
            entityKey,
          );
          newState = EditorState.createWithContent(textWithEntity);
        }
        onChange(newState);
      }
    };

    const onAddFile = (url, displayLink) => {
      if (url && displayLink) {
        const currentContent = editorState.getCurrentContent();
        currentContent.createEntity('FILE', 'IMMUTABLE', {
          url,
        });
        const entityKey = currentContent.getLastCreatedEntityKey();
        const textWithEntity = Modifier.insertText(
          currentContent,
          selection,
          displayLink,
          null,
          entityKey,
        );
        const newState = EditorState.createWithContent(textWithEntity);
        onChange(newState);
      }
    };

    const toggleColor = (toggledColor) => {
      const nextContentState = Object.keys(styleMap).reduce((contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color);
      }, editorState.getCurrentContent());
      let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');
      if (selection.isCollapsed()) {
        nextEditorState = currentStyle.reduce((state, color) => {
          return RichUtils.toggleInlineStyle(state, color);
        }, nextEditorState);
      }
      if (!currentStyle.has(toggledColor)) {
        nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor);
      }
      onChange(nextEditorState);
    };

    const getCurrentSelection = () => {
      const currentSelection = editorState.getSelection();
      const anchorKey = currentSelection.getAnchorKey();
      const currentContent = editorState.getCurrentContent();
      const currentBlock = currentContent.getBlockForKey(anchorKey);
      const start = currentSelection.getStartOffset();
      const end = currentSelection.getEndOffset();
      return currentBlock.getText().slice(start, end);
    };

    let spinner;
    if (isUploading) {
      spinner = (
        <Flex
          position={'absolute'}
          w='100%'
          h='100%'
          bgColor={'gray.400'}
          opacity={0.3}
          justifyContent={'center'}
          alignItems={'center'}
          zIndex={90}
        >
          <Spinner w='5rem' h='5rem' color='ZSGreen.700' />
        </Flex>
      );
    }

    return (
      <Box
        minH='inherit'
        className={`editor-wrapper ${hasFocus ? 'has-focus' : ''}`}
        data-testid={testId}
        position='relative'
      >
        {spinner}
        <Box
          borderStyle={'solid'}
          borderWidth='1px'
          borderColor='gray.400'
          borderTopRadius='6px'
          borderBottom='0'
        >
          <Toolbar>
            {() => (
              <Flex flexWrap='wrap'>
                <CustomInlineStyleButton
                  type='bold'
                  toggleInlineStyle={toggleInlineStyle}
                  currentStyle={currentStyle}
                />
                <CustomInlineStyleButton
                  type='italic'
                  toggleInlineStyle={toggleInlineStyle}
                  currentStyle={currentStyle}
                />
                <CustomInlineStyleButton
                  type='underline'
                  toggleInlineStyle={toggleInlineStyle}
                  currentStyle={currentStyle}
                />

                <CustomInlineStyleButton
                  type='H1'
                  toggleInlineStyle={toggleInlineStyle}
                  currentStyle={currentStyle}
                />
                <CustomInlineStyleButton
                  type='H2'
                  toggleInlineStyle={toggleInlineStyle}
                  currentStyle={currentStyle}
                />
                <CustomInlineStyleButton
                  type='H3'
                  toggleInlineStyle={toggleInlineStyle}
                  currentStyle={currentStyle}
                />
                <Separator />
                <CustomBlockTypeButton
                  blockType={blockType}
                  type='align-left'
                  toggleBlockType={toggleBlockType}
                />
                <CustomBlockTypeButton
                  blockType={blockType}
                  type='align-center'
                  toggleBlockType={toggleBlockType}
                />
                <CustomBlockTypeButton
                  blockType={blockType}
                  type='align-right'
                  toggleBlockType={toggleBlockType}
                />
                <Separator />
                <CustomLinkButton onAddLink={onAddLink} selectedText={getCurrentSelection()} />
                <Separator />
                <EmojiSelect closeOnEmojiSelect />
                <Separator />
                <ColorControls
                  editorState={editorState}
                  onToggle={toggleColor}
                  currentStyle={currentStyle}
                />
                <CustomBlockTypeButton
                  blockType={blockType}
                  type='ordered-list-item'
                  toggleBlockType={toggleBlockType}
                />
                <CustomBlockTypeButton
                  blockType={blockType}
                  type='unordered-list-item'
                  toggleBlockType={toggleBlockType}
                />
                <CustomBlockTypeButton
                  blockType={blockType}
                  type='blockquote'
                  toggleBlockType={toggleBlockType}
                />
                {imageUpload && (
                  <>
                    <Separator />
                    <CustomImageButton
                      fileInputRef={fileInputRef}
                      onClick={() => fileInputRef.current.click()}
                      uploadImageCallBack={uploadImageCallBack}
                    />
                    <CustomVideoButton uploadVideoCallback={uploadVideoCallback} />
                  </>
                )}
              </Flex>
            )}
          </Toolbar>
        </Box>
        <Editor
          ref={editorRef}
          editorState={editorState}
          editorStyles={{ lineHeight: '10px' }}
          decorators={[
            {
              strategy: findLinkEntities,
              component: Link,
            },
            {
              strategy: findFileEntities,
              component: FileComponent,
            },
          ]}
          onChange={onChange}
          plugins={plugins}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          spellCheck
          customStyleMap={styleMap}
          blockStyleFn={getBlockStyle}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
        />
        <EmojiSuggestions />
        <AlignmentTool />
      </Box>
    );
  },
);

RichEditor.displayName = 'RichEditor';
