import { Box, Divider, Flex, Heading, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAllNews } from '../../api';
import { Pagination } from '../../components-common/pagination';
import { parseLanguageCode } from '../../helpers/languageHelper';
import { setErrorAction } from '../../redux';
import { SingleNews } from './single-news';

const entriesPerPage = 3;
const maxPage = 3;

export const RecentNews = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const { jwt, user } = useSelector((state) => state.auth);
  const [recentNews, setRecentNews] = useState([]);
  const [pagination, setPagination] = useState({ page: 1 });

  const fetchNews = async (currentPage, pageSize = entriesPerPage) => {
    const locale = parseLanguageCode(i18n.language);

    let country;
    if (user.country) {
      country = {
        $or: [
          { countries: { id: { $in: [user.country.id] } } },
          { countries: { id: { $null: true } } },
        ],
      };
    } else {
      country = { countries: { id: { $null: true } } };
    }

    const whereData = {
      $and: [
        { publishedAt: { $notNull: true } },
        { $not: { id: location?.pathname?.split('/')[2] } },
        { locale },
        country,
      ],
    };

    const response = await fetchAllNews(
      jwt,
      toast,
      navigate,
      (value) => dispatch(setErrorAction(value)),
      whereData,
      pageSize,
      currentPage,
    );
    setPagination(response.data.pagination);
    setRecentNews(response.data.results);
  };

  useEffect(() => {
    const initRecentNews = async () => {
      await fetchNews(pagination.page);
    };
    initRecentNews();
  }, [jwt]);

  return (
    <Box h='100%' w='100%' my={2} py={4}>
      <Heading mb={8} size='md' color='brandColors.brandGreen'>
        {t('news.latest-news')}
      </Heading>
      <Box w='90%' ml='auto' mr='auto'>
        {recentNews.map((news, i) => {
          const linkTo = `../${news.id}`;
          return (
            <SingleNews
              idx={i}
              key={news.id}
              news={news}
              linkTo={linkTo}
              jwt={jwt}
              navigate={navigate}
              toast={toast}
              w='100%'
            />
          );
        })}
        <Divider my={8} color='gray.100' opacity={1} />
        <Flex alignItems='center' justifyContent='center'>
          <Pagination
            activePage={pagination.page}
            changePageCallback={(val, entriesPerPageValue) => fetchNews(val, entriesPerPageValue)}
            pages={pagination?.pageCount < 3 ? pagination?.pageCount : maxPage}
            arg={{ mb: 1, mt: 1 }}
          />
        </Flex>
      </Box>
    </Box>
  );
};
