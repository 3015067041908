import { Box, BoxProps } from '@chakra-ui/react';
import { If } from '@dornach/ziegler-ui-kit';
import { UserDataBody } from './user-data-body';
import { UserDataHeader } from './user-data-header';
import { IRecordInfo } from './type';
import { IUserAuth } from '../../types';

interface Props extends BoxProps {
  user: IUserAuth;
  template?: (user: IUserAuth) => IRecordInfo[];
  hideAvatar?: boolean;
  hideBody?: boolean;
  propsBody?: BoxProps;
}
export const UserDataAll = ({ user, hideAvatar, hideBody, propsBody, ...props }: Props) => (
  <Box p={4} wordBreak='break-word' transition={' width 2s, height 4s'} {...props}>
    <UserDataHeader user={user} p={0} hideAvatar={hideAvatar} />
    <If condition={!hideBody}>
      <UserDataBody user={user} p={0} pt={4} {...propsBody} />
    </If>
  </Box>
);
