import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';

import { getHeaders } from './common';
import { PATH_CITY, PATH_FIND_MANY_CITY } from './utils/path';
import { catchError } from './utils/catch-error';

const fieldsAllCity = ['cityName'];

export const fetchAllCity = (jwt, toast) =>
  axios
    .get(`${PATH_CITY}?${qs.stringify({ fields: fieldsAllCity })}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast));

export const customFindManyCity = async (jwt, toast, navigate, filter) => {
  const countryFilter = {
    country: filter,
  };
  const response = await axios
    .get(`${PATH_FIND_MANY_CITY}?${qs.stringify(countryFilter)}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
  return response.data;
};

export const findCityFilterOptions = async (jwt, toast, navigate, filter) => {
  const countryFilter = {
    country: filter,
  };
  const response = await axios
    .get(
      `${PATH_CITY}?${qs.stringify(
        { ...parseFilters(countryFilter), fields: fieldsAllCity },
        {
          encodeValuesOnly: true, // prettify URL
        },
      )}`,
      getHeaders(jwt),
    )
    .catch((error) => catchError(error, toast, navigate));
  return response.data;
};

const parseFilters = (filters) => ({
  filters: {
    $and: [
      get(filters, ['country', 'id'])
        ? {
            country: get(filters, ['country', 'id']),
          }
        : {},
      get(filters, ['country']) && !get(filters, ['country', 'id'])
        ? {
            country: {
              countryName: {
                $containsi: get(filters, ['country']),
              },
            },
          }
        : {},
    ],
  },
});
