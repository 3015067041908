import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { fetchFavouriteBusinessApps } from '../api/business-apps';
import { fetchFavouritePrivateBusinessApps } from '../api/business-apps-private';

const INIT_STATE = {
  favourites: null,
};

export const getFavouritesAction = createAsyncThunk(
  'auth/getFavourites',
  async (params, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user.id;
    const response = await Promise.all([
      fetchFavouriteBusinessApps(params.jwt, userId),
      fetchFavouritePrivateBusinessApps(params.jwt, userId),
    ]);
    const apps = map(response[0].data.business_apps, (d) => ({ ...d, isPrivate: false }));
    const privateApps = map(response[1].data.data, (d) => ({
      ...d.attributes,
      id: d.id,
      isPrivate: true,
    }));
    const data = [...apps, ...privateApps];
    return { data };
  },
);

export const favouritesSlice = createSlice({
  name: 'favourites',
  initialState: {
    ...INIT_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(getFavouritesAction.fulfilled, (state, action) => {
      state.favourites = action.payload.data;
    });
  },
});
