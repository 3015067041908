import React from 'react';
import { Box, Divider, Flex, Heading, Img, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import zieglerImg from '../../assets/ziegler.png';
import { ReactComponent as Authenticity } from '../../assets/icons/ziegler/icon_Authenticity.svg';
import { ReactComponent as Expertise } from '../../assets/icons/ziegler/icon_Expertise.svg';
import { ReactComponent as Entrepreneurship } from '../../assets/icons/ziegler/icon_Entrepreneurship.svg';
import { ReactComponent as Reliability } from '../../assets/icons/ziegler/icon_Reliability.svg';
import { ReactComponent as Check } from '../../assets/icons/ziegler/check.svg';

export const AboutView = () => {
  const { t } = useTranslation();

  const CommitmentItem = ({ text1, text2 }) => (
    <>
      <Flex flexDirection='row' justifyContent='space-between' height='100px'>
        <Flex w='50%' alignItems='center' mr={4}>
          <Box mr={6}>
            <Check />
          </Box>
          <Text>{text1}</Text>
        </Flex>
        <Flex w='50%' alignItems='center' ml={4}>
          <Box mr={6}>
            <Check />
          </Box>
          <Text>{text2}</Text>
        </Flex>
      </Flex>
      <Divider color='gray.100' />
    </>
  );

  return (
    <Flex flexDirection='column' data-testid='aboutZieglerContent'>
      <Heading size='sm' color='brandColors.brandGreen' mb={8} mt={2}>
        {t('about.welcome')}
      </Heading>
      <Img src={zieglerImg} />
      <Flex flexDirection='row' justifyContent='space-between' color='ZLGreen.500' mt={4}>
        <Flex alignItems='center'>
          <Box mr={2}>
            <Text fontSize='xx-large'>115</Text>
          </Box>
          <Box>
            <Text fontSize='x-small' width='80px'>
              {t('about.years')}
            </Text>
          </Box>
        </Flex>
        <Flex alignItems='center'>
          <Box mr={2}>
            <Text fontSize='xx-large'>+3 200</Text>
          </Box>
          <Box>
            <Text fontSize='x-small' width='80px'>
              {t('about.employees')}
            </Text>
          </Box>
        </Flex>
        <Flex alignItems='center'>
          <Box mr={2}>
            <Text fontSize='xx-large'>156</Text>
          </Box>
          <Box>
            <Text fontSize='x-small' width='80px'>
              {t('about.offices')}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Box color='ZLGreen.500' my={8}>
        <Heading size='xs' mb={4}>
          {t('about.mission')}
        </Heading>
        <Text>{t('about.mission-text')}</Text>
      </Box>
      <Box>
        <Heading size='xs' mb={4} color='ZLGreen.500'>
          {t('about.values')}
        </Heading>
        <Flex flexDirection='column' my={8}>
          <Flex flexDirection='row' justifyContent='space-between' mb={8}>
            <Box w='50%'>
              <Authenticity />
              <Heading size='xs' color='ZLGreen.500' my={2}>
                {t('about.authenticity')}
              </Heading>
              <Text>{t('about.authenticity-text')}</Text>
            </Box>
            <Box w='50%'>
              <Expertise />
              <Heading size='xs' color='ZLGreen.500' my={2}>
                {t('about.expertise')}
              </Heading>
              <Text>{t('about.expertise-text')}</Text>
            </Box>
          </Flex>
          <Divider color='gray.100' />
          <Flex flexDirection='row' justifyContent='space-between' mt={8}>
            <Box w='50%'>
              <Entrepreneurship />
              <Heading size='xs' color='ZLGreen.500' my={2}>
                {t('about.entrepreneurship')}
              </Heading>
              <Text>{t('about.entrepreneurship-text')}</Text>
            </Box>
            <Box w='50%'>
              <Reliability />
              <Heading size='xs' color='ZLGreen.500' my={2}>
                {t('about.reliability')}
              </Heading>
              <Text>{t('about.reliability-text')}</Text>
            </Box>
          </Flex>
          <Divider color='gray.100' />
        </Flex>
      </Box>
      <Box>
        <Heading size='xs' mb={4} color='ZLGreen.500'>
          {t('about.commitments')}
        </Heading>
        <Flex flexDirection='column'>
          <CommitmentItem text1={t('about.commitments-1')} text2={t('about.commitments-2')} />
          <CommitmentItem text1={t('about.commitments-3')} text2={t('about.commitments-4')} />
          <CommitmentItem text1={t('about.commitments-5')} text2={t('about.commitments-6')} />
          <CommitmentItem text1={t('about.commitments-7')} text2={t('about.commitments-8')} />
          <CommitmentItem text1={t('about.commitments-9')} text2={t('about.commitments-10')} />
        </Flex>
      </Box>
    </Flex>
  );
};
