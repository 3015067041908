import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react';

import { setActiveCategoryAction } from '../../redux';
import { AboutDropdown } from '../../components-common/about-dropdown';
import { Helmet } from '../../components-common/helmet';
import {
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  FILTER_MENU_WIDTH,
  MAIN_CONTENT_POSITION,
  PAGINATION_HEIGHT,
  PAGINATION_POSITION,
  PATH_ABOUT_ZIEGLER,
} from '../../constants';

export const About = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Flex>
      <Helmet title={t('title.about')} />
      <Grid
        templateAreas={`"header header"
                    "nav main"
                    `}
        gridTemplateRows={'auto 1fr'}
        gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
        h='100%'
        columnGap={FILTER_MENU_GAP_COL}
        data-testid='aboutZiegler'
      >
        <Grid
          style={{ gridArea: 'header' }}
          templateAreas={`"title pagination"`}
          gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
          h='100%'
          columnGap={FILTER_MENU_GAP_COL}
          position={'sticky'}
          top={PAGINATION_POSITION}
          paddingY={FILTER_MENU_GAP_ROW}
          bgColor={'white'}
          zIndex={98}
        >
          <GridItem area={'title'} alignSelf={'center'}>
            <Flex minH={PAGINATION_HEIGHT} alignItems={'center'}>
              <Heading variant='mdLarge' color='brandColors.brandGreen'>
                <Link
                  to={PATH_ABOUT_ZIEGLER}
                  onClick={() => dispatch(setActiveCategoryAction(null))}
                >
                  {t('about.about-ziegler')}
                </Link>
              </Heading>
            </Flex>
          </GridItem>
          <GridItem area={'pagination'} />
        </Grid>
        <GridItem
          area={'nav'}
          position={'sticky'}
          top={MAIN_CONTENT_POSITION}
          h={`calc(100vh - ${MAIN_CONTENT_POSITION})`}
          overflowY={'auto'}
        >
          <AboutDropdown isInNav={false} />
        </GridItem>
        <GridItem area={'main'}>
          <Outlet />
        </GridItem>
      </Grid>
    </Flex>
  );
};
