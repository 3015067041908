import axios from 'axios';
import qs from 'qs';
import { get, identity, pickBy } from 'lodash';

import { DEFAULT_ENTRIES_PER_PAGE } from '../constants';
import { getHeaders } from './common';
import { PATH_BRANCHES_LIST, PATH_EXPORT_BRANCHES, PATH_FIND_MANY_BRANCH } from './utils/path';
import { catchError } from './utils/catch-error';

const fieldsAllBranchesList = [
  'address',
  'phone',
  'email',
  'branchName',
  'phonePrefix',
  'postcode',
];
const populateAllBranchesList = {
  country: {
    fields: ['countryName'],
  },
  certificates: {
    fields: ['name'],
  },
  city: {
    fields: ['cityName'],
  },
  company: {
    fields: ['companyName'],
  },
};

export const customFindManyBranches = async ({ jwt, toast, navigate }, filters) => {
  const response = await axios
    .get(
      `${PATH_FIND_MANY_BRANCH}?${qs.stringify({ filters: parseFilters(filters) })}`,
      getHeaders(jwt),
    )
    .catch((error) => catchError(error, toast, navigate));
  return response.data;
};
export const fetchAllBranchesList = (
  jwt,
  toast,
  navigate,
  errorCallback,
  page,
  filters,
  order,
  pageSize = DEFAULT_ENTRIES_PER_PAGE,
  fields = fieldsAllBranchesList,
  populate = populateAllBranchesList,
) => {
  errorCallback(false);
  return axios
    .get(
      `${PATH_BRANCHES_LIST}?${qs.stringify({
        ...parseFilters(filters),
        fields,
        populate,
        pagination: {
          page,
          pageSize,
        },
        sort: order || ['branchName'],
      })}`,
      getHeaders(jwt),
    )
    .catch((error) => catchError(error, toast, navigate, errorCallback));
};

export const exportBranches = async (jwt, toast, navigate, errorCallback, filters) => {
  const params = {
    slug: 'api::branch-list.branch-list',
    exportFormat: 'json',
    search: qs.stringify({ ...parseFilters(filters) }) || null,
    applySearch: true,
    relationsAsId: false,
    deepness: 2,
  };
  const response = await axios
    .post(`${PATH_EXPORT_BRANCHES}`, pickBy(params, identity), getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
  return response.data;
};

const parseFilters = (filters) => ({
  filters: {
    $and: [
      get(filters, ['branchName'])
        ? {
            branchName: {
              $containsi: get(filters, ['branchName']),
            },
          }
        : {},
      get(filters, ['company', 'id'])
        ? {
            company: get(filters, ['company', 'id']),
          }
        : {},
      get(filters, ['company']) && !get(filters, ['company', 'id'])
        ? {
            company: {
              companyName: {
                $containsi: get(filters, ['company']),
              },
            },
          }
        : {},
      get(filters, ['country', 'id'])
        ? {
            country: get(filters, ['country', 'id']),
          }
        : {},
      get(filters, ['country']) && !get(filters, ['country', 'id'])
        ? {
            country: {
              countryName: {
                $containsi: get(filters, ['country']),
              },
            },
          }
        : {},
      get(filters, ['city', 'id'])
        ? {
            city: get(filters, ['city', 'id']),
          }
        : {},
      get(filters, ['city']) && !get(filters, ['city', 'id'])
        ? {
            city: {
              cityName: {
                $containsi: get(filters, ['city']),
              },
            },
          }
        : {},
      get(filters, ['email'])
        ? {
            email: {
              $containsi: get(filters, ['email']),
            },
          }
        : {},
      get(filters, ['phone'])
        ? {
            phone: {
              $containsi: get(filters, ['phone']),
            },
          }
        : {},
      get(filters, ['address'])
        ? {
            address: {
              $containsi: get(filters, ['address']),
            },
          }
        : {},
      get(filters, ['postcode'])
        ? {
            postcode: {
              $containsi: get(filters, ['postcode']),
            },
          }
        : {},
      get(filters, ['certificates', 'id'])
        ? {
            certificates: get(filters, ['certificates', 'id']),
          }
        : {},
      get(filters, ['certificates']) && !get(filters, ['certificates', 'id'])
        ? {
            certificates: {
              name: {
                $containsi: get(filters, ['certificates']),
              },
            },
          }
        : {},
    ],
  },
});
