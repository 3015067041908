import { Box, Center, Spinner } from '@chakra-ui/react';
import React from 'react';
import { If } from '../if';

export const BlurSpinner = ({ condition }) => {
  return (
    <If condition={condition}>
      <Center
        position='absolute'
        bgColor='#fff8'
        w='100%'
        minH='40vh'
        h='100%'
        zIndex={1000}
        alignItems='flex-start'
        pt='20vh'
      >
        <Box borderRadius='120' position='fixed' border='5px solid #fff' bottom='50vh'>
          <Box
            bgColor='#fff'
            p='60px'
            borderRadius='120'
            boxShadow='-webkit-box-shadow: 0px 0px 200px 200px rgba(255,255,255,1);
-moz-box-shadow: 0px 0px 200px 200px rgba(255,255,255,1);
box-shadow: 0px 0px 200px 200px rgba(255,255,255,1);'
          >
            <Spinner
              emptyColor='gray.200'
              thickness='4px'
              speed='0.6s'
              color='ZLGreen.700'
              h='100px'
              w='100px'
            />
          </Box>
        </Box>
      </Center>
    </If>
  );
};
