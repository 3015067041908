import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  HStack,
  Heading,
  Img,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { add, format } from 'date-fns';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  customFindManyBranches,
  customFindManyCountries,
  fetchAllAdvertsCategories,
  fetchAllCurrencies,
  postNewAdvert,
  updateAdvert,
} from '../../api';
import { ReactComponent as IconImage } from '../../assets/icons/icon_image.svg';
import CustomMultiSelect from '../../components-common/custom-select';
import { RichEditor } from '../../components-common/editor';
import { GridInputWrapper } from '../../components-common/grid-input-wrapper';
import { ADVERT_MAX_NB_PHOTOS } from '../../constants';
import { resizedImgUrl } from '../../helpers/imageHelper';
import { parseLanguageCode } from '../../helpers/languageHelper';
import './index.css';

export const AddAdvertModal = ({
  users,
  singleAdvert,
  jwt,
  isOpen,
  onClose,
  currentUser,
  fetchFilteredAdverts,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const date = new Date();
  const richEditorRef = useRef(null);
  const [advertCategories, setAdvertCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [branches, setBranches] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const { t, i18n } = useTranslation();
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);
  const [displayedFiles, setDisplayedFiles] = useState([]);
  const [isUploadInvalidFormat, setIsUploadInvalidFormat] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initAdvertModal = async () => {
      if (singleAdvert?.photo) {
        setSavedFiles(singleAdvert.photo);
        setDisplayedFiles(
          singleAdvert.photo.map((photo) => {
            const displayedPhoto = {
              isSaved: true,
              id: photo.id,
              name: photo.name,
              thumbnail: resizedImgUrl(photo, 'thumbnail'),
            };
            return displayedPhoto;
          }),
        );
      }

      fetchCategories();
      fetchCountries();
      fetchCurrencies();
    };

    initAdvertModal();

    return () => {
      setUploadedFiles([]);
      setIsUploadingFiles(false);
    };
  }, [jwt, i18n, singleAdvert]);

  const fetchCategories = async () => {
    const { data } = await fetchAllAdvertsCategories(
      jwt,
      toast,
      navigate,
      parseLanguageCode(i18n.language),
    );
    setAdvertCategories(data);
  };

  const fetchCountries = async () => {
    customFindManyCountries({ jwt, toast, navigate }).then((countries) => {
      setCountries(countries);
    });

    if (singleAdvert?.country?.id) {
      fetchBranches(singleAdvert.country);
    } else if (currentUser?.country?.id) {
      fetchBranches(currentUser.country);
    } else {
      fetchBranches();
    }
  };

  const fetchBranches = (country) => {
    let countryFilter = {};
    if (country) {
      countryFilter = { country: { id: country.id } };
    }
    customFindManyBranches({ jwt, toast, navigate }, countryFilter).then((branchesData) => {
      setBranches(branchesData);
    });
  };

  const fetchCurrencies = async () => {
    const { data: dataCurrencies } = await fetchAllCurrencies(jwt, toast, navigate);
    setCurrencies(dataCurrencies.data);
  };

  const addUploadedFile = (file, currentUploadedIndex) => {
    if (['image/png', 'image/jpg', 'image/jpeg'].includes(file?.type)) {
      const reader = new FileReader();

      let thumbnail;
      reader.addEventListener(
        'load',
        () => {
          // convert image file to base64 string
          thumbnail = reader.result;

          setUploadedFiles((uploadedFilesState) => [...uploadedFilesState, file]);
          setDisplayedFiles((displayedFilesState) => [
            ...displayedFilesState,
            { isSaved: false, id: currentUploadedIndex, name: file.name, thumbnail },
          ]);
        },
        false,
      );

      reader.readAsDataURL(file);
      return true;
    }
    setIsUploadInvalidFormat(true);
    return false;
  };

  const uploadHandler = (event) => {
    event.preventDefault();
    setIsUploadInvalidFormat(false);
    if (disableUpload === true) {
      return;
    }

    setIsUploadingFiles(true);

    let currentUploadedIndex = 0;
    const nbUploadedFiles = uploadedFiles.length;
    const nbDisplayedFiles = displayedFiles.length;

    if (nbUploadedFiles > 0) {
      let maxId = 0;
      uploadedFiles.forEach((uploadedFile) => {
        if (uploadedFile.id > maxId) {
          maxId = uploadedFile.id;
        }
      });
      currentUploadedIndex = maxId + 1;
    }

    switch (event.type) {
      case 'change': {
        const fileChange = event.target.files[0];

        fileChange.id = currentUploadedIndex;
        addUploadedFile(fileChange, currentUploadedIndex);
        break;
      }
      case 'drop': {
        if (event.dataTransfer.items) {
          [...event.dataTransfer.items].every((item, i) => {
            if (item.kind === 'file' && nbDisplayedFiles + i < ADVERT_MAX_NB_PHOTOS) {
              const fileDrop = item.getAsFile();
              fileDrop.id = currentUploadedIndex + i;
              if (!addUploadedFile(fileDrop, currentUploadedIndex + i)) {
                return false;
              }
              return true;
            }
            return false;
          });
        } else {
          [...event.dataTransfer.files].every((file, i) => {
            if (nbDisplayedFiles + i < ADVERT_MAX_NB_PHOTOS) {
              const fileDrop = file;
              fileDrop.id = currentUploadedIndex + i;
              if (!addUploadedFile(fileDrop, currentUploadedIndex + i)) {
                return false;
              }
              return true;
            }
            return false;
          });
        }
        setIsDraggingOver(false);
        break;
      }
      default:
        // unsupported
        break;
    }

    setIsUploadingFiles(false);
  };

  const deleteFile = (event, displayedFile, i) => {
    event.preventDefault();
    event.stopPropagation();

    if (displayedFile.isSaved) {
      const newSavedFiles = [];
      savedFiles.forEach((currentSavedFile, savedFilesIndex) => {
        if (currentSavedFile.id !== displayedFile.id) {
          newSavedFiles.push(currentSavedFile);
        }
      });
      setSavedFiles([...newSavedFiles]);
    } else {
      const newUploadedFiles = [];
      uploadedFiles.forEach((currentUploadedFile, uploadedFilesIndex) => {
        if (currentUploadedFile.id !== displayedFile.id) {
          newUploadedFiles.push(currentUploadedFile);
        }
      });
      setUploadedFiles([...newUploadedFiles]);
    }

    const newDisplayedFiles = displayedFiles;
    newDisplayedFiles.splice(i, 1);
    setDisplayedFiles([...newDisplayedFiles]);
  };

  let defaultValues;

  if (singleAdvert) {
    const value = singleAdvert.content;
    defaultValues = {
      title: singleAdvert.title,
      expires: singleAdvert.expires,
      currency: singleAdvert.currency?.id,
      negociate: singleAdvert.negociate,
      price: singleAdvert.price,
      branch: singleAdvert.branch?.id,
      country: singleAdvert.country?.id,
      advert_category: singleAdvert.advert_category?.id,
      content: value
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
        : EditorState.createEmpty(),
    };
  } else {
    defaultValues = {
      title: '',
      expires: format(add(new Date(), { months: 1 }), 'yyyy-MM-dd'),
      branch: currentUser.branchList?.id,
      country: currentUser.country?.id,
      advert_category: null,
      currency: null,
      negociate: false,
      price: '',
      content: EditorState.createEmpty(),
    };
  }

  const {
    register: advertForm,
    handleSubmit: handleSubmitAdvert,
    setValue: setValueAdvert,
    getValues: getValuesAdvert,
    formState: { errors },
    control: controlAdvert,
    watch: watchAdvert,
  } = useForm({
    defaultValues,
  });

  watchAdvert('title');
  const selectedCategory = watchAdvert('advert_category');
  const selectedCountry = watchAdvert('country');
  const selectedBranch = watchAdvert('branch');
  const selectedCurrency = watchAdvert('currency');
  watchAdvert('price');
  const selectedNegociate = watchAdvert('negociate');

  const onSubmit = async (data, event) => {
    if (isLoading === true) {
      return;
    }
    setIsLoading(true);
    const rawContentState = convertToRaw(getValuesAdvert().content.getCurrentContent());
    const newData = {
      ...data,
      content: JSON.stringify(rawContentState),
      photo: savedFiles.map((savedFile) => {
        return savedFile.id;
      }),
    };

    let result = false;
    if (singleAdvert?.id) {
      newData.id = singleAdvert.id;
      result = await updateAdvert(jwt, toast, navigate, newData, uploadedFiles);
    } else {
      result = await postNewAdvert(jwt, toast, navigate, newData, currentUser, uploadedFiles);
    }

    if (result === true) {
      await fetchFilteredAdverts(singleAdvert?.id ? undefined : {});
      onCloseModal();
    }
    setIsLoading(false);
  };

  const onCloseModal = () => {
    onClose();
  };

  const onDragOver = (event) => {
    event.preventDefault();
    if (disableUpload === true) {
      return;
    }
    setIsDraggingOver(true);
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    if (disableUpload === true) {
      return;
    }
    setIsDraggingOver(false);
  };

  let fileUploadText;
  let disableUpload = false;
  if (displayedFiles.length < ADVERT_MAX_NB_PHOTOS) {
    fileUploadText = (
      <VStack
        width='100%'
        justifyContent='center'
        data-testid='advertsEditModalPhotoNewUploadLabel'
      >
        <IconImage color='ZSGreen.600' />
        <Heading color='brandColors.brandGreen' variant='smLarge'>
          {t('adverts.file-upload')}
        </Heading>
        <Text color='gray.550' variant='lg'>
          {t('adverts.file-upload-2')}
        </Text>
      </VStack>
    );
  } else {
    disableUpload = true;
    fileUploadText = (
      <Heading
        alignSelf='center'
        color='brandColors.brandGreen'
        variant='smLarge'
        data-testid='advertsEditModalPhotoMaxUploadLabel'
      >
        {t('adverts.max-file-upload')}
      </Heading>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} closeOnOverlayClick={false} size='xl'>
      {isOpen && (
        <>
          <ModalOverlay />

          <ModalContent px={2} py={4} maxWidth='55rem' data-testid='advertsEditModal'>
            <form onSubmit={handleSubmitAdvert(onSubmit)}>
              {singleAdvert ? (
                <ModalHeader>
                  <Heading
                    variant='lgSmall'
                    color='brandColors.brandGreen'
                    data-testid='advertsEditModalTitle'
                  >
                    {t('adverts.edit-advert')}
                  </Heading>
                </ModalHeader>
              ) : (
                <ModalHeader>
                  <Heading
                    variant='lgSmall'
                    color='brandColors.brandGreen'
                    data-testid='advertsEditModalTitle'
                  >
                    {t('adverts.new-advert')}
                  </Heading>
                </ModalHeader>
              )}

              <ModalCloseButton />

              <ModalBody pt={4}>
                <Grid
                  gridTemplateRows={'1fr'}
                  gridTemplateColumns={'repeat(12, 1fr)'}
                  rowGap='4'
                  columnGap='6'
                >
                  <GridInputWrapper
                    arg={{ colStart: '1', colEnd: '13' }}
                    name={t('adverts.title')}
                    isRequired
                    error={errors.title}
                    testId='advertsEditModalTitle'
                  >
                    <Input
                      placeholder='Title'
                      maxLength='100'
                      name='title'
                      data-testid='advertsEditModalTitleInput'
                      {...advertForm('title', { required: t('errors.empty') })}
                    />
                  </GridInputWrapper>

                  <GridInputWrapper
                    arg={{ colStart: '1', colEnd: '7' }}
                    name={t('adverts.category')}
                    isRequired
                    error={errors.advert_category}
                    testId='advertsEditModalCategory'
                  >
                    {errors.advert_category && <Text color='red.500'>{t('errors.empty')}</Text>}
                    <CustomMultiSelect
                      required
                      control={controlAdvert}
                      arg={{
                        name: 'advert_category',
                        'aria-label': t('common.select-category'),
                        value: advertCategories.find((data) => data.id === selectedCategory),
                        getOptionLabel: (option) => option.attributes.advertCategoryName,
                        getOptionValue: (option) => option.id,
                        placeholder: t('common.select-category'),
                        options: advertCategories,
                        isClearable: true,
                      }}
                      testId='advertsEditModalCategoryInput'
                    />
                  </GridInputWrapper>

                  <GridInputWrapper
                    arg={{ colStart: '7', colEnd: '13' }}
                    name={t('adverts.expires')}
                    isRequired
                    error={errors.expires}
                    testId='advertsEditModalExpires'
                  >
                    <Input
                      type='date'
                      min={format(date, 'yyyy-MM-dd')}
                      max={format(add(new Date(), { months: 1 }), 'yyyy-MM-dd')}
                      placeholder={t('adverts.expires')}
                      name='expires'
                      {...advertForm('expires')}
                      data-testid='advertsEditModalExpiresInput'
                    />
                  </GridInputWrapper>

                  <GridInputWrapper
                    arg={{ colStart: '1', colEnd: '7' }}
                    name={t('adverts.price')}
                    error={errors.price}
                    testId='advertsEditModalPrice'
                  >
                    <Input
                      type='number'
                      min='0'
                      max='100000'
                      pattern='\d'
                      placeholder={t('adverts.price')}
                      name='price'
                      {...advertForm('price')}
                      data-testid='advertsEditModalPriceInput'
                    />
                  </GridInputWrapper>

                  <GridInputWrapper
                    arg={{ colStart: '7', colEnd: '11' }}
                    name={t('adverts.currency')}
                    isRequired
                    error={errors.currency}
                    testId='advertsEditModalCurrency'
                  >
                    {errors.currency && <Text color='red.500'>{t('errors.empty')}</Text>}
                    <CustomMultiSelect
                      required
                      control={controlAdvert}
                      arg={{
                        name: 'currency',
                        value: currencies.find((data) => data.id === selectedCurrency),
                        'aria-label': t('adverts.currency'),
                        getOptionLabel: (option) => option.attributes?.iso_code,
                        getOptionValue: (option) => option.id,
                        placeholder: t('adverts.currency'),
                        options: currencies,
                        isClearable: true,
                      }}
                      testId='advertsEditModalCurrencyInput'
                    />
                  </GridInputWrapper>

                  <GridInputWrapper
                    arg={{ colStart: '11', colEnd: '13' }}
                    name={t('adverts.price-negociate')}
                    error={errors.negociate}
                    testId='advertsEditModalNegociate'
                  >
                    <HStack my='auto' justifyContent='center'>
                      <Switch
                        id='advert-modal-negociate'
                        name='negociate'
                        {...advertForm('negociate')}
                        data-testid='advertsEditModalNegociateSwitch'
                      />
                      <FormLabel htmlFor='advert-modal-negociate' mb='0'>
                        {selectedNegociate === true ? t('common.yes') : t('common.no')}
                      </FormLabel>
                    </HStack>
                  </GridInputWrapper>

                  <GridInputWrapper
                    arg={{ colStart: '1', colEnd: '7' }}
                    name={t('adverts.country')}
                    isRequired
                    error={errors.country}
                    testId='advertsEditModalCountry'
                  >
                    {errors.country && <Text color='red.500'>{t('errors.empty')}</Text>}
                    <CustomMultiSelect
                      required
                      control={controlAdvert}
                      arg={{
                        name: 'country',
                        'aria-label': t('common.select-country'),
                        value: countries.find((data) => data.id === selectedCountry),
                        getOptionLabel: (option) => option.countryName,
                        getOptionValue: (option) => option.id,
                        placeholder: t('common.select-country'),
                        options: countries,
                        isClearable: true,
                        onChange: (e) => {
                          setValueAdvert('country', e);
                          setValueAdvert('branch', null);
                          fetchBranches(e);
                        },
                      }}
                      testId='advertsEditModalCountryInput'
                    />
                  </GridInputWrapper>

                  <GridInputWrapper
                    arg={{ colStart: '7', colEnd: '13' }}
                    name={t('common.branch')}
                    testId='advertsEditModalBranch'
                  >
                    <CustomMultiSelect
                      control={controlAdvert}
                      arg={{
                        name: 'branch',
                        'aria-label': t('common.select-branch'),
                        value:
                          selectedBranch === null
                            ? null
                            : branches.find((data) => data.id === selectedBranch),
                        getOptionLabel: (option) => option.branchName,
                        getOptionValue: (option) => (!option.id ? option.branchName : option.id),
                        placeholder: t('common.select-branch'),
                        options: branches,
                        isClearable: true,
                      }}
                      testId='advertsEditModalBranchInput'
                    />
                  </GridInputWrapper>

                  <GridInputWrapper
                    arg={{ minHeight: '150px', maxHeight: '350px', colStart: '1', colEnd: '13' }}
                    name={t('adverts.content')}
                    testId='advertsEditModalDescription'
                  >
                    <RichEditor
                      content={getValuesAdvert().content}
                      setContent={(content) => setValueAdvert('content', content)}
                      ref={richEditorRef}
                      testId='advertsEditModalDescriptionInput'
                    />
                  </GridInputWrapper>

                  <GridInputWrapper
                    arg={{ colStart: '1', colEnd: '13' }}
                    name={t('adverts.photo')}
                    testId='advertsEditModalPhoto'
                  >
                    <Box w='100%' minHeight='100px' className='file-input'>
                      <input
                        type='file'
                        accept='.jpg, .jpeg, .png'
                        id='file-input'
                        className='file-input__input'
                        name='photo'
                        onChange={uploadHandler}
                        disabled={disableUpload}
                        data-testid='advertsEditModalPhotoUploadInput'
                      />
                      <label
                        width='100%'
                        height='100%'
                        disabled={disableUpload}
                        className={`file-input__label ${
                          isDraggingOver && disableUpload === false ? 'dragOver' : ''
                        } ${disableUpload === true ? 'disabled' : ''}`}
                        htmlFor='file-input'
                        onDragLeave={onDragLeave}
                        onDragEnd={onDragLeave}
                        onDragEnter={onDragOver}
                        onDrop={uploadHandler}
                        onDragOver={onDragOver}
                        data-testid='advertsEditModalPhotoUploadLabel'
                      >
                        {fileUploadText}

                        <VStack spacing={4} alignItems='start' mt={8}>
                          {displayedFiles?.map((f, i) => (
                            <HStack spacing={4} key={i} data-testid='advertsEditModalPhotoUploaded'>
                              <Img
                                borderColor='ZLGreen.100'
                                borderStyle='solid'
                                borderWidth='1px'
                                title={f.name}
                                width='48px'
                                height='48px'
                                maxW='none'
                                objectFit='contains'
                                src={f.thumbnail}
                              />
                              <Text>{f.name}</Text>
                              <Tooltip label={t('common.delete')}>
                                <DeleteIcon
                                  cursor='pointer'
                                  _hover={{ color: 'red' }}
                                  onClick={(event) => deleteFile(event, f, i)}
                                />
                              </Tooltip>
                            </HStack>
                          ))}
                          {isUploadingFiles && (
                            <Spinner
                              emptyColor='gray.200'
                              thickness='4px'
                              speed='0.6s'
                              color='ZLGreen.600'
                              h='48px'
                              w='48px'
                            />
                          )}
                          {isUploadInvalidFormat && (
                            <Heading
                              alignSelf='center'
                              color='red'
                              variant='smLarge'
                              data-testid='advertsEditModalPhotoInvalidUploadLabel'
                            >
                              {t('adverts.invalid-file-format').replace('%s', 'jpg, jpeg, png')}
                            </Heading>
                          )}
                        </VStack>
                      </label>
                    </Box>
                  </GridInputWrapper>
                </Grid>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant='ZGreenGhost'
                  size='md'
                  mr={3}
                  onClick={onCloseModal}
                  isDisabled={isLoading === true}
                  data-testid='advertsEditModalCancelButton'
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  variant='ZGreenSolid'
                  size='md'
                  type='submit'
                  isLoading={isLoading === true}
                  isDisabled={Object.keys(errors).length !== 0}
                  data-testid='advertsEditModalSaveButton'
                >
                  {t('common.save')}
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </>
      )}
    </Modal>
  );
};
