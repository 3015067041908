import { Edge, useEdgesState, useNodesState } from 'reactflow';
import { HierarchyNode, HierarchyPointNode, stratify, tree } from 'd3-hierarchy';
import React, { useEffect, useState } from 'react';

import { IId } from '../../../types';
import { useStateRef } from '../../../helpers/hooks/use-state-ref';
import { ORG_CHART_NODE_HEIGHT, ORG_CHART_NODE_WIDTH } from '../../../constants/org-chart';

interface Props<T> {
  users?: T[];
  getNewCards: (id: number) => void;
}

interface INodePosition {
  id?: string;
  x: number;
  y: number;
}

// first render -----------------------------------------------------------------|
//                                                                               |--> generating new user tree -|
//                                                          --> fetch new users -|    (hierarchy)               |
//                                                        no|                                                   |
//              ---> show cards ---> if(was the card open?)-|                                                   |
//              |                                        yes|                                                   |
//              |                                           --> show children card -----------------------------|
//              |                                                                                               |---> renderData    set node and edges
// onClickCard -|--> hidden cards ----------------------------> hidden children card ---------------------------|     (change selected node and edges)
//              |                                                                                               |
//              ---> selected card (yes/no) --------------------------------------------------------------------|

export const useOrgChartDesktop = <
  T extends { id: number; line_manager: IId; line_workers: IId[] },
>({
  users,
  getNewCards,
}: Props<T>) => {
  interface INode {
    id: string;
    type: string;
    position: { x: number; y: number };
    data: T;
  }
  interface IEdge {
    id: string;
    type: string;
    target: string;
    source: string;
    data?: {
      isActive?: boolean;
    };
  }
  interface IHierarchyPointNodeModify extends HierarchyPointNode<INode> {
    hiddenChildren: IHierarchyPointNodeModify[] | undefined;
  }

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<IEdge>([]);
  const [cardOldPosition, setCardOldPosition] = useState<INodePosition>();

  const [selectedNodeRef, setSelectedNode] = useStateRef<undefined | T>(undefined);
  const [isBlockNodeRef, setIsBlockNode] = useStateRef<boolean>(false);
  const [hiddenBranchListIdRef, setHiddenBranchList] = useStateRef<HierarchyNode<INode>[]>([]);

  const g = React.useMemo(() => tree<INode>(), []);

  // ---generating user trees---
  const generateNode = (users: T[]) =>
    users.map((users, i) => ({
      id: `${users.id}`,
      type: 'myCustomNode',
      position: { x: 0, y: 100 * i },
      data: {
        ...users,
        show: false,
      },
    }));

  const generateEdge = (users: T[]) => {
    return users
      .filter((users) => users.line_manager)
      .map((users, i) => ({
        id: `e${users.id}-${users.line_manager.id}`,
        target: `${users.id}`,
        source: `${users.line_manager.id}`,
        type: 'myCustomEdge',
      }));
  };

  const getHierarchy = (rawNodes, rawEdges) => {
    if (rawNodes.length === 0) return undefined;
    const hierarchy = stratify<INode>()
      .id((node) => node.id)
      .parentId((node) => rawEdges.find((edge) => edge.target === node.id)?.source);
    const root = hierarchy(rawNodes);

    return root;
  };

  const hierarchy = React.useMemo(() => {
    if (!users || !users[0]) return;
    const newNodes = generateNode(users);

    const newEdges = generateEdge(users);
    setEdges(newEdges);
    return getHierarchy(newNodes, newEdges);
  }, [users]);

  // ---cart click methods---
  const hiddenBranch = (selectedBranch: IHierarchyPointNodeModify) => {
    if (selectedBranch.children) {
      // eslint-disable-next-line no-param-reassign
      selectedBranch.hiddenChildren = selectedBranch.children;
      // eslint-disable-next-line no-param-reassign
      selectedBranch.children = undefined;
    }
  };
  const hiddenCards = (selectedBranch: IHierarchyPointNodeModify) => {
    if (hierarchy) {
      hiddenBranch(selectedBranch);
      setHiddenBranchList([...hiddenBranchListIdRef.current, selectedBranch]);
      renderData(hierarchy, false);
    }
  };

  const showCards = (selectedBranch: IHierarchyPointNodeModify) => {
    const newCardOldPosition: INodePosition = { ...(selectedBranch as HierarchyPointNode<INode>) };
    if (hierarchy && selectedBranch.hiddenChildren) {
      // eslint-disable-next-line no-param-reassign
      selectedBranch.children = selectedBranch.hiddenChildren;
      setHiddenBranchList(
        hiddenBranchListIdRef.current.filter((branch) => branch.id !== selectedBranch.data.id),
      );
      return renderData(hierarchy, newCardOldPosition);
    }
    setCardOldPosition(newCardOldPosition);
    return getNewCards(selectedBranch.data.data.id);
  };

  const handleNode = (card: number) => {
    if (hierarchy && !isBlockNodeRef.current) {
      setIsBlockNode(true);
      const selectedBranch = hierarchy.find((user) => parseInt(user.data.id, 10) === card);
      if (selectedBranch) {
        if (selectedBranch.children?.[0]) {
          return hiddenCards(selectedBranch as IHierarchyPointNodeModify);
        }
        if (selectedBranch.data.data.line_workers[0]) {
          return showCards(selectedBranch as IHierarchyPointNodeModify);
        }
      }
      return console.error('The branch on which the action was performed was not found');
    }
  };

  const clearSelect = () => {
    setSelectedNode(undefined);
  };

  // ---render org-chart---
  const renderEdge = () => {
    if (hierarchy && selectedNodeRef.current?.id) {
      const activeNode = hierarchy.find(
        (branch) => branch.data.data.id === selectedNodeRef.current?.id,
      );
      if (activeNode) {
        const activeNodePath = hierarchy.path(activeNode);
        const newEdges = edges.map((edge) => {
          if (activeNodePath.find((nodeInPath) => nodeInPath.id === edge.target)) {
            return {
              ...edge,
              data: {
                isActive: true,
              },
            } as Edge;
          }
          return {
            ...edge,
            data: {
              isActive: false,
            },
          } as Edge;
        });
        setEdges([
          ...newEdges.filter((edge) => !edge.data.isActive),
          ...newEdges.filter((edge) => edge.data.isActive),
        ]);
      }
    } else {
      setEdges(
        edges.map(
          (edge) =>
            ({
              ...edge,
              data: {
                isActive: false,
              },
            }) as Edge,
        ),
      );
    }
  };

  const renderNode = (root: HierarchyNode<INode>, newCardOldPosition?: INodePosition | false) => {
    const layout = g.nodeSize([ORG_CHART_NODE_WIDTH * 1.6, ORG_CHART_NODE_HEIGHT * 1.6])(root);

    const newNodes = generateNewNode(layout);

    const dataOldPosition = newCardOldPosition || cardOldPosition;
    if (dataOldPosition && newCardOldPosition !== false) {
      const xs = newNodes.findIndex((node) => {
        if (node.id === dataOldPosition.id) {
          return true;
        }
        return false;
      });
      const newX = newNodes[xs].position.x;
      newNodes[xs].position.x = dataOldPosition.x;
      setTimeout(() => {
        newNodes[xs].position.x = newX;
        setNodes([...newNodes]);
      }, 200);
      setCardOldPosition(undefined);
    }

    return setNodes(newNodes);
  };
  const generateNewNode = (layout: HierarchyPointNode<INode>) =>
    layout.descendants().map((node) => {
      const isSelectedNode = selectedNodeRef.current?.id === node.data.data.id;
      return {
        ...node.data,
        position: { x: node.x, y: node.y },
        data: {
          ...node.data.data,
          children: node.children,
          handleNode: () => handleNode(node.data.data.id),
          selectNode: () => setSelectedNode(isSelectedNode ? undefined : node.data.data),
          isSelectedNode,
        },
      };
    });
  const renderData = (root: HierarchyNode<INode>, newCardOldPosition?: INodePosition | false) => {
    renderEdge();

    renderNode(root, newCardOldPosition);
  };

  useEffect(() => {
    if (hierarchy) {
      hiddenBranchListIdRef.current.map((branch) => {
        const selectedBranch = hierarchy.find((user) => user.data.id === branch.id);

        if (selectedBranch) {
          return hiddenBranch(selectedBranch as IHierarchyPointNodeModify);
        }
        return console.error('The branch on which the action was performed was not found');
      });

      renderData(hierarchy);
    }
  }, [hierarchy, selectedNodeRef.current]);

  useEffect(() => {
    setIsBlockNode(false);
  }, [nodes]);

  return {
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    selectedUser: selectedNodeRef.current,
    clearSelect,
  };
};
