import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { IApiParams, ICommonApi, IForumCategory, IQuestionForumAllData } from '../types';

import { getHeaders } from './common';
import { catchError } from './utils/catch-error';
import {
  PATH_FORUM_CATEGORIES,
  PATH_FORUM_TOPICS,
  PATH_FORUM_TOPICS_CLOSE,
  PATH_UPLOAD,
} from './utils/path';

export const fetchForumCategories = (
  { jwt, toast, navigate }: IApiParams,
  callbackError?: (arg: boolean) => void,
) => {
  callbackError?.(false);
  return axios
    .get(PATH_FORUM_CATEGORIES, getHeaders(jwt))
    .then((response: AxiosResponse<IForumCategory[]>) => response.data)
    .catch((error) => catchError(error, toast, navigate, callbackError));
};

export const fieldsAllTopics = [
  'title',
  'content',
  'createdAt',
  'content_raw',
  'last_activity',
  'last_activity_type',
  'modified',
  'closed',
];

export const populateAllTopics = {
  categories: {
    fields: ['title', 'color', 'locale'],
    populate: {
      localizations: {
        fields: ['title', 'color', 'locale'],
      },
    },
  },
  users_permissions_user: {
    fields: ['name', 'surname', 'imageUrl', 'blocked'],
    populate: {
      ekspertise: {
        fields: ['expertiseName'],
      },
    },
  },
  contributors: {
    fields: ['name', 'surname', 'imageUrl', 'blocked'],
  },
};
const sortAllTopics = ['createdAt:desc'];

const parseFilters = (filters) => {
  const keywordFilter = filters?.keyword;
  const categoryFilter = filters?.category;

  let keyword;
  if (keywordFilter) {
    keyword = {
      $or: [
        {
          content_raw: {
            $containsi: keywordFilter,
          },
        },
        {
          title: {
            $containsi: keywordFilter,
          },
        },
      ],
    };
  }

  let category;
  if (categoryFilter && categoryFilter.length > 0) {
    const categories = categoryFilter.map((item) => item.id);

    /* categoryFilter[0].localizations?.data?.forEach((locale) => {
      categories.push(locale.id);
    }); */

    category = {
      $or: [
        { categories: { id: { $in: categories } } },
        { categories: { localizations: { id: { $in: categories } } } },
      ],
    };
  }

  const parsedFilters = {
    $and: [keyword, category],
  };

  return parsedFilters;
};

export const fetchAllTopics = (
  { jwt, toast, navigate },
  {
    errorCallback,
    filters,
    fields = fieldsAllTopics,
    populate = populateAllTopics,
    sort = sortAllTopics,
    pageSize,
    page,
  }: ICommonApi | undefined = {},
) => {
  const queryRaw = {
    fields,
    populate,
    filters: filters ? parseFilters(filters) : undefined,
    sort,
    pagination:
      pageSize && page
        ? {
            pageSize,
            page,
          }
        : undefined,
  };
  errorCallback?.(false);
  return axios
    .get(`${PATH_FORUM_TOPICS}?${qs.stringify(queryRaw)}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate, errorCallback));
};

export const fetchSingleTopic = (
  { jwt, toast, navigate },
  { errorCallback, id, fields = fieldsAllTopics, populate = populateAllTopics },
) => {
  const queryRaw = {
    select: fields,
    populate,
  };
  errorCallback(false);
  return axios
    .get(`${PATH_FORUM_TOPICS}/${id}?${qs.stringify(queryRaw)}`, getHeaders(jwt))
    .catch((error) => {
      catchError(error, toast, navigate, errorCallback);
    });
};

export const newTopic = async ({ jwt, toast, navigate }: IApiParams, data) => {
  return axios
    .post(`${PATH_FORUM_TOPICS}`, data, getHeaders(jwt))
    .then((response: AxiosResponse<IQuestionForumAllData>) => response.data)
    .catch((error) => catchError(error, toast, navigate));
};

export const updateTopic = async ({ jwt, toast, navigate }: IApiParams, data) => {
  return axios
    .put(`${PATH_FORUM_TOPICS}/${data.id}`, data, getHeaders(jwt))
    .then((response: AxiosResponse<IQuestionForumAllData>) => response.data)
    .catch((error) => catchError(error, toast, navigate));
};

export const closeSingleTopic = async ({ jwt, toast, navigate }: IApiParams, data) => {
  return axios
    .put(`${PATH_FORUM_TOPICS_CLOSE}/${data.id}`, null, getHeaders(jwt))
    .then((response: AxiosResponse<IQuestionForumAllData>) => response.data)
    .catch((error) => catchError(error, toast, navigate));
};

export const uploadImage = (jwt, toast, navigate, file) => {
  const formData = new FormData();
  formData.append('files', file);
  return axios
    .post(`${PATH_UPLOAD}`, formData, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};

export const removeImage = (jwt, toast, navigate, fileId) => {
  return axios
    .delete(`${PATH_UPLOAD}/files/${fileId}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};
