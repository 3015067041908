import { DEFAULT_ENTRIES_PER_PAGE } from '../constants';
import { customFindManyBranches, fetchAllBranchesList } from './branches-list';
import { findManyCertificates } from './certificates';
import { customFindManyCity } from './city';
import { customFindManyCompany } from './company';
import { customFindManyCountries } from './countries';
import { customFindManyDepartments } from './departments';
import { customFindManyExpertises } from './expertises';
import { fetchAllLanguagesList, fetchAllNewsNotifLanguagesList } from './languages-list';
import { fetchAllUsers } from './users';

export * from './adverts';
export * from './adverts-category';
export * from './branches-list';
export * from './comments';
export * from './common';
export * from './company';
export * from './countries';
export * from './currencies';
export * from './departments';
export * from './expertises';
export * from './google-drive';
export * from './languages-list';
export * from './likes';
export * from './locales';
export * from './organizations';
export * from './posts';
export * from './users';

export const fetchUserRelationData = ({ jwt, toast, navigate }, country) =>
  Promise.all([
    customFindManyCountries({ jwt, toast, navigate }),
    customFindManyDepartments({ jwt, toast, navigate }),
    customFindManyExpertises({ jwt, toast, navigate }),
    customFindManyCompany({ jwt, toast, navigate }),
    country?.id &&
      customFindManyBranches({ jwt, toast, navigate }, { country: { id: country.id } }),
    fetchAllLanguagesList(jwt, toast, navigate),
    fetchAllNewsNotifLanguagesList(jwt, toast, navigate),
  ]);

export const fetchUserRelationDataAndUser = (jwt, toast, navigate, errorCallback) =>
  Promise.all([
    customFindManyCountries({ jwt, toast, navigate }),
    customFindManyBranches({ jwt, toast, navigate }),
    customFindManyDepartments({ jwt, toast, navigate }),
    customFindManyExpertises({ jwt, toast, navigate }),
    customFindManyCompany({ jwt, toast, navigate }),
    fetchAllUsers(
      { jwt, toast, navigate },
      { errorCallback, pageSize: DEFAULT_ENTRIES_PER_PAGE, page: 1 },
    ),
  ]);

export const fetchBranchesListRelationAndBranches = (
  jwt,
  toast,
  navigate,
  errorCallback,
  page,
  filters,
  order,
  entriesPerPage,
) =>
  Promise.all([
    fetchAllBranchesList(jwt, toast, navigate, errorCallback, page, filters, order, entriesPerPage),
    customFindManyCountries({ jwt, toast, navigate }),
    customFindManyCompany({ jwt, toast, navigate }),
    customFindManyCity(jwt, toast, navigate),
    findManyCertificates(jwt, toast, navigate),
  ]);
