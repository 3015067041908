import axios from 'axios';
import qs from 'qs';
import { PATH_COMMENTS } from './utils/path';
import { getHeaders } from './common';
import { catchError } from './utils/catch-error';
import { maxLimit } from './utils/common';

export const fetchComments = (jwt, toast, navigate, filters, fields, populate, sort) => {
  const query = qs.stringify({
    filters,
    fields,
    populate,
    sort,
    pagination: {
      pageSize: maxLimit,
      page: 1,
    },
  });
  return axios
    .get(`${PATH_COMMENTS}?${query}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};

export const countComments = (jwt, toast, navigate, resource, resourceId) => {
  const query = qs.stringify({
    resource,
    resource_id: resourceId,
  });
  return axios
    .get(`${PATH_COMMENTS}/getCount?${query}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};

export const postComment = (
  jwt,
  toast,
  navigate,
  resourceId,
  resource,
  authorUser,
  content,
  threadOf = null,
) => {
  const data = {
    data: {
      users_permissions_user: +authorUser,
      content,
      thread_of: threadOf,
      resource,
      resource_id: +resourceId,
    },
  };
  return axios
    .post(`${PATH_COMMENTS}`, data, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};

export const updateComment = (
  jwt,
  toast,
  navigate,
  commentId,
  resourceId,
  resource,
  authorUser,
  content,
  threadOf = null,
) => {
  const data = {
    data: {
      users_permissions_user: +authorUser,
      content,
      thread_of: threadOf,
      resource,
      resource_id: +resourceId,
    },
  };
  return axios
    .put(`${PATH_COMMENTS}/${commentId}`, data, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};

export const deleteComment = (jwt, toast, navigate, postId, authorUser, commentId) => {
  return axios
    .delete(`${PATH_COMMENTS}/${commentId}`, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
};
