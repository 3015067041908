import axios from 'axios';
import qs from 'qs';
import { parseLanguageCode } from '../helpers/languageHelper';
import { getHeaders } from './common';
import { PATH_BANNER } from './utils/path';

export const fetchBanner = (jwt, language, callback) =>
  axios
    .get(
      `${PATH_BANNER}?${qs.stringify({
        fields: ['alertText', 'show', 'type'],
        locale: parseLanguageCode(language),
      })}`,
      getHeaders(jwt),
    )
    .then((response) => {
      if (response.data.error) {
        // eslint-disable-next-line no-console
        console.warn(response.data.error);
      } else {
        callback?.(response);
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.warn(error);
    });
