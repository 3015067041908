import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import { EditorState, convertFromRaw } from 'draft-js';
import { filter, get, isEmpty, map } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';
import { fetchAllUsers } from '../../api';
import { deleteComment, postComment, updateComment } from '../../api/comments';
import { SingleComment } from '../../components/comments/single-comment';
import {
  COMMENTS_SORT_LIKED,
  COMMENTS_SORT_NEWEST,
  COMMENTS_SORT_OLDEST,
  HORIZONTAL_PADDING,
} from '../../constants';
import { rawContent } from '../../helpers/contentHelper';
import { RichEditor } from '../editor';

import { reactSelectTheme } from '../../theme';
import './sort.css';

/**
 * @typedef {object} CommentsProps
 * @property {any[]} comments
 * @property {(any) => Promise<void>} reload
 * @property {(any) => Promise<void>} loadLikes
 * @property {string} resource
 * @property {string} resourceId
 * @property {any} ref
 * @property {boolean=} editable
 * @property {boolean=} repliable
 * @property {boolean=} removable
 * @property {boolean=} closeable
 * @property {boolean=} imageable
 * @property {boolean=} sortable
 * @property {string=} yourCommentLabel
 * @property {string=} commentsLabel
 * @property {boolean=} commentsCollapsed
 * @property {string=} replyEditorPlacement
 * @property {string=} scrollToId
 */

/**
 * @template T
 * @typedef {import('react').ForwardRefExoticComponent<import('react').PropsWithChildren<CommentsProps> & import('react').RefAttributes<T>>} ForwardRefComponent
 */

/**
 * @type {ForwardRefComponent<HTMLDivElement>}
 */
export const Comments = forwardRef(
  (
    {
      comments,
      reload,
      resource,
      resourceId,
      editable,
      repliable = true,
      removable,
      closeable,
      loadLikes,
      sortable,
      yourCommentLabel,
      commentsLabel,
      commentsCollapsed = true,
      replyEditorPlacement = 'top',
      imageable = false,
      scrollToId,
    },
    ref,
  ) => {
    const replyRef = useRef();
    const richEditorRef = useRef(null);
    const commentEditorRef = useRef(null);
    const toast = useToast();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { jwt, user } = useSelector((state) => state.auth);
    const [newComment, setNewComment] = useState(EditorState.createEmpty());
    const [threadOf, setThreadOf] = useState(null);
    const [reply, setReply] = useState(EditorState.createEmpty());
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState(null);
    const [editCandidate, setEditCandidate] = useState(null);
    const [users, setUsers] = useState();
    const [isCommentsCollapsed, setIsCommentsCollapsed] = useState(commentsCollapsed);
    const [isLoading, setIsLoading] = useState(true);
    const [sortList, setSortList] = useState([
      { id: COMMENTS_SORT_NEWEST, name: t('sorting.newest') },
      { id: COMMENTS_SORT_OLDEST, name: t('sorting.oldest') },
      { id: COMMENTS_SORT_LIKED, name: t('sorting.liked') },
    ]);
    const [defaultSorted, setDefaultSorted] = useState(
      JSON.parse(localStorage.getItem('commentSort')) ?? {
        id: COMMENTS_SORT_NEWEST,
        name: t('sorting.newest'),
      },
    );

    useImperativeHandle(
      ref,
      () => ({
        setFocus: () => {
          richEditorRef?.current?.setFocus();
        },
        scrollTo: () => {
          richEditorRef?.current?.scrollTo();
        },
      }),
      [],
    );

    const fetchUsers = async () => {
      const { data } = await fetchAllUsers({ jwt, toast, navigate });
      setUsers(data.users);
    };

    useEffect(() => {
      const initCommentView = async () => {
        await fetchUsers();
        // note: timeout is required for the editor, mentions are not working without it
        setTimeout(() => {
          setIsLoading(false);
        });
      };
      initCommentView();
    }, []);

    const changeThreadOf = (id = null) => {
      setEditCandidate(null);
      setThreadOf(id);
    };

    const toggleEditCandidateContent = (comment) => {
      setThreadOf(null);
      if (editCandidate) {
        setEditCandidate(null);
      } else {
        setEditCandidate({
          id: comment.id,
          content: EditorState.createWithContent(convertFromRaw(JSON.parse(comment.content))),
        });
      }
    };

    const onEditedCommentSave = (comment) => {
      updateComment(
        jwt,
        toast,
        navigate,
        comment.id,
        resourceId,
        resource,
        user.id,
        commentEditorRef.current.getDBFormatContent(editCandidate.content),
        comment.thread_of,
      ).then(() => {
        reload(resourceId);
        setEditCandidate(null);
        commentEditorRef.current?.setUploadedImages([]);
        commentEditorRef.current?.setEditorState(EditorState.createEmpty());
      });
    };

    const closeDeleteModal = () => {
      setDeleteCandidate(null);
      setIsDeleteModalOpen(false);
    };

    const deleteModal = () => (
      <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('comments.removing-comment')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight='bold' fontSize='xl'>
              {t('common.are-you-sure')}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={closeDeleteModal}>
              {t('common.no')}
            </Button>
            <Button
              variant='ghost'
              onClick={() => {
                deleteComment(jwt, toast, navigate, resourceId, user.id, deleteCandidate).then(() =>
                  reload(resourceId),
                );
                closeDeleteModal();
              }}
            >
              {t('common.yes')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );

    const isContentEmpty = (content) => {
      return isEmpty(rawContent(content)) || rawContent(content) === '\n';
    };

    const onReplyCancel = () => {
      setThreadOf(null);
      setReply(EditorState.createEmpty());
    };

    const onReplySave = (comment) => {
      postComment(
        jwt,
        toast,
        navigate,
        resourceId,
        resource,
        user.id,
        richEditorRef.current.getDBFormatContent(reply),
        comment.id,
      ).then(() => {
        reload(resourceId);
        setThreadOf(null);
        setReply(EditorState.createEmpty());
        setIsDeleteModalOpen(false);
      });
    };

    const editorButtons = (comment, content, onSave, onCancel) => (
      <Box alignSelf='flex-end'>
        <Button size='sm' variant='ZGreenSecondary' alignSelf='flex-end' mr={2} onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button
          size='sm'
          colorScheme='green'
          alignSelf='flex-end'
          isDisabled={isContentEmpty(content)}
          onClick={onSave}
        >
          {t('common.ok')}
        </Button>
      </Box>
    );

    let commentsHeader;
    if (comments && comments.length > 0) {
      commentsHeader = (
        <Flex mt={8} mb={4} alignItems={'center'}>
          <Heading size='md' color='brandColors.brandGreen'>
            {commentsLabel || t('comments.comments', { nb: comments.length })}
          </Heading>
          <Spacer />
          <HStack>
            <label style={{ textWrap: 'nowrap' }} htmlFor='commentsSortList'>
              {t('knowledge-base.sort-by')}
            </label>
            <Select
              name='sortList'
              className={'sortlist'}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={t('common.select-branch')}
              defaultValue={defaultSorted}
              options={sortList}
              styles={reactSelectTheme}
              inputId='commentsSortList'
              onChange={(newValue) => {
                localStorage.setItem('commentSort', JSON.stringify(newValue));
                reload(resourceId);
              }}
            />
          </HStack>
        </Flex>
      );
    }

    const replyEditor = (
      <>
        <Heading pe={HORIZONTAL_PADDING} mt={8} mb={4} size='md' color='brandColors.brandGreen'>
          {yourCommentLabel || t('comments.your-comment')}
        </Heading>
        <Flex pe={HORIZONTAL_PADDING} flexDirection='column' mb={12}>
          <Flex flexDirection='row' gap={4} mb={4}>
            <Avatar src={get(user, 'imageUrl')} />
            <Box minH='150px' width='100%' id='comment'>
              {!isLoading && (
                <RichEditor
                  content={newComment}
                  setContent={(content) => setNewComment(content)}
                  users={users}
                  ref={richEditorRef}
                  imageUpload={imageable}
                />
              )}
            </Box>
          </Flex>
          <Button
            isDisabled={isContentEmpty(newComment)}
            colorScheme='green'
            size='sm'
            alignSelf='flex-end'
            onClick={() => {
              postComment(
                jwt,
                toast,
                navigate,
                resourceId,
                resource,
                user.id,
                richEditorRef.current.getDBFormatContent(newComment),
              ).then((data) => {
                reload(resourceId, data?.data?.result?.data?.id);
                setNewComment(EditorState.createEmpty());
                richEditorRef.current?.setUploadedImages([]);
                richEditorRef.current?.setEditorState(EditorState.createEmpty());
              });
            }}
          >
            {t('comments.publish')}
          </Button>
        </Flex>
      </>
    );

    return (
      <Box>
        <Flex flexDirection='column'>
          {replyEditorPlacement !== 'bottom' && repliable && replyEditor}

          {commentsHeader}

          {map(
            comments,
            (comment, index) =>
              (isCommentsCollapsed ? index < 4 : index > -1) &&
              !comment.thread_of && (
                <Box key={comment.id}>
                  <SingleComment
                    jwt={jwt}
                    toast={toast}
                    navigate={navigate}
                    comment={comment}
                    loadLikes={loadLikes}
                    userId={user.id}
                    editCandidate={editCandidate}
                    ref={commentEditorRef}
                    users={users}
                    editable={editable}
                    repliable={repliable}
                    removable={removable}
                    closeable={closeable}
                    imageable={imageable}
                    setEditCandidate={setEditCandidate}
                    onEditedCommentSave={onEditedCommentSave}
                    toggleEditCandidateContent={toggleEditCandidateContent}
                    setThreadOf={changeThreadOf}
                    scrollToId={scrollToId}
                  />
                  {threadOf === comment.id && (
                    <Flex flexDirection='column' ml={14}>
                      <Flex flexDirection='row' mb={4}>
                        <Avatar src={get(user, 'imageUrl')} />
                        <Box minH='150px' width='100%' ml={2}>
                          <RichEditor
                            content={reply}
                            setContent={(content) => setReply(content)}
                            users={users}
                            ref={replyRef}
                            imageUpload={imageable}
                            autoFocus
                          />
                        </Box>
                      </Flex>
                      {editorButtons(comment, reply, () => onReplySave(comment), onReplyCancel)}
                    </Flex>
                  )}
                  <Grid
                    borderBottomStyle={'solid'}
                    borderBottomColor={'gray.200'}
                    borderBottomWidth={'1px'}
                    gridTemplateColumns={'var(--chakra-sizes-14) 1fr'}
                    gap={0}
                  >
                    <Divider
                      orientation='vertical'
                      borderColor={'gray.200'}
                      borderLeftWidth={'4px'}
                      margin={'auto'}
                      h={'calc(100% - var(--chakra-sizes-14))'}
                    />

                    <Box gap={0}>
                      {map(
                        filter(comments, (replyData) => comment.id === replyData.thread_of),
                        (replyData, index, filtered) => (
                          <Box key={replyData.id}>
                            <SingleComment
                              jwt={jwt}
                              toast={toast}
                              navigate={navigate}
                              comment={replyData}
                              loadLikes={loadLikes}
                              userId={user.id}
                              editCandidate={editCandidate}
                              ref={commentEditorRef}
                              users={users}
                              editable={editable}
                              repliable={repliable}
                              removable={removable}
                              closeable={closeable}
                              imageable={imageable}
                              setEditCandidate={setEditCandidate}
                              onEditedCommentSave={onEditedCommentSave}
                              toggleEditCandidateContent={toggleEditCandidateContent}
                              setThreadOf={changeThreadOf}
                              scrollToId={scrollToId}
                              withBorder={index < filtered.length - 1}
                            />
                          </Box>
                        ),
                      )}
                    </Box>
                  </Grid>
                </Box>
              ),
          )}
          {isCommentsCollapsed && comments.length > 4 && (
            <Text
              mb={2}
              fontSize='small'
              fontWeight='bold'
              color='gray.550'
              cursor='pointer'
              onClick={() => setIsCommentsCollapsed(false)}
            >
              {t('comments.see-more')} ({comments.length - 4})
            </Text>
          )}

          {replyEditorPlacement === 'bottom' && repliable && replyEditor}
        </Flex>
        {deleteModal(deleteCandidate)}
      </Box>
    );
  },
);

Comments.displayName = 'Comments';
