import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  isInError: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    ...INIT_STATE,
  },
  reducers: {
    setError: (state, action) => {
      state.isInError = action.payload;
    },
  },
});

export const { setError: setErrorAction } = errorSlice.actions;
