import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { camelCase } from 'lodash';
import { IfElse, ErrorHandleWrapper } from '../../components-common/if';
import { SpinnerScreen } from '../../components-common/spinner-screen';
import { fetchSingleSubSubCategory } from '../../api/categories';
import { strapiDataParserSingle } from '../../common/strapi';
import { parsedContent } from '../../helpers/contentHelper';
import { setErrorAction } from '../../redux';

export const SubSubCategoryView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jwt } = useSelector((state) => state.auth);
  const { activeSubSubCategory } = useSelector((state) => state.categories);
  const [isLoading, setIsLoading] = useState(true);
  const [subSubCategory, setSubSubCategory] = useState(null);

  const fetchCategory = () => {
    setIsLoading(true);
    fetchSingleSubSubCategory(jwt, activeSubSubCategory, navigate, (value) =>
      dispatch(setErrorAction(value)),
    ).then((response) => {
      setSubSubCategory(strapiDataParserSingle(response));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (activeSubSubCategory) {
      fetchCategory();
    }
  }, [activeSubSubCategory]);

  if (!subSubCategory) {
    return <Box />;
  }

  return (
    <ErrorHandleWrapper>
      <Flex mt={16} data-testid={`${camelCase(`about${subSubCategory.title}`)}Content`}>
        <IfElse condition={isLoading}>
          <SpinnerScreen />
          {subSubCategory && (
            <Box>
              <Heading mb={4} variant='xlSmall' _hover={{ color: 'ZLGreen.600' }}>
                {subSubCategory.title}
              </Heading>
              {subSubCategory.content && (
                <Box dangerouslySetInnerHTML={{ __html: parsedContent(subSubCategory.content) }} />
              )}
            </Box>
          )}
        </IfElse>
      </Flex>
    </ErrorHandleWrapper>
  );
};
