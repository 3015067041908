import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToast, Grid, Text, Heading, Center, GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { UserDataAll } from '../../components-common/user-data';

import {
  customFindManyBranches,
  customFindManyCompany,
  customFindManyCountries,
  customFindManyDepartments,
  customFindManyExpertises,
  fetchAllUsers,
  fieldsAllUsers,
  populateAllUsers,
} from '../../api';
import { Pagination } from '../../components-common/pagination';
import { Helmet } from '../../components-common/helmet';
import {
  DEFAULT_ENTRIES_PER_PAGE,
  FILTER_MENU_GAP_COL,
  FILTER_MENU_GAP_ROW,
  FILTER_MENU_WIDTH,
  MAIN_CONTENT_POSITION,
  PAGINATION_POSITION,
} from '../../constants';
import { ErrorHandleWrapper } from '../../components-common/if';
import { setErrorAction } from '../../redux';
import { FiltersProvider } from '../../components-common/filters/filters-provider';
import { InputAndLabel } from '../../components-common/inputs/input-label';
import { SelectDataStrapi } from '../../components-common/inputs/select-data-strapi';
import { SpinnerScreen } from '../../components-common/spinner-screen';

export const UsersListView = () => {
  const { t, i18n } = useTranslation();
  const filterRef = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jwt } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [expertisesList, setExpertisesList] = useState([]);
  const [pagination, setPagination] = useState({ page: 1 });
  const [entriesPerPage, setEntriesPerPage] = useState(DEFAULT_ENTRIES_PER_PAGE);
  const [defaultFilters, setDefaultFilters] = useState();

  useEffect(() => {
    const initUserList = async () => {
      await Promise.all([
        fetchFilteredUsers(pagination.page, entriesPerPage),
        fetchCompanies(),
        fetchCountries(),
        fetchBranches(''),
        fetchDepartments(),
        fetchDepartments(),
        fetchExpertises(),
      ]);
    };

    initUserList();
  }, [jwt, i18n]);

  const fetchFilteredUsers = async (currentPage, pageSize = entriesPerPage) => {
    const filters = filterRef.current?.getFilterValues();

    const { data } = await fetchAllUsers(
      { jwt, toast, navigate },
      {
        errorCallback: (value) => dispatch(setErrorAction(value)),
        filters,
        fields: fieldsAllUsers,
        populate: populateAllUsers,
        pageSize,
        page: currentPage,
      },
    );
    setPagination(data.pagination);
    setUsersList(data.users);
  };

  const search = async (page = 1, pageSize = entriesPerPage) => {
    setIsLoading(true);
    await fetchFilteredUsers(page, pageSize);
    setIsLoading(false);
  };

  const fetchCompanies = () => {
    customFindManyCompany({ jwt, toast, navigate }).then((companies) => {
      setCompaniesList(companies);
    });
  };

  const fetchCountries = () => {
    customFindManyCountries({ jwt, toast, navigate }).then((countries) => {
      setCountriesList(countries);
    });
  };

  const fetchBranches = (country) => {
    let countryFilter = {};
    if (country !== null && country !== '') {
      countryFilter = { country: { id: country.id } };
    }
    customFindManyBranches({ jwt, toast, navigate }, countryFilter).then((branches) => {
      setBranchesList(branches);
    });
  };

  const fetchDepartments = () => {
    customFindManyDepartments({ jwt, toast, navigate }).then((departments) => {
      setDepartmentsList(departments);
    });
  };

  const fetchExpertises = () => {
    customFindManyExpertises({ jwt, toast, navigate }).then((expertises) => {
      setExpertisesList(expertises);
    });
  };

  let userListBody;
  if (isLoading) {
    userListBody = <SpinnerScreen />;
  } else if (usersList.length > 0) {
    userListBody = (
      <Grid
        w='99%'
        gridTemplateColumns='repeat(auto-fill, minmax(400px, 1fr))'
        gap={4}
        data-testid='userList'
      >
        {usersList.map((user) => (
          <Fragment key={user.id}>
            <UserDataAll
              p={6}
              boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
              border='solid 1px'
              borderColor='blackAlpha.200'
              borderRadius='5px'
              user={user}
              propsBody={{
                pt: 0,
                pl: 16,
                ml: 1,
              }}
            />
          </Fragment>
        ))}
      </Grid>
    );
  } else {
    userListBody = (
      <Center height='100%'>
        <Text variant='2xl'>{t('common.no-result')}</Text>
      </Center>
    );
  }

  return (
    <ErrorHandleWrapper>
      <Helmet title={t('title.users')} data-testid='userHeader' />

      <Grid
        templateAreas={`"header header"
                    "nav main"
                    `}
        gridTemplateRows={'auto 1fr'}
        gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
        h='100%'
        columnGap={FILTER_MENU_GAP_COL}
      >
        <Grid
          style={{ gridArea: 'header' }}
          templateAreas={`"title pagination"`}
          gridTemplateColumns={`${FILTER_MENU_WIDTH} 1fr`}
          h='100%'
          columnGap={FILTER_MENU_GAP_COL}
          position={'sticky'}
          top={PAGINATION_POSITION}
          paddingY={FILTER_MENU_GAP_ROW}
          bgColor={'white'}
          zIndex={98}
        >
          <GridItem area={'title'} alignSelf={'center'} w='100%'>
            <Heading variant='mdLarge' color='brandColors.brandGreen'>
              {t('common.user')}
            </Heading>
          </GridItem>
          <GridItem area={'pagination'} alignItems={'center'}>
            <Pagination
              entriesPerPage={entriesPerPage}
              setEntriesPerPage={setEntriesPerPage}
              activePage={pagination?.page}
              changePageCallback={async (val, entriesPerPageValue) =>
                search(val, entriesPerPageValue)
              }
              pages={pagination?.pageCount}
              testId='usersTop'
            />
          </GridItem>
        </Grid>

        <GridItem
          area={'nav'}
          position={'sticky'}
          top={MAIN_CONTENT_POSITION}
          h={`calc(100vh - ${MAIN_CONTENT_POSITION})`}
          w='100%'
          overflowY={'auto'}
        >
          <FiltersProvider ref={filterRef} handleSearch={search} defaultValues={defaultFilters}>
            <InputAndLabel
              label={t('common.full-name')}
              name='fullName'
              testId='usersFullName'
              placeholder={`${t('common.search')}...`}
            />
            <InputAndLabel
              label={t('common.email')}
              name='email'
              testId='usersEmail'
              placeholder={`${t('common.search')}...`}
            />
            <SelectDataStrapi
              testId='usersOrg'
              label={t('common.organization')}
              placeholder={t('common.select-organization')}
              name='company'
              dataset={companiesList}
              getOptionLabel={(data) => {
                return data.companyName;
              }}
              getOptionValue={(data) => {
                return !data.id ? data.companyName : data.id;
              }}
            />
            <SelectDataStrapi
              testId='usersCountry'
              label={t('common.country')}
              placeholder={t('common.select-country')}
              name='country'
              dataset={countriesList}
              getOptionLabel={(data) => {
                return data.countryName;
              }}
              getOptionValue={(data) => {
                return !data.id ? data.countryName : data.id;
              }}
              onChangeFilter={(e) => {
                filterRef.current.setValueFilter('country', e);
                filterRef.current.setValueFilter('branch', null);
                fetchBranches(e);
              }}
            />
            <SelectDataStrapi
              testId='usersBranch'
              label={t('common.branch')}
              placeholder={t('common.select-branch')}
              name='branch'
              dataset={branchesList}
              getOptionLabel={(data) => {
                return data.branchName;
              }}
              getOptionValue={(data) => {
                return !data.id ? data.branchName : data.id;
              }}
            />
            <SelectDataStrapi
              testId='usersDept'
              label={t('common.department')}
              placeholder={t('common.select-department')}
              name='department'
              dataset={departmentsList}
              getOptionLabel={(data) => {
                return data.departmentName;
              }}
              getOptionValue={(data) => {
                return !data.id ? data.departmentName : data.id;
              }}
            />
            <SelectDataStrapi
              testId='usersExpertise'
              label={t('common.expertise')}
              placeholder={t('common.select-expertise')}
              name='ekspertise'
              dataset={expertisesList}
              getOptionLabel={(data) => {
                return data.expertiseName;
              }}
              getOptionValue={(data) => {
                return !data.id ? data.expertiseName : data.id;
              }}
            />
          </FiltersProvider>
        </GridItem>
        <GridItem area={'main'} pb={12}>
          {userListBody}
        </GridItem>
      </Grid>
    </ErrorHandleWrapper>
  );
};
