import axios from 'axios';
import { PATH_FIND_MANY_CERTIFICATES } from './utils/path';
import { getHeaders } from './common';
import { catchError } from './utils/catch-error';

export const findManyCertificates = async (jwt, toast, navigate) => {
  const response = await axios
    .get(PATH_FIND_MANY_CERTIFICATES, getHeaders(jwt))
    .catch((error) => catchError(error, toast, navigate));
  return response.data;
};
